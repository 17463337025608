import { Button, InputGroupItem } from "@patternfly/react-core";
import { useCallback } from "react";

import './appended-button.css';

interface AppendedButtonProps {
    onClick: () => void;
}

const AppendedButton = (props: AppendedButtonProps) => {
    const { onClick } = props;

    const handleClick = useCallback(() => {
        onClick();
    }, [onClick]);

    return (
        <InputGroupItem>
            <Button className="appended-field-button" variant="control" onClick={handleClick}>...</Button>
        </InputGroupItem>
    );
}

export default AppendedButton;
