import React, { useCallback, useEffect, useState } from 'react';
import FormSelect from '../../../components/form-select';

interface MachineTypeSelectProps {
    value: string;
    onSelect: (value: string) => void,
}

type SupportedMachine = { value: string, label: string };

const supportedMachines: SupportedMachine[] = [
    { label: 'Varian TrueBeam / TrueBeam STx', value: 'varian truebeam' },
    { label: 'Varian Halcyon', value: 'varian halcyon' },
    { label: 'Elekta Agility MLC', value: 'elekta versahd' },
    { label: 'Elekta Unity', value: 'elekta unity' },
];

// const selectItems = supportedMachines.map(supportedMachine => ({ label: supportedMachine, value: supportedMachine }));

const MachineTypeSelect = (props: MachineTypeSelectProps) => {

    const { value, onSelect } = props;

    const [currentValue, setCurrentValue] = useState(supportedMachines[0]);

    useEffect(() => {
        // sets current value
        const valueLowercase = value.toLocaleLowerCase();
        const matchingMachine = supportedMachines.find(m => m.value === valueLowercase);
        if (matchingMachine) {
            setCurrentValue(matchingMachine);
        }

    }, [value]);

    const handleSelect = useCallback((value: string) => {
        onSelect(value);
    }, [onSelect]);

    return (<FormSelect
        items={supportedMachines}
        selectedValue={currentValue.value}
        onSelect={handleSelect}
        className="machine-type-select"
    />);
}

export default MachineTypeSelect;
