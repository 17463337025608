import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../customization-page.css';
import { CustomizationObjectType, ModelType } from '../../../store/global-types/customization-types';
import { MVisionAppClient } from '../../../store/configurationTarget/mvision-client-list';
import { StoreState } from '../../../store/store';
import { advancedRoiCustomizationAdded, allCustomizationChangesReverted, contouringSelectors, customizationBaseDuplicated, customizationBaseRemoved, customizationOutputDuplicated, customizationOutputRemoved, modelCustomizationRenamed, modelCustomizationSaved, singleModelCustomizationReset } from '../../../store/contouring/contouringSlice';
import CustomizationToolbar from '../CustomizationToolbar';

interface ContourCustomizationToolbarProps {
    customizationOutputId?: string,
    currentView: CustomizationObjectType,
}

const ContourCustomizationToolbar = (props: ContourCustomizationToolbarProps) => {
    const { customizationOutputId, currentView } = props;

    const dispatch = useDispatch();

    const modelCustomizationSaveError = useSelector((state: StoreState) => state.contouring.modelCustomizationSaveError);
    const modelCustomizationDataError = useSelector((state: StoreState) => state.contouring.modelCustomizationDataError);
    const isSavingInProgress = useSelector((state: StoreState) => state.contouring.isModelCustomizationSavingInProgress);

    // needed for duplicated model name validation
    const allCustomizationNamesAndModelIds = useSelector(contouringSelectors.selectAllCustomizationNamesAndParentModelIds);

    const isAnyCustomizationModelModified = useSelector(contouringSelectors.selectIsAnyCustomizationModelModified);

    const customizationOutput = useSelector((state: StoreState) => customizationOutputId ? contouringSelectors.selectOutputById(state, customizationOutputId) : undefined);
    const customizationBase = useSelector((state: StoreState) => customizationOutput ? contouringSelectors.selectCustomizationBaseById(state, customizationOutput.modelCustomizationBaseId) : undefined);
    const segmentationModel = useSelector((state: StoreState) => customizationBase ? contouringSelectors.selectModelById(state, customizationBase.modelId) : undefined);
    const isCustomizationOutputResetInProgress = useSelector((state: StoreState) => state.contouring.isCustomizationOutputResetInProgress);

    const handleUndo = useCallback(() => {
        dispatch(allCustomizationChangesReverted());
    }, [dispatch]);

    const handleSave = useCallback((currentTarget: MVisionAppClient) => {
        dispatch(modelCustomizationSaved(currentTarget));
    }, [dispatch]);

    const handleAddAdvancedStructure = useCallback((customizationOutputId: string, newRoiId: string) => {
        dispatch(advancedRoiCustomizationAdded({ outputId: customizationOutputId, newRoiId: newRoiId }));
    }, [dispatch]);

    const handleAddNewCustomization = useCallback((customizationBaseId: string, newCustomizationName: string) => {
        dispatch(customizationBaseDuplicated({ customizationBaseId, newCustomizationName }));
    }, [dispatch]);

    const handleAddNewOutput = useCallback((customizationOutputId: string, newFilename: string) => {
        dispatch(customizationOutputDuplicated({ customizationOutputId, newFilename }));
    }, [dispatch]);

    const handleCustomizationDelete = useCallback((customizationBaseId: string) => {
        dispatch(customizationBaseRemoved(customizationBaseId));
    }, [dispatch]);

    const handleOutputDelete = useCallback((customizationOutputId: string) => {
        dispatch(customizationOutputRemoved(customizationOutputId));
    }, [dispatch]);

    const handleOutputReset = useCallback((customizationOutputId: string) => {
        dispatch(singleModelCustomizationReset(customizationOutputId));
    }, [dispatch]);

    const handleRenameCustomization = useCallback((customizationBaseId: string, newCustomizationName: string) => {
        dispatch(modelCustomizationRenamed({ customizationBaseId, name: newCustomizationName }));
    }, [dispatch]);

    return (
        <CustomizationToolbar
            currentView={currentView}
            customizationOutputId={customizationOutputId}
            modelType={ModelType.Contouring}

            allCustomizationNamesAndModelIds={allCustomizationNamesAndModelIds}
            isAnyCustomizationModelModified={isAnyCustomizationModelModified}
            customizationOutput={customizationOutput}
            customizationBase={customizationBase}
            model={segmentationModel}

            modelCustomizationSaveError={modelCustomizationSaveError}
            modelCustomizationDataError={modelCustomizationDataError}
            isSavingInProgress={isSavingInProgress}
            isCustomizationResetInProgress={false}
            isCustomizationOutputResetInProgress={isCustomizationOutputResetInProgress}

            onUndo={handleUndo}
            onSave={handleSave}
            onAddNewCustomization={handleAddNewCustomization}
            onAddNewOutput={handleAddNewOutput}
            onDeleteCustomization={handleCustomizationDelete}
            onDeleteOutput={handleOutputDelete}
            onResetOutput={handleOutputReset}
            onAddAdvancedStructure={handleAddAdvancedStructure}
            onRenameCustomization={handleRenameCustomization}
        />
    );
}

export default ContourCustomizationToolbar;
