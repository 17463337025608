import CockpitApi from "./web-apis/backend-clients/cockpit-api";
import { SystemFileQuery, createNewSystemFileQuery } from "./store/textEditor/text-editor-file";
import CloudApi from "./web-apis/backend-clients/cloud-api";
import { DeploymentMode } from "./store/appConfig/appDeploymentInfoTypes";
import LocalApi from "./web-apis/backend-clients/local-api";

export enum LoginType { None, AzureLogin };

/** Major configuration settings for the app, usually set based on current deployment environment (cloud, local) 
 * and production level (development, production, nonclinical, clinical, ...) */
export interface ConfigInterface {
    isInsideFrame: boolean;
    allowTextEditor: boolean;
    allowDashboard?: boolean;
    allowLicenseUpdate: boolean,
    showDebugInfoInUi?: boolean;
    /** TODO: handle showErrorsInUi better and in a consistent fashion. Currently it's always true and doesn't really cause trouble, but in the future it might. */
    showErrorsInUi?: boolean,
    loginType: LoginType,
    deploymentMode: DeploymentMode,
}

const localCockpitConfig: ConfigInterface = {
    isInsideFrame: true,
    allowTextEditor: true,
    allowLicenseUpdate: true,
    loginType: LoginType.None,
    deploymentMode: DeploymentMode.Cockpit,
};

const localWindowsConfig: ConfigInterface = {
    isInsideFrame: false,
    allowTextEditor: false,
    allowLicenseUpdate: true,
    loginType: LoginType.None,
    deploymentMode: DeploymentMode.Local,
};

const cloudConfig: ConfigInterface = {
    isInsideFrame: false,
    allowTextEditor: false,
    allowLicenseUpdate: false,
    loginType: LoginType.AzureLogin,
    deploymentMode: DeploymentMode.Cloud,
};

const defaultConfig = {
    showErrorsInUi: true,
    showDebugInfoInUi: false,
    allowDashboard: true,
};

/** Returns a client object for accessing backend API for matching config deployment mode. */
export function getBackendApi(config: ConfigInterface) {
    if (config.deploymentMode === DeploymentMode.Cloud) {
        return new CloudApi();
    } else if (config.deploymentMode === DeploymentMode.Cockpit) {
        return new CockpitApi();
    }  else if (config.deploymentMode === DeploymentMode.Local) {
        return new LocalApi();
    } else {
        throw new Error(`No valid backend API for deployment mode '${config.deploymentMode}'`);
    }
}

/** Gets matching major app configuration settings depending on current deployment mode (cloud or local environment). */
export function getConfigClient(deploymentMode: DeploymentMode): ConfigInterface {
    switch (deploymentMode) {
        case DeploymentMode.Cloud:
            return { ...defaultConfig, ...cloudConfig };
        case DeploymentMode.Cockpit:
            return { ...defaultConfig, ...localCockpitConfig };
        case DeploymentMode.Local:
            return { ...defaultConfig, ...localWindowsConfig };
        default:
            throw new Error(`No valid config interface for deployment mode '${deploymentMode}'`);
    }
}

export const getSystemFileQueries = (): SystemFileQuery[] => {

    // this environment tag can be used in system file queries to search for files specific to this particular
    // environment configuration (e.g. "clinical" or "nonclinical"). the value is read from manifest.json.
    const environmentTag = new CockpitApi().getEnvironmentTag();

    // SystemFileQuery constructor arguments: search path, allow writing, description shown in UI
    return [
        // customized: modelConfig/modelSelection (default files are inside container, so not accessible)
        createNewSystemFileQuery(`/etc/mvision/${environmentTag}/mvbackend/modelConfig.json`, true, 'Model customization. Consider using the Model Customization page instead of modifying this file directly.'),
        createNewSystemFileQuery(`/etc/mvision/${environmentTag}/mvbackend/modelSelection.json`, true, 'Model selection rules. Please see supplied documentation regarding adjusting model selection rules.'),

        // files containing MVision provided credentials
        createNewSystemFileQuery('/etc/mvision/private/sp.txt', true, 'MVision-provided credentials.'),
        createNewSystemFileQuery('/etc/mvision/private/key.txt', true, 'MVision-provided credentials.'),
        createNewSystemFileQuery('/etc/mvision/private/acr-access-id.txt', true, 'MVision-provided credentials.'),
        createNewSystemFileQuery('/etc/mvision/private/acr-access-key.txt', true, 'MVision-provided credentials.'),

        // daemon configs: for changing destination TPS, etc.
        createNewSystemFileQuery(`/srv/mvision/${environmentTag}/mvdaemon/options.json`, true, 'Daemon configuration.'),

        // details of the MVision containers
        createNewSystemFileQuery(`/srv/mvision/${environmentTag}/docker-compose.yml`, true, 'MVision container details.'),

        // linux config files: SOME files might be absent
        createNewSystemFileQuery('/etc/apt/sources.list', true, 'Linux configuration.'),
        createNewSystemFileQuery('/{lib,etc,run}/netplan/*.yaml', true, 'Network configuration.'),
        createNewSystemFileQuery(`/etc/cron.d/mvision-${environmentTag}-upgrades`, true, 'Unattended OS upgrades.'),
        createNewSystemFileQuery('/etc/apt/apt.conf.d/20auto-upgrades', true, 'Unattended OS upgrades.'),
        createNewSystemFileQuery('/etc/apt/apt.conf.d/50unattended-upgrades', true, 'Unattended OS upgrades.'),
        createNewSystemFileQuery('/etc/security/pwquality.conf', true, 'Linux configuration.'),
        createNewSystemFileQuery('/etc/profile.d/proxy.sh', true, 'Unattended OS upgrades.'),
        createNewSystemFileQuery('/etc/systemd/timesyncd.conf', true, 'NTP servers.'),
        createNewSystemFileQuery('/etc/systemd/timesyncd.conf.d/*.conf', true, 'NTP servers.'),
        createNewSystemFileQuery('/run/systemd/timesyncd.conf.d/*.conf', true, 'NTP servers.'),
        createNewSystemFileQuery('/usr/lib/systemd/timesyncd.conf.d/*.conf', true, 'NTP servers.'),
        createNewSystemFileQuery('/etc/docker/daemon.json', true, 'Reserved IP address pool.'),
    ];
}