import { Card, CardBody, CardTitle } from '@patternfly/react-core';
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import './UserSettings.css';
import { getUserName, MVisionAppClient } from "../../store/configurationTarget/mvision-client-list";
import { configurationTargetSelectors } from '../../store/configurationTarget/configurationTargetSlice';
import ConfigurationImportExport from "./ConfigurationImportExport";
import ConfigurationReset from './ConfigurationReset';
import { ModelType } from '../../store/global-types/customization-types';

interface ConfigurationTargetBoxDetailsProps {
    modelType: ModelType,
    isResetInProgress: boolean,
    importExportErrorMessage: string | null,
    onResetAllModelCustomizations: (configurationTarget: MVisionAppClient) => void,
    onExportModelCustomization: (configurationTarget: MVisionAppClient) => void,
    onImportUserData: (json: any) => Promise<boolean>,
}

const ConfigurationTargetBoxDetails = (props: ConfigurationTargetBoxDetailsProps) => {

    const { isResetInProgress, importExportErrorMessage, onResetAllModelCustomizations, onExportModelCustomization, onImportUserData, modelType } = props;

    const { t } = useTranslation();

    let modelTypeUiString = '';
    switch (modelType) {
        case ModelType.Contouring:
            modelTypeUiString = t('settings.modelType.contour');
            break;
        case ModelType.Dose:
            modelTypeUiString = t('settings.modelType.dose');
            break;
        default:
            throw new Error(`Unsupported model type: ${modelType}`);
    }

    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);

    return (
        <>
            <Card isFlat className="reset-to-factory-settings-box">
                {currentTarget ? (
                    <>
                        <CardTitle className="settings-box-title">
                            <Trans i18nKey="settings.configurationTarget.title">
                                <span className="configuration-target-model-type">{{ modelType: modelTypeUiString }}</span> settings for configuration target: <span className="configuration-target-box-name">{{ configurationTargetName: getUserName(currentTarget) }}</span>
                            </Trans>
                        </CardTitle>
                        <CardBody>

                            <ConfigurationReset
                                modelTypeUiString={modelTypeUiString}
                                isResetInProgress={isResetInProgress}
                                onResetAllModelCustomizations={onResetAllModelCustomizations}
                            />

                            <br />

                            <ConfigurationImportExport
                                modelTypeUiString={modelTypeUiString}
                                importExportErrorMessage={importExportErrorMessage}
                                onExportModelCustomization={onExportModelCustomization}
                                onImportUserData={onImportUserData}
                            />

                        </CardBody>
                    </>
                ) : (
                    <>
                        <CardTitle>{t('settings.configurationTarget.empty.title')}</CardTitle>
                        <CardBody>
                            <div>{t('settings.configurationTarget.empty.message')}</div>
                        </CardBody>
                    </>
                )}
            </Card>

        </>
    );

}

export default ConfigurationTargetBoxDetails;
