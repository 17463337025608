import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Tab, Tabs, TabTitleText } from "@patternfly/react-core";
import { useTranslation } from "react-i18next";

import { appConfigSelectors } from "../../store/appConfig/appConfigSlice";
import { SupportedModelType } from "../../store/appConfig/appDeploymentInfoTypes";
import { appStatusSelectors } from "../../store/appStatus/appStatusSlice";
import { ModelType } from "../../store/global-types/customization-types";

import './tabbed-supported-model-types.css';

const CONTOURING_TAB_KEY = 0;
const DOSE_TAB_KEY = 1;

export type ModelTypeContent = { modelType: ModelType, content: JSX.Element };

interface TabbedSupportedModelTypesProps {
    content: ModelTypeContent[],
    hasNoBorderBottom?: boolean | undefined,
}

/** Helper for getting correct content from props for given model type, or null if the content wasn't specified. */
const getContent = (modelType: ModelType, content: ModelTypeContent[]) => {
    const matchingContent = content.find(c => c.modelType === modelType);
    return matchingContent?.content || null;
}

/** Component for showing content in a tabbed interface based on enabled supported model types, or
 * hiding the tabs UI if only one model type is supported. Also defaults to opening a tab that matches
 * the latest model type that was interacted with. */
const TabbedSupportedModelTypes = (props: TabbedSupportedModelTypesProps) => {

    const { content, hasNoBorderBottom } = props;

    const { t } = useTranslation();

    const [activeTabKey, setActiveTabKey] = useState<string | number>(CONTOURING_TAB_KEY);

    const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);
    const supportedModelTypes = useMemo(() => deploymentInfo?.supportedModelTypes || [], [deploymentInfo]);
    const currentModelType = useSelector(appStatusSelectors.selectCurrentAppModelType);

    const useTabs = supportedModelTypes.length > 1;

    const isContourConfigSupported = useMemo(() => supportedModelTypes.includes(SupportedModelType.Contour) || false, [supportedModelTypes]);
    const isDoseConfigSupported = useMemo(() => supportedModelTypes.includes(SupportedModelType.Dose) || false, [supportedModelTypes]);

    const handleTabClick = useCallback((_: unknown, tabIndex: string | number) => { setActiveTabKey(tabIndex); }, [setActiveTabKey]);

    useEffect(() => {
        // pre-emptively open matching tab when latest model type user interacted with changes
        switch (currentModelType) {
            case ModelType.Contouring:
                setActiveTabKey(CONTOURING_TAB_KEY);
                break;
            case ModelType.Dose:
                setActiveTabKey(DOSE_TAB_KEY);
                break;
            default:
                break;
        }
    }, [currentModelType]);

    if (!useTabs) {
        if (isContourConfigSupported) {
            return getContent(ModelType.Contouring, content);
        } else if (isDoseConfigSupported) {
            return getContent(ModelType.Dose, content);
        } else {
            return null;
        }
    }

    return (
        <Tabs
            activeKey={activeTabKey}
            onSelect={handleTabClick}
            hasNoBorderBottom={hasNoBorderBottom}
            className="mv-tabs unselectable"
        >
            {isContourConfigSupported && (
                <Tab title={<TabTitleText>{(t('tab.contour'))}</TabTitleText>} eventKey={CONTOURING_TAB_KEY}>
                    {getContent(ModelType.Contouring, content)}
                </Tab>)}
            {isDoseConfigSupported && (
                <Tab title={<TabTitleText>{(t('tab.dose'))}</TabTitleText>} eventKey={DOSE_TAB_KEY}>
                    {getContent(ModelType.Dose, content)}
                </Tab>)}
        </Tabs>
    );
}

export default TabbedSupportedModelTypes;
