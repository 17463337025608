import BackendApiInterface, { CombinedVersionInfoJson, JsonValue, LabelJsonWrapper, QUERY_PARAM_SESSION_ID } from "../backend-api-interface";
import { get, has } from "lodash-es";
import { sleep } from "../../util/sleep";
import NoAuthPage from "../../pages/auth/NoAuthPage";
import { Dispatch } from "@reduxjs/toolkit";
import { MVisionAppClient } from "../../store/configurationTarget/mvision-client-list";
import loadExternalScript from "../../util/load-external-script";
import { DISPLAY_VERSION, getSessionId, getDefaultAppName } from "../../environments";
import { convertResponseToError, getErrorMessageFromBackendValidationErrorJson } from "../../util/errors";


// IMPORTANT: cockpit already appends http://localhost prefix to all URLs
import apiUrls, { DEFAULT_USER_ID, getLabelingUrl } from "./backend-urls";
import { configurationTargetListFetched } from "../../store/configurationTarget/configurationTargetSlice";
import { convertModelTypeToApiString, ModelType } from "../../store/global-types/customization-types";
import { DeploymentConfigInfo } from "../../store/appConfig/appDeploymentInfoTypes";

const maxRetryAttempts = 64;
const retryWaitInMilliseconds = 250;

let backendPort: string = '';
let environmentTag: string = '';

const BACKEND_RESPONSE_OK = '"OK"';

/**
 * Wrapper for accessing MVision Configuration functions through Cockpit API.
 * IMPORTANT: Accessing this class will crash if cockpit is not loaded into environment!
 */
export default class CockpitApi implements BackendApiInterface {

    public init(dispatch: Dispatch, deploymentInfo: DeploymentConfigInfo) {
        // set local values
        if (!deploymentInfo.cockpitBackendPort || !deploymentInfo.cockpitEnvironmentTag) {
            throw new Error('Invalid configuration -- cannot initialize local cockpit api');
        }

        backendPort = deploymentInfo.cockpitBackendPort;
        environmentTag = deploymentInfo.cockpitEnvironmentTag;

        // load cockpit API scripts if we're in a cockpit environment
        loadExternalScript('cockpit', '../base1/cockpit.js');

        // we don't need to do login here, but we do need to fetch the client list
        dispatch(configurationTargetListFetched());
    }

    private getErrorMessageFromError(obj: any): string {
        if (has(obj, 'detail')) {
            return `An error was received from backend: ${obj.detail}`;
        }
        else if (has(obj, 'message')) {
            // not sure why typescript wants to cast obj as never here, so we're forcibly casting it to any
            return `An error was received from backend: ${(obj as any).message}`;
        }
        else {
            return 'Received no valid response from backend.';;
        }
    }

    private async getHttpClient(): Promise<any> {
        // see https://cockpit-project.org/guide/latest/cockpit-http.html

        let client = null;

        let attempt = 0;
        while (client === null && attempt < maxRetryAttempts) {
            attempt++;
            try {
                client = (window as any).cockpit.http(backendPort);
            }
            catch (err) {
                if (err instanceof TypeError && (err as TypeError).message === 'window.cockpit.http is not a function') {
                    // wait a bit for the script to load and try again
                    await sleep(retryWaitInMilliseconds);
                }
                else {
                    console.log(err);
                    throw err;
                }
            }
        }

        if (client === null) {
            throw new Error('Could not load Cockpit HTTP client.');
        }

        return client;
    }

    private async doLocalCockpitHttpGet(cockpitClient: any, apiUrl: string, headers?: any) {
        let response = null;
        let errorMessage: string | null = null;
        let responseOk = false;

        // set compulsory mvision api headers
        let httpHeaders = headers || {};
        httpHeaders['appVersion'] = get(httpHeaders, 'appVersion', this.getAppVersion());
        httpHeaders['user-agent'] = get(httpHeaders, 'user-agent', this.getAppVersion());

        // append client ID to query parameters
        let queryParams: any = {};
        queryParams[QUERY_PARAM_SESSION_ID] = getSessionId();

        try {
            response = await cockpitClient.get(apiUrl, queryParams, httpHeaders);
            responseOk = true;
        }
        catch (ex) {
            responseOk = false;
            if (ex && get(ex, 'problem', '').includes('not-found')) {
                errorMessage = 'Could not connect to backend.';
            } else {
                console.error(ex);
                errorMessage = this.getErrorMessageFromError(ex);
            }
        }

        return { responseOk, response, errorMessage };
    }

    private async getAndParseJsonResponse(apiUrl: string, appClient: MVisionAppClient | undefined, headers?: any, maxRetries: number = 0): Promise<any> {
        const client = await this.getHttpClient();
        let errorMessage: string | null = null;
        let response = null;
        let currentRetry = 0;
        let responseOk = false;

        // set compulsory mvision api headers
        let httpHeaders: any = headers ? headers : {};
        if (appClient) { httpHeaders['userId'] = get(httpHeaders, 'userId', appClient.userId); }
        httpHeaders['appVersion'] = get(httpHeaders, 'appVersion', this.getAppVersion());
        httpHeaders['user-agent'] = this.getAppVersion();

        while (!responseOk && currentRetry <= maxRetries) {
            const apiCallResult = await this.doLocalCockpitHttpGet(client, apiUrl, httpHeaders);
            errorMessage = apiCallResult.errorMessage;
            responseOk = apiCallResult.responseOk;

            if (!apiCallResult.responseOk) {
                console.log(`Call to ${apiUrl} failed on retry #${currentRetry}/${maxRetries}`);
                await sleep(retryWaitInMilliseconds);
            }
            else {
                response = apiCallResult.response;
            }

            currentRetry++;
        }

        if (errorMessage) {
            throw new Error(errorMessage);
        }
        if (!response) {
            const message = `Received no valid response from backend.`;
            console.error(message);
            console.log(response);
            throw new Error(message);
        }

        return JSON.parse(response);
    }

    private async postJson(url: string, appClient: MVisionAppClient | undefined, headers?: any, json?: string): Promise<boolean> {
        const client = await this.getHttpClient();

        // set compulsory mvision api headers
        let httpHeaders: any = headers ? headers : {};
        httpHeaders['appVersion'] = get(httpHeaders, 'appVersion', this.getAppVersion());
        httpHeaders['user-agent'] = this.getAppVersion();
        if (appClient) {
            httpHeaders['userId'] = get(httpHeaders, 'userId', appClient.userId);
        }

        // append client ID to query parameters
        let queryParams: any = {};
        queryParams[QUERY_PARAM_SESSION_ID] = getSessionId();

        const requestOptions: any = {
            path: url,
            method: 'POST',
            params: queryParams,
            headers: httpHeaders,
        };

        if (json !== undefined) {
            requestOptions.body = json;
        }

        const response = client.request(requestOptions);

        // cockpit API: must call http.request.input() if request doesn't have BODY
        if (json === undefined) {
            response.input();
        }

        // Cockpit's http.request returns a Promise that we must handle with
        // then/catch instead of async/await so we get the second catch parameter
        // when needed in the promise reject case
        const result = await response
            .then((data: any) => {
                return data;;
            })
            .catch((ex: any, data: any) => {
                // return the received error data, we throw it
                // as an exception a bit later
                return data;
            });

        // handle the result from the response promise
        if (result === BACKEND_RESPONSE_OK) {
            return true;
        } else {
            const resultData = JSON.parse(result);
            const message = getErrorMessageFromBackendValidationErrorJson(resultData);

            if (json === undefined) {
                throw new Error(message);
            } else {
                throw await convertResponseToError(resultData, json, message);
            }
        }
    }

    private async delete(url: string, appClient: MVisionAppClient, headers?: any): Promise<boolean> {
        const client = await this.getHttpClient();

        // set compulsory mvision api headers
        let httpHeaders: any = headers ? headers : {};
        httpHeaders['appVersion'] = get(httpHeaders, 'appVersion', this.getAppVersion());
        httpHeaders['user-agent'] = this.getAppVersion();
        httpHeaders['userId'] = get(httpHeaders, 'userId', appClient.userId);

        // append client ID to query parameters
        let queryParams: any = {};
        queryParams[QUERY_PARAM_SESSION_ID] = getSessionId();

        const response = await client.request({
            path: url,
            method: 'DELETE',
            params: queryParams,
            headers: httpHeaders,
        }).input();

        if (!response) {
            console.error(response);
            throw new Error(`Received no valid response from backend`);
        }

        return response === BACKEND_RESPONSE_OK;
    }

    public async fetchContouringCustomizationAsync(appClient: MVisionAppClient | undefined): Promise<any> {
        this.checkAppClientIsValid(appClient);

        return await this.getAndParseJsonResponse(apiUrls.contouringConfig, appClient, undefined, 25);
    }

    public async saveContouringCustomizationAsync(appClient: MVisionAppClient | undefined, json: string): Promise<boolean> {
        this.checkAppClientIsValid(appClient);

        return await this.postJson(apiUrls.contouringConfig, appClient, undefined, json);
    }

    public async deleteContouringCustomizationAsync(appClient: MVisionAppClient | undefined): Promise<boolean> {
        this.checkAppClientIsValid(appClient);

        return await this.delete(apiUrls.contouringConfig, appClient);
    }

    /** LOCAL: Returns default configuration for a single contouring model */
    public async fetchDefaultConfigurationForContouringModelAsync(modelName: string): Promise<any> {
        return await this.getAndParseJsonResponse(apiUrls.makeGetDefaultConfigForContourModelUrl(modelName), undefined, { userId: DEFAULT_USER_ID });
    }

    public async fetchDoseCustomizationAsync(appClient: MVisionAppClient | undefined): Promise<any> {
        this.checkAppClientIsValid(appClient);

        return await this.getAndParseJsonResponse(apiUrls.doseConfig, appClient, undefined, 25);
    }

    public async saveDoseCustomizationAsync(appClient: MVisionAppClient | undefined, json: string): Promise<boolean> {
        this.checkAppClientIsValid(appClient);

        return await this.postJson(apiUrls.doseConfig, appClient, undefined, json);
    }

    public async deleteDoseCustomizationAsync(appClient: MVisionAppClient | undefined): Promise<boolean> {
        this.checkAppClientIsValid(appClient);

        return await this.delete(apiUrls.doseConfig, appClient);
    }

    /** LOCAL: Returns default configuration for a single dose model */
    public async fetchDefaultConfigurationForDoseModelAsync(modelName: string): Promise<any> {
        return await this.getAndParseJsonResponse(apiUrls.makeGetDefaultConfigForDoseModelUrl(modelName), undefined, { userId: DEFAULT_USER_ID });
    }

    public async fetchLicenseStatusAsync(appClient: MVisionAppClient | undefined): Promise<any> {
        this.checkAppClientIsValid(appClient);

        return await this.getAndParseJsonResponse(apiUrls.getLicenseUrl, appClient);
    }

    public async fetchAllDaemonConfigsAsync(appClient: MVisionAppClient | undefined): Promise<any> {
        this.checkAppClientIsValid(appClient);

        return await this.getAndParseJsonResponse(apiUrls.daemonsConfig, appClient);
    }

    public async fetchDaemonConfigAsync(appClient: MVisionAppClient | undefined, daemonSessionId: string): Promise<any> {
        this.checkAppClientIsValid(appClient);

        return await this.getAndParseJsonResponse(apiUrls.daemonConfig, appClient, { daemonSessionId: daemonSessionId });
    }

    public async saveDaemonConfigAsync(appClient: MVisionAppClient | undefined, json: string): Promise<boolean> {
        this.checkAppClientIsValid(appClient);

        return await this.postJson(apiUrls.daemonConfig, appClient, undefined, json);
    }

    public async resetDaemonConfigAsync(appClient: MVisionAppClient | undefined, daemonSessionId: string): Promise<boolean> {
        this.checkAppClientIsValid(appClient);

        return await this.postJson(apiUrls.resetDaemonConfig, appClient, { daemonSessionId: daemonSessionId });
    }

    public async deleteDaemonConfigAsync(appClient: MVisionAppClient | undefined, daemonSessionId: string): Promise<boolean> {
        this.checkAppClientIsValid(appClient);

        return await this.delete(apiUrls.daemonConfig, appClient, { daemonSessionId: daemonSessionId });
    }

    public async saveLicenseAsync(appClient: MVisionAppClient | undefined, newLicense: string): Promise<boolean> {
        this.checkAppClientIsValid(appClient);

        const licenseJson = JSON.stringify({ license: newLicense });

        return await this.postJson(apiUrls.saveLicenseUrl, appClient, undefined, licenseJson);
    }

    public async fetchLabelingAsync(modelTypes: ModelType[]): Promise<CombinedVersionInfoJson> {
        const labelingJsons: LabelJsonWrapper[] = [];

        const backendJson = await this.getAndParseJsonResponse(apiUrls.backendVersionUrl, undefined);

        for (const modelType of modelTypes) {
            const labelingJson = await this.getAndParseJsonResponse(getLabelingUrl(modelType), undefined);
            labelingJsons.push({ modelType: modelType, labeling: labelingJson });
        }

        return { backendVersion: backendJson, labeling: labelingJsons };
    }

    public async fetchClientListAsync(): Promise<any> {
        return await this.getAndParseJsonResponse(apiUrls.getClientListUrl, undefined, undefined, 25);
    }

    public async fetchActiveContouringModelsAsync(appClient: MVisionAppClient | undefined, modelType: ModelType): Promise<any> {
        this.checkAppClientIsValid(appClient);

        return await this.getAndParseJsonResponse(apiUrls.getListActiveModelsUrl, appClient, { modelType: convertModelTypeToApiString(modelType) });
    }

    public async getUserSettingsAsync(): Promise<any> {
        // see user-settings.tsx
        throw new Error('User settings are not implemented');

        // return await this.getAndParseJsonResponse(getAllUserSettingsUrl, undefined, undefined, 25);
    }

    public async fetchAccessRightsAsync(): Promise<any> {
        return await this.getAndParseJsonResponse(apiUrls.getAccessRightsUrl, undefined);
    }

    public async saveUserSettingAsync(setting: string, jsonValue: string): Promise<boolean> {
        // see user-settings.tsx
        throw new Error('User settings are not implemented');

        // throw new Error('TODO: USE PUT HERE!');
        // return await this.postJson(generateSaveSettingUrl(setting), undefined, undefined, jsonValue);
    }

    public async saveUserSettingsAsync(settingsJson: { [x: string]: JsonValue; }): Promise<boolean> {
        // see user-settings.tsx
        throw new Error('User settings are not implemented');
    }

    // returns the "localEnvironmentTag" variable from config.json
    public getEnvironmentTag(): string {
        return environmentTag;
    }

    getAppVersion(): string {
        return `${getDefaultAppName()}/${DISPLAY_VERSION}/LOCAL`;
    }

    getAppWrapperComponent(): (props: React.PropsWithChildren<{}>) => JSX.Element {
        return NoAuthPage;
    }

    checkAppClientIsValid(appClient: MVisionAppClient | undefined): asserts appClient is MVisionAppClient {
        if (appClient === undefined) {
            throw new Error('AppClient was undefined.');
        }
    }
}
