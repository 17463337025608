import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { TextContent, Text, TextVariants, } from '@patternfly/react-core';

import SmallAccordionButton from './SmallAccordionButton';

import './customization-section.css';

interface CustomizationSectionProps {
    label: string | JSX.Element,
    collapsedMessage: string,
    isExpanded: boolean,
    onClick: () => void,
}

const CustomizationSection = (props: PropsWithChildren<CustomizationSectionProps>) => {

    const { children, label, collapsedMessage, isExpanded, onClick } = props;

    return (
        <div className="customization-section">
            <TextContent>
                <Text component="h3">
                    <SmallAccordionButton
                        isActive={isExpanded}
                        label={label}
                        onClick={onClick}
                        className="customization-section-accordion" />
                </Text>
            </TextContent>
            <div className={isExpanded ? '' : 'is-collapsed'}>
                {children}
            </div>
            {!isExpanded && (<div className="section-hint"><Text component={TextVariants.small}>{collapsedMessage}</Text></div>)}

        </div>
    )
}

export default CustomizationSection;
