import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RoiItem from './RoiItem';
import '../customization-page.css';
import { useTranslation } from 'react-i18next';
import { StoreState } from '../../../store/store';
import { advancedRoiCustomizationAdded, advancedRoiCustomizationRemoved, contouringSelectors, roiCustomizationChangesReverted, roiCustomizationNameUpdated } from '../../../store/contouring/contouringSlice';
import { fullRoiDetailsShown } from '../../../store/appStatus/appStatusSlice';
import { generateNewId } from '../../../store/global-types/customization-helpers';

interface RoiCustomizationFormProps {
    roiId: string,
    roiIdList: string[];
}

const RoiCustomizationForm = (props: RoiCustomizationFormProps) => {
    const { roiId, roiIdList } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const roi = useSelector((state: StoreState) => contouringSelectors.selectRoiById(state, roiId));
    const hasOriginalRoi = useSelector((state: StoreState) => contouringSelectors.selectOriginalRoiById(state, roiId) !== undefined);

    const handleDuplicateRoi = useCallback(() => {
        if (roi?.customizationOutputId === undefined) { throw new Error('ROI has no customization output defined -- cannot duplicate'); }
        const newRoiId = generateNewId();
        dispatch(advancedRoiCustomizationAdded({ outputId: roi.customizationOutputId, newRoiId: newRoiId, duplicatedRoiId: roi!.id }));
    }, [dispatch, roi]);

    const handleUndoStructureChanges = useCallback(() => {
        dispatch(roiCustomizationChangesReverted({ roiId: roi!.id, isGlobalRoi: false }));
    }, [dispatch, roi]);

    const handleRevertName = useCallback(() => {
        dispatch(roiCustomizationNameUpdated({ roiId: roi!.id, name: roi!.operation, isGlobalRoi: false }));
    }, [dispatch, roi]);

    const handleRemoveRoi = useCallback(() => {
        dispatch(advancedRoiCustomizationRemoved(roi!.id));
    }, [dispatch, roi]);

    const handleShowFullRoiDetails = useCallback(() => {
        dispatch(fullRoiDetailsShown({ currentRoiId: roiId, roiIdList: roiIdList, isGlobalRoi: false }));
    }, [dispatch, roiId, roiIdList]);

    if (roi === undefined) {
        return null;
    }

    return (
        <RoiItem
            roiId={roiId}
            isGlobalRoi={false}
            rowActions={[
                {
                    title: t('customizeRoi.showFullRoiDetails'),
                    onClick: handleShowFullRoiDetails,
                },
                {
                    title: t('customizeRoi.duplicateAsAdvancedRoi'),
                    onClick: handleDuplicateRoi,
                },
                {
                    title: t('customizeRoi.undoChanges'),
                    onClick: handleUndoStructureChanges,
                    disabled: !hasOriginalRoi || !roi.isModified,
                }, {
                    title: roi.isBuiltInRoi ? t('customizeRoi.revertNameToDefault') : t('customizeRoi.changeNameToMatchOperation'),
                    onClick: handleRevertName,
                    disabled: roi.name === roi.operation,
                }, {
                    title: t('customizeRoi.removeRoiCustomization'),
                    onClick: handleRemoveRoi,
                    disabled: roi.isBuiltInRoi,
                    tooltipProps: roi.isBuiltInRoi ? t('customizeRoi.removeRoiCustomization.help.onlyAdvancedRoisCanBeRemoved') : undefined,
                },
            ]}
        />
    );
}

export default RoiCustomizationForm;
