import { Dispatch } from "@reduxjs/toolkit";
import { all, call, fork, put, select } from "typed-redux-saga";
import { generateNewSessionId } from "../../environments";
import { getAppDeploymentInfo, getHelpDocuments } from "../appConfig/appConfigSagas";
import { getAppVersion, watchServerVersionPeriodically } from "../appVersion/appVersionSagas";
import { appConfigSelectors, appConfigSet } from '../appConfig/appConfigSlice';
import { getBackendApi, getConfigClient } from "../../app-config";
import { appInitializationSucceeded } from "./appStatusSlice";
import { firstTimeCloudLoginSaga } from "../auth/authSagas";

export function* initializeAppSaga(dispatch: Dispatch) {
    // generate a client ID and load version & configuration JSON files
    yield* all([call(generateNewSessionId), call(getAppVersion), call(getAppDeploymentInfo)]);

    // set correct app config
    const deploymentInfo = yield* select(appConfigSelectors.selectAppDeploymentInfo);
    if (!deploymentInfo) {
        throw new Error('App initialization failed -- could not retrieve app deployment info');
    }
    const configClient = getConfigClient(deploymentInfo.deploymentMode);

    yield* put(appConfigSet(configClient));

    // initialise current backend client configuration
    yield* call(async () => getBackendApi(configClient).init(dispatch, deploymentInfo));

    // app initialization is done
    yield* put(appInitializationSucceeded());

    // start possible first-time auth
    yield* fork(firstTimeCloudLoginSaga);

    // start watching updated version.json
    yield* fork(watchServerVersionPeriodically);

    // these initializations can be done concurrently with other things
    yield* all([call(getHelpDocuments)]);
}
