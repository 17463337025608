/**
 * An abstraction of a file from local system that is ready to be loaded in MVision Configuration's text editor.
 */
export type TextEditorFile = {

    /** Full path for the file, including file name. */
    filePath: string;

    /** Set true to allow writing into this file; set false to mark it as read-only. */
    allowWrite: boolean;

    /** Description about the file shown in UI. */
    description: string | undefined;

    /** File contents. */
    fileContents: string;

    /** Original file contents, never to be modified. */
    originalFileContents: string;

    /** True if this file is currently being loaded in, false otherwise. */
    isLoadingFile: boolean;

    /** True if the file has been loaded in, false otherwise. */
    isLoaded: boolean;

    /** True if (unsaved) modifications have been made to this file, false otherwise. */
    isModified: boolean;

    /** Represents an error message if there was an error during file save, or null if no errors occurred. */
    errorSavingFile: string | null;
}

export const createNewTextEditorFile = (filePath: string, allowWrite: boolean, description?: string) => {
    return {
        filePath: filePath,
        allowWrite: allowWrite,
        description: description,
        fileContents: '',
        originalFileContents: '',
        isLoadingFile: false,
        isLoaded: false,
        isModified: false,
        errorSavingFile: null,
    };
}

/** A bash query pattern used to search the local system for one or more files for the text editor. */
export type SystemFileQuery = {

    /**
     * Path or pattern to find file or files.
     * @example
     * '/etc/systemd/timesyncd.conf'
     * @example
     * '/{lib,etc,run}/netplan/*.yaml'
     */
    queryPattern: string;

    /** True if writing into these files is allowed, false if the files are read-only. */
    allowWrite: boolean;

    /** Description of the file(s) shown in UI. */
    description: string | undefined;
}

export const createNewSystemFileQuery = (queryPattern: string, allowWrite: boolean, description?: string): SystemFileQuery => {
    return {
        queryPattern: queryPattern,
        allowWrite: allowWrite,
        description: description,
    };
}
