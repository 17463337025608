import { PageSection, PageSectionVariants, TextContent, Text, Alert, FormGroup, TextInput, TextArea, Form } from '@patternfly/react-core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { StoreState } from '../../store/store';
import { appConfigSelectors } from '../../store/appConfig/appConfigSlice';
import { labelingFetched } from '../../store/appVersion/appVersionSlice';
import { appVersionToString } from '../../store/appVersion/appVersionTypes';
import TabbedSupportedModelTypes, { ModelTypeContent } from '../../components/TabbedSupportedModelTypes/TabbedSupportedModelTypes';
import LabelingView from './LabelingView';
import { DISPLAY_VERSION } from '../../environments';

import './about-page.css';
import ExternalLink from '../../components/external-link';

const AboutPage = () => {

    // reload labeling whenever the page is reloaded
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(labelingFetched());
    }, [dispatch]);

    const productLabels = useSelector((state: StoreState) => state.appVersion.productLabels);
    const productLabelError = useSelector((state: StoreState) => state.appVersion.productLabelError);
    const backendVersionAsString = useSelector((state: StoreState) => state.appVersion.backendVersionAsString);
    const appVersion = useSelector((state: StoreState) => state.appVersion.version);
    const appConfig = useSelector(appConfigSelectors.selectAppConfig);
    const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);

    // print version information to console when about page is opened
    useEffect(() => {
        if (productLabels) {
            console.log(productLabels);
        }
        if (appVersion) {
            console.log(appVersionToString(appVersion));
        }
    }, [appVersion, productLabels]);

    const modelTypeContent: ModelTypeContent[] = productLabels?.map(l => ({ modelType: l.modelType, content: <LabelingView productLabel={l} /> })) || [];

    return (
        <>
            <PageSection variant={PageSectionVariants.default}>
                <TextContent className='unselectable'>
                    <Text component="h1">{t('page.about.title')}</Text>
                </TextContent>
            </PageSection>

            {appConfig && appConfig.showErrorsInUi && productLabelError !== null && (
                <PageSection>
                    <Alert variant="danger" isInline title="An error occurred when trying to retrieve labeling information">
                        <p>An error occurred when trying to retrieve labeling information:</p>
                        <p>{productLabelError}</p>
                    </Alert>
                </PageSection>
            )}

            <PageSection variant={PageSectionVariants.light}>
                <PageSection variant={PageSectionVariants.light} className="limit-width">
                    <div className="labeling">

                        <div className="modelTypeSection labeling-section">
                            {productLabels !== null && (
                                <TabbedSupportedModelTypes
                                    content={modelTypeContent}
                                />
                            )}
                        </div>

                        <Form isHorizontal className="labeling-section">
                            {appVersion && (
                                <FormGroup
                                    label={t('appTitle')}
                                    fieldId="app-version-readonly" >
                                    <TextInput value={`${DISPLAY_VERSION} (${appVersion.commit})`} type="text" id="app-version-readonly" readOnlyVariant="default" />
                                </FormGroup>
                            )}
                            {backendVersionAsString && (
                                <FormGroup
                                    label={t('page.about.backendAndWorkersAppName')}
                                    fieldId="backend-version-readonly" >
                                    <TextArea
                                        readOnly
                                        id="backend-version-readonly"
                                        value={backendVersionAsString}
                                        resizeOrientation='vertical'
                                        className="text-editor-area label"
                                        spellCheck="false"
                                        rows={7}
                                    />
                                </FormGroup>
                            )}
                            {deploymentInfo && deploymentInfo.documentLinks && deploymentInfo.documentLinks.length > 1 && (
                                <FormGroup
                                    label={t('settings.documents')}
                                    fieldId="terms-and-conditions"
                                    className="terms-conditions-list"
                                >
                                    <div className="pdf-instructions">{t('page.about.pdfInstructions')}</div>

                                    {deploymentInfo.documentLinks.map((link, index) => (
                                        <ExternalLink key={index} url={link.url} className="terms-document-link">{link.label}</ExternalLink>
                                    ))}
                                </FormGroup>
                            )}
                        </Form>

                    </div>
                </PageSection>
            </PageSection >
        </>
    );
}

export default AboutPage;
