import { Popover, Button } from "@patternfly/react-core";
import { useTranslation, Trans } from "react-i18next";
import { OutlinedQuestionCircleIcon } from '@patternfly/react-icons';
import { DeploymentConfigInfo } from "../store/appConfig/appDeploymentInfoTypes";

interface RegexHelpProps {
    deploymentConfigInfo: DeploymentConfigInfo | undefined,
    appendTo?: HTMLElement | HTMLDivElement | ((ref?: HTMLElement) => HTMLElement) | "inline" | undefined | null,
    regexAlwaysOn?: boolean,
}

const RegexHelp = (props: RegexHelpProps) => {
    const { deploymentConfigInfo, appendTo, regexAlwaysOn } = props;

    const { t } = useTranslation();

    return (
        <Popover
            headerContent={<div>Regular expressions</div>}
            bodyContent={(
                <div className="regex-help">
                    <div>{(appendTo === undefined && !regexAlwaysOn) ? t('regex.help.toggle') : t('regex.help.alwaysOn')}</div>
                    {deploymentConfigInfo?.regexHelpUrl && (<div><Trans i18nKey="regex.help.website">This <a target="_blank" rel="noreferrer" href={deploymentConfigInfo.regexHelpUrl}>Regular Expression tester</a> can be used as an aid with writing Regular Expressions and for testing them.</Trans></div>)}
                    {deploymentConfigInfo?.regexHelpFile && (<div><Trans i18nKey="regex.help.helpFile">A <a target="_blank" rel="noreferrer" href={deploymentConfigInfo.regexHelpFile}>Regular Expressions cheat sheet</a> is available.</Trans></div>)}
                </div>
            )
            }
            appendTo={appendTo || undefined}
            withFocusTrap={true}
        >
            {appendTo === undefined ? (
                <Button variant="plain"><OutlinedQuestionCircleIcon /></Button>
            ) : (
                <Button variant="control" className="appended-field-button"><OutlinedQuestionCircleIcon /></Button>
            )}
        </Popover>
    );
}

export default RegexHelp;
