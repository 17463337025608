import React, { useCallback, useMemo, } from 'react';
import { ModelSelectionUndoState } from '../ModelSelectionPage';
import DicomRuleForm from '../DicomRuleForm';
import { ModelType } from '../../../store/global-types/customization-types';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../store/store';
import { contouringSelectors, dicomAttributeRuleAdded, dicomRuleRemoved } from '../../../store/contouring/contouringSlice';
import { makeSelectFormValidationErrorsForDicomRule } from '../../../store/contouring/contouringSelectors';
import { createNewDicomAttributeRule } from '../../../store/global-types/customization-helpers';
import { literalTextToRegex } from '../../../util/string-convert';




interface ContourDicomRuleProps {
    dicomRuleId: string,
    runningNumber: number,
    setUndoState: (undoState: ModelSelectionUndoState) => void,
    isUndoDisabled?: boolean,
    getDicomAttributeRule: (id: string, runningNumber: number, onRemoveDicomRuleButtonClicked: () => void) => JSX.Element,
}

const ContourDicomRule = (props: ContourDicomRuleProps) => {
    const { dicomRuleId, runningNumber, setUndoState, isUndoDisabled, getDicomAttributeRule } = props;

    const dispatch = useDispatch();

    const dicomRule = useSelector((state: StoreState) => contouringSelectors.selectDicomRuleById(state, dicomRuleId));
    const validationError = useSelector((state: StoreState) => contouringSelectors.selectCustomizationValidationError(state, dicomRuleId));

    const selectFormValidationErrorsForRule = useMemo(makeSelectFormValidationErrorsForDicomRule, []);
    const formValidationErrors = useSelector((state: StoreState) => selectFormValidationErrorsForRule(state, dicomRuleId));

    const handleCreateNewDicomAttributeRule = useCallback(() => {
        const newAttribute = createNewDicomAttributeRule('', literalTextToRegex(''), dicomRuleId, true);
        dispatch(dicomAttributeRuleAdded(newAttribute));
    }, [dispatch, dicomRuleId]);

    const handleRemoveDicomRule = useCallback(() => {
        dispatch(dicomRuleRemoved(dicomRuleId));
    }, [dispatch, dicomRuleId]);

    if (dicomRule === undefined) {
        return null;
    }

    return (
        <DicomRuleForm 
            modelType={ModelType.Contouring}
            dicomRule={dicomRule}
            validationError={validationError}
            formValidationErrors={formValidationErrors}
            runningNumber={runningNumber}
            setUndoState={setUndoState}
            isUndoDisabled={isUndoDisabled}
            onCreateNewDicomAttributeRule={handleCreateNewDicomAttributeRule}
            onDicomRuleRemoved={handleRemoveDicomRule}
            getDicomAttributeRule={getDicomAttributeRule}
        />
    );
}

export default ContourDicomRule;
