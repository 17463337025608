import { EntityAdapter, Update } from "@reduxjs/toolkit";
import { ContouringCustomizationEntities, ContouringCustomizationOutput, GlobalContouringRoi } from "../../contouring/contouring-types";
import { DoseCustomizationEntities, DoseCustomizationOutput } from "../../dose/dose-types";
import { AeTitleRule, CustomizationBase, CustomizationObjectType, CustomizationOutput, DEFAULT_MODEL_CUSTOMIZATION_NAME, DicomAttributeRule, DicomRule, METADATA_FILENAME_ATTRIBUTE, Model, ModelType, ModelVisibility, OutputMetadataItem, VALIDATION_MESSAGE_AE_TITLES_MUST_BE_UNIQUE } from "../customization-types";
import { AnySliceState, getFormValidationErrorIdsForItem, getFormValidationErrorIdsForItems, setObjectAndAncestorsAsModified } from "../reducer-helpers";
import { BackendValidationErrorViewModel, FormValidationError, generateNewFormValidationError } from "../store-errors";
import { assertIsDefined } from "../../../util/assert";
import { pull } from "lodash-es";
import { FormValidationErrorType } from "../form-errors";
import { notEmpty } from "../../../util/filter";


type OutputAdapterType = EntityAdapter<ContouringCustomizationOutput, string> | EntityAdapter<DoseCustomizationOutput, string>;

// type OutputType = ContouringCustomizationOutput | DoseCustomizationOutput;

type DicomAttributeValidationResults = { passedIds: string[]; failedIds: string[]; };

type CustomizationEntities = ContouringCustomizationEntities | DoseCustomizationEntities;

export type FormValidationErrorCalculationResults = { validationErrorIdsToRemove: string[], validationErrorsToAdd: FormValidationError[] };

/** Collection of slice-specific IDs for removal (where appropriate) */
export type RemovedOutputEntities = {
    globalRoiChanges: Update<GlobalContouringRoi, string>[];
    roiIdsToBeRemoved: string[];
    targetIdsToBeRemoved: string[];
    metadataIdsToBeRemoved: string[];
};

/**
 * This class contains generic reducer methods for different model types (contour+, dose+, etc). These should be
 * called from within appropriate slices (e.g. contouringSlice) to ensure the slices run similar code. Use the
 * appropriate inherited class for your slice (ContourSliceHelper, DoseSliceHelper, etc).
 */
export default abstract class GenericSliceWrapper<T extends AnySliceState,
> {

    modelAdapter: EntityAdapter<Model, string>;
    customizationBaseAdapter: EntityAdapter<CustomizationBase, string>;
    abstract outputAdapter: OutputAdapterType;
    outputMetadataAdapter: EntityAdapter<OutputMetadataItem, string>;
    aeTitleRuleAdapter: EntityAdapter<AeTitleRule, string>;
    dicomRuleAdapter: EntityAdapter<DicomRule, string>;
    dicomAttributeRuleAdapter: EntityAdapter<DicomAttributeRule, string>;
    backendValidationErrorAdapter: EntityAdapter<BackendValidationErrorViewModel, string>;
    formValidationErrorAdapter: EntityAdapter<FormValidationError, string>;

    constructor(
        modelAdapter: EntityAdapter<Model, string>,
        customizationBaseAdapter: EntityAdapter<CustomizationBase, string>,
        outputMetadataAdapter: EntityAdapter<OutputMetadataItem, string>,
        aeTitleRuleAdapter: EntityAdapter<AeTitleRule, string>,
        dicomRuleAdapter: EntityAdapter<DicomRule, string>,
        dicomAttributeRuleAdapter: EntityAdapter<DicomAttributeRule, string>,
        backendValidationErrorAdapter: EntityAdapter<BackendValidationErrorViewModel, string>,
        formValidationErrorAdapter: EntityAdapter<FormValidationError, string>,
    ) {
        this.modelAdapter = modelAdapter;
        this.customizationBaseAdapter = customizationBaseAdapter;
        this.outputMetadataAdapter = outputMetadataAdapter;
        this.aeTitleRuleAdapter = aeTitleRuleAdapter;
        this.dicomRuleAdapter = dicomRuleAdapter;
        this.dicomAttributeRuleAdapter = dicomAttributeRuleAdapter;
        this.backendValidationErrorAdapter = backendValidationErrorAdapter;
        this.formValidationErrorAdapter = formValidationErrorAdapter;
    }

    /**
     * Updates a model's visibility.
     * @param modelId Internal (entity adapter) ID of the model to update.
     * @param visibility The new visibility setting for the model.
     */
    public updateModelVisibility(state: T, modelId: string, visibility: ModelVisibility) {
        this.modelAdapter.updateOne(state.models, { id: modelId, changes: { visibility: visibility, isModified: true } });
    }

    /**
    * Updates the description field on a customization base object.
    * @param customizationBaseId Internal (entity adapter) ID of the customization base to update.
    * @param description The new description to set.
    */
    public updateModelCustomizationDescription(state: T, customizationBaseId: string, description: string) {
        this.customizationBaseAdapter.updateOne(state.customizationBases, {
            id: customizationBaseId,
            changes: { description, isModified: true },
        });
        setObjectAndAncestorsAsModified(state, [customizationBaseId], CustomizationObjectType.CustomizationBase);
    }

    /**
     * Updates a customization metadata item tuple. If either attribute or value is left as undefined then that particular prop is not updated
     * and the existing prop is left unchanged.
     * @param metadataId Internal (entity adapter) ID of the customization metadata item that will be updated.
     * @param attribute The new attribute name of the metadata item that will be used, or undefined if attribute should not be changed.
     * @param value The new value of the metadata item, or undefined if value should not be changed.
     * @param isUndoOperation Marks this update as part of an undo operation. Note that the correct reverted attribute and value props
     * must be supplied to this function manually, this function does not perform an undo by itself. Optional, defaults to false.
     */
    public updateMetadataItem(state: T, metadataId: string, attribute: string | undefined, value: string | undefined, isUndoOperation?: boolean) {
        const metadataItem = state.outputMetadata.entities[metadataId];
        if (metadataItem && (attribute !== undefined || value !== undefined)) {
            const changes: Partial<OutputMetadataItem> = { isModified: !isUndoOperation };
            if (attribute !== undefined) { changes['attribute'] = attribute; }
            if (value !== undefined) { changes['value'] = value; }

            this.outputMetadataAdapter.updateOne(state.outputMetadata, { id: metadataId, changes: changes });

            // update matching output filename prop if needed
            if (metadataItem.modelCustomizationOutputId !== undefined) {
                const currentAttribute = attribute !== undefined ? attribute : metadataItem.attribute;
                const currentValue = value !== undefined ? value : metadataItem.value;

                if (currentAttribute === METADATA_FILENAME_ATTRIBUTE) {
                    // update changed filename
                    this.updateOutput(state, { id: metadataItem.modelCustomizationOutputId, changes: { filename: currentValue || '' } });
                } else if (metadataItem.attribute === METADATA_FILENAME_ATTRIBUTE) {
                    // attribute name was changed away from 'filename' -- try to find another matching value, then update
                    const output = this.findOutput(state, metadataItem.modelCustomizationOutputId);
                    if (output === undefined) { throw new Error(`Could not find customization output matching ID ${metadataItem.modelCustomizationOutputId}`); }
                    const matchingFilenameMetadataItems = Object.values(state.outputMetadata.entities)
                        .filter(m => m !== undefined && output.metadata.includes(m.id) && m.attribute === METADATA_FILENAME_ATTRIBUTE);
                    this.updateOutput(state, { id: metadataItem.modelCustomizationOutputId, changes: { filename: matchingFilenameMetadataItems[0]?.value || '' } });

                }
            }

            if (!isUndoOperation) {
                setObjectAndAncestorsAsModified(state, [metadataId], CustomizationObjectType.Metadata);
            }
        }
    }

    /**
     * Removes a customization metadata item.
     * @param metadataId Internal (entity adapter) ID of the customization metadata item that will be removed.
     */
    public removeMetadataItem(state: T, metadataId: string) {
        const metadataItem = state.outputMetadata.entities[metadataId];
        if (metadataItem) {
            const output = metadataItem.modelCustomizationOutputId ? this.findOutput(state, metadataItem.modelCustomizationOutputId) : undefined;

            this.outputMetadataAdapter.removeOne(state.outputMetadata, metadataId);

            this.backendValidationErrorAdapter.removeOne(state.backendValidationErrors, metadataId);
            this.formValidationErrorAdapter.removeMany(state.formValidationErrors, getFormValidationErrorIdsForItem(state, metadataId));

            if (output) {
                this.updateOutput(state, { id: output.id, changes: { metadata: output.metadata.filter(mId => mId !== metadataId), isModified: true } });

                // update matching output filename prop if needed -- we might have multiple filename metadata entries so first try finding another match
                const matchingFilenameMetadataItems = Object.values(state.outputMetadata.entities)
                    .filter(m => m !== undefined && output.metadata.includes(m.id) && m.attribute === METADATA_FILENAME_ATTRIBUTE);
                this.updateOutput(state, { id: output.id, changes: { filename: matchingFilenameMetadataItems[0]?.value || '' } });

                setObjectAndAncestorsAsModified(state, [output.id], CustomizationObjectType.CustomizationOutput);
            }
        }
    }

    /**
    * Adds supplied AE Title rule into customization configuration.
    * @param aeTitleRule The AE Title rule to add.
    */
    public addAeTitleRule(state: T, aeTitleRule: AeTitleRule) {
        assertIsDefined(aeTitleRule.modelCustomizationBaseId, 'modelCustomizationBaseId not defined for new AE Title rule');

        const customization = state.customizationBases.entities[aeTitleRule.modelCustomizationBaseId];
        assertIsDefined(customization, `Could not find customization base with matching ID ${aeTitleRule.modelCustomizationBaseId}`);

        this.aeTitleRuleAdapter.addOne(state.aeTitleRules, aeTitleRule);
        this.customizationBaseAdapter.updateOne(state.customizationBases, { id: customization.id, changes: { aeTitleRules: customization.aeTitleRules.concat(aeTitleRule.id), isModified: true } });
        setObjectAndAncestorsAsModified(state, [aeTitleRule.id], CustomizationObjectType.AeTitleRule);

        this.validateAeTitleRuleActionUniqueness(state, aeTitleRule.action);
    }

    /**
     * Updates the 'action' field in an AE Title rule.
     * @param aeTitleRuleId Internal (entity adapter) ID of the AE Title rule to update.
     * @param action The new action value for the rule.
     */
    public updateAeTitleRuleAction(state: T, aeTitleRuleId: string, action: string) {
        const previousActionName = state.aeTitleRules.entities[aeTitleRuleId]?.action;
        this.aeTitleRuleAdapter.updateOne(state.aeTitleRules, { id: aeTitleRuleId, changes: { action: action, isModified: true } });

        setObjectAndAncestorsAsModified(state, [aeTitleRuleId], CustomizationObjectType.AeTitleRule);

        if (previousActionName !== undefined) {
            this.validateAeTitleRuleActionUniqueness(state, previousActionName);
        }
        this.validateAeTitleRuleActionUniqueness(state, action);
    }

    /**
     * Removes an AE Title rule from customization configuration.
     * Throws if the AE Title rule to be removed is not found from configuration.
     * @param aeTitleRuleId Internal (entity adapter) ID of the AE Title rule to remove.
     */
    public removeAeTitleRule(state: T, aeTitleRuleId: string) {
        const aeTitleRuleToRemove = state.aeTitleRules.entities[aeTitleRuleId];
        this.aeTitleRuleAdapter.removeOne(state.aeTitleRules, aeTitleRuleId);

        // update matching parent objects
        assertIsDefined(aeTitleRuleToRemove, 'AE title rule about to be removed is not defined');
        assertIsDefined(aeTitleRuleToRemove.modelCustomizationBaseId, 'modelCustomizationBaseId not defined for the AE title rule about to be removed');
        const customization = state.customizationBases.entities[aeTitleRuleToRemove.modelCustomizationBaseId];
        assertIsDefined(customization, `Could not find customization base with matching ID ${aeTitleRuleToRemove.modelCustomizationBaseId}`);
        this.customizationBaseAdapter.updateOne(state.customizationBases, { id: customization.id, changes: { aeTitleRules: pull(customization.aeTitleRules, aeTitleRuleId), isModified: true } });
        setObjectAndAncestorsAsModified(state, [customization.id], CustomizationObjectType.CustomizationBase);

        this.validateAeTitleRuleActionUniqueness(state, aeTitleRuleToRemove.action);
    }

    /**
     * Adds supplied DICOM rule into customization configuration.
     * Note that DICOM attribute rules under a DICOM rule must be added separately.
     * @param dicomRule The DICOM rule to add.
     */
    public addDicomRule(state: T, dicomRule: DicomRule) {
        assertIsDefined(dicomRule.modelCustomizationBaseId, 'modelCustomizationBaseId not defined for new DICOM rule');

        const customization = state.customizationBases.entities[dicomRule.modelCustomizationBaseId];
        assertIsDefined(customization, `Could not find customization base with matching ID ${dicomRule.modelCustomizationBaseId}`);

        this.dicomRuleAdapter.addOne(state.dicomRules, dicomRule);
        this.customizationBaseAdapter.updateOne(state.customizationBases, { id: customization.id, changes: { dicomRules: customization.dicomRules.concat(dicomRule.id), isModified: true } });
        setObjectAndAncestorsAsModified(state, [dicomRule.id], CustomizationObjectType.DicomRule);
    }

    /**
     * Removes a DICOM rule from customization configuration. Also removes any DICOM attribute
     * rules under the DICOM rule.
     * Throws if the DICOM rule to be removed is not found from configuration.
     * @param dicomRuleId Internal (entity adapter) ID of the DICOM rule to remove.
     */
    public removeDicomRule(state: T, dicomRuleId: string) {
        const dicomRuleToRemove = state.dicomRules.entities[dicomRuleId];
        if (dicomRuleToRemove === undefined) { throw new Error(`Could not find DICOM rule ${dicomRuleId} to remove`); }
        this.dicomAttributeRuleAdapter.removeMany(state.dicomAttributeRules, dicomRuleToRemove.dicomAttributes);
        this.dicomRuleAdapter.removeOne(state.dicomRules, dicomRuleId);

        // update matching parent objects
        assertIsDefined(dicomRuleToRemove, 'DICOM rule about to be removed is not defined');
        assertIsDefined(dicomRuleToRemove.modelCustomizationBaseId, 'modelCustomizationBaseId not defined for the DICOM rule about to be removed');
        const customization = state.customizationBases.entities[dicomRuleToRemove.modelCustomizationBaseId];
        assertIsDefined(customization, `Could not find customization base with matching ID ${dicomRuleToRemove.modelCustomizationBaseId}`);
        this.customizationBaseAdapter.updateOne(state.customizationBases, { id: customization.id, changes: { dicomRules: pull(customization.dicomRules, dicomRuleId), isModified: true } });
        setObjectAndAncestorsAsModified(state, [customization.id], CustomizationObjectType.CustomizationBase);
    }

    /**
     * Adds supplied DICOM attribute rule into customization configuration.
     * Note that the parent DICOM rule must already exist in configuration or this function will throw.
     * @param dicomAttributeRule The DICOM attribute rule to add.
     */
    public addDicomAttributeRule(state: T, dicomAttributeRule: DicomAttributeRule) {
        const parentDicomRule = state.dicomRules.entities[dicomAttributeRule.parentDicomRuleId];
        if (parentDicomRule === undefined) { throw new Error(`Could not find parent DicomRule object with ID ${dicomAttributeRule.parentDicomRuleId}`); }
        if (!parentDicomRule.dicomAttributes.includes(dicomAttributeRule.id)) {
            this.dicomRuleAdapter.updateOne(state.dicomRules, {
                id: dicomAttributeRule.parentDicomRuleId,
                changes: {
                    dicomAttributes: parentDicomRule.dicomAttributes.concat(dicomAttributeRule.id),
                    isModified: true
                }
            });
        }

        this.dicomAttributeRuleAdapter.addOne(state.dicomAttributeRules, dicomAttributeRule);

        // mark matching parent entities as modified
        setObjectAndAncestorsAsModified(state, [dicomAttributeRule.id], CustomizationObjectType.DicomAttributeRule);

        this.validateDicomRuleAndAttributes(state, dicomAttributeRule.parentDicomRuleId);
    }

    /**
     * Removes a DICOM attribute rule from customization configuration.
     * Throws if the DICOM attribute rule to be removed or its parent DICOM rule are not found from configuration.
     * @param dicomAttributeRuleId Internal (entity adapter) ID of the DICOM attribute rule to remove.
     */
    public removeDicomAttributeRule(state: T, dicomAttributeRuleId: string) {
        const dicomAttributeToRemove = state.dicomAttributeRules.entities[dicomAttributeRuleId];
        if (dicomAttributeToRemove === undefined) { throw new Error(`Could not find DICOM attribute rule ${dicomAttributeRuleId} to remove`); }

        const parentDicomRule = state.dicomRules.entities[dicomAttributeToRemove.parentDicomRuleId];
        if (parentDicomRule === undefined) { throw new Error(`Could not find parent DICOM rule ${dicomAttributeToRemove.parentDicomRuleId} for DICOM attribute ${dicomAttributeRuleId} -- cannot remove attribute`); }
        this.dicomRuleAdapter.updateOne(state.dicomRules, {
            id: dicomAttributeToRemove.parentDicomRuleId,
            changes: {
                dicomAttributes: pull(parentDicomRule.dicomAttributes, dicomAttributeRuleId),
                isModified: true
            }
        });

        this.dicomAttributeRuleAdapter.removeOne(state.dicomAttributeRules, dicomAttributeRuleId);

        setObjectAndAncestorsAsModified(state, [parentDicomRule.id], CustomizationObjectType.DicomRule);

        this.validateDicomRuleAndAttributes(state, parentDicomRule.id);
    }

    /**
     * Updates a DICOM attribute rule.
     * @param dicomAttributeRuleId Internal (entity adapter) ID of the DICOM attribute rule to update.
     * @param dicomAttribute The attribute field of the rule is updated to this value.
     * @param dicomValue The DICOM value field of the rule is updated to this value.
     */
    updateDicomAttributeRule(state: T, dicomAttributeRuleId: string, dicomAttribute: string, dicomValue: string) {
        const dicomAttributeRule = state.dicomAttributeRules.entities[dicomAttributeRuleId];
        if (dicomAttributeRule === undefined) { throw new Error(`Could not find DICOM attribute rule with id ${dicomAttributeRuleId}`); }
        const dicomRuleId = dicomAttributeRule.parentDicomRuleId;

        // save changes to the object immediately
        this.dicomAttributeRuleAdapter.updateOne(state.dicomAttributeRules,
            {
                id: dicomAttributeRuleId,
                changes: {
                    attribute: dicomAttribute,
                    value: dicomValue,
                    isModified: true,
                }
            });

        setObjectAndAncestorsAsModified(state, [dicomAttributeRuleId], CustomizationObjectType.DicomAttributeRule);

        // perform validation AFTER changes have been saved
        this.validateDicomRuleAndAttributes(state, dicomRuleId);
    }


    /**
     * Removes a tag from a (newly created) customization metadata item that would mark it as being the target of being
     * automatically scrolled to in UI.
     * 
     * This tag is generally given to metadata items after their creation in the UI and should be removed once
     * the UI has scrolled to the correct position in the UI once.
     * 
     * @param metadataId Internal (entity adapter) ID of the customization metadata item where this tag is to be removed.
     */
    public removeScrollToViewFromMetadata(state: T, metadataId: string) {
        this.outputMetadataAdapter.updateOne(state.outputMetadata, { id: metadataId, changes: { scrollToView: false } });
    }

    /**
     * Removes the specified customization base entity and all entities under it in the hierarchy.
     * @param customizationBaseId The ID of the customization base to remove.
     */
    public removeCustomizationBase(state: T, customizationBaseId: string) {
        const customization = state.customizationBases.entities[customizationBaseId];

        if (customization === undefined) {
            throw new Error(`Could not find customization base ${customizationBaseId}`);
        }

        if (customization.customizationName === DEFAULT_MODEL_CUSTOMIZATION_NAME) {
            throw new Error('Default customizations cannot be deleted.');
        }

        // mark base model as modified so we know we need to save the configuration
        setObjectAndAncestorsAsModified(state, [customizationBaseId], CustomizationObjectType.CustomizationBase);

        const outputs = customization.outputs.map(oId => this.findOutput(state, oId)).filter(notEmpty);
        const globalRoiChanges: Update<GlobalContouringRoi, string>[] = [];
        const roiIdsToBeRemoved: string[] = [];
        const targetIdsToBeRemoved: string[] = [];
        const metadataIdsToBeRemoved: string[] = [];
        const dicomAttributeIdsToBeRemoved: string[] = [];

        // get dicom attribute rules to remove
        for (const dicomRuleId of customization.dicomRules) {
            const dicomRule = state.dicomRules.entities[dicomRuleId];
            if (dicomRule === undefined) {
                throw new Error(`Could not retrieve dicom rule ${dicomRuleId} for customization base ${customizationBaseId}`);
            }
            dicomAttributeIdsToBeRemoved.push(...dicomRule.dicomAttributes);
        }

        const model = state.models.entities[customization.modelId];
        if (model === undefined) {
            throw new Error(`Could not retrieve segmentation model parent ${customization.modelId} for customization base ${customizationBaseId}`);
        }

        for (const output of outputs) {
            const removedOutputEntities = this.collectCustomizationOutputEntitiesFromStoreForRemoval(state, output, customizationBaseId);
            globalRoiChanges.push(...removedOutputEntities.globalRoiChanges);
            roiIdsToBeRemoved.push(...removedOutputEntities.roiIdsToBeRemoved);
            targetIdsToBeRemoved.push(...removedOutputEntities.targetIdsToBeRemoved);
            metadataIdsToBeRemoved.push(...removedOutputEntities.metadataIdsToBeRemoved);
        }

        // remove & handle removal of slice-specific items (including updating global rois where relevant)
        this.removeOutputRelatedItems(state, customization.outputs, { globalRoiChanges, roiIdsToBeRemoved, targetIdsToBeRemoved, metadataIdsToBeRemoved });

        // delete generic items
        this.dicomAttributeRuleAdapter.removeMany(state.dicomAttributeRules, dicomAttributeIdsToBeRemoved);
        this.aeTitleRuleAdapter.removeMany(state.aeTitleRules, customization.aeTitleRules);
        this.dicomRuleAdapter.removeMany(state.dicomRules, customization.dicomRules);
        this.customizationBaseAdapter.removeOne(state.customizationBases, customizationBaseId);

        // update segmentation model
        this.modelAdapter.updateOne(state.models, { id: customization.modelId, changes: { customizations: model.customizations.filter(c => c !== customizationBaseId) } });

        // no need to specifically remove validation errors here -- they will not show up in UI and will be cleared from store during next save operation
        // however **form** validation errors should be removed
        const removedIds: string[] = [
            customizationBaseId,
            ...customization.dicomRules,
            ...customization.aeTitleRules,
            ...dicomAttributeIdsToBeRemoved,
            ...customization.outputs,
            ...metadataIdsToBeRemoved,
            ...roiIdsToBeRemoved,
            ...targetIdsToBeRemoved,
        ];
        this.formValidationErrorAdapter.removeMany(state.formValidationErrors, getFormValidationErrorIdsForItems(state, removedIds));
    }

    /**
     * Marks any supplied customization entities as having been modified.
     * @param action A collection of customization entities to mark as modified as per their
     * entity adapter IDs.
     */
    public setStateObjectsAsModified(state: T, entitiesToUpdate: Partial<CustomizationEntities>) {

        // first set all supplied common items as modified

        const aeTitleRuleIds = entitiesToUpdate.aeTitleRules?.map(id => id.id);
        const dicomRuleIds = entitiesToUpdate.dicomRules?.map(id => id.id);
        const dicomAttributeRuleIds = entitiesToUpdate.dicomAttributeRules?.map(id => id.id);
        const modelsIds = entitiesToUpdate.models?.map(id => id.id);
        const customizationBasesIds = entitiesToUpdate.customizationBases?.map(id => id.id);

        if (aeTitleRuleIds && aeTitleRuleIds.length > 0) {
            this.aeTitleRuleAdapter.updateMany(
                state.aeTitleRules,
                aeTitleRuleIds.map(aeTitleRuleId => ({ id: aeTitleRuleId, changes: { isModified: true } }))
            );
        }

        if (dicomRuleIds && dicomRuleIds.length > 0) {
            this.dicomRuleAdapter.updateMany(
                state.dicomRules,
                dicomRuleIds.map(dicomRuleId => ({ id: dicomRuleId, changes: { isModified: true } }))
            );
        }

        if (dicomAttributeRuleIds && dicomAttributeRuleIds.length > 0) {
            this.dicomAttributeRuleAdapter.updateMany(
                state.dicomAttributeRules,
                dicomAttributeRuleIds.map(dicomAttributeRuleId => ({ id: dicomAttributeRuleId, changes: { isModified: true } }))
            );
        }

        if (modelsIds && modelsIds.length > 0) {
            this.modelAdapter.updateMany(
                state.models,
                modelsIds.map(modelId => ({ id: modelId, changes: { isModified: true } }))
            );
        }
        
        if (customizationBasesIds && customizationBasesIds.length > 0) {
            this.customizationBaseAdapter.updateMany(
                state.customizationBases,
                customizationBasesIds.map(customizationBaseId => ({ id: customizationBaseId, changes: { isModified: true } }))
            );
        }
        

        // then set specific items as modified
        this.setOutputRelatedItemsAsModified(state, entitiesToUpdate);

    }



    /****  abstract model type-specific getters etc   ****/

    /** Returns customization output from an entity adapter store of matching slice. */
    protected abstract findOutput(state: T, outputId: string): CustomizationOutput | undefined;

    /** Updates a customization output from an entity adapter store of matching slice. */
    protected abstract updateOutput(state: T, update: Update<CustomizationOutput, string>): void;

    /** Collects which store entities need to be removed and updated when a customization output is removed. */
    public abstract collectCustomizationOutputEntitiesFromStoreForRemoval(state: T, output: CustomizationOutput, customizationBaseId: string): RemovedOutputEntities;

    protected abstract removeOutputRelatedItems(state: T, outputIds: string[], items: RemovedOutputEntities): void;

    protected abstract setOutputRelatedItemsAsModified(state: T, entitiesToUpdate: Partial<CustomizationEntities>): void;

    /** Calculates and returns form validation error for output from matching slice. */
    protected abstract calculateFormValidationErrorsForCustomizationOutput(state: T, outputId: string): FormValidationErrorCalculationResults;





    /** validators, internal helper functions */

    /**
     * Validates that the whole contouring customization configuration has no AE Title
     * rules with repeated action values -- i.e. there should be no multiple identical
     * AE Title rules in the configuration (even if they call different models).
     * 
     * This should only be called from within contouringConfig reducers (or from unit tests).
     * 
     * @param action The action value to validate for uniqueness.
     */
    private validateAeTitleRuleActionUniqueness(state: T, action: string) {
        const aeTitleRulesWithSameAction = Object.values(state.aeTitleRules.entities).filter(ae => ae?.action === action);
        // values in this array should actually never be undefined but EntityState collections are being uncooperative here
        if (aeTitleRulesWithSameAction.length > 1) {
            aeTitleRulesWithSameAction.forEach(ae => {
                if (ae !== undefined) {
                    ae.isValid = false;
                    ae.validationMessage = VALIDATION_MESSAGE_AE_TITLES_MUST_BE_UNIQUE;
                }
            });
        } else if (aeTitleRulesWithSameAction[0] !== undefined) {
            aeTitleRulesWithSameAction[0].isValid = true;
            aeTitleRulesWithSameAction[0].validationMessage = undefined;
        }
    }

    /** 
     * Perform full validation on DICOM rule and related DICOM attribute rules. Set validation states to the store objects accordingly.
     * 
     * This should only be called from within contouringConfig reducers (or from unit tests).
     */
    private validateDicomRuleAndAttributes(state: T, dicomRuleId: string): boolean {

        const dicomRule = state.dicomRules.entities[dicomRuleId];

        if (!dicomRule) { return true; }

        // collect dicom attributes for this rule
        const dicomAttributeRules = dicomRule.dicomAttributes.map(aId => state.dicomAttributeRules.entities[aId]);

        // do the validation and collect passed and failed entity IDs:

        // contains dicom rule ids
        const hasAtLeastOneAttributeResults = this.validateDicomRuleHasAtLeastOneAttribute(dicomRule);

        // these two contain dicom ATTRIBUTE ids
        const attributeNameNotEmptyAttributeRules = this.validateDicomAttributeNamesAreNotEmpty(dicomAttributeRules);
        const attributeNameNotUniqueAttributeRules = this.validateAllDicomAttributeNamesHaveNoDuplicates(dicomAttributeRules);
        const allFailedDicomAttributeRules = attributeNameNotEmptyAttributeRules.failedIds.concat(attributeNameNotUniqueAttributeRules.failedIds);

        const isValidOverall = (hasAtLeastOneAttributeResults.failedIds.length + allFailedDicomAttributeRules.length) === 0;

        // collect existing validation errors for delta
        const existingErrors = Object.values(state.formValidationErrors.entities)
            .filter(e => [
                FormValidationErrorType.DicomRuleMustHaveAtLeastOneAttribute,
                FormValidationErrorType.DicomAttributeNameMustNotBeEmpty,
                FormValidationErrorType.DicomAttributesMustNotRepeat
            ].includes(e.errorType));

        // handle dicom rule validation error (if any)
        // NOTE: this bit needs to be rewritten once there are more than one validation rules for dicom rules themselves!
        const dicomRulePassed = hasAtLeastOneAttributeResults.failedIds.length === 0;
        const existingDicomRuleError = existingErrors.find(e => e.errorType === FormValidationErrorType.DicomRuleMustHaveAtLeastOneAttribute && e.itemId === dicomRuleId);
        if (dicomRulePassed && existingDicomRuleError !== undefined) {
            // remove existing error
            this.formValidationErrorAdapter.removeOne(state.formValidationErrors, existingDicomRuleError.id);
        } else if (!dicomRulePassed && !existingDicomRuleError) {
            // add a new error
            this.formValidationErrorAdapter.addOne(
                state.formValidationErrors,
                generateNewFormValidationError(FormValidationErrorType.DicomRuleMustHaveAtLeastOneAttribute, dicomRuleId, CustomizationObjectType.DicomRule));
        }

        // handle dicom attribute errors
        const existingDicomAttributeErrors = existingErrors
            .filter(e => e.errorType !== FormValidationErrorType.DicomRuleMustHaveAtLeastOneAttribute
                && dicomRule.dicomAttributes.includes(e.itemId));
        for (const dicomAttributeId of dicomRule.dicomAttributes) {
            // we need to collect old validation errors that we need to remove
            const existingErrorIdsToRemove: string[] = [];
            const existingErrorsForThisAttribute = existingDicomAttributeErrors.filter(e => e.itemId === dicomAttributeId);
            const existingEmptyNameErrors = existingErrorsForThisAttribute.filter(e => e.errorType === FormValidationErrorType.DicomAttributeNameMustNotBeEmpty);
            const existingDuplicateNamesErrors = existingErrorsForThisAttribute.filter(e => e.errorType === FormValidationErrorType.DicomAttributesMustNotRepeat);

            // empty name error overrides duplicate name error
            if (attributeNameNotEmptyAttributeRules.failedIds.includes(dicomAttributeId)) {
                existingErrorIdsToRemove.push(...existingDuplicateNamesErrors.map(e => e.id));
                if (existingEmptyNameErrors.length === 0) {
                    this.formValidationErrorAdapter.addOne(state.formValidationErrors, generateNewFormValidationError(
                        FormValidationErrorType.DicomAttributeNameMustNotBeEmpty, dicomAttributeId, CustomizationObjectType.DicomAttributeRule
                    ));
                }
            } else if (attributeNameNotUniqueAttributeRules.failedIds.includes(dicomAttributeId)) {
                existingErrorIdsToRemove.push(...existingEmptyNameErrors.map(e => e.id));
                if (existingDuplicateNamesErrors.length === 0) {
                    this.formValidationErrorAdapter.addOne(state.formValidationErrors, generateNewFormValidationError(
                        FormValidationErrorType.DicomAttributesMustNotRepeat, dicomAttributeId, CustomizationObjectType.DicomAttributeRule
                    ));
                }
            } else {
                // this attribute passed validation
                existingErrorIdsToRemove.push(...(existingEmptyNameErrors.map(e => e.id).concat(existingDuplicateNamesErrors.map(e => e.id))));
            }

            // remove any existing validation errors tagged as such
            if (existingErrorIdsToRemove.length > 0) {
                this.formValidationErrorAdapter.removeMany(state.formValidationErrors, existingErrorIdsToRemove);
            }
        }

        // update isValid props
        this.dicomRuleAdapter.updateOne(state.dicomRules, { id: dicomRuleId, changes: { isValid: isValidOverall } });
        this.dicomAttributeRuleAdapter.updateMany(state.dicomAttributeRules, dicomRule.dicomAttributes.map(id => ({ id: id, changes: { isValid: !allFailedDicomAttributeRules.includes(id) } })));

        return isValidOverall;
    }



    /** Checks that given dicom rule has at least one attribute. Returns a list of item ids which fail and which pass the validation. */
    private validateDicomRuleHasAtLeastOneAttribute(dicomRule: DicomRule): DicomAttributeValidationResults {
        const validationResults: DicomAttributeValidationResults = { passedIds: [], failedIds: [] };

        const dicomRuleId = dicomRule.id;

        const dicomAttributeRuleIds = dicomRule.dicomAttributes;
        if (dicomAttributeRuleIds.length < 1) {
            validationResults.failedIds.push(dicomRuleId);
        } else {
            validationResults.passedIds.push(dicomRuleId);
        }

        return validationResults;
    }

    /** Checks that given dicom attribute's name is not empty. Returns a list of item ids which fail and which pass the validation. */
    private validateDicomAttributeNamesAreNotEmpty(dicomAttributeRules: DicomAttributeRule[]): DicomAttributeValidationResults {
        const validationResults: DicomAttributeValidationResults = { passedIds: [], failedIds: [] };

        // this must be done for every dicom attribute rule in the dicom rule in one go,
        // not only just the one we just edited. otherwise we will have cases where other
        // validation rules will erroneously supercede this validation case when a dicom
        // attribute is being removed
        for (const attributeRule of dicomAttributeRules) {
            if (attributeRule.attribute !== undefined && attributeRule.attribute.trim() === '') {
                validationResults.failedIds.push(attributeRule.id);
            } else {
                validationResults.passedIds.push(attributeRule.id);
            }
        }

        return validationResults;
    }

    /** Validates that the dicom attribute specified in a dicom attribute rule is unique within its whole dicom rule
     * (i.e. no duplicates). Returns a list of item ids which fail and which pass the validation.
     */
    private validateAllDicomAttributeNamesHaveNoDuplicates(dicomAttributeRules: DicomAttributeRule[]): DicomAttributeValidationResults {
        const validationResults: DicomAttributeValidationResults = { passedIds: [], failedIds: [] };

        // first, collect all dicom attribute names and store their IDs
        const duplicateNames: { [attributeName: string]: string[] } = {};
        for (const attributeRule of dicomAttributeRules) {
            duplicateNames[attributeRule.attribute] = duplicateNames[attributeRule.attribute] || [];
            duplicateNames[attributeRule.attribute].push(attributeRule.id);
        }

        // then, invalidate all rules that share attribute names, and validate everything else
        for (const attributeName of Object.keys(duplicateNames)) {
            if (duplicateNames[attributeName].length > 1) {
                // set all dicom attribute IDs as invalid
                validationResults.failedIds.push(...duplicateNames[attributeName]);
            } else {
                // set as valid
                validationResults.passedIds.push(duplicateNames[attributeName][0]);
            }
        }

        return validationResults;
    }



    /** Perform form validation for given customization output and apply results to state. */
    public performFormValidationForOutput(state: T, outputId: string) {
        const results = this.calculateFormValidationErrorsForCustomizationOutput(state, outputId);

        // add new errors
        if (results.validationErrorsToAdd.length > 0) {
            this.formValidationErrorAdapter.addMany(state.formValidationErrors, results.validationErrorsToAdd);
        }

        // remove outdated errors
        if (results.validationErrorIdsToRemove.length > 0) {
            this.formValidationErrorAdapter.removeMany(state.formValidationErrors, results.validationErrorIdsToRemove);
        }
    }


    /// unit test helper export functions
    // these functions just export existing functions that are otherwise internal to this file
    // these should NOT be used outside of unit tests

    public _unitTest_calculateFormValidationErrorsForCustomizationOutput(state: T, outputId: string): FormValidationErrorCalculationResults {
        return this.calculateFormValidationErrorsForCustomizationOutput(state, outputId);
    }

    public _unitTest_validateDicomRuleHasAtLeastOneAttribute(dicomRule: DicomRule): DicomAttributeValidationResults {
        return this.validateDicomRuleHasAtLeastOneAttribute(dicomRule);
    }

    public _unitTest_validateDicomAttributeNamesAreNotEmpty(dicomAttributeRules: DicomAttributeRule[]): DicomAttributeValidationResults {
        return this.validateDicomAttributeNamesAreNotEmpty(dicomAttributeRules);
    }

    public _unitTest_validataAllDicomAttributeNamesHaveNoDuplicates(dicomAttributeRules: DicomAttributeRule[]): DicomAttributeValidationResults {
        return this.validateAllDicomAttributeNamesHaveNoDuplicates(dicomAttributeRules);
    }

}
