import { createSelector } from "@reduxjs/toolkit";
import { BackendValidationErrorViewModel, FormValidationError } from "../global-types/store-errors";
import { contouringSelectors } from "./contouringSlice";
import { ContouringRoi, isContouringCustomizationOutput } from "./contouring-types";
import { CustomizationBase, CustomizationBaseWithModelName, CustomizationObjectType, CustomizationOutput, Model, ModelVisibility } from "../global-types/customization-types";
import { naturalSort } from "../../util/sort";
import { userSelectors } from "../user/userSlice";
import { StoreState } from "../store";
import { generateCustomizationTreeViewItems, SelectorWithInputArg } from "../global-types/customizationSelectors";

////////////////////
//
// A collection of a bit more complex selectors (mainly factory functions) for contouringConfig.
// The more straightforward selectors are embedded directly into contouringConfigSlice.
//
////////////////////



/** Factory function for making a memoized selector that returns all form validation errors objects that are related to a specific roi customization. */
export const makeSelectFormValidationErrorsForRoi = (): SelectorWithInputArg<FormValidationError[], string> => createSelector(
    [(_, roiId: string) => roiId, contouringSelectors.selectFormValidationErrors],
    (roiId, formValidationErrors) => formValidationErrors.filter(e => e.itemId === roiId && e.itemType === CustomizationObjectType.Roi)
);

/** Factory function for making a memoized selector that returns all form validation errors objects that are related to a specific dicom rule. */
export const makeSelectFormValidationErrorsForDicomRule = (): SelectorWithInputArg<FormValidationError[], string> => createSelector(
    [(_, dicomRuleId: string) => dicomRuleId, contouringSelectors.selectFormValidationErrors],
    (dicomRuleId, formValidationErrors) => formValidationErrors.filter(e => e.itemId === dicomRuleId && e.itemType === CustomizationObjectType.DicomRule)
);

/** Factory function for making a memoized selector that returns all form validation errors objects that are related to a specific dicom attribute rule. */
export const makeSelectFormValidationErrorsForDicomAttributeRule = (): SelectorWithInputArg<FormValidationError[], string> => createSelector(
    [(_, dicomAttributeRuleId: string) => dicomAttributeRuleId, contouringSelectors.selectFormValidationErrors],
    (dicomAttributeRuleId, formValidationErrors) => formValidationErrors.filter(e => e.itemId === dicomAttributeRuleId && e.itemType === CustomizationObjectType.DicomAttributeRule)
);

/** Factory function for making a memoized selector that returns all roi customization objects that belong to a specific customization output. */
export const makeSelectRoiCustomizationsForOutput = (): SelectorWithInputArg<ContouringRoi[], string> => createSelector(
    [contouringSelectors.selectRois, (_, customizationOutputId: string) => customizationOutputId],
    (roiCustomizations, customizationOutputId) => roiCustomizations.filter(r => r.customizationOutputId === customizationOutputId)
);

/**
 * Factory function for making a memoized selector that returns true if any metadata for given output id is modified.
 */
export const makeSelectIsAnyMetadataForOutputModified = (): SelectorWithInputArg<boolean, string> => createSelector(
    [
        (state: StoreState, customizationOutputId: string) => contouringSelectors.selectOutputById(state, customizationOutputId),
        (state: StoreState) => contouringSelectors.selectOutputMetadataEntities(state)
    ],
    (customizationOutput, metadataEntities) => customizationOutput?.metadata.some(mId => metadataEntities[mId]?.isModified) || false
);

/**
 * Factory function for making a memoized selector that returns true if any ROI for given output id is modified.
 */
export const makeSelectIsAnyRoiForOutputModified = (): SelectorWithInputArg<boolean, string> => createSelector(
    [
        (state: StoreState, customizationOutputId) => contouringSelectors.selectOutputById(state, customizationOutputId),
        (state: StoreState) => contouringSelectors.selectRoiEntities(state)
    ],
    (customizationOutput, roiCustomizationEntities) => customizationOutput?.rois.some(rId => roiCustomizationEntities[rId]?.isModified) || false
);

/**
 * Factory function for making a memoized selector that returns true if any selection rule (trigger) for a customization base id is modified.
 */
export const makeSelectIsAnyTriggerForBaseModified = (): SelectorWithInputArg<boolean, string> => createSelector(
    [
        (state: StoreState, customizationBaseId) => contouringSelectors.selectCustomizationBaseById(state, customizationBaseId),
        (state: StoreState) => contouringSelectors.selectAeTitleRuleEntities(state),
        (state: StoreState) => contouringSelectors.selectDicomRuleEntities(state)
    ],
    (customizationBase, aeTitleRuleEntities, dicomRuleEntities) => {
        const isAnyAeTitleRuleModified = customizationBase?.aeTitleRules.some(aId => aeTitleRuleEntities[aId]?.isModified) || false;
        if (isAnyAeTitleRuleModified) { return true; }
        return customizationBase?.dicomRules.some(dId => dicomRuleEntities[dId]?.isModified === true) || false;
    }
);

/**
 * Returns customization base IDs and their names in alphabetical order.
 */
export const selectCustomizationBasesInNameOrder = createSelector(
    [(state: StoreState): CustomizationBase[] => contouringSelectors.selectCustomizationBases(state),
    (state: StoreState): Record<string, Model> => contouringSelectors.selectModelEntities(state),
    (state: StoreState): boolean => userSelectors.selectAreDeprecatedModelsShown(state),
    (state: StoreState): boolean => userSelectors.selectAreNonLicensedModelsShown(state),
    (state: StoreState): boolean => userSelectors.selectAreUserHiddenModelsShown(state)],
    (customizations, modelEntities, areDeprecatedModelsShown, areNonLicensedModelsShown, areUserHiddenModelsShown) => {

        // filter customizations per current user config
        const filteredCustomizations = customizations.filter(c => {
            const model = modelEntities[c.modelId];
            if (!model) { throw new Error(`Could not find model for customization ${c.customizationName} (${c.id})`); }
            if (model.visibility === ModelVisibility.AlwaysShown) { return true; }
            if (model.visibility === ModelVisibility.AlwaysHidden) { return areUserHiddenModelsShown; }
            if (!model.isAvailable && !areNonLicensedModelsShown) { return false; }
            if (model.isDeprecated && !areDeprecatedModelsShown) { return false; }
            return true;
        })

        const customizationNamesWithIds: CustomizationBaseWithModelName[] = filteredCustomizations.map(c => ({
            customizationBaseId: c.id,
            label: `${modelEntities[c.modelId]?.label || ''} ${c.customizationName}`
        }));

        return naturalSort(customizationNamesWithIds, 'label');
    });


/** Generate a tree view hierarchy of models and some of their child objects. This is used by the customization page side navigation.
 * This data isn't ready-to-go for Patternfly's TreeView component but the separate convertTreeViewDataIntoItemHierarchy helper function
 * in customization-helpers.tsx is used to generate the appropriate data from what this function produces.
 */
export const selectContourTreeViewItems = createSelector([
    (state: StoreState) => contouringSelectors.selectModelsInAlphabeticalOrder(state),
    (state: StoreState) => contouringSelectors.selectCustomizationBasesInAlphabeticalOrder(state),
    (state: StoreState) => contouringSelectors.selectOutputEntities(state),
    (state: StoreState) => contouringSelectors.selectOutputMetadataEntities(state),
    (state: StoreState) => contouringSelectors.selectCustomizationValidationErrorEntities(state),

],
    (models, customizationBases, outputEntities, metadataEntities, validationEntities) => {

        // create an inline validation function that will be supplied to tree view generator function
        const areOutputChildrenValid = (output: CustomizationOutput, validationEntities: Record<string, BackendValidationErrorViewModel>): boolean => {
            if (!isContouringCustomizationOutput(output)) {
                throw new Error('Invalid output type');
            }
            return output.rois.every(rId => !validationEntities[rId] || validationEntities[rId]!.type !== CustomizationObjectType.Roi);
        }

        const results = generateCustomizationTreeViewItems(models, customizationBases, outputEntities, metadataEntities, validationEntities, areOutputChildrenValid);
        return results;
    });
