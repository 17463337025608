import { Button, EmptyState, EmptyStateBody, Page, EmptyStateHeader, EmptyStateFooter,  } from '@patternfly/react-core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './logged-out-page.css';
import logo70b from '../../img/mvision-title.png';

const LoggedOutPage = () => {
    const {t} = useTranslation();

    return (
        <Page>
            <EmptyState>
                <div className="logged-out-logo">
                    <img src={logo70b} alt="MVision logo" />
                </div>

                <EmptyStateHeader titleText={<>{t('loggedOutPage.title')}</>} headingLevel="h4" />
                <EmptyStateBody>{t('loggedOutPage.body', { appName: t('appTitle') })}</EmptyStateBody><EmptyStateFooter>
                <Button component="a" variant="primary" href="./">{t('loggedOutPage.action')}</Button>
            </EmptyStateFooter></EmptyState>
        </Page >

    );
}

export default LoggedOutPage;
