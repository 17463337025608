import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { doseSelectors, dicomAttributeRuleRemoved, dicomAttributeRuleUpdated } from '../../../store/dose/doseSlice';
import DicomAttributeRuleForm from '../DicomAttributeRuleForm';
import { makeSelectFormValidationErrorsForDicomAttributeRule } from '../../../store/dose/doseSelectors';
import { ModelType } from '../../../store/global-types/customization-types';
import { ModelSelectionUndoState } from '../ModelSelectionPage';
import { StoreState } from '../../../store/store';



interface DoseDicomAttributeRuleProps {
    dicomAttributeRuleId: string,
    runningNumber: number,
    setUndoState: (undoState: ModelSelectionUndoState) => void,
    onRemoveDicomRule: () => void,
    isUndoDisabled?: boolean,
}

const DoseDicomAttributeRule = (props: DoseDicomAttributeRuleProps) => {
    const { dicomAttributeRuleId, runningNumber, setUndoState, onRemoveDicomRule, isUndoDisabled } = props;

    const dispatch = useDispatch();
    const daemonConfigs = useSelector((state: StoreState) => state.daemon.daemonConfigs);

    const dicomAttributeRule = useSelector((state: StoreState) => doseSelectors.selectDicomAttributeRuleById(state, dicomAttributeRuleId));

    const selectFormValidationErrorsForRule = useMemo(makeSelectFormValidationErrorsForDicomAttributeRule, []);
    const formValidationErrors = useSelector((state: StoreState) => selectFormValidationErrorsForRule(state, dicomAttributeRuleId));

    // TODO: implement retainedDicomAttributes calculation if necessary
    const retainedDicomAttributes = {};
    // // Calculate retainedDicomAttributes when daemonConfigs changes
    // const retainedDicomAttributes = useMemo(() => {
    //     if (!daemonConfigs) {
    //         // Return empty object when daemonConfigs is not initialized
    //         return {};
    //     }
    //     return mapRetainedDicomAttributes(daemonConfigs);
    // }, [daemonConfigs]);

    const handleRemoveDicomAttribute = useCallback(() => {
        dispatch(dicomAttributeRuleRemoved(dicomAttributeRuleId));
    }, [dispatch, dicomAttributeRuleId, dicomAttributeRuleRemoved]);

    const handleUpdateDicomAttribute = useCallback((attribute: string, value: string) => {
        dispatch(dicomAttributeRuleUpdated({ id: dicomAttributeRuleId, dicomAttribute: attribute, dicomValue: value }));
    }, [dispatch, dicomAttributeRuleId, dicomAttributeRuleUpdated]);



    // don't render anything if we don't have a valid object
    if (dicomAttributeRule === undefined) {
        return null;
    }

    return (
        <DicomAttributeRuleForm
            modelType={ModelType.Dose}
            dicomAttributeRule={dicomAttributeRule}
            formValidationErrors={formValidationErrors}
            runningNumber={runningNumber}
            setUndoState={setUndoState}
            isUndoDisabled={isUndoDisabled}
            retainedDicomAttributes={retainedDicomAttributes}
            onRemoveDicomRule={onRemoveDicomRule}
            onDicomAttributeRuleRemoved={handleRemoveDicomAttribute}
            onDicomAttributeRuleUpdated={handleUpdateDicomAttribute}
        />
    );
}

export default DoseDicomAttributeRule;
