import { useCallback, useEffect, useMemo, useState } from 'react';
import { Text, Switch, Toolbar, ToolbarContent, ToolbarItem, Button, } from '@patternfly/react-core';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import { Trans, useTranslation } from 'react-i18next';
import { PlusIcon } from '@patternfly/react-icons'

import RoiCustomizationForm from './RoiCustomizationForm';
import '../customization-page.css';
import SectionLabel from '../SectionLabel';
import { columnIds, columnLangKeys, columnTooltips } from './ContourRoiTable';
import { StoreState } from '../../../store/store';
import { appConfigSelectors } from '../../../store/appConfig/appConfigSlice';
import { advancedRoiCustomizationAdded, allRoisInCustomizationOutputToggled, contouringSelectors, metadataItemAdded, metadataItemRemoved, metadataItemUpdated, modelCustomizationDescriptionUpdated, modelVisibilityUpdated, scrollToViewFromModelCustomizationMetadataRemoved } from '../../../store/contouring/contouringSlice';
import { makeSelectIsAnyMetadataForOutputModified, makeSelectIsAnyRoiForOutputModified, makeSelectIsAnyTriggerForBaseModified, makeSelectRoiCustomizationsForOutput } from '../../../store/contouring/contouringSelectors';
import { CustomizationObjectType, ModelVisibility } from '../../../store/global-types/customization-types';
import { CONTOUR_METADATA_ATTRIBUTE_PRESETS } from '../../../store/contouring/contouring-types';
import ContourSelectionRules from '../../selection/contour/ContourSelectionRules';
import CustomizationSection from '../CustomizationSection';
import CustomizationForm from '../CustomizationForm';
import { generateNewId } from '../../../store/global-types/customization-helpers';

interface ContourCustomizationFormProps {
    customizationOutputId: string,
}

const ContourCustomizationForm = (props: ContourCustomizationFormProps) => {
    const { customizationOutputId } = props;
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);

    const customizationOutput = useSelector((state: StoreState) => contouringSelectors.selectOutputById(state, customizationOutputId));
    const customizationBase = useSelector((state: StoreState) => customizationOutput ? contouringSelectors.selectCustomizationBaseById(state, customizationOutput.modelCustomizationBaseId) : undefined);
    const model = useSelector((state: StoreState) => customizationBase ? contouringSelectors.selectModelById(state, customizationBase.modelId) : undefined);
    const validationEntities = useSelector(contouringSelectors.selectCustomizationValidationErrorEntities);
    const outputMetadata = useSelector(contouringSelectors.selectOutputMetadataEntities);
    const originalMetadata = useSelector(contouringSelectors.selectOriginalOutputMetadataEntities);

    const selectRoiCustomizationsForOutput = useMemo(makeSelectRoiCustomizationsForOutput, []);
    const roiCustomizations = useSelector((state: StoreState) => selectRoiCustomizationsForOutput(state, customizationOutputId));

    const selectIsAnyRoiForOutputModified = useMemo(makeSelectIsAnyRoiForOutputModified, []);
    const isAnyRoiModified = useSelector((state: StoreState) => selectIsAnyRoiForOutputModified(state, customizationOutputId));

    const selectIsAnyMetadataForOutputModified = useMemo(makeSelectIsAnyMetadataForOutputModified, []);
    const isAnyMetadataModified = useSelector((state: StoreState) => selectIsAnyMetadataForOutputModified(state, customizationOutputId));

    const selectIsAnyTriggerRuleForBaseModified = useMemo(makeSelectIsAnyTriggerForBaseModified, []);
    const isAnyTriggerRuleModified = useSelector((state: StoreState) => customizationOutput ? selectIsAnyTriggerRuleForBaseModified(state, customizationOutput.modelCustomizationBaseId) : false);

    const [isRoisSectionExpanded, setRoisSectionExpanded] = useState(true);
    const [areAllRoisIncluded, setAreAllRoisIncluded] = useState(true);

    const handleToggleRoiCustomizationExpanded = useCallback(() => {
        setRoisSectionExpanded(!isRoisSectionExpanded);
    }, [isRoisSectionExpanded]);

    const onSetModelVisibility = useCallback((modelId: string, visibility: ModelVisibility) => {
        dispatch(modelVisibilityUpdated({ modelId, visibility }));
    }, [dispatch]);

    const onDescriptionChanged = useCallback((text: string) => {
        if (customizationBase) {
            dispatch(modelCustomizationDescriptionUpdated({ customizationBaseId: customizationBase.id, description: text }));
        }
    }, [dispatch, customizationBase]);

    const handleSelectAllRois = useCallback((_: unknown, includeAllRois: boolean) => {
        if (customizationOutput) {
            dispatch(allRoisInCustomizationOutputToggled({ customizationOutputId: customizationOutput.id, isIncluded: includeAllRois }));
            setAreAllRoisIncluded(includeAllRois);
        }
    }, [dispatch, customizationOutput]);

    const handleAddMetadataEntry = useCallback((customizationOutputId: string) => {
        dispatch(metadataItemAdded(customizationOutputId));
    }, [dispatch]);

    const handleAddAdvancedStructure = useCallback(() => {
        const newRoiId = generateNewId();
        dispatch(advancedRoiCustomizationAdded({ outputId: customizationOutputId, newRoiId: newRoiId }));
    }, [dispatch, customizationOutputId]);


    useEffect(() => {
        // update the state of the "all rois included" switch
        if (customizationOutput && customizationOutput.rois.length > 0) {
            setAreAllRoisIncluded(roiCustomizations.every(r => r.isIncluded));
        }
    }, [customizationOutput, customizationBase, roiCustomizations]);

    const onMetadataItemUpdated = useCallback((metadataId: string, attribute: string | undefined, value: string | undefined, isUndoOperation?: boolean) => {
        dispatch(metadataItemUpdated({ metadataId, attribute, value, isUndoOperation }));
    }, [dispatch, metadataItemUpdated]);

    const onMetadaItemRemoved = useCallback((metadataId: string) => {
        dispatch(metadataItemRemoved(metadataId));
    }, [dispatch, metadataItemRemoved]);

    const removeScrollToViewFromMetadataItem = useCallback((metadataId: string) => {
        dispatch(scrollToViewFromModelCustomizationMetadataRemoved(metadataId));
    }, [dispatch, scrollToViewFromModelCustomizationMetadataRemoved]);


    const includedCount = useMemo(() => roiCustomizations.filter(r => r.isIncluded).length, [roiCustomizations]);
    const totalStructureCount = roiCustomizations.length;

    // sanity check that we have retrieved valid objects from redux store
    // (this check has to be done after all hooks calls)
    if (customizationOutput === undefined || customizationBase === undefined || model === undefined) {
        return null;
    }


    const roisHaveValidationErrors = customizationOutput.rois.some(rId => validationEntities[rId] !== undefined && validationEntities[rId]!.type === CustomizationObjectType.Roi);

    const roisSectionLabel = <SectionLabel
        label={t('customizationPage.roisSection.title')}
        isModified={isAnyRoiModified}
        isValid={!roisHaveValidationErrors}
        itemCount={customizationOutput.rois.length} />

    return (
        <CustomizationForm
            model={model}
            customizationBase={customizationBase}
            customizationOutput={customizationOutput}
            validationEntities={validationEntities}
            outputMetadata={outputMetadata}
            originalMetadata={originalMetadata}
            metadataPresets={CONTOUR_METADATA_ATTRIBUTE_PRESETS}
            isAnyMetadataModified={isAnyMetadataModified}
            isAnyTriggerRuleModified={isAnyTriggerRuleModified}
            onAddMetadataEntry={handleAddMetadataEntry}
            onSetModelVisibility={onSetModelVisibility}
            onDescriptionChanged={onDescriptionChanged}
            onMetadataItemUpdated={onMetadataItemUpdated}
            onMetadaItemRemoved={onMetadaItemRemoved}
            removeScrollToViewFromMetadataItem={removeScrollToViewFromMetadataItem}
            contentSummary={
                (<Text component="p">
                    <Trans i18nKey="customizationPage.includedCounts">
                        Currently structure sets generated with this customization will include <b>{{ includedCount }}</b> out of <b>{{ totalStructureCount }}</b> available structures.
                    </Trans>
                </Text>
                )}
            selectionRules={(
                <ContourSelectionRules
                    customizationBaseId={customizationBase.id}
                    isUndoDisabled={true}
                    setUndoState={() => { }}
                    heading={`model-selection-for-${customizationBase.id}`}
                    hideCustomizationName
                    showMultipleOutputsWarning={customizationBase.outputs.length > 1}
                />
            )}
        >

            <CustomizationSection
                label={roisSectionLabel}
                collapsedMessage={t('customizationPage.roisSection.message')}
                isExpanded={isRoisSectionExpanded}
                onClick={handleToggleRoiCustomizationExpanded}
            >
                <Table variant='compact' isStickyHeader className="mv-sticky-fix">
                    <Thead aria-label='Contour ROIs'>
                        <Tr className='customization-form-table unselectable'>
                            <Th aria-label='Is modified?'></Th>
                            <Th aria-label='Structure Name'>{t(columnLangKeys[columnIds.name])}</Th>
                            <Th aria-label='Is structure included in structure set?' info={{ tooltip: columnTooltips[columnIds.isIncluded]() }} className='enabled-col-th'>{t(columnLangKeys[columnIds.isIncluded])}</Th>
                            <Th aria-label='Color'>{t(columnLangKeys[columnIds.color])}</Th>
                            <Th aria-label='Operation' info={{ tooltip: columnTooltips[columnIds.operation](deploymentInfo?.operationsManualUrl) }}>{t(columnLangKeys[columnIds.operation])}</Th>
                            <Th aria-label='Interpreted Type'>{t(columnLangKeys[columnIds.interpretedType])}</Th>
                            <Th aria-label='Coding scheme value'>{t(columnLangKeys[columnIds.fmaCodeValue])}</Th>
                            <Th aria-label='Coding scheme code meaning'>{t(columnLangKeys[columnIds.fmaCodeMeaning])}</Th>
                            <Th aria-label='Coding scheme designator'>{t(columnLangKeys[columnIds.fmaCodeSchemeDesignator])}</Th>
                            <Th aria-label='Action menu'></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td></Td>
                            <Td></Td>
                            <Td aria-label="Toggle inclusion of all structures">
                                <Switch
                                    aria-label="Toggle inclusion of all structures"
                                    id={'select-all-rois-switch'}
                                    isChecked={areAllRoisIncluded}
                                    onChange={handleSelectAllRois}
                                />
                            </Td>
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                            <Td></Td>
                        </Tr>

                        {customizationOutput.rois.map(rId => <RoiCustomizationForm roiId={rId} key={rId} roiIdList={customizationOutput.rois} />)}
                    </Tbody>
                </Table>
                <div className="contour-rois-toolbar-section">
                    <Toolbar>
                        <ToolbarContent>
                            <ToolbarItem>
                                <Button
                                    variant="secondary"
                                    icon={<PlusIcon />}
                                    onClick={handleAddAdvancedStructure}>{t('customizationToolbar.addAdvancedRoi')}</Button>
                            </ToolbarItem>
                        </ToolbarContent>
                    </Toolbar>
                </div>
            </CustomizationSection>
        </CustomizationForm>
    );
}

export default ContourCustomizationForm;
