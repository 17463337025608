/** 
 *  UNITS
 * 
 * This file contains unit types for units where we want to keep track of the exact factor of the unit we're dealing 
 * with, such as dose or length -- e.g. when handling lengths it's important to keep track whether the units are
 * in centimeters or millimeters. The types defined in this file keep track of which unit factor their values are
 * in.
 * 
 * Any units defined in this file should ONLY be handled with these unit types and their respective helper functions
 * throughout the application -- using JavaScript Number objects for values of these units is strongly discouraged.
 * 
 * The getter helper functions defined in this file should be used to get correct values out of these units. Which
 * helper to use depends a bit on the context. For contexts where the factor of the unit will always be the same
 * you should use the getXInY functions (e.g. getDoseInGy). For context where the factor of the unit may depend
 * on how the UI is configured (via e.g. user or clinic preferences) it's better to use the getXValue (e.g. getDoseValue)
 * functions instead.
 * 
 * */

import { round } from "lodash-es";
import { SelectedDoseUnit } from "./customization-types";

const RADIATION_DOSE_ROUND_TO_DECIMALS = 2;
const LENGTH_ROUND_TO_DECIMALS = 2;

/** Units of dose radiation. NOTE: currently unused. */
export enum RadiationDoseUnit {
    /** Radiation dose unit as greys */
    DoseInGy = 'DoseInGy',
    /** Radiation dose unit as centigreys */
    DoseInCGy = 'DoseInCCGy',
}

/** Units of length */
export enum LengthUnit {
    /** Length unit as millimeters */
    LengthInMm = 'LengthInMm',
    /** Length unit as centimeters */
    LengthInCm = 'LengthInCm',
}


/** A value of a radiation dose. NOTE: currently unused. */
export type RadiationDoseValue = {
    /** The value of this radiation dose in greys (Gy) */
    _doseInGy: number;
}

/** A value of a length */
export type LengthValue = {
    /** The value of this length in millimeters (mm) */
    _lengthInMm: number;
}


/** Returns value of given dose as a number of given unit */
export const getRadiationDoseValue = (dose: RadiationDoseValue, unit: RadiationDoseUnit): number => {
    switch (unit) {
        case RadiationDoseUnit.DoseInGy:
            return getRadiationDoseInGy(dose);
        case RadiationDoseUnit.DoseInCGy:
            return getRadiationDoseInCGy(dose);
        default:
            throw new Error(`Unsupported unit: ${unit}`);
    }
}

/** Returns value of given length as a number of given unit */
export const getLengthValue = (length: LengthValue, unit: LengthUnit): number => {
    switch (unit) {
        case LengthUnit.LengthInMm:
            return getLengthInMm(length);
        case LengthUnit.LengthInCm:
            return getLengthInCm(length);
        default:
            throw new Error(`Unsupported unit: ${unit}`);
    }
}


/** Returns the value of given dose in greys. */
export const getRadiationDoseInGy = (dose: RadiationDoseValue): number => dose._doseInGy;

/** Returns the value of given dose in centigreys. */
export const getRadiationDoseInCGy = (dose: RadiationDoseValue): number => dose._doseInGy * 100;

/** Returns the value of given length in millimeters. */
export const getLengthInMm = (length: LengthValue): number => length._lengthInMm;

/** Returns the value of given length in centimeters. */
export const getLengthInCm = (length: LengthValue): number => round(length._lengthInMm / 10, LENGTH_ROUND_TO_DECIMALS);



/** Creates a RadiationDoseValue object from given value of given unit */
export const createRadiationDose = (value: number, unitOfValue: RadiationDoseUnit): RadiationDoseValue => {
    switch (unitOfValue) {
        case RadiationDoseUnit.DoseInCGy:
            // include extra rounding padding for cGy -> Gy conversion accuracy
            return { _doseInGy: round(value / 100, RADIATION_DOSE_ROUND_TO_DECIMALS + 2) };
        case RadiationDoseUnit.DoseInGy:
            return { _doseInGy: round(value, RADIATION_DOSE_ROUND_TO_DECIMALS) };
        default:
            throw new Error(`Unsupported unit: ${unitOfValue}`);
    }
}

/** Creates a LengthValue object from given value of given unit */
export const createLength = (value: number, unitOfValue: LengthUnit): LengthValue => {
    switch (unitOfValue) {
        case LengthUnit.LengthInMm:
            return { _lengthInMm: round(value, LENGTH_ROUND_TO_DECIMALS) };
        case LengthUnit.LengthInCm:
            return { _lengthInMm: round(value * 10, LENGTH_ROUND_TO_DECIMALS) };
        default:
            throw new Error(`Unsupported unit: ${unitOfValue}`);
    }
}


/** Converts a SelectedDoseUnit enum into a RadiationDoseUnit, or throws if cannot convert. */
export const convertSelectedDoseUnitToRadiationDoseUnit = (selectedDoseUnit: SelectedDoseUnit): RadiationDoseUnit => {
    switch (selectedDoseUnit) {
        case SelectedDoseUnit.Gy:
            return RadiationDoseUnit.DoseInGy
        case SelectedDoseUnit.cGy:
            return RadiationDoseUnit.DoseInCGy
        default:
            throw new Error(`Unsupported unit: ${selectedDoseUnit}`);
    }
}
