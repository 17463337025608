import { ExternalLinkAltIcon } from '@patternfly/react-icons'

interface ExternalLinkProps {
    /** The URL for this external link */
    url: string;
    className?: string;
}

/** Represents an external link in UI that opens in a new tab/window. */
const ExternalLink = (props: React.PropsWithChildren<ExternalLinkProps>) => {
    const { url, children, className } = props;

    return (
        <span>
            <a href={url} target="_blank" rel="noopener noreferrer" className={className}>{children}
                <span className="external-link-icon">
                    <ExternalLinkAltIcon />
                </span>
            </a>
        </span>
    );
}

export default ExternalLink;
