import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomizationBasesInNameOrder } from '../../../store/contouring/contouringSelectors';
import { allCustomizationChangesReverted, contouringSelectors, modelCustomizationSaved, modelSelectionChangesReverted } from '../../../store/contouring/contouringSlice';
import { StoreState } from '../../../store/store';
import { configurationTargetSelectors } from '../../../store/configurationTarget/configurationTargetSlice';
import ModelSelectionPage from '../ModelSelectionPage';
import { ModelSelectionScope, ModelType } from '../../../store/global-types/customization-types';

const ContourModelSelectionPage = () => {

    const dispatch = useDispatch();

    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);

    const customizationBaseIdsInOrder = useSelector((state: StoreState) => selectCustomizationBasesInNameOrder(state));
    const customizationBaseEntities = useSelector(contouringSelectors.selectCustomizationBaseEntities)
    const isAnyCustomizationModelModified = useSelector(contouringSelectors.selectIsAnyCustomizationModelModified);
    const isEverySelectionRuleValid = useSelector(contouringSelectors.selectIsEverySelectionRuleValid);

    const modelCustomizationSaveError = useSelector((state: StoreState) => state.contouring.modelCustomizationSaveError);
    const isSavingInProgress = useSelector((state: StoreState) => state.contouring.isModelCustomizationSavingInProgress);
    const customizationFetchError = useSelector((state: StoreState) => state.contouring.customizationFetchError);
    const validationErrorEntities = useSelector(contouringSelectors.selectCustomizationValidationErrorEntities);

    const isContentAvailable = currentTarget !== undefined && !!customizationBaseIdsInOrder;

    const handleUndo = useCallback(() => {
        if (!isSavingInProgress) {
            dispatch(allCustomizationChangesReverted());
            return true;
        }

        return false;
    }, [dispatch, isSavingInProgress]);

    const handleSave = useCallback(() => {
        if (!isSavingInProgress) {
            if (!currentTarget) {
                throw new Error('No configuration target selected, cannot save');
            }
            dispatch(modelCustomizationSaved(currentTarget));
            return true;
        }

        return false;
    }, [dispatch, isSavingInProgress, currentTarget]);

    const handleSingleUndo = useCallback((scope: ModelSelectionScope, id: string) => {
        dispatch(modelSelectionChangesReverted({ revertScope: scope, id }));
        return true;
    }, [dispatch]);


    return (<ModelSelectionPage
        modelType={ModelType.Contouring}
        customizationBaseIdsInOrder={customizationBaseIdsInOrder}
        customizationBaseEntities={customizationBaseEntities}
        isSavingInProgress={isSavingInProgress}
        onSave={handleSave}
        onUndo={handleUndo}
        onSingleUndo={handleSingleUndo}
        isAnyCustomizationModelModified={isAnyCustomizationModelModified}
        isEverySelectionRuleValid={isEverySelectionRuleValid}
        isContentAvailable={isContentAvailable}
        customizationFetchError={customizationFetchError}
        validationErrorEntities={validationErrorEntities}
        modelCustomizationSaveError={modelCustomizationSaveError}
    />);
};

export default ContourModelSelectionPage;
