import { has, isNumber } from "lodash-es";
import { AeTitleRule, CustomizationBase, CustomizationOutput, DicomAttributeRule, DicomRule, SelectedDoseUnit, isCustomizationOutput, Model, OutputMetadataItem } from "../global-types/customization-types";
import { LengthValue } from "../global-types/units";

export enum TargetMethod { NotSet = 'not_set', Variable = 'variable', Fixed = 'fixed' };

export enum DoseScalingMethod { NotSet = 'not_set', Volume = 'volume', Mean = 'mean' };

export const EXPORT_DOSE_JSON_FILE_PREFIX = 'mvision-dose-';

/** Prefix to use for dose prediction AE Titles */
export const AETITLE_PREFIX_DOSE = 'MVDP';

/** A single customization output (ie. a result file) for a dose model. A customization base contains one or more of these. */
export type DoseCustomizationOutput = CustomizationOutput & {

    /** List of OARs used during dose prediction. */
    rois: string[]; // DoseRoi ids

    /** List of target ROIs (i.e. PTV) used during dose prediction. */
    targets: string[]; // DoseTarget ids

    /** The method to scale the predicted dose map values. */
    doseScaling: DoseScaling;

    /** Pixel spacing */
    pixelSpacing: LengthValue;

    /** The method used to crop the dose in the Z-direction. */
    doseCropping: DoseCropping;

    /** Whether to return the RTPLAN or not. */
    isRTPlanIncluded: boolean;

    /** Whether to set BeamSequence for RTPLAN or not. */
    isBeamIncluded: boolean;

    /** Machine type used in the RTPLAN file. */
    machineType: string;

    /** Machine name used in the RTPLAN file. */
    machineName: string;

    /** Method of finding the target. Either "variable" for getting the prescription dose from the ROI name, 
     * or "fixed" for a fixed dose if the regular expression is matched. Applies to all target ROIs. */
    targetMethod: TargetMethod,

    /** Unit of radiation used in with this customization's dose targets. */
    targetUnit: SelectedDoseUnit,
};

/** Customization settings for a single dose ROI. */
export type DoseRoi = {
    /** Redux ID of this dose ROI. */
    id: string;

    /** ROI name. */
    name: string;
    /** Regular expression used to find this ROI. */
    regExp: string;
    /** This ROI must be found for the dose prediction to work. If this ROI is not found, then the model may not process the task or produce bad quality dose prediction. */
    isMandatory: boolean;

    /** True if this roi customization has unsaved changes, false otherwise. */
    isModified: boolean;
    // /** If true, scroll the viewport to show this item when it's mounted into DOM. This is used to auto-scroll to newly added items. */
    // scrollToView: boolean;

    /** Parent model customization output this roi customization belongs to. */
    outputId?: string;  // this is optional because it's usually set post-constructor
    // /** Parent global roi customization this roi customization belongs to, if any. */
    // globalRoiId?: string | undefined;  // this is optional because it's usually set post-constructor
};

/** A target ROI (i.e. PTV) used during dose prediction. */
export type DoseTarget = {
    /** Redux ID of this dose target. */
    id: string;

    /** Name of the ROI to target. This is user-definable and purely to help users distinguish between different
     * dose targets in a customization -- this field is not used for anything by the actual algorithm. */
    roiName: string;

    /** Regular expression to find target ROIs, optionally including a method to extract the PTV prescription. Only used with variable method. */
    regExp: string;

    /** Fixed prescription of the target ROI. If set as number then the unit of the number is read from parent output object. */
    prescription: number | null;

    /** True if this dose target roi customization has unsaved changes, false otherwise. */
    isModified: boolean;

    /** Parent model customization output this dose target roi customization belongs to. */
    outputId?: string;
};

/** The method to scale the predicted dose map values. */
export type DoseScaling = {
    /** Boolean to flag whether this method will be used or not. NOTE: not user-settable, dose scaling is always on. */
    isActive: boolean;

    /** Dose scaling method. "volume" is volume attribute is used, "mean" otherwise  */
    method: DoseScalingMethod;

    /** In "mean" method: expected relative PTV mean dose.
     * In "volume" method: expected relative dose. */
    dose: number;

    /** Relative volume of the maximum PTV that needs to have a certain minimum dose. Only used with "volume" method. */
    volume: number;
};

/** The method used to crop the dose. */
export type DoseCropping = {
    /** Boolean to flag whether this method will be used or not. */
    isEnabled: boolean;

    /** The distance away from the ROI union that will be cropped away (in Z-direction). */
    zMargin: LengthValue;
};

/** A full collection of dose customization-related entities and any
 * related internal helper objects. */
export type DoseCustomizationEntities = {
    models: Model[];
    customizationBases: CustomizationBase[];
    doseOutputs: DoseCustomizationOutput[];
    outputMetadata: OutputMetadataItem[];
    doseRois: DoseRoi[];
    doseTargets: DoseTarget[];
    // globalRoiCustomizations: GlobalContouringRoi[];
    aeTitleRules: AeTitleRule[];
    dicomRules: DicomRule[];
    dicomAttributeRules: DicomAttributeRule[];
};

/** A full collection of dose customization-related entities without
 * any internal helper entities that are not needed for export.
 * NOTE: as of now this is identical with DoseCustomizationEntities but
 * it's possible these will diverge in future. */
export type DoseCustomizationEntitiesForExport = {
    models: Model[];
    customizationBases: CustomizationBase[];
    doseOutputs: DoseCustomizationOutput[];
    outputMetadata: OutputMetadataItem[];
    doseRois: DoseRoi[];
    doseTargets: DoseTarget[];
    aeTitleRules: AeTitleRule[];
    dicomRules: DicomRule[];
    dicomAttributeRules: DicomAttributeRule[];
};

/** View model entities related to dose output */
export type DoseCustomizationOutputEntities = {
    outputs: DoseCustomizationOutput[];
    metadata: OutputMetadataItem[];
    rois: DoseRoi[];
    targets: DoseTarget[];
};


// *** type guards ***

export const isDoseCustomizationOutput = (obj: any): obj is DoseCustomizationOutput => {
    const output = obj as DoseCustomizationOutput;
    return isCustomizationOutput(obj)
        && output.rois !== undefined
        && output.targets !== undefined;
}

export const isDoseRoi = (obj: any): obj is DoseRoi => {
    const roi = obj as DoseRoi;
    return roi.regExp !== undefined
        // must not look like ContouringRoi or GlobalContouringRoi
        && !has(roi, 'operation')
        && !has(roi, 'color')
        && !has(roi, 'coveredRois');
}

export const isDoseTarget = (obj: any): obj is DoseTarget => {
    const target = obj as DoseTarget;
    return target.regExp !== undefined && (target.prescription === null || isNumber(target.prescription));
}
