import React, { useCallback, useEffect, useRef, useState } from 'react';
import DebouncedTextInput, { VALIDATION_DEFAULT, VALIDATION_ERROR, VALIDATION_WARNING } from './debounced-text-input';
import { useTranslation } from 'react-i18next';
import ValidationHelperText from './validation-helper-text';
import { round } from 'lodash-es';

interface NumberInputProps {
    fieldId: string,
    defaultValue: number | undefined,
    /** onChange function will only be called if the current number passes validation */
    onChange: (value: number) => void,
    /** Minimum allowed value for validation */
    lowerBound?: number,
    /** Maximum allowed value for validation */
    upperBound?: number,
    /** Optional unit to display in validation messages */
    unit?: string,
}

enum NumberValidationState {
    Ok = 'Ok',
    InvalidNumber = 'InvalidNumber',
    OutOfBounds = 'OutOfBounds',
}

const getInputValidationState = (numberValidationState: NumberValidationState) => {
    switch (numberValidationState) {
        case NumberValidationState.Ok:
            return VALIDATION_DEFAULT;
        case NumberValidationState.InvalidNumber:
            return VALIDATION_ERROR;
        case NumberValidationState.OutOfBounds:
            return VALIDATION_WARNING;
        default:
            throw new Error(`Invalid validation state: ${numberValidationState}`);
    }
}

const validateNumber = (value: string, lowerBound?: number, upperBound?: number): { validationResult: NumberValidationState, valueAsNumber: number } => {
    const valueAsNumber = parseFloat(value);

    // 1. Check if number is valid
    let validationResult = !isNaN(valueAsNumber) ? NumberValidationState.Ok : NumberValidationState.InvalidNumber;

    // 2. Check if number is out of bounds
    if (validationResult === NumberValidationState.Ok && lowerBound !== undefined) { if (valueAsNumber < lowerBound) { validationResult = NumberValidationState.OutOfBounds; } }
    if (validationResult === NumberValidationState.Ok && upperBound !== undefined) { if (valueAsNumber > upperBound) { validationResult = NumberValidationState.OutOfBounds; } }

    return { validationResult, valueAsNumber };
}

/**
 * NumberInput is a UI component that allows users to enter strings which are parsed as numbers.
 * @param props 
 * @returns 
 */
const NumberInput = (props: NumberInputProps) => {
    const { fieldId, defaultValue, onChange, lowerBound, upperBound, unit } = props;

    const { t } = useTranslation();

    const [validationState, setValidationState] = useState<NumberValidationState>(NumberValidationState.Ok);
    const [internalValue, setInternalValue] = useState('');

    const inputRef = useRef<HTMLInputElement | null>(null);

    // perform internal setup on initialization & when defaultValue changes
    useEffect(() => {
        setInternalValue(defaultValue !== undefined ? defaultValue.toString() : '');
    }, [defaultValue]);

    // perform validation for the typed-in number both immediately and on a debounce
    const handleChange = useCallback((value: string) => {
        setInternalValue(value);
        const { validationResult, valueAsNumber } = validateNumber(value, lowerBound, upperBound);

        setValidationState(validationResult);

        if (validationResult === NumberValidationState.Ok) {
            onChange(valueAsNumber);

            // update internal value with the internal value converted into a regular number
            // (which in practice converts the value to a "regular" number e.g. "1." -> 1, ".1" -> 0.1, "1e2" -> 100)
            // IF the input value was valid AND this component is no longer focused
            // (we don't want to set the value of the internal value while user is editing it)
            if (!inputRef.current || !document.activeElement || document.activeElement !== inputRef.current) {
                setInternalValue(valueAsNumber.toString());
            }
        }

        return validationResult;
    }, [onChange]);

    const handleBlur = useCallback((value: string) => {
        // don't do anything if user never changed the value (or reverted it back to the original one), otherwise...
        if (value !== defaultValue?.toString() || '') {
            // revert form back to previous valid value on blur if validation is currently not ok
            const result = handleChange(value);
            if (result !== NumberValidationState.Ok) {
                handleChange(defaultValue?.toString() || '');
            }
        }
    }, [onChange, defaultValue]);

    const getValidationErrorMessage = useCallback((numberValidationState: NumberValidationState) => {

        let lower = lowerBound !== undefined ? new Intl.NumberFormat(undefined, { minimumFractionDigits: 1, }).format(lowerBound) : lowerBound;
        let upper = upperBound !== undefined ? new Intl.NumberFormat(undefined, { minimumFractionDigits: 1, }).format(upperBound) : upperBound;

        // add unit strings to bound values
        if (unit !== undefined) {
            lower = `${lower} ${unit}`;
            upper = `${upper} ${unit}`;
        }

        switch (numberValidationState) {
            case NumberValidationState.Ok:
                return undefined;
            case NumberValidationState.InvalidNumber:
                return t('component.numberInput.validation.enterValidNumber');
            case NumberValidationState.OutOfBounds:
                if (lowerBound !== undefined && upperBound !== undefined) {
                    return t('component.numberInput.validation.outOfBounds.both', { lowerBound: lower, upperBound: upper });
                }
                else if (lowerBound === undefined && upperBound !== undefined) {
                    return t('component.numberInput.validation.outOfBounds.upperBound', { upperBound: upper });
                } else if (lowerBound !== undefined && upperBound === undefined) {
                    return t('component.numberInput.validation.outOfBounds.lowerBound', { lowerBound: lower });
                }
                else {
                    throw new Error('No bounds were defined!');
                }
            default:
                throw new Error(`Invalid validation state: ${numberValidationState}`);
        }
    }, [unit, lowerBound, upperBound]);

    return (
        <div>
            <DebouncedTextInput
                fieldId={fieldId}
                defaultValue={internalValue}
                onDebouncedChange={handleChange}
                onImmediateChange={handleChange}
                onBlur={handleBlur}
                type="text"
                validated={getInputValidationState(validationState)}
                debounceTimeMs={150}
                inputRef={inputRef}
            />
            <ValidationHelperText
                validated={getInputValidationState(validationState)}
                helperText={getValidationErrorMessage(validationState)}
            />
        </div>
    );
}

export default NumberInput;
