import { isNumber, isObject } from "lodash-es";
import { DoseCustomizationEntities, DoseCustomizationEntitiesForExport, DoseCustomizationOutput, DoseCustomizationOutputEntities, DoseRoi, DoseScaling, DoseScalingMethod, DoseTarget, TargetMethod, DoseCropping } from "./dose-types";
import { CustomizationBase, OutputMetadataItem, isAeTitleRule, METADATA_FILENAME_ATTRIBUTE, SelectedDoseUnit, ModelType, isDicomRule, Model, AeTitleRule, DicomRule, DicomAttributeRule } from "../global-types/customization-types";
import { createNewModelCustomizationBase, generateNewId, UI_ID_ATTRIBUTE } from "../global-types/customization-helpers";
import { assertExpectedProps } from "../../util/expected-prop";
import { _convertOutputMetadataViewModelToJson, convertCustomizationJsonObjectToViewModels, convertCustomizationViewModelsToJson, convertSingleCustomizationJsonEntryToViewModelObjects, CustomizationBaseExtractResult, extractOutputMetadata, ModelTriggersByCustomizationNames } from "../global-types/view-model-conversion-helpers";
import { naturalSort } from "../../util/sort";
import { enumFromStringOrDefault } from "../../util/enum";
import { createLength, LengthUnit, LengthValue, getLengthInCm } from "../global-types/units";

export const DEFAULT_DOSE_REGEX_VARIABLE = '(?i)^ptv.*?(\\d{2,}(?:\\.\\d+)?)(?:\\s*\\w*)?';
export const DEFAULT_DOSE_REGEX_FIXED = '^$';

export const createNewDoseCustomizationOutput = (
    metadataIds: string[],
    roiIds: string[],
    targetIds: string[],
    doseScaling: DoseScaling,
    pixelSpacing: LengthValue,
    doseCropping: DoseCropping,
    isRTPlanIncluded: boolean,
    isBeamIncluded: boolean,
    machineType: string,
    machineName: string,
    targetMethod: TargetMethod,
    targetUnit: SelectedDoseUnit,
    customizationBaseId: string,
    filename?: string,
    id?: string,
    isModified?: boolean): DoseCustomizationOutput => ({
        id: id || generateNewId(),
        metadata: metadataIds,
        rois: roiIds,
        targets: targetIds,
        doseScaling: doseScaling,
        pixelSpacing: pixelSpacing,
        doseCropping: doseCropping,
        isRTPlanIncluded: isRTPlanIncluded,
        isBeamIncluded: isBeamIncluded,
        machineType: machineType,
        machineName: machineName,
        targetMethod: targetMethod,
        targetUnit: targetUnit,
        modelCustomizationBaseId: customizationBaseId,
        filename: filename || '',
        isModified: isModified || false,
    });

export const createNewDoseScaling = (dose: number, isActive: boolean, method: DoseScalingMethod, volume: number): DoseScaling => ({
    dose,
    isActive,
    method,
    volume
});

export const createNewDoseCropping = (zMargin: LengthValue, isEnabled: boolean): DoseCropping => ({
    zMargin: zMargin,
    isEnabled: isEnabled,
});

export const createNewDoseRoi = (name: string, regExp: string,
    isMandatory: boolean, outputId?: string,
    id?: string, isModified?: boolean): DoseRoi => ({
        id: id || generateNewId(),

        name: name,
        regExp: regExp,
        isMandatory: isMandatory,

        isModified: isModified || false,
        outputId: outputId,
    });

export const createNewDoseTarget = (
    roiName: string,
    regExp: string,
    prescription: number | null,
    outputId?: string,
    id?: string,
    isModified?: boolean): DoseTarget => ({
        id: id || generateNewId(),

        roiName: roiName,
        regExp: regExp,
        prescription: prescription,

        isModified: isModified || false,
        outputId: outputId,
    });


export const defaultCreateNewDoseTarget = (outputId: string, targetMethod: TargetMethod): DoseTarget => {
    const regex = getDefaultRegexForTargetMethod(targetMethod);
    return createNewDoseTarget('', regex, null, outputId, undefined, true);
}


/** Convert dose customization JSON from config API into flattened internal view model representation.
 * 
 * Regarding sorting: if any sorting is done, it's generally done to try to ensure order by original
 * name using naturalSort. Dynamic sorting during user operation is generally not wanted as it could
 * erratically shift the position of the element on the page. Set the 'noSorting' prop to 'true' if sorting
 * is not wanted (e.g. for roundtrip unit tests).
 */
export const convertDoseJsonObjectToViewModels = (jsonObject: any, noSorting: boolean = false): DoseCustomizationEntities => {

    const results = convertCustomizationJsonObjectToViewModels(jsonObject, ModelType.Dose, noSorting);

    // return converted results with following notes:
    // - any undefined items are treated as empty arrays (in an unlikely case there's e.g. no selection rules at all)
    // - ignore any fields related to other algorithms (although if there were anything like that present then 
    //   the earlier conversion function should have already thrown)
    return {
        models: results.models || [],
        customizationBases: results.customizationBases || [],
        doseOutputs: results.doseOutputs || [],
        outputMetadata: results.outputMetadata || [],
        doseRois: results.doseRois || [],
        doseTargets: results.doseTargets || [],
        aeTitleRules: results.aeTitleRules || [],
        dicomRules: results.dicomRules || [],
        dicomAttributeRules: results.dicomAttributeRules || [],
    }
}

/** Convert model customization DTO/JSON containing just ONE dose model customization from backend 
 * API into flattened internal view model representation. Returns matching view model objects.
 * 
 * Regarding sorting: if any sorting is done, it's generally done to try to ensure order by original
 * name using naturalSort. Dynamic sorting during user operation is generally not wanted as it could
 * erratically shift the position of the element on the page. Set the 'noSorting' prop to 'true' if sorting
 * is not wanted (e.g. for roundtrip unit tests).
 */
export const convertSingleModelJsonToDoseOutput = (jsonObject: any, noSorting: boolean = false): DoseCustomizationOutputEntities => {
    if (!jsonObject || !isObject(jsonObject)) {
        throw new Error('Invalid JSON object provided for contouring customizations conversion.');
    }

    const modelCollection: Model[] = [];
    const customizationBaseCollection: CustomizationBase[] = [];
    const outputCollection: DoseCustomizationOutput[] = [];
    const metadataCollection: OutputMetadataItem[] = [];
    const roiCollection: DoseRoi[] = [];
    const targetCollection: DoseTarget[] = [];
    const aeTitleRuleCollection: AeTitleRule[] = [];
    const dicomRuleCollection: DicomRule[] = [];
    const dicomAttributeRuleCollection: DicomAttributeRule[] = [];

    const assertMessage = 'Unexpected contouring model/structure customization format.';

    convertSingleCustomizationJsonEntryToViewModelObjects(
        jsonObject,

        modelCollection,
        customizationBaseCollection,
        metadataCollection,
        aeTitleRuleCollection,
        dicomRuleCollection,
        dicomAttributeRuleCollection,

        [], [],

        outputCollection,
        roiCollection,
        targetCollection,

        assertMessage,
        ModelType.Dose,
        noSorting
    );

    return {
        outputs: outputCollection,
        metadata: metadataCollection,
        rois: roiCollection,
        targets: targetCollection,
    }
}


/**
 * Helper function for converting a customization base and all its outputs and other child objects into dose customization entities.
 * You shouldn't need to call this function manually.
 */
export const _convertDoseCustomizationJsonToViewModel = (
    modelCustomizationBaseResult: CustomizationBaseExtractResult,
    modelId: string,
    doseOutputCollection: DoseCustomizationOutput[],
    outputMetadataCollection: OutputMetadataItem[],
    doseRoiCollection: DoseRoi[],
    doseTargetCollection: DoseTarget[],
    modelTriggersByCustomizationNames: ModelTriggersByCustomizationNames,
    assertMessage: string,
    noSorting: boolean,
): CustomizationBase => {
    const { customizationId, customizationName, customizationDescription, dtoOutputs } = modelCustomizationBaseResult;

    // each 'file' will become its own customization output object
    const outputs: DoseCustomizationOutput[] = dtoOutputs.map((customizationOutputEntry: any) => {

        // assert that output entries look correct
        assertExpectedProps(customizationOutputEntry, [
            { propName: 'metadata', propType: 'array' },
            { propName: 'rois', propType: 'array' },
            { propName: 'targets', propType: 'object' },
            { propName: 'dose_scaling', propType: 'object' },
            { propName: 'dose_geometry', propType: 'object' },
            { propName: 'include_rtplan', propType: 'boolean' },
            { propName: 'include_beam', propType: 'boolean' },
            { propName: 'machine_type', propType: 'string' },
            { propName: 'machine_name', propType: 'string' },
        ], assertMessage);

        // extract metadata
        const metadata = extractOutputMetadata(customizationOutputEntry, assertMessage);

        // extract rois
        const rois: DoseRoi[] = customizationOutputEntry['rois'].map((roiEntry: any) => {
            // assert that roi entries look correct
            assertExpectedProps(roiEntry, [
                { propName: 'roi_name', propType: 'string' },
                { propName: 'reg_exp', propType: 'string' },
                { propName: 'mandatory', propType: 'boolean' },
            ], assertMessage);

            return createNewDoseRoi(
                roiEntry['roi_name'],
                roiEntry['reg_exp'],
                roiEntry['mandatory']);
        });

        // extract targets
        const targetsEntry = customizationOutputEntry['targets'];
        assertExpectedProps(targetsEntry, [
            { propName: 'method', propType: 'string' },
            { propName: 'unit', propType: 'string' },
            { propName: 'rois', propType: 'array' },
        ]);

        const targetMethod = enumFromStringOrDefault(TargetMethod, targetsEntry['method'], TargetMethod.NotSet);
        const targetUnit = enumFromStringOrDefault(SelectedDoseUnit, targetsEntry['unit'], SelectedDoseUnit.NotSet);

        // unparsed 'default' values are not allowed!
        if (targetMethod === TargetMethod.NotSet) { throw new Error(`Could not parse target method from '${targetsEntry['method']}`); }
        if (targetUnit === SelectedDoseUnit.NotSet) { throw new Error(`Could not parse dose unit from '${targetsEntry['unit']}`); }

        const targets: DoseTarget[] = targetsEntry['rois'].map((targetEntry: any) => {
            // assert that target entries look correct (ignore prescription here)
            assertExpectedProps(targetEntry, [
                { propName: 'roi_name', propType: 'string' },
                { propName: 'reg_exp', propType: 'string' },
            ], assertMessage);

            // parse target prescription
            if (targetEntry['prescription'] !== null && !isNumber(targetEntry['prescription'])) {
                throw new Error(assertMessage);
            }

            return createNewDoseTarget(
                targetEntry['roi_name'],
                targetEntry['reg_exp'],
                targetEntry['prescription'],
            );
        });

        // collect ids. ensure roi id order within models
        const metadataIds = metadata.map(m => m.id);
        const roiIds = noSorting ? rois.map(r => r.id) : naturalSort(rois, 'name').map(r => r.id);
        const targetIds = noSorting ? targets.map(r => r.id) : naturalSort(targets, 'regExp').map(t => t.id);
        const filename = metadata.find(m => m.attribute === METADATA_FILENAME_ATTRIBUTE)?.value;

        // extract dose scaling
        const doseScalingEntry = customizationOutputEntry['dose_scaling'];
        assertExpectedProps(doseScalingEntry, [
            { propName: 'dose', propType: 'number' },
            { propName: 'attribute', propType: 'boolean' },
            { propName: 'method', propType: 'string' },
            { propName: 'volume', propType: 'number' },
        ], assertMessage);

        const doseScalingMethod = enumFromStringOrDefault(DoseScalingMethod, doseScalingEntry['method'], DoseScalingMethod.NotSet);

        // unparsed 'default' values are not allowed!
        if (doseScalingMethod === DoseScalingMethod.NotSet) { throw new Error(`Could not parse dose scaling method from '${doseScalingEntry['method']}`); }

        const doseScaling = createNewDoseScaling(
            doseScalingEntry['dose'],
            doseScalingEntry['attribute'],
            doseScalingMethod,
            doseScalingEntry['volume'],
        );

        // extract dose geometry
        const doseGeometryEntry = customizationOutputEntry['dose_geometry'];
        assertExpectedProps(doseGeometryEntry, [
            { propName: 'pixel_spacing', propType: 'number' },
            { propName: 'dose_cropping', propType: 'object' },
        ], assertMessage);

        // extract dose cropping
        const doseCroppingEntry = doseGeometryEntry['dose_cropping'];
        assertExpectedProps(doseCroppingEntry, [
            { propName: 'z_margin', propType: 'number' },
            { propName: 'enabled', propType: 'boolean' },
        ], assertMessage);

        const doseCropping = createNewDoseCropping(
            createLength(doseCroppingEntry['z_margin'], LengthUnit.LengthInCm),
            doseCroppingEntry['enabled'],
        );

        // extract pixel spacing
        let pixelSpacing = doseGeometryEntry['pixel_spacing'];
        if (!isNumber(pixelSpacing) || isNaN(pixelSpacing)) { throw new Error('Unsupported pixel spacing value'); }
        const parsedPixelSpacing = createLength(pixelSpacing, LengthUnit.LengthInCm);



        const output = createNewDoseCustomizationOutput(
            metadataIds,
            roiIds,
            targetIds,
            doseScaling,
            parsedPixelSpacing,
            doseCropping,
            customizationOutputEntry['include_rtplan'],
            customizationOutputEntry['include_beam'],
            customizationOutputEntry['machine_type'],
            customizationOutputEntry['machine_name'],
            targetMethod,
            targetUnit,
            customizationId,
            filename);

        // update parent ids to child objects
        metadata.forEach(m => m.modelCustomizationOutputId = output.id);
        rois.forEach(r => r.outputId = output.id);
        targets.forEach(t => t.outputId = output.id);

        // add newly created objects into collections that will eventually be put into redux store
        outputMetadataCollection.push(...metadata);
        doseRoiCollection.push(...rois);
        doseTargetCollection.push(...targets);

        return output;
    });

    const outputIds = outputs.map(o => o.id);
    const matchingTriggers = modelTriggersByCustomizationNames[customizationName] || [];
    const aeTitleRules = matchingTriggers.filter(isAeTitleRule);
    const dicomRules = matchingTriggers.filter(isDicomRule);
    const aeTitleRuleIds = aeTitleRules.map(t => t.id);
    const dicomRuleIds = dicomRules.map(t => t.id);
    const customization = createNewModelCustomizationBase(customizationName, customizationDescription, outputIds, aeTitleRuleIds, dicomRuleIds, modelId, customizationId);

    // update matching customization IDs now that we have a customization object
    matchingTriggers.forEach(t => t.modelCustomizationBaseId = customization.id);

    // add newly created objects into collections that will eventually be put into redux store
    doseOutputCollection.push(...outputs);

    return customization;
}





/** Convert dose customization view models to JSON presentation.
 * @param contouringEntities The dose customization view model entities to convert to JSON.
 * @param noUiId Internal IDs for the view models needed for UI functionality will not be included in the export if true, and will be included
 * if set to false. Defaults to false. Including internal IDs is needed when sending data to backend to be able to cross-reference received
 * errors to their matching UI view models, but these IDs should be omitted when this JSON will be user-facing (e.g. when exported directly
 * as a downloaded file) or when running certain unit tests (e.g. round-trip tests will break if extra UI ID fields are present).
 * @returns An array of model JSON objects, ready to be sent to backend.
 */
export const convertDoseViewModelsToJson = (contouringEntities: Partial<DoseCustomizationEntitiesForExport>, noUiId: boolean = false): any => {

    const jsonModels = convertCustomizationViewModelsToJson(contouringEntities, ModelType.Dose, noUiId);

    return jsonModels;
}


/**
 * Convert internal view model representation of dose output objects and related children
 * into a model customization JSON hierarchy. You shouldn't need to call this manually.
 * @param jsonCustomization Result JSON will be embedded into this JSON.
 * @param output Dose output view model to convert to JSON.
 * @param outputMetadata Collection of output metadata related to this customization set.
 * @param doseRois Collection of ROI customizations related to this customization set.
 * @param doseTargets Collection of ROI targets related to this customization set.
 * @param errorMessage Error message to throw on failure.
 * @param noUiId Marks if internal UI IDs should be included in converted JSON.
 */
export const _convertDoseCustomizationOutputToJson = (
    jsonCustomization: any,
    output: DoseCustomizationOutput,
    outputMetadata: OutputMetadataItem[],
    doseRois: DoseRoi[],
    doseTargets: DoseTarget[],
    errorMessage: string,
    noUiId: boolean = false): any => {
    const jsonObject: any[] = [];

    if (output === undefined) { throw new Error(errorMessage); }
    if (output.doseScaling.method === DoseScalingMethod.NotSet) {
        throw new Error(`Unsupported dose scaling method: ${output.doseScaling.method}`);
    }
    if (output.targetMethod === TargetMethod.NotSet) { throw new Error(`Target method is not set.`); }
    if (output.targetUnit === SelectedDoseUnit.NotSet) { throw new Error(`Dose unit is not set.`); }
    const jsonOutput: any = {
        'metadata': [],
        'rois': [],
        'targets': {
            'method': output.targetMethod,
            'unit': output.targetUnit,
            'rois': [],
        },
        'dose_scaling': {
            'attribute': output.doseScaling.isActive,
            'method': output.doseScaling.method,
            'volume': output.doseScaling.volume,
            'dose': output.doseScaling.dose
        },
        'dose_geometry': {
            'pixel_spacing': getLengthInCm(output.pixelSpacing),
            'dose_cropping': {
                'enabled': output.doseCropping.isEnabled,
                'z_margin': getLengthInCm(output.doseCropping.zMargin),
            }
        },
        'include_rtplan': output.isRTPlanIncluded,
        'include_beam': output.isBeamIncluded,
        'machine_type': output.machineType,
        'machine_name': output.machineName,
    };
    if (!noUiId) { jsonOutput[UI_ID_ATTRIBUTE] = output.id }

    for (const metadataId of output.metadata) {
        const metadata = outputMetadata.find(m => m.id === metadataId);
        if (metadata === undefined) { throw new Error(errorMessage); }
        _convertOutputMetadataViewModelToJson(jsonOutput, metadata, noUiId);
    }

    for (const roiId of output.rois) {
        const roi = doseRois.find(r => r.id === roiId);
        if (roi === undefined) { throw new Error(errorMessage); }

        const jsonRoi: any = {
            'roi_name': roi.name.trim(),
            'mandatory': roi.isMandatory,
            'reg_exp': roi.regExp,
        };
        if (!noUiId) { jsonRoi[UI_ID_ATTRIBUTE] = roi.id }
        jsonOutput['rois'].push(jsonRoi);
    }

    for (const targetId of output.targets) {
        const target = doseTargets.find(t => t.id === targetId);
        if (target === undefined) { throw new Error(errorMessage); }

        const jsonTarget: any = {
            'roi_name': target.roiName,
            'reg_exp': target.regExp,
            // save the prescription field as null if current target method does not support it
            'prescription': output.targetMethod === TargetMethod.Variable ? null : target.prescription,
        };
        if (!noUiId) { jsonTarget[UI_ID_ATTRIBUTE] = target.id }
        jsonOutput['targets']['rois'].push(jsonTarget);
    }

    jsonCustomization['files'].push(jsonOutput);
}

export const duplicateRoiCustomization = (roi: DoseRoi, outputId?: string): DoseRoi => {
    return {
        id: generateNewId(),
        name: roi.name,
        isMandatory: roi.isMandatory,
        regExp: roi.regExp,
        isModified: true,
        outputId: outputId,
    };
}

export const duplicateTargetCustomization = (target: DoseTarget, outputId?: string): DoseTarget => {
    return {
        id: generateNewId(),
        roiName: target.roiName,
        regExp: target.regExp,
        prescription: target.prescription,
        isModified: true,
        outputId: outputId,
    };
}


export const readModelCustomizationJsonFile = (json: any): Promise<DoseCustomizationEntities | null> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            try {
                const jsonObject = JSON.parse(reader.result as string);

                const modelCustomizationImport = convertDoseJsonObjectToViewModels(jsonObject);
                resolve(modelCustomizationImport);
            } catch (e) {
                reject(e);
            }
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsText(json);
    });
};

export const getDefaultRegexForTargetMethod = (targetMethod: TargetMethod) => {
    switch (targetMethod) {
        case TargetMethod.Variable:
            return DEFAULT_DOSE_REGEX_VARIABLE;
        case TargetMethod.Fixed:
            return DEFAULT_DOSE_REGEX_FIXED;

        default:
            throw new Error(`Unsupported target method: ${targetMethod}`);
    }
}
