import { StackItem, TextContent, Text } from '@patternfly/react-core';

interface DoseOutputSectionHelpTextProps {
    helpText: string,
}

const DoseOutputSectionHelpText = (props: DoseOutputSectionHelpTextProps) => {
    const { helpText } = props;

    return (
        <StackItem className="dose-output-item">
            <div className="dose-output-help-text">
                <TextContent>
                    <Text>
                        {helpText}
                    </Text>
                </TextContent>
            </div>
        </StackItem>
    );

}

export default DoseOutputSectionHelpText;
