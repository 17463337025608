import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppAuthState, LogInProcessState } from './auth';

export type AuthSliceState = {
    /** Collection of azure app registrations and whether they are, and need to be, logged in by the user. */
    appAuthStates: AppAuthState[],

    /** Latest login error, if any. */
    loginError: Error | null,

    /** True if current authentication flow requires user interaction, false otherwise. */
    isInteractionRequired: boolean,
};

export const initialState: AuthSliceState = {
    appAuthStates: [],
    loginError: null,
    isInteractionRequired: false,
};


/** Authentication flow-related redux store slice. */
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {

        /** 
         * Start tracking a new app auth state (whether we're logged into a specific azure app registration).
         * @param action payload: the AppAuthState object to start tracking.
         */
        appAuthStateAdded(state, action: PayloadAction<AppAuthState>) {
            const appAuthState = action.payload;
            if (state.appAuthStates.find(a => a.appAuthName === appAuthState.appAuthName) === undefined) {
                state.appAuthStates.push(appAuthState);
            }
        },

        /** 
         * Start logging in to a specific app auth (azure app registration).
         * @param action payload: name of the app auth to log in to.
         */
        appAuthLoginStarted(state, action: PayloadAction<string>) {
            const appAuthName = action.payload;
            const authState = state.appAuthStates.find(a => a.appAuthName === appAuthName);

            if (authState === undefined) {
                throw new Error(`App auth ${appAuthName} has not been configured -- cannot log in.`);
            }

            if (authState.logInProcessState !== LogInProcessState.LoggedIn) {
                authState.logInProcessState = LogInProcessState.LoggingInProgress;
            }
        },

        /** 
         * Sets or clears current login error.
         * @param action payload: configuration for new login error, or null if error should be cleared.
         */
        loginErrorSet(state, action: PayloadAction<Error | null>) {
            const loginError = action.payload;
            state.loginError = loginError;
        },

        /** 
         * Marks an app auth state as having been successfully logged in.
         * @param action payload: name of the app auth that was logged into.
         */
        logInSucceeded(state, action: PayloadAction<string>) {
            const appAuthName = action.payload;
            const authState = state.appAuthStates.find(a => a.appAuthName === appAuthName);
            if (authState) {
                authState.logInProcessState = LogInProcessState.LoggedIn;
            } else {
                throw new Error(`App auth ${appAuthName} has not been configured -- cannot set as logged in.`);
            }
        },

        /** Marks log out as having been requested. */
        logOutRequested() {
            // this is an empty action and is only used for signalling in sagas
        },

        /** Sets if interaction is required or not in current authentication flow. */
        interactionRequiredSet(state, action: PayloadAction<boolean>) {
            state.isInteractionRequired = action.payload;
        },

    },
    selectors: {
        /** Returns true if at least one required app auth has been logged into, false otherwise. */
        selectIsLoggedIntoAtLeastOneAppAuth: (state): boolean => state.appAuthStates.some(a => a.isRequired && a.logInProcessState === LogInProcessState.LoggedIn),
    }

});

export const { appAuthStateAdded, appAuthLoginStarted, loginErrorSet, logInSucceeded, logOutRequested, interactionRequiredSet } = authSlice.actions;

// uncomment if these are needed locally
// const localSelectors = authSlice.getSelectors();

export const { getInitialState, selectors: authSelectors } = authSlice;

export default authSlice.reducer;
