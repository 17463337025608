import { useCallback, useEffect, useState } from 'react';
import { DataListItem, DataListItemRow, DataListItemCells, DataListCell, DataListAction, Flex, FlexItem, TextInput, Divider, Stack, StackItem, Alert, Dropdown, MenuToggle, MenuToggleElement, DropdownItem, DropdownList, DropdownGroup, Toolbar, ToolbarContent, ToolbarItem, Button } from '@patternfly/react-core';
import { useDispatch } from 'react-redux';
import { CogIcon, EllipsisVIcon } from '@patternfly/react-icons';
import { useTranslation } from 'react-i18next';

import { useScroll } from '../../hooks/useScroll';
import InputLabel from '../../components/input-label';
import { DEFAULT_CONTOUR_FILENAME } from '../../store/contouring/contouring-types';
import { ensureFilenameHasDcmExtension } from './file-operations';
import { CustomizationObjectType, METADATA_FILENAME_ATTRIBUTE, OutputMetadataItem } from '../../store/global-types/customization-types';

import './customization-page.css';
import { BackendValidationErrorViewModel } from '../../store/global-types/store-errors';
import { get } from 'lodash-es';

interface MetadataItemFormProps {
    metadataItem?: OutputMetadataItem,
    originalMetadataItem?: OutputMetadataItem,
    validationEntities: Record<string, BackendValidationErrorViewModel>,
    metadataPresets: string[],
    onMetadataItemUpdated: (metadataId: string, attribute: string | undefined, value: string | undefined, isUndoOperation?: boolean) => void,
    onMetadaItemRemoved: (metadataId: string) => void,
    removeScrollToViewFromMetadataItem: (metadataId: string) => void,
}

const MetadataItemForm = (props: MetadataItemFormProps) => {
    const { metadataItem, originalMetadataItem, validationEntities, metadataPresets, onMetadataItemUpdated, onMetadaItemRemoved, removeScrollToViewFromMetadataItem } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isActionListOpen, setActionListOpenState] = useState(false);

    const [isPresetToggleOpen, setIsPresetToggleOpen] = useState(false);

    const handleUndo = useCallback(() => {
        if (metadataItem && originalMetadataItem) {
            onMetadataItemUpdated(metadataItem.id, originalMetadataItem.attribute, originalMetadataItem.value, true);
        }
    }, [dispatch, onMetadataItemUpdated, metadataItem, originalMetadataItem]);

    const handleRemove = useCallback(() => {
        if (metadataItem) {
            onMetadaItemRemoved(metadataItem.id);
        }
    }, [dispatch, metadataItem]);

    const handleAttributeChange = useCallback((_: unknown, attribute: string) => {
        if (metadataItem) {
            onMetadataItemUpdated(metadataItem.id, attribute, undefined);
        }
    }, [dispatch, onMetadataItemUpdated, metadataItem]);

    const handleValueChange = useCallback((_: unknown, value: string) => {
        if (metadataItem) {
            onMetadataItemUpdated(metadataItem.id, undefined, value);
        }
    }, [dispatch, onMetadataItemUpdated, metadataItem]);

    const handleBlur = useCallback((_?: never, attribute?: string) => {

        // optional 'attribute' argument allows us to call this function more easily
        // before we've applied updates to store
        const attrib = attribute || metadataItem?.attribute;

        // make sure a file name has an extension as we leave the matching input
        if (metadataItem && attrib === METADATA_FILENAME_ATTRIBUTE) {
            const updatedFilename = ensureFilenameHasDcmExtension(metadataItem.value || DEFAULT_CONTOUR_FILENAME);
            if (updatedFilename !== metadataItem.value) {
                onMetadataItemUpdated(metadataItem.id, undefined, updatedFilename);
            }
        }
    }, [dispatch, onMetadataItemUpdated, metadataItem]);

    const handleToggleActionListOpen = useCallback(() => setActionListOpenState(!isActionListOpen), [isActionListOpen]);
    const handlePresetToggle = useCallback(() => setIsPresetToggleOpen(!isPresetToggleOpen), [isPresetToggleOpen]);

    const [executeScroll, scrollRef] = useScroll<HTMLDivElement>(true);
    const [isHighlightedItem, setIsHighlightedItem] = useState(false);

    useEffect(() => {
        if (metadataItem && scrollRef && metadataItem.scrollToView) {
            setIsHighlightedItem(true);
            removeScrollToViewFromMetadataItem(metadataItem.id);
            executeScroll();
        }
    }, [executeScroll, scrollRef, dispatch, metadataItem]);

    const handleSelectPreset = useCallback((dicomAttributeName: string) => {
        handleAttributeChange(undefined, dicomAttributeName);
        setIsPresetToggleOpen(false);
        handleBlur(undefined, dicomAttributeName);
    }, [handleAttributeChange, handleBlur]);   

    if (metadataItem === undefined) {
        return null;
    }

    const metadataAttributePresets = metadataPresets.map(preset => (
        <DropdownItem key={preset} onClick={() => handleSelectPreset(preset)}>{preset}</DropdownItem>
    ));

    const prefix = `metadata-${metadataItem.id}`;
    const attributeId = `${prefix}-attribute`;
    const valueId = `${prefix}-value`;

    const highlight = isHighlightedItem ? 'highlighted-item' : '';

    const validationError = get(validationEntities, [metadataItem.id], undefined);
    const hasValidationError = validationError !== undefined && validationError.type === CustomizationObjectType.Metadata;

    return (
        <DataListItem aria-labelledby={`Metadata configuration for metadata item ${metadataItem.id}`} key={metadataItem.id}>
            <Stack>
                <StackItem>
                    <DataListItemRow className="roi-customization">

                        <DataListItemCells dataListCells={[
                            <DataListCell key="structure-customizations" className={`structure-customizations ${hasValidationError ? 'validation-error' : ''}`}>
                                <div ref={scrollRef} className="metadata-scroll-offset">
                                    {metadataItem.isModified && <div className="roi-item-is-modified" title="This metadata item has unsaved changes.">*</div>}
                                    <Flex className={`customization-row ${highlight}`}>
                                        <FlexItem className="metadata-attribute">
                                            <InputLabel label={t('customizeMetadata.attribute')} useRef>
                                                <TextInput
                                                    type="text"
                                                    id={attributeId}
                                                    onChange={handleAttributeChange}
                                                    onBlur={handleBlur}
                                                    value={metadataItem.attribute}
                                                />
                                                <Dropdown
                                                    isOpen={isPresetToggleOpen}
                                                    isPlain
                                                    title="Optional preset metadata attribute names"
                                                    toggle={(toggleRef: React.Ref<MenuToggleElement>) => (
                                                        <MenuToggle ref={toggleRef} onClick={handlePresetToggle} isExpanded={isPresetToggleOpen} variant='plain' className='menutoggle-plain-select'>
                                                            <CogIcon />
                                                        </MenuToggle>
                                                    )}
                                                    onOpenChange={(isOpen: boolean) => setIsPresetToggleOpen(isOpen)}
                                                    popperProps={{ position: 'right' }}
                                                    shouldFocusFirstItemOnOpen={false}
                                                >
                                                    <DropdownGroup label={t('customizeMetadata.presetLabel')} className='dropdown-group-label'>
                                                        <Divider className='unselectable' />
                                                        {metadataAttributePresets}
                                                    </DropdownGroup>
                                                </Dropdown>
                                            </InputLabel>
                                        </FlexItem>
                                        <FlexItem className="mv-flex-grow metadata-value">
                                            <InputLabel label={t('customizeMetadata.value')} useRef>
                                                <TextInput
                                                    type="text"
                                                    id={valueId}
                                                    onChange={handleValueChange}
                                                    onBlur={handleBlur}
                                                    value={metadataItem.value}
                                                />
                                            </InputLabel>
                                        </FlexItem>
                                    </Flex>
                                </div>
                            </DataListCell>
                        ]} />
                        <DataListAction style={{ alignItems: "center" }} id={`actions-for-${metadataItem.id}`} aria-label={`Actions for metadata item ${metadataItem.id}`} aria-labelledby="" isPlainButtonAction>
                            <Dropdown
                                isPlain
                                isOpen={isActionListOpen}
                                popperProps={{ position: "right" }}
                                onSelect={handleToggleActionListOpen}
                                toggle={(toggleRef: React.Ref<MenuToggleElement>) => (
                                    <MenuToggle ref={toggleRef} onClick={handleToggleActionListOpen} variant='plain'>
                                        <EllipsisVIcon />
                                    </MenuToggle>)}

                                onOpenChange={handleToggleActionListOpen}
                                shouldFocusFirstItemOnOpen={false}
                            >
                                <DropdownList>
                                    <DropdownItem
                                        key="undo-metadata-changes"
                                        component="button"
                                        isDisabled={originalMetadataItem === undefined || !metadataItem.isModified}
                                        onClick={handleUndo}>{t('customizeMetadata.undoChanges')}</DropdownItem>
                                    <DropdownItem
                                        key="remove-metadata-item"
                                        component="button"
                                        onClick={handleRemove}>{t('customizeMetadata.removeItem')}</DropdownItem>
                                </DropdownList>
                            </Dropdown>
                        </DataListAction>

                    </DataListItemRow>
                </StackItem>
                {hasValidationError && (
                    <StackItem className="validation-error-box">
                        <Alert variant="danger" isInline isPlain isExpandable title={`Validation error: ${validationError.message}`}>
                            <div>Error type: {validationError.detail}</div>
                            <div>Error ctx: {validationError.ctx}</div>
                        </Alert>
                    </StackItem>
                )}
            </Stack>
        </DataListItem>

    );
}

export default MetadataItemForm;
