// Retained DICOM attributes that are used for model selection
export enum RetainStatus {
    NotRetained = 'NotRetained',
    Retained = 'Retained',
    RetainedInSome = 'RetainedInSome',
}

export interface RetainedDicomAttributes {
    [attribute: string]: RetainStatus;
}
