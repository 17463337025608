import { TextContent, Text, Form, FormGroup, TextInput, TextArea } from "@patternfly/react-core";
import { useTranslation } from "react-i18next";

import { Labeling } from "../../store/appVersion/labeling";

interface LabelingProps {
    productLabel: Labeling,
}

const LabelingView = (props: LabelingProps) => {

    const { productLabel } = props;

    const { t } = useTranslation();

    return (
        <>
            <TextContent className="main-info">
                <Text component="h1">{productLabel.manufacturerModelName}</Text>
                <Text component="h3" className="manufacturer">{productLabel.manufacturer}</Text>
                {productLabel.description && (<Text component="p" className="description">{productLabel.description}</Text>)}
            </TextContent>

            <Form isHorizontal>
                <FormGroup
                    label="UDI"
                    fieldId="udi-readonly" >
                    <TextInput value={productLabel.udi || ''} type="text" id="udi-readonly" readOnlyVariant="default" />
                </FormGroup>
                <FormGroup
                    label={t('page.about.labelingContainer')}
                    fieldId="labeling-readonly" >
                    <TextArea
                        readOnly
                        id="labeling-readonly"
                        value={productLabel.labelingAsString}
                        resizeOrientation='vertical'
                        className="text-editor-area label"
                        spellCheck="false"
                        rows={8}
                    />
                </FormGroup>
            </Form>
        </>
    );
}

export default LabelingView;
