import { useTranslation } from 'react-i18next';
import './LogInPage.css';
import logo from '../../img/mvision-title.png';

/**
 * This page will explain the user that they're now logged in. This page should be wrapped inside
 * <AppWrapper> so trying to access this page will automatically attempt to log the user in.
 * 
 * The application has some deployment modes (local, cockpit) that do not require an explicit
 * login. Technically it's possible to access this page via URL if the user knows how to in
 * those deployment modes, which makes little sense, but all they're getting is a slightly 
 * confusing message that's not exactly untrue. There's also currently no direct way to access 
 * this page except from the InteractionRequiredModal (which should never appear in the 
 * aforementioned deployment modes) or by knowing and typing the URL path manually.
 */
const LogInPage = () => {

    const { t } = useTranslation();

    return (
        <div className="logged-in-container">
            <div className="logged-in-box">

                <div className="logobox">
                    <img src={logo} alt="MVision logo" />
                </div>

                <div>{t('page.login.paragraph1')}</div>

                <div>{t('page.login.paragraph2')}</div>
            </div>
        </div>
    );
}

export default LogInPage;
