import {
    Button,
    Icon,
    Masthead,
    MastheadBrand,
    MastheadContent,
    MastheadMain,
    MastheadToggle,
    PageToggleButton
} from '@patternfly/react-core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ConfigurationTargetSelector from './components/client-selector';
import { DEFAULT_APP_NAME } from './environments';
import { CogIcon, OutlinedQuestionCircleIcon, PanelCloseIcon, PanelOpenIcon } from '@patternfly/react-icons';
import TiltButton from './components/tilt-button';
import routes from './routes';

import logo32w from './img/mvision-logo-white-32.png';
import { getCurrentLangKey } from './i18n';
import { StoreState } from './store/store';
import { appConfigSelectors } from './store/appConfig/appConfigSlice';
import { DeploymentMode } from './store/appConfig/appDeploymentInfoTypes';
import { configurationTargetSelectors } from './store/configurationTarget/configurationTargetSlice';
import { useState } from 'react';

interface MastheadAndHeaderProps {
    handleOpenUserSettings: () => void;
    handleOpenHelpDialog: () => void;
    isUserSettingsOpen: boolean;
}

const CustomMasthead = (props: MastheadAndHeaderProps) => {
    const { t } = useTranslation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const handleSidebarIconState = () => setIsSidebarOpen(prevState => !prevState);

    return (
        <Masthead style={{ justifyItems: "right", alignItems: 'center'}} className='unselectable'>
            <MastheadMain>
                <MastheadToggle onClick={handleSidebarIconState}>
                    <PageToggleButton variant="plain" aria-label="Global navigation">
                        {isSidebarOpen ? <PanelOpenIcon /> : <PanelCloseIcon /> }
                    </PageToggleButton>
                </MastheadToggle>
                <MastheadBrand>
                    <Link to={routes.homePage.slug}>
                        <span className="masthead-logo" title={t('appTitle')}>
                            <img src={logo32w} alt="MVision logo" />
                        </span>
                    </Link>
                </MastheadBrand>
            </MastheadMain>
            <MastheadContent>
                <HeaderTools {...props} />
            </MastheadContent>
        </Masthead>
    );
};

const HeaderTools = (props: MastheadAndHeaderProps) => {
    const targetList = useSelector(configurationTargetSelectors.selectConfigurationTargetList);
    const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);
    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);
    const currentLangKey = getCurrentLangKey();
    const isHelpDocumentAvailable = useSelector((state: StoreState) =>
        appConfigSelectors.selectIsHelpDocumentAvailable(state, currentLangKey)
    );

    // Determine if the target selector should be shown
    const isTargetSelectorShown =
        targetList && deploymentInfo && deploymentInfo.deploymentMode === DeploymentMode.Cloud;

    // If in local mode, display local configuration message
    const hiddenTargetSelector = currentTarget && currentTarget.localMode ? (
        <div title={`${DEFAULT_APP_NAME} is using local configuration API.`}>Local mode</div>
    ) : null;

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {isTargetSelectorShown ? (
                <ConfigurationTargetSelector targetList={targetList} />
            ) : (
                hiddenTargetSelector
            )}
            {isHelpDocumentAvailable && (
                <Button onClick={props.handleOpenHelpDialog} variant="plain" aria-label="Help" icon={<Icon size='lg'><OutlinedQuestionCircleIcon /></Icon>} />
            )}
            <TiltButton forceTilt={props.isUserSettingsOpen} tiltOrigin="12px 12px" variant="plain" onClick={props.handleOpenUserSettings}>
                <Icon size='lg'><CogIcon /></Icon>
            </TiltButton>
        </div>
    );
};

export default CustomMasthead;
