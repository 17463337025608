import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeploymentConfigInfo, DeploymentMode } from './appDeploymentInfoTypes';
import { Backend, createBackend } from '../auth/auth';
import { ConfigInterface } from '../../app-config';
import { HelpDocument, LangKey } from '../../i18n';
import { getHelpFileFallbackLangKey } from '../../environments';

export type AppConfigSliceState = {
    /** Current configuration regarding this app's deployment, ie. configuration that's specific
     * to this instance of this app that's running on this particular web server. Can contain
     * e.g. configuration specific to current region (eu, uk, us, etc).
     */
    appDeploymentInfo: DeploymentConfigInfo | undefined;

    /** Current backend, if set. NOTE: not used in Cockpit deployment mode. */
    backend: Backend | undefined,

    /** Current major app configuration, usually based on deployment mode (cloud or local). */
    appConfig: ConfigInterface | undefined,

    /** Currently available localized help documents. */
    helpDocuments: HelpDocument[],
};

export const initialState: AppConfigSliceState = {
    appDeploymentInfo: undefined,
    backend: undefined,
    appConfig: undefined,
    helpDocuments: [],
};

/** Redux slice for handling app configuration */
const appConfigSlice = createSlice({
    name: 'appConfig',
    initialState,
    reducers: {
        /** Sets app deployment info to supplied value. This is generally read from the config.json file on the hosting web
         * server's root.
         * @param action payload: the DeploymentConfigInfo object to use as app deployment info.
         */
        appDeploymentInfoSet(state, action: PayloadAction<DeploymentConfigInfo>) {
            const appDeploymentInfo = action.payload;
            state.appDeploymentInfo = appDeploymentInfo;

            // set cloud backend if relevant
            if (appDeploymentInfo.deploymentMode === DeploymentMode.Cloud && appDeploymentInfo.backendUrl !== undefined && appDeploymentInfo.cloudBackendTier !== undefined) {
                const backend = createBackend(appDeploymentInfo.backendName || 'Backend', appDeploymentInfo.backendUrl, appDeploymentInfo.cloudBackendTier);
                state.backend = backend;
            }
            //  set local backend if relevant
            else if (appDeploymentInfo.deploymentMode === DeploymentMode.Local && appDeploymentInfo.backendUrl !== undefined) {
                const backend = createBackend(appDeploymentInfo.backendName || 'Backend', appDeploymentInfo.backendUrl, undefined);
                state.backend = backend;
            } else if (appDeploymentInfo.deploymentMode !== DeploymentMode.Cockpit) {
                throw new Error('Could not configure backend settings -- please check your config.json configuration.');
            }
        },

        /** Sets main app configuration, generally based on current deployment mode (cloud or local environment).
         * @param action payload: the ConfigInterface object to use as app config.
         */
        appConfigSet(state, action: PayloadAction<ConfigInterface>) {
            const appConfig = action.payload;
            state.appConfig = appConfig;
        },

        /**
         * Stores the given localized help documents in redux store.
         * @param action List of localized help documents that are available in the application.
         */
        helpDocumentsSet(state, action: PayloadAction<HelpDocument[]>) {
            const helpDocuments = action.payload;
            state.helpDocuments = helpDocuments;
        },
    },
    selectors: {
        /** Returns app deployment info (i.e. config.json) */
        selectAppDeploymentInfo: (state) => state.appDeploymentInfo,

        /** Returns current cloud backend. NOTE: not used in Cockpit deployment mode. */
        selectBackend: (state) => state.backend,

        /** Returns current main app configuration */
        selectAppConfig: (state) => state.appConfig,

        /** Returns true if the page layout should be rendered inside a containing frame (i.e. cockpit), false if not, and undefined if unknown (i.e. if config is not yet loaded). */
        selectIsLayoutInsideFrame: (state) => state.appConfig?.isInsideFrame,

        /** Returns true if a valid help document is available for specified language or a fallback is available, false otherwise.
         * 'undefined' is supported as a LangKey for convenience so we can return fallback language help document in certain cases.
         */
        selectIsHelpDocumentAvailable: createSelector(
            [
                (state: AppConfigSliceState) => state.helpDocuments,
                (state: AppConfigSliceState, currentLanguage: LangKey | undefined) => currentLanguage
            ],
            (helpDocuments, currentLanguage) => {
                const isHelpFileAvailable = helpDocuments.some(h => h.langKey === currentLanguage);
                if (!isHelpFileAvailable && getHelpFileFallbackLangKey() !== undefined) {
                    return helpDocuments.some(h => h.langKey === getHelpFileFallbackLangKey());
                }
                return isHelpFileAvailable;
            }),

        /** Returns markdown help document for current language, or the fallback help file, or undefined if not found or fallback is not in use.
         * 'undefined' is supported as a LangKey for convenience so we can return fallback language help document in certain cases.
         */
        selectHelpDocument: createSelector(
            [
                (state: AppConfigSliceState) => state.helpDocuments,
                (state: AppConfigSliceState, currentLanguage: LangKey | undefined) => currentLanguage
            ],
            (helpDocuments, currentLanguage) => {
                let helpDocument: HelpDocument | undefined = helpDocuments.find(h => h.langKey === currentLanguage);
                if (!helpDocument && getHelpFileFallbackLangKey() !== undefined) {
                    helpDocument = helpDocuments.find(h => h.langKey === getHelpFileFallbackLangKey());
                }

                if (helpDocument) {
                    return helpDocument.helpDocument;
                }

                return undefined;
            }),
    }
});

export const { appDeploymentInfoSet, appConfigSet, helpDocumentsSet } = appConfigSlice.actions;

// uncomment if these are needed locally
// const localSelectors = appConfigSlice.getSelectors();

export const { getInitialState, selectors: appConfigSelectors } = appConfigSlice;

export default appConfigSlice.reducer;
