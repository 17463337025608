import React, { useCallback } from 'react';
import { Table, Tbody, Th, Thead, Tr } from '@patternfly/react-table';
import { ResourceKey } from 'i18next';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '@patternfly/react-icons';
import { Button, Level, LevelItem, Toolbar, ToolbarContent, ToolbarItem } from '@patternfly/react-core';
import { useDispatch, useSelector } from 'react-redux';

import DoseTargetForm from './DoseTargetForm';
import { doseSelectors, newDoseTargetAdded } from '../../../store/dose/doseSlice';
import { StoreState } from '../../../store/store';
import { TargetMethod } from '../../../store/dose/dose-types';

/** Ids for different types of dose target table columns. */
export const columnIds = {
    roiName: 'Name',
    matchPattern: 'Match pattern',
    prescription: 'Prescription',
};

/** Styling names for dose target table columns. */
export const columnCssNames = {
    modified: 'column-modified',
    roiName: 'column-roi-name',
    matchPattern: 'column-match-pattern',
    prescription: 'column-prescription',
}


interface DoseTargetTableProps {
    outputId: string;
    targetIds: string[];
}

const DoseTargetTable = (props: DoseTargetTableProps) => {

    const { outputId, targetIds } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const output = useSelector((state: StoreState) => doseSelectors.selectOutputById(state, outputId));

    const handleAddDoseTarget = useCallback(() => {
        dispatch(newDoseTargetAdded(outputId));
    }, [dispatch, outputId]);

    if (output === undefined) {
        return null;
    }

    const isFixedMethod = output.targetMethod === TargetMethod.Fixed;

    return (
        <div>
            <div>
                <Table variant='compact' isStickyHeader className="mv-sticky-fix">
                    <Thead>
                        <Tr className='customization-form-table unselectable'>
                            <Th aria-label='Is modified?'></Th>
                            <Th aria-label='Match pattern' info={{ tooltip: t('customizationPage.dose.targets.table.matchPattern.tooltip') }}>
                                {isFixedMethod ? t('column.targetNameOrMatchPattern') : t('column.matchPattern')}
                            </Th>
                            {isFixedMethod && <Th aria-label='Fixed prescription'>{t('column.fixedPrescription')}</Th>}
                            <Th aria-label='Target name' info={{ tooltip: t('customizationPage.dose.targets.table.roiName.tooltip') }}>{t('column.roiName')}</Th>
                            <Th aria-label='Action menu'></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {targetIds.map(tId => <DoseTargetForm targetId={tId} key={tId} targetIdList={targetIds} />)}
                    </Tbody>
                </Table>
            </div>

            <div>
                <Level>
                    <LevelItem>
                        <Toolbar>
                            <ToolbarContent>
                                <ToolbarItem>
                                    <Button
                                        size="sm"
                                        variant="secondary"
                                        icon={<PlusIcon />}
                                        // isDisabled={isToolbarDisabled}
                                        onClick={handleAddDoseTarget}>{t('customizationPage.dose.rois.addNewDoseTarget')}</Button>
                                </ToolbarItem>

                            </ToolbarContent>
                        </Toolbar>
                    </LevelItem>
                </Level>
            </div>

        </div>
    );
}

export default DoseTargetTable;
