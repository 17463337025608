import React, { useCallback } from 'react';
import { Button, ButtonProps, Modal, ModalVariant } from '@patternfly/react-core';

interface ConfirmationModalProps {
    isOpen: boolean,
    title?: string,
    description?: string,
    onConfirm: (type?: string) => void,
    onClose: () => void,
    variant?: ModalVariant,
    confirmLabel?: string,
    cancelLabel?: string,
    confirmVariant?: ButtonProps['variant'];
    type?: string;

    /** Disable buttons and closing the modal when loading is in progress. */
    isLoadingInProgress?: boolean;
}

const ConfirmationModal = (props: React.PropsWithChildren<ConfirmationModalProps>) => {
    const { isOpen, title, onClose, variant, confirmLabel, cancelLabel, onConfirm, confirmVariant, children, description, type, isLoadingInProgress } = props;

    const loadingProps: Partial<ButtonProps> = {
        isLoading: isLoadingInProgress,
    }

    const handleClose = useCallback(() => {
        if (!isLoadingInProgress) {
            onClose();
        }
    }, [isLoadingInProgress, onClose]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            variant={variant || ModalVariant.default}
            title={title}
            description={description}
            showClose={!isLoadingInProgress}
            actions={[
                <Button key="confirm" variant={confirmVariant || 'primary'} onClick={() => onConfirm(type)} isDisabled={isLoadingInProgress} {...loadingProps}>{confirmLabel || 'OK'}</Button>,
                <Button key="cancel" variant="tertiary" onClick={onClose} isDisabled={isLoadingInProgress}>{cancelLabel || 'Cancel'}</Button>
            ]}
        >
            {children}
        </Modal>
    );
}

export default ConfirmationModal;
