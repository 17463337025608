import { DaemonConfig } from "./daemon/daemon-config";
import { RetainedDicomAttributes, RetainStatus } from "./global-types/daemon-types";

export const mapRetainedDicomAttributes = (daemonConfigs: DaemonConfig[]): RetainedDicomAttributes => {
    // Initialize the object to hold retained DICOM attributes
    const retainedDicomAttributes: RetainedDicomAttributes = {};

    // Initialize a set to hold all unique attribute names
    const attributeNames: Set<string> = new Set();

    // Iterate over each daemon configuration
    daemonConfigs.forEach((config) => {
        // Add attribute names from all configurations to the set
        config.retainAttributes.forEach((attribute) => {
            attributeNames.add(attribute);
        });
    });

    // Iterate over each attribute name in the set
    attributeNames.forEach((name) => {
        // Check if the attribute is present in all configurations
        const isRetainedInAll = daemonConfigs.every(config => config.retainAttributes.includes(name));

        // Update RetainedDicomAttributes based on retention status
        if (isRetainedInAll) {
            retainedDicomAttributes[name] = RetainStatus.Retained;
        } else {
            retainedDicomAttributes[name] = RetainStatus.RetainedInSome;
        }
    });

    return retainedDicomAttributes;
    
};