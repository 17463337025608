import { Dispatch } from "@reduxjs/toolkit";
import AuthenticatedPage from "../../pages/auth/AuthenticatedPage";
import BackendApiInterface, { CombinedVersionInfoJson, JsonValue, LabelJsonWrapper } from "../backend-api-interface";
import { BackendClient, getCloudAuthBackendClient } from "../cloud-backend-auth";
import store from "../../store/store";
import { MVisionAppClient } from "../../store/configurationTarget/mvision-client-list";
import { convertResponseToError, getErrorMessageFromBackendValidationErrorJson } from "../../util/errors";
import apiUrls, { DEFAULT_USER_ID, getLabelingUrl } from "./backend-urls";
import { appConfigSelectors } from "../../store/appConfig/appConfigSlice";
import { convertModelTypeToApiString, ModelType } from "../../store/global-types/customization-types";
import { DeploymentConfigInfo } from "../../store/appConfig/appDeploymentInfoTypes";

/**
 * Wrapper for accessing MVision Configuration functions through MVision cloud.
 */
export default class CloudApi implements BackendApiInterface {

    /** Returns a backend client for any backend API calls matching whatever is the current
     * cloud backend.
     */
    getAuthBackendClient(): BackendClient {
        const backend = appConfigSelectors.selectBackend(store.getState());
        if (backend === undefined) {
            throw new Error(`Could not get current cloud backend.`);
        }

        return getCloudAuthBackendClient(backend);
    }

    async init(dispatch: Dispatch, deploymentInfo: DeploymentConfigInfo) {
        // no-op init
    }

    /** Performs a GET fetch request to backend. */
    private async fetchFromApi(url: string, headers?: any): Promise<any> {
        const client = this.getAuthBackendClient();
        const response = await client.fetch(url, { headers: headers || {} });
        const json = await response.json();

        if (!response || response.status !== 200) {
            const message = getErrorMessageFromBackendValidationErrorJson(json);
            console.error(message);
            console.log(json);
            throw new Error(message);
        }

        return json;
    }

    /** Sends a POST request to backend, with an optional JSON body and optional headers. */
    private async postToApi(url: string, headers?: any, jsonData?: string): Promise<any> {
        const client = this.getAuthBackendClient();
        const data = jsonData;

        const httpRequestOptions: any = {};
        if (jsonData) { httpRequestOptions['body'] = data; }
        if (headers) { httpRequestOptions['headers'] = headers; }
        const response = await client.post(url, httpRequestOptions, { postJson: true });
        const json = await response.json();

        if (!response || response.status !== 200) {
            const message = getErrorMessageFromBackendValidationErrorJson(json);
            console.error(message);
            console.log(response);
            if (jsonData) {
                throw await convertResponseToError(json, jsonData, message);
            } else {
                throw new Error(message);
            }
        }

        return json;
    }

    /** Sends a PUT request to backend, with an optional JSON body and optional headers. */
    private async putToApi(url: string, headers?: any, jsonData?: string): Promise<any> {
        const client = this.getAuthBackendClient();
        const data = jsonData;

        const httpRequestOptions: any = { 'method': 'put' };
        if (jsonData) { httpRequestOptions['body'] = data; }
        if (headers) { httpRequestOptions['headers'] = headers; }
        const response = await client.fetch(url, httpRequestOptions, { postJson: true });
        const json = await response.json();

        if (!response || response.status !== 200) {
            const message = getErrorMessageFromBackendValidationErrorJson(json);
            console.error(message);
            console.log(response);
            if (jsonData) {
                throw await convertResponseToError(json, jsonData, message);
            } else {
                throw new Error(message);
            }
        }

        return json;
    }

    /** Performs a DELETE request to backend. */
    private async deleteFromApi(url: string, headers: any): Promise<boolean> {
        const client = this.getAuthBackendClient();

        const response = await client.fetch(url, { headers: headers, 'method': 'delete' });
        const json = await response.json();

        if (!response || response.status !== 200) {
            const message = getErrorMessageFromBackendValidationErrorJson(json);
            console.error(message);
            console.log(json);
            throw new Error(message);
        }

        return json;
    }

    public async fetchContouringCustomizationAsync(appClient: MVisionAppClient | undefined): Promise<any> {
        if (appClient === undefined) {
            // return null if no app client was given
            return null;
        }

        return await this.fetchFromApi(apiUrls.contouringConfig, { userId: appClient.userId });
    }

    public async saveContouringCustomizationAsync(appClient: MVisionAppClient | undefined, json: string): Promise<boolean> {
        if (appClient === undefined) {
            // return null if no app client was given
            return false;
        }

        return await this.postToApi(apiUrls.contouringConfig, { userId: appClient.userId }, json);
    }

    public async deleteContouringCustomizationAsync(appClient: MVisionAppClient | undefined): Promise<boolean> {
        if (appClient === undefined) {
            // return null if no app client was given
            return false;
        }

        return await this.deleteFromApi(apiUrls.contouringConfig, { userId: appClient.userId });
    }

    /** CLOUD: Returns default configuration for a single contouring model */
    public async fetchDefaultConfigurationForContouringModelAsync(modelName: string): Promise<any> {
        return await this.fetchFromApi(apiUrls.makeGetDefaultConfigForContourModelUrl(modelName), { userId: DEFAULT_USER_ID });
    }

    public async fetchDoseCustomizationAsync(appClient: MVisionAppClient | undefined): Promise<any> {
        if (appClient === undefined) {
            // return null if no app client was given
            return null;
        }

        return await this.fetchFromApi(apiUrls.doseConfig, { userId: appClient.userId });
    }

    public async saveDoseCustomizationAsync(appClient: MVisionAppClient | undefined, json: string): Promise<boolean> {
        if (appClient === undefined) {
            // return null if no app client was given
            return false;
        }

        return await this.postToApi(apiUrls.doseConfig, { userId: appClient.userId }, json);
    }

    public async deleteDoseCustomizationAsync(appClient: MVisionAppClient | undefined): Promise<boolean> {
        if (appClient === undefined) {
            // return null if no app client was given
            return false;
        }

        return await this.deleteFromApi(apiUrls.doseConfig, { userId: appClient.userId });
    }

    /** CLOUD: Returns default configuration for a single dose model */
    public async fetchDefaultConfigurationForDoseModelAsync(modelName: string): Promise<any> {
        return await this.fetchFromApi(apiUrls.makeGetDefaultConfigForDoseModelUrl(modelName), { userId: DEFAULT_USER_ID });
    }

    public async fetchAllDaemonConfigsAsync(appClient: MVisionAppClient | undefined): Promise<any> {
        if (appClient === undefined) {
            return null;
        }

        return await this.fetchFromApi(apiUrls.daemonsConfig, { userId: appClient.userId });
    }

    public async fetchDaemonConfigAsync(appClient: MVisionAppClient | undefined, daemonSessionId: string): Promise<any> {
        if (appClient === undefined) {
            return null;
        }

        return await this.fetchFromApi(apiUrls.daemonConfig, { userId: appClient.userId, daemonSessionId: daemonSessionId });
    }

    public async saveDaemonConfigAsync(appClient: MVisionAppClient | undefined, json: string): Promise<boolean> {
        if (appClient === undefined) {
            return false;
        }

        return await this.postToApi(apiUrls.daemonConfig, { userId: appClient.userId }, json);
    }

    public async resetDaemonConfigAsync(appClient: MVisionAppClient | undefined, daemonSessionId: string): Promise<boolean> {
        if (appClient === undefined || !daemonSessionId) {
            return false;
        }

        return await this.postToApi(apiUrls.resetDaemonConfig, { userId: appClient.userId, daemonSessionId: daemonSessionId });
    }

    public async deleteDaemonConfigAsync(appClient: MVisionAppClient | undefined, daemonSessionId: string): Promise<boolean> {
        if (appClient === undefined || !daemonSessionId) {
            return false;
        }

        return await this.deleteFromApi(apiUrls.daemonConfig, { userId: appClient.userId, daemonSessionId: daemonSessionId });
    }

    public async fetchLicenseStatusAsync(appClient: MVisionAppClient | undefined): Promise<any> {
        if (appClient === undefined) {
            // return an empty license if no app client was given
            return {};
        }

        return await this.fetchFromApi(apiUrls.getLicenseUrl, { userId: appClient.userId });
    }

    public async saveLicenseAsync(appClient: MVisionAppClient | undefined, newLicense: string): Promise<boolean> {
        throw new Error("Method not implemented.");
    }

    public async fetchLabelingAsync(modelTypes: ModelType[]): Promise<CombinedVersionInfoJson> {
        const labelingJsons: LabelJsonWrapper[] = [];

        const backendJson = await this.fetchFromApi(apiUrls.backendVersionUrl);

        for (const modelType of modelTypes) {
            const labelingJson = await this.fetchFromApi(getLabelingUrl(modelType));
            labelingJsons.push({ modelType: modelType, labeling: labelingJson });
        }

        return { backendVersion: backendJson, labeling: labelingJsons };
    }

    public async fetchAccessRightsAsync(): Promise<any> {
        return await this.fetchFromApi(apiUrls.getAccessRightsUrl);
    }

    public async fetchActiveContouringModelsAsync(appClient: MVisionAppClient | undefined, modelType: ModelType): Promise<any> {
        if (appClient === undefined) {
            // return null if no app client was given
            return null;
        }

        return await this.fetchFromApi(apiUrls.getListActiveModelsUrl, { userId: appClient.userId, modelType: convertModelTypeToApiString(modelType) });
    }

    public async fetchClientListAsync(): Promise<any> {
        const client = this.getAuthBackendClient();
        const response = await client.fetch(apiUrls.getClientListUrl);
        const json = await response.json();

        if (!response || response.status !== 200) {
            const message = getErrorMessageFromBackendValidationErrorJson(json);
            console.error(message);
            console.log(json);
            throw new Error(message);
        }

        return json;
    }

    public async getUserSettingsAsync(): Promise<any> {
        // see user-settings.tsx
        throw new Error('User settings are not implemented');

        // const client = this.getCloudAuthBackendClient();
        // const response = await client.fetch(getAllUserSettingsUrl);
        // const json = await response.json();

        // if (!response || response.status !== 200) {
        //     const message = this.getErrorMessageFromErrorJson(json);
        //     console.error(message);
        //     console.log(json);
        //     throw new Error(message);
        // }

        // return json;
    }

    public async saveUserSettingAsync(setting: string, jsonValue: string): Promise<boolean> {
        // see user-settings.tsx
        throw new Error('User settings are not implemented');

        // return await this.putToApi(generateSaveSettingUrl(setting), undefined, jsonValue);
    }

    public async saveUserSettingsAsync(settingsJson: { [x: string]: JsonValue; }): Promise<boolean> {
        // see user-settings.tsx
        throw new Error('User settings are not implemented');

        // return await this.putToApi(getAllUserSettingsUrl, undefined, JSON.stringify(settingsJson));
    }


    public getAppWrapperComponent(): (props: React.PropsWithChildren<{}>) => JSX.Element {
        return AuthenticatedPage;
    }
}
