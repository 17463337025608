import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { StoreState } from '../../../store/store';
import { doseRoiChangesReverted, doseSelectors } from '../../../store/dose/doseSlice';
import DoseRoiItem from './DoseRoiItem';

interface DoseRoiFormProps {
    roiId: string;
    roiIdList: string[];
}

const DoseRoiForm = (props: DoseRoiFormProps) => {

    const { roiId, roiIdList } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const roi = useSelector((state: StoreState) => doseSelectors.selectRoiById(state, roiId));

    const handleUndoRoiChanges = useCallback(() => {
        dispatch(doseRoiChangesReverted(roiId));
    }, [dispatch, roiId]);

    if (roi === undefined) {
        return null;
    }

    return (
        <DoseRoiItem
            roiId={roiId}
            rowActions={[
                {
                    title: t('customizationPage.dose.roi.undoChanges'),
                    onClick: handleUndoRoiChanges,
                    disabled: !roi.isModified,
                },
            ]}
        />
    );
}

export default DoseRoiForm;
