import { useCallback, useState } from 'react';
import { Modal, ModalVariant, Button } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';

import CustomizationNameField, { defaultDuplicatedModelNameValidation, validateDuplicatedModelName } from './CustomizationNameField';
import { Model, CustomizationBase } from '../../store/global-types/customization-types';

import './customization-page.css';
import { CustomizationNameAndModelId } from './CustomizationToolbar';

interface RenameCustomizationModalProps {
    isOpen: boolean,
    onClose: () => void,
    model: Model,
    customization: CustomizationBase,
    allCustomizationNamesAndModelIds: CustomizationNameAndModelId[],
    onRenameModelCustomization: (customizationBaseId: string, newCustomizationName: string) => void,
}

const RenameCustomizationModal = (props: RenameCustomizationModalProps) => {
    const { isOpen, onClose, model, customization, allCustomizationNamesAndModelIds, onRenameModelCustomization } = props;

    const { t } = useTranslation();

    const [newNameValidation, setNewNameValidation] = useState(defaultDuplicatedModelNameValidation);
    const [newCustomizationName, setNewCustomizationName] = useState('');

    const handleCustomizationRenameChange = useCallback((newName: string) => {
        setNewCustomizationName(newName);
        const validationResult = validateDuplicatedModelName(allCustomizationNamesAndModelIds, newName, model.id);
        setNewNameValidation(validationResult);
    }, [allCustomizationNamesAndModelIds, model.id]);

    const handleConfirmRename = useCallback(() => {
        onRenameModelCustomization(customization.id, newCustomizationName);
        onClose();
    }, [onRenameModelCustomization, customization.id, newCustomizationName, onClose]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={t('dialog.renameCustomization')}
            variant={ModalVariant.medium}
            actions={[
                <Button key="confirm-rename" variant="primary" onClick={handleConfirmRename} isDisabled={newNameValidation.validated === 'error' || !newCustomizationName}>{t('common.rename')}</Button>,
                <Button key="cancel-rename" variant="tertiary" onClick={onClose}>{t('common.cancel')}</Button>,
            ]}
        >
            <div>
                <CustomizationNameField
                    modelName={model.modelName}
                    sourceCustomizationName={customization.customizationName}
                    customizationNameValidation={newNameValidation}
                    onCustomizationNameChange={handleCustomizationRenameChange}
                    customizationName={newCustomizationName}
                />
            </div>
        </Modal>
    );
}

export default RenameCustomizationModal;
