import { Split, SplitItem, Stack, StackItem, TextContent, Text, Alert, DataListCell, DataListItem, DataListItemRow, DataListItemCells } from '@patternfly/react-core';
import React, { PropsWithChildren } from 'react';

import './dose-output-form.css';
import { BackendValidationErrorViewModel } from '../../../store/global-types/store-errors';

interface DoseOutputSectionProps {
    title?: string,
    isModified: boolean,
    hasValidationError: boolean,
    validationError: BackendValidationErrorViewModel | undefined,
}

const DoseOutputSection = (props: PropsWithChildren<DoseOutputSectionProps>) => {

    const { title, isModified, hasValidationError, validationError, children } = props;

    const dataListCells = (
        <DataListCell key="modelRules" width={4}>
            <div className="dose-output-section">
                <Stack>
                    <StackItem>
                        <Split>
                            {title && (
                                <SplitItem className="output-section-title">
                                    <TextContent>
                                        <Text><b>{title}{`${isModified ? '*' : ''}`}</b></Text>
                                    </TextContent>
                                </SplitItem>
                            )}
                            <SplitItem isFilled>
                                <Stack>
                                    {children}
                                </Stack>
                            </SplitItem>
                        </Split>
                    </StackItem>
                    {
                        hasValidationError && validationError && (
                            <StackItem className="output-section-error validation-error validation-error-box">
                                <Alert variant="danger" isInline isPlain isExpandable title={`Validation error: ${validationError.message}`}>
                                    <div>Error type: {validationError.detail}</div>
                                    <div>Error ctx: {validationError.ctx}</div>
                                </Alert>
                            </StackItem>
                        )
                    }
                </Stack>
            </div>
        </DataListCell>
    );

    return (
        <DataListItem aria-labelledby={`Dose file customization for ${title}`}>
            <DataListItemRow className="model-selection-model-rules">
                <DataListItemCells dataListCells={dataListCells} />
            </DataListItemRow>
        </DataListItem>
    );
}

export default DoseOutputSection;
