import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "../store";
import { doseSelectors } from "./doseSlice";
import { CustomizationBase, CustomizationBaseWithModelName, CustomizationObjectType, CustomizationOutput, Model, ModelVisibility } from "../global-types/customization-types";
import { DoseRoi, DoseTarget, isDoseCustomizationOutput } from "./dose-types";
import { BackendValidationErrorViewModel, FormValidationError } from "../global-types/store-errors";
import { generateCustomizationTreeViewItems, SelectorWithInputArg } from "../global-types/customizationSelectors";
import { userSelectors } from "../user/userSlice";
import { naturalSort } from "../../util/sort";

/** Generate a tree view hierarchy of models and some of their child objects. This is used by the customization page side navigation.
 * This data isn't ready-to-go for Patternfly's TreeView component but the separate convertTreeViewDataIntoItemHierarchy helper function
 * in customization-helpers.tsx is used to generate the appropriate data from what this function produces.
 */
export const selectDoseTreeViewItems = createSelector([
    (state: StoreState) => doseSelectors.selectModelsInAlphabeticalOrder(state),
    (state: StoreState) => doseSelectors.selectCustomizationBasesInAlphabeticalOrder(state),
    (state: StoreState) => doseSelectors.selectOutputEntities(state),
    (state: StoreState) => doseSelectors.selectOutputMetadataEntities(state),
    (state: StoreState) => doseSelectors.selectCustomizationValidationErrorEntities(state),
],
    (models, customizationBases, outputEntities, metadataEntities, validationEntities) => {

        // create an inline validation function that will be supplied to tree view generator function
        const areOutputChildrenValid = (output: CustomizationOutput, validationEntities: Record<string, BackendValidationErrorViewModel>): boolean => {
            if (!isDoseCustomizationOutput(output)) {
                throw new Error('Invalid output type');
            }
            return output.rois.every(rId => !validationEntities[rId] || validationEntities[rId]!.type !== CustomizationObjectType.Roi);
        }

        const results = generateCustomizationTreeViewItems(models, customizationBases, outputEntities, metadataEntities, validationEntities, areOutputChildrenValid);
        return results;
    });


/** Factory function for making a memoized selector that returns all form validation errors objects that are related to a specific roi customization. */
export const makeSelectFormValidationErrorsForRoi = (): SelectorWithInputArg<FormValidationError[], string> => createSelector(
    [(_, roiId: string) => roiId, doseSelectors.selectFormValidationErrors],
    (roiId, formValidationErrors) => formValidationErrors.filter(e => e.itemId === roiId && e.itemType === CustomizationObjectType.Roi)
);

/** Factory function for making a memoized selector that returns all dose roi objects that belong to a specific dose output. */
export const makeSelectDoseRoisForOutput = (): SelectorWithInputArg<DoseRoi[], string> => createSelector(
    [doseSelectors.selectRois, (_, outputId: string) => outputId],
    (doseRois, outputId) => doseRois.filter(r => r.outputId === outputId)
);

/** Factory function for making a memoized selector that returns all dose target objects that belong to a specific dose output. */
export const makeSelectDoseTargetsForOutput = (): SelectorWithInputArg<DoseTarget[], string> => createSelector(
    [doseSelectors.selectTargets, (_, outputId: string) => outputId],
    (doseTargets, outputId) => doseTargets.filter(r => r.outputId === outputId)
);

/**
* Factory function for making a memoized selector that returns true if any metadata for given output id is modified.
*/
export const makeSelectIsAnyMetadataForOutputModified = (): SelectorWithInputArg<boolean, string> => createSelector(
    [
        (state: StoreState, customizationOutputId: string) => doseSelectors.selectOutputById(state, customizationOutputId),
        (state: StoreState) => doseSelectors.selectOutputMetadataEntities(state)
    ],
    (customizationOutput, metadataEntities) => customizationOutput?.metadata.some(mId => metadataEntities[mId]?.isModified) || false
);

/**
 * Factory function for making a memoized selector that returns true if any dose ROI for given output id is modified.
 */
export const makeSelectIsAnyRoiForOutputModified = (): SelectorWithInputArg<boolean, string> => createSelector(
    [
        (state: StoreState, outputId) => doseSelectors.selectOutputById(state, outputId),
        (state: StoreState) => doseSelectors.selectRoiEntities(state)
    ],
    (customizationOutput, roiCustomizationEntities) => customizationOutput?.rois.some(rId => roiCustomizationEntities[rId]?.isModified) || false
);

/**
 * Factory function for making a memoized selector that returns true if any dose target for given output id is modified.
 */export const makeSelectIsAnyTargetForOutputModified = (): SelectorWithInputArg<boolean, string> => createSelector(
    [
        (state: StoreState, outputId) => doseSelectors.selectOutputById(state, outputId),
        (state: StoreState) => doseSelectors.selectTargetEntities(state)
    ],
    (customizationOutput, targetCustomizationEntities) => customizationOutput?.targets.some(tId => targetCustomizationEntities[tId]?.isModified) || false
);

/**
 * Factory function for making a memoized selector that returns true if any selection rule (trigger) for a customization base id is modified.
 */
export const makeSelectIsAnyTriggerForBaseModified = (): SelectorWithInputArg<boolean, string> => createSelector(
    [
        (state: StoreState, customizationBaseId) => doseSelectors.selectCustomizationBaseById(state, customizationBaseId),
        (state: StoreState) => doseSelectors.selectAeTitleRuleEntities(state),
        (state: StoreState) => doseSelectors.selectDicomRuleEntities(state)
    ],
    (customizationBase, aeTitleRuleEntities, dicomRuleEntities) => {
        const isAnyAeTitleRuleModified = customizationBase?.aeTitleRules.some(aId => aeTitleRuleEntities[aId]?.isModified) || false;
        if (isAnyAeTitleRuleModified) { return true; }
        return customizationBase?.dicomRules.some(dId => dicomRuleEntities[dId]?.isModified === true) || false;
    }
);

/** Factory function for making a memoized selector that returns all form validation errors objects that are related to a specific dicom rule. */
export const makeSelectFormValidationErrorsForDicomRule = (): SelectorWithInputArg<FormValidationError[], string> => createSelector(
    [(_, dicomRuleId: string) => dicomRuleId, doseSelectors.selectFormValidationErrors],
    (dicomRuleId, formValidationErrors) => formValidationErrors.filter(e => e.itemId === dicomRuleId && e.itemType === CustomizationObjectType.DicomRule)
);

/** Factory function for making a memoized selector that returns all form validation errors objects that are related to a specific dicom attribute rule. */
export const makeSelectFormValidationErrorsForDicomAttributeRule = (): SelectorWithInputArg<FormValidationError[], string> => createSelector(
    [(_, dicomAttributeRuleId: string) => dicomAttributeRuleId, doseSelectors.selectFormValidationErrors],
    (dicomAttributeRuleId, formValidationErrors) => formValidationErrors.filter(e => e.itemId === dicomAttributeRuleId && e.itemType === CustomizationObjectType.DicomAttributeRule)
);

/**
 * Returns customization base IDs and their names in alphabetical order.
 */
export const selectCustomizationBasesInNameOrder = createSelector(
    [(state: StoreState): CustomizationBase[] => doseSelectors.selectCustomizationBases(state),
    (state: StoreState): Record<string, Model> => doseSelectors.selectModelEntities(state),
    (state: StoreState): boolean => userSelectors.selectAreDeprecatedModelsShown(state),
    (state: StoreState): boolean => userSelectors.selectAreNonLicensedModelsShown(state),
    (state: StoreState): boolean => userSelectors.selectAreUserHiddenModelsShown(state)],
    (customizations, modelEntities, areDeprecatedModelsShown, areNonLicensedModelsShown, areUserHiddenModelsShown) => {

        // filter customizations per current user config
        const filteredCustomizations = customizations.filter(c => {
            const model = modelEntities[c.modelId];
            if (!model) { throw new Error(`Could not find model for customization ${c.customizationName} (${c.id})`); }
            if (model.visibility === ModelVisibility.AlwaysShown) { return true; }
            if (model.visibility === ModelVisibility.AlwaysHidden) { return areUserHiddenModelsShown; }
            if (!model.isAvailable && !areNonLicensedModelsShown) { return false; }
            if (model.isDeprecated && !areDeprecatedModelsShown) { return false; }
            return true;
        })

        const customizationNamesWithIds: CustomizationBaseWithModelName[] = filteredCustomizations.map(c => ({
            customizationBaseId: c.id,
            label: `${modelEntities[c.modelId]?.label || ''} ${c.customizationName}`
        }));

        return naturalSort(customizationNamesWithIds, 'label');
    });
    