import { naturalSort } from "../../util/sort";
import { ContouringSliceState, globalRoiCustomizationAdapter, roiCustomizationAdapter } from "./contouringSlice";
import { roiCustomizationMatchesGlobalRoi, createGlobalRoiCustomization } from "./contouring-helpers";
import { ContouringRoi, GlobalContouringRoi } from "./contouring-types";
import { CustomizationObjectType } from "../global-types/customization-types";
import { setObjectAndAncestorsAsModified } from "../global-types/reducer-helpers";


///////////////
//
// contouringConfig reducer helper functions
//
// This file contains helper functions for the contouringConfig reducer. These functions are not meant to be used outside of the slice file
// (apart from unit tests).
//
//////////////


/**
 * A helper function for updating a field in a contouring ROI customization object.
 *  
 * This should only be called from within contouringConfig reducers (or from unit tests).
 * 
 * @param roiId Internal (entity adapter) ID of the ROI to update.
 * @param isGlobalRoi True if the ROI being updated is a global ROI (and thus the changes should be applied to all the actual
         * ROIs that this global ROI represents) or false if the ROI being updated is a normal single ROI.
 * @param changes The field(s) to update.
 * @param noGlobalRoiRecalculate If true don't recalculate global ROIs (which would most likely be affected by changes to any normal or global ROIs). Defaults to false/undefined
 * (in which case global ROIs ARE re-calculated). The only reasons to not re-calculate global ROIs would be either updates to only fields that are not used in global ROI
 * groupings (e.g. isIncluded) or if calling this function is part of a mass operation and global ROIs are calculated for the entire batch afterwards. Otherwise global
 * ROIs SHOULD always be recalculated or they will go out of sync!
 */
export const updateRoiCustomizationField = (state: ContouringSliceState, roiId: string, isGlobalRoi: boolean, changes: Partial<ContouringRoi> | Partial<GlobalContouringRoi>, noGlobalRoiRecalculate?: boolean) => {
    const roiIds = isGlobalRoi ? state.globalRoiCustomizations.entities[roiId]?.coveredRois : [roiId];

    if (roiIds) {
        // update 1 or more matching rois -- then mark matching models as modified, and also update the global customization entry if applicable
        // TODO: consider calculating here automatically if isModified is needed instead of having to pass it from all the callers
        roiCustomizationAdapter.updateMany(state.roiCustomizations, roiIds.map(roiId => ({ id: roiId, changes: changes })));
        if (!noGlobalRoiRecalculate) {
            if (isGlobalRoi) {
                // if we were modifying a global roi, let's also update that entry to match
                calculateGlobalRoiChangesForGlobalRoi(state, roiId, changes);
            } else {
                // if we're modifying a normal roi, we need to check if this modification removes or adds it from/to a global roi
                calculateGlobalRoiChangesForRegularRoi(state, roiId);
            }
        }
        setObjectAndAncestorsAsModified(state, roiIds, CustomizationObjectType.Roi);
    }
}

/** Checks if a just-modified roi customization belongs to a globalRoi, and removes it from it if they are no longer identical,
 * or vice versa. Note that GlobalRoi majority groupings are NOT re-calculated (e.g. if removing this roi from a global roi would
 * make some other group of roi customizations the majority group after this change, the global roi will nevertheless not changed)
 * as that would be confusing during use.
 * 
 * This should only be called from within contouringConfig reducers (or from unit tests).
 */
export const calculateGlobalRoiChangesForRegularRoi = (state: ContouringSliceState, roiId: string) => {
    const roi = state.roiCustomizations.entities[roiId];

    if (roi !== undefined) {
        let isNewGlobalRoiNeeded = false;
        const globalRoiId = roi.globalRoiId;

        if (globalRoiId !== undefined) {
            // check if this roi needs to be removed from matching global roi
            const globalRoi = state.globalRoiCustomizations.entities[globalRoiId];
            if (globalRoi && !roiCustomizationMatchesGlobalRoi(roi, globalRoi)) {
                // we may need to create a new global roi after other operations if operations no longer match
                isNewGlobalRoiNeeded = globalRoi.operation !== roi.operation;

                const coveredRois = globalRoi.coveredRois.filter(rId => rId !== roiId);
                const excludedRois = isNewGlobalRoiNeeded ? globalRoi.excludedRois.filter(r => r !== roiId) :
                    globalRoi.excludedRois.includes(roiId) ? globalRoi.excludedRois : globalRoi.excludedRois.concat(roiId);
                if (!isNewGlobalRoiNeeded) {
                    // update previous global roi out of the roi object, unless we need potentially a completely new global roi
                    // in which case this operation is done later
                    roiCustomizationAdapter.updateOne(state.roiCustomizations, { id: roiId, changes: { globalRoiId: undefined } });
                }

                // either update or remove the previous global roi
                if (coveredRois.length === 0 && excludedRois.length === 0) {
                    globalRoiCustomizationAdapter.removeOne(state.globalRoiCustomizations, globalRoiId);
                } else {
                    globalRoiCustomizationAdapter.updateOne(state.globalRoiCustomizations, {
                        id: globalRoiId,
                        changes: { ...getGlobalRoiItemsInAlphabeticalOrder(state, globalRoi, coveredRois, excludedRois) }
                    });
                }
            }
        } else {
            // a new global roi is potentially needed (or the roi can be added to an existing one)
            isNewGlobalRoiNeeded = true;
        }

        if (isNewGlobalRoiNeeded) {
            // check if this roi could be added to an existing global roi
            const globalRoiWithSameOperation = Object.values(state.globalRoiCustomizations.entities).find(g => g?.operation === roi.operation);
            if (globalRoiWithSameOperation !== undefined) {
                // check that global roi and roi are equal
                const matchesGlobalRoi = roiCustomizationMatchesGlobalRoi(roi, globalRoiWithSameOperation);
                const coveredRois = matchesGlobalRoi ?
                    globalRoiWithSameOperation.coveredRois.includes(roiId) ? globalRoiWithSameOperation.coveredRois : globalRoiWithSameOperation.coveredRois.concat(roiId) :
                    globalRoiWithSameOperation.coveredRois.filter(rId => rId !== roiId);
                const excludedRois = !matchesGlobalRoi ?
                    globalRoiWithSameOperation.excludedRois.includes(roiId) ? globalRoiWithSameOperation.excludedRois : globalRoiWithSameOperation.excludedRois.concat(roiId) :
                    globalRoiWithSameOperation.excludedRois.filter(rId => rId !== roiId);
                roiCustomizationAdapter.updateOne(state.roiCustomizations, { id: roiId, changes: { globalRoiId: matchesGlobalRoi ? globalRoiWithSameOperation.id : undefined } });
                globalRoiCustomizationAdapter.updateOne(state.globalRoiCustomizations, {
                    id: globalRoiWithSameOperation.id,
                    changes: { ...getGlobalRoiItemsInAlphabeticalOrder(state, globalRoiWithSameOperation, coveredRois, excludedRois) }
                })
            } else {
                // no existing global roi -- create a new one
                const newGlobalRoi = createGlobalRoiCustomization([roi], [], true);
                roiCustomizationAdapter.updateOne(state.roiCustomizations, { id: roiId, changes: { globalRoiId: newGlobalRoi.id } });
                globalRoiCustomizationAdapter.addOne(state.globalRoiCustomizations, newGlobalRoi);
            }
        }
    }
}

/** Checks if just performed updates to a global ROI would cause any changes in ROIs (e.g. if previously excluded ROIs would now be part
 * of this global ROI).
 * 
 * This should only be called from within contouringConfig reducers (or from unit tests).
) */
export const calculateGlobalRoiChangesForGlobalRoi = (state: ContouringSliceState, globalRoiId: string, changes: Partial<GlobalContouringRoi>) => {
    const globalRoi = state.globalRoiCustomizations.entities[globalRoiId];
    if (globalRoi !== undefined) {
        // apply same updates as were applied for regular rois
        const globalRoiChanges = { ...changes };

        // check if any excluded regular rois now match global roi
        const changedRoiIds: string[] = [];
        for (const roiId of globalRoi.excludedRois) {
            const roi = state.roiCustomizations.entities[roiId];
            if (roi && roiCustomizationMatchesGlobalRoi(roi, globalRoi, changes) && !globalRoi.coveredRois.includes(roiId)) {
                changedRoiIds.push(roiId);
            }
        }

        if (changedRoiIds.length > 0) {
            const coveredChanges = globalRoi.coveredRois.concat(changedRoiIds);
            const excludedChanges = globalRoi.excludedRois.filter(rId => !changedRoiIds.includes(rId));
            const sortedChanges = getGlobalRoiItemsInAlphabeticalOrder(state, globalRoi, coveredChanges, excludedChanges);
            globalRoiChanges['coveredRois'] = sortedChanges.coveredRois;
            globalRoiChanges['excludedRois'] = sortedChanges.excludedRois;
            roiCustomizationAdapter.updateMany(state.roiCustomizations, changedRoiIds.map(rId => ({ id: rId, changes: { globalRoiId: globalRoiId } })));
        }


        globalRoiCustomizationAdapter.updateOne(state.globalRoiCustomizations, { id: globalRoiId, changes: { isModified: true, ...globalRoiChanges } });
    }
}

/**
 * Sorts ROIs within a Global ROI in alphabetical order.
 * 
 * This should only be called from within contouringConfig reducers (or from unit tests).
 * 
 * @param globalRoi The Global ROI for which the sorting is performed. This object is not mutated within this function, any additions or replacements to the props
 * must be done outside this function with the return data.
 * @param coveredRoiIds List of ROI IDs that the Global ROI covers.
 * @param excludedRoiIds List of ROI IDs that the Global ROI does not cover (but still belong under the same Global ROI).
 * @returns an object with coveredRois and excludedRois props sorted in alphabetical order.
 */
export const getGlobalRoiItemsInAlphabeticalOrder = (state: ContouringSliceState, globalRoi: GlobalContouringRoi, coveredRoiIds: string[], excludedRoiIds: string[])
    : { coveredRois: string[], excludedRois: string[] } => {
    const coveredRois = coveredRoiIds.map(cId => state.roiCustomizations.entities[cId]);
    if (coveredRois.some(c => c.customizationOutputId === undefined)) { throw new Error(`Global roi ${globalRoi.id} covered ROIs list contains one or more undefined rois`); }
    const excludedRois = excludedRoiIds.map(cId => state.roiCustomizations.entities[cId]);
    if (excludedRois.some(c => c.customizationOutputId === undefined)) { throw new Error(`Global roi ${globalRoi.id} excludedRois ROIs list contains one or more undefined rois`); }

    const coveredRoisWithOutputs = coveredRois.map(c => ({ roiId: c.id, output: state.contourOutputs.entities[c.customizationOutputId!] }));
    if (coveredRoisWithOutputs.some(c => c.output === undefined)) { throw new Error(`No output found for a covered roi in ${globalRoi.id}`); }
    const coveredRoisWithOutputsAndBases = (coveredRoisWithOutputs).map(c => ({ roiId: c.roiId, output: c.output!, base: state.customizationBases.entities[c.output!.modelCustomizationBaseId] }));
    if (coveredRoisWithOutputsAndBases.some(c => c.base === undefined)) { throw new Error(`No base found for a covered roi in ${globalRoi.id}`); }
    const coveredRoisWithOutputsAndBasesAndModels = (coveredRoisWithOutputsAndBases).map(c => ({ roiId: c.roiId, output: c.output!, base: c.base!, model: state.models.entities[c.base!.modelId] }));
    if (coveredRoisWithOutputsAndBasesAndModels.some(c => c.model === undefined)) { throw new Error(`No model found for a covered roi in ${globalRoi.id}`); }
    const toBeSortedCoveredRois = coveredRoisWithOutputsAndBasesAndModels.map(c => ({ roiId: c.roiId, label: `${c.model!.modelName}.${c.base.customizationName}.${c.output.filename}` }));

    const excludedRoisWithOutputs = excludedRois.map(c => ({ roiId: c.id, output: state.contourOutputs.entities[c.customizationOutputId!] }));
    if (excludedRoisWithOutputs.some(c => c.output === undefined)) { throw new Error(`No output found for an excluded roi in ${globalRoi.id}`); }
    const excludedRoisWithOutputsAndBases = (excludedRoisWithOutputs).map(c => ({ roiId: c.roiId, output: c.output!, base: state.customizationBases.entities[c.output!.modelCustomizationBaseId] }));
    if (excludedRoisWithOutputsAndBases.some(c => c.base === undefined)) { throw new Error(`No base found for an excluded roi in ${globalRoi.id}`); }
    const excludedRoisWithOutputsAndBasesAndModels = (excludedRoisWithOutputsAndBases).map(c => ({ roiId: c.roiId, output: c.output!, base: c.base!, model: state.models.entities[c.base!.modelId] }));
    if (excludedRoisWithOutputsAndBasesAndModels.some(c => c.model === undefined)) { throw new Error(`No model found for an excluded roi in ${globalRoi.id}`); }
    const toBeSortedExcludedRois = excludedRoisWithOutputsAndBasesAndModels.map(c => ({ roiId: c.roiId, label: `${c.model!.modelName}.${c.base.customizationName}.${c.output.filename}` }));

    const sortedCoveredRoiIds = naturalSort(toBeSortedCoveredRois, 'label').map(c => c.roiId);
    const sortedExcludedRoiIds = naturalSort(toBeSortedExcludedRois, 'label').map(c => c.roiId);

    return { coveredRois: sortedCoveredRoiIds, excludedRois: sortedExcludedRoiIds };
}



/**
 * Guarantees that covered and excluded ROIs in a Global ROI are in alphabetical order.
 */
export const ensureGlobalRoiItemsAreInAlphabeticalOrder = (state: ContouringSliceState, globalRoiId: string) => {
    const globalRoi = state.globalRoiCustomizations.entities[globalRoiId];
    if (globalRoi === undefined) {
        throw new Error(`Could not retrieve global roi ${globalRoiId}`);
    }

    const changes = getGlobalRoiItemsInAlphabeticalOrder(state, globalRoi, globalRoi.coveredRois, globalRoi.excludedRois);

    globalRoiCustomizationAdapter.updateOne(state.globalRoiCustomizations, { id: globalRoiId, changes: { ...changes } });
}
