import { Dropdown, DropdownItem, DropdownList, MenuToggle } from '@patternfly/react-core';
import { IAction } from '@patternfly/react-table';
import { EllipsisVIcon} from '@patternfly/react-icons'

import { useCallback, useState } from 'react';

export const ItemActionsColumn = ({ items }: { items: IAction[] }) => {
    const [isOpen, setIsOpen] = useState(false);

    const onToggle = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    return (
        <Dropdown
            onSelect={() => setIsOpen(false)}
            isOpen={isOpen}
            toggle={(toggleRef) => <MenuToggle ref={toggleRef} onClick={onToggle} variant='plain'><EllipsisVIcon/></MenuToggle>}
            isPlain
            shouldFocusFirstItemOnOpen={false}
            popperProps={{ position: 'right' }}
            onOpenChange={() => setIsOpen(!isOpen)}
        >
            <DropdownList style={{ width:"max-content" }}>
            {items.map((item, index) => (
                    <DropdownItem 
                        key={index} 
                        component="button"
                        autoFocus={false}
                        onClick={(event) => item.onClick && item.onClick(event, index, {}, {})}
                        isDisabled={item.disabled}
                    >
                        {item.title}
                    </DropdownItem>
            ))}
            </DropdownList>
        </Dropdown>
    );
};
