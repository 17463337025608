import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Modal } from '@patternfly/react-core';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { interactionRequiredSet } from '../../store/auth/authSlice';
import routes from '../../routes';
import { StoreState } from '../../store/store';

import './InteractionRequiredModal.css';

const InteractionRequiredModal = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAuthInteractionRequired = useSelector((state: StoreState) => state.auth.isInteractionRequired);

    const handleLogInClicked = useCallback(() => {
        // close the modal dialog when user clicks on the "log in" button, no matter in which state 
        // the current auth flow is in -- the next operation that would require auth will either
        // succeed or fail accordingly and that's good enough
        dispatch(interactionRequiredSet(false));
    }, [dispatch]);

    return (
        <Modal
            isOpen={isAuthInteractionRequired}
            title={t('modal.interactionRequired.title')}
            variant={'medium'}
            showClose={false}
        >
            <div className="login-required-text">
                <div>{t('modal.interactionRequired.paragraph1')}</div>

                <div>{t('modal.interactionRequired.paragraph2')}</div>

                <div><Link to={routes.loginPage.slug} onClick={handleLogInClicked} target="_blank" rel="noopener noreferrer"><Button>{t('common.logIn')}</Button></Link></div>
            </div>
        </Modal>
    );
}

export default InteractionRequiredModal;
