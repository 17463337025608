import { call, put, select, takeEvery } from "typed-redux-saga";
import { get } from "lodash-es";
import { AccessRights, getBypassedAccessRights, getInitialUserAccessRights } from "./access-rights";
import { appConfigSelectors } from "../appConfig/appConfigSlice";
import { DeploymentMode, DisabledFeature, isFeatureDisabled } from "../appConfig/appDeploymentInfoTypes";
import { accessRightsSet, userSettingsFetched, userSettingsSet } from "./userSlice";
import { callApi } from "../sagas";

/** A one-time call for fetching user's access rights.
 * TODO: we might want to call this every now and then to invalidate long sessions as needed.
 * However this should be planned properly so we handle e.g. network glitches in a reasonable
 * fashion.
 */
export function* fetchUserAccessRightsSaga() {
    yield* callApi({
        doApiCall: function* (client) {
            let accessRights: AccessRights | null = null;

            // Call the API to fetch access rights
            // bypass this if we're in cockpit or local mode or the feature has been disabled in deployment config
            const config = yield* select(appConfigSelectors.selectAppDeploymentInfo);
            if (!config) { throw new Error('No deployment config file found'); }
            if (config.deploymentMode === DeploymentMode.Cockpit || config.deploymentMode === DeploymentMode.Local || isFeatureDisabled(config, DisabledFeature.AccessRights)) {
                accessRights = getBypassedAccessRights();
            } else {
                accessRights = yield* call(() => client.fetchAndParseAccessRights());
            }

            return accessRights;
        },
        onSuccess: function* (result) {
            yield* put(accessRightsSet({ accessRights: result }));
        },
        onFailure: function* () {
            yield* put(accessRightsSet({ accessRights: getInitialUserAccessRights(), errorMessage: 'An error occurred when trying to retrieve access rights' }));
        }
    });
}

function* fetchUserSettingsSaga() {
    yield* callApi({
        doApiCall: function* (client) {
            const userSettings = yield* call(async () => client.fetchUserSettingsAsync());
            return userSettings;
        },
        onSuccess: function* (result) {
            yield* put(userSettingsSet({ userSettings: result }));
        },
        onFailure: function* (error) {
            console.error('Was not able to fetch user settings');
            const errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
            yield* put(userSettingsSet({ userSettings: null, errorMessage }));
        }
    });
}


function* watchFetchUserSettingsSaga() {
    yield* takeEvery(userSettingsFetched, fetchUserSettingsSaga);
}

/** Returns all relevant watches to be added to a main root watch saga */
export function getWatchesForUserSagas() {
    return [
        // TODO: re-enable this watch once we actually use user settings
        // watchFetchUserSettingsSaga(),  
    ];
}