import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label, Switch } from '@patternfly/react-core';
import InfoCircleIcon from '@patternfly/react-icons/dist/esm/icons/info-circle-icon';
import { useTranslation } from 'react-i18next';
import { Td, Tr } from '@patternfly/react-table';

import CustomizationLabel from '../../components/customization-label';
import { columnIds } from '../customization/contour/ContourRoiTable';
import { getFormValidationErrorMessage } from '../../store/global-types/form-errors';
import { StoreState } from '../../store/store';
import { makeSelectFormValidationErrorsForRoi } from '../../store/contouring/contouringSelectors';
import { contouringSelectors, roiCustomizationIncludedInModelUpdated } from '../../store/contouring/contouringSlice';

import "./global-rois.css";

interface CoveredRoiProps {
    coveredRoiId: string;
}


const CoveredRoi = (props: CoveredRoiProps) => {
    const { coveredRoiId } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const roi = useSelector((state: StoreState) => contouringSelectors.selectRoiById(state, coveredRoiId));
    const customizationOutput = useSelector((state: StoreState) => roi && roi.customizationOutputId ? contouringSelectors.selectOutputById(state, roi.customizationOutputId) : undefined);
    const customizationBase = useSelector((state: StoreState) => customizationOutput && contouringSelectors.selectCustomizationBaseById(state, customizationOutput.modelCustomizationBaseId));
    const segmentationModel = useSelector((state: StoreState) => customizationBase && contouringSelectors.selectModelById(state, customizationBase.modelId));

    // get possible form validation errors for this ROI in its own output
    const selectFormValidationErrorsForRoi = useMemo(makeSelectFormValidationErrorsForRoi, []);
    const formValidationErrors = useSelector((state: StoreState) => selectFormValidationErrorsForRoi(state, coveredRoiId));
    const hasRoiValidationErrors = formValidationErrors.length > 0;

    const handleIncludedChange = useCallback((_: unknown, isIncluded: boolean) => {
        dispatch(roiCustomizationIncludedInModelUpdated({ roiId: coveredRoiId, isIncluded, isGlobalRoi: false }));
    }, [dispatch, coveredRoiId]);

    if (!roi || !customizationOutput || !customizationBase || !segmentationModel) {
        if (!roi) { console.log(`no roi: ${coveredRoiId}`) }
        else if (!customizationOutput) console.log(`no customization output: ${roi.customizationOutputId}`)
        else if (!customizationBase) console.log(`no customization: ${customizationOutput.modelCustomizationBaseId}`)
        else if (!segmentationModel) console.log(`no segmentation model: ${customizationBase.modelId}`)
        return null;
    }

    return (
        <Tr key={coveredRoiId}>
            <Td dataLabel={columnIds.customization}>
                <CustomizationLabel friendlyName={segmentationModel.label} modelName={segmentationModel.modelName} customizationName={customizationBase.customizationName} />
                {hasRoiValidationErrors && (
                    <span className="form-validation-errors-row" title={t('customizationPage.globalRoi.validationErrors')}>{formValidationErrors.map(e => (<Label color="red" icon={<InfoCircleIcon />}>{getFormValidationErrorMessage(e.errorType)}</Label>))}</span>
                )}
            </Td>

            <Td dataLabel={columnIds.targetFile}>
                {t('customizationPage.targetFile')}: <span className="target-file">{customizationOutput.filename}</span>
            </Td>

            <Td dataLabel={columnIds.isIncluded}>
                <Switch
                    id={`covered-roi-included-input-${roi.id}`}
                    isChecked={roi.isIncluded}
                    onChange={handleIncludedChange}
                    label={t('toggle.roiIncludedInStructureSet.on')}
                    labelOff={t('toggle.roiIncludedInStructureSet.off')}
                />
            </Td>

        </Tr>
    );
}

export default CoveredRoi;
