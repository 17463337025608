import React from 'react';
import { PageSection, PageSectionVariants, EmptyState, EmptyStateBody, EmptyStateHeader } from '@patternfly/react-core';

import { useSelector } from 'react-redux';

import '../customization-page.css';

import { useTranslation } from 'react-i18next';
import { CustomizationObjectType } from '../../../store/global-types/customization-types';
import { StoreState } from '../../../store/store';
import { appConfigSelectors } from '../../../store/appConfig/appConfigSlice';
import { doseSelectors } from '../../../store/dose/doseSlice';
import DoseCustomizationForm from './DoseCustomizationForm';
import DoseCustomizationToolbar from './DoseCustomizationToolbar';


interface ContourCustomizationPageProps {
    /** ID of the item we're currently focusing on (on what we last clicked on) */
    currentTreeViewFocus: string,
}


const ContourCustomizationPage = (props: ContourCustomizationPageProps) => {

    const { currentTreeViewFocus } = props;

    const { t } = useTranslation();

    const baseIds = useSelector(doseSelectors.selectCustomizationBaseIds);
    const outputIds = useSelector(doseSelectors.selectOutputIds);
    const customizationFetchError = useSelector((state: StoreState) => state.contouring.customizationFetchError);
    const appConfig = useSelector(appConfigSelectors.selectAppConfig);


    // detect what type of view we're currently presenting
    let currentViewType = CustomizationObjectType.None;
    if (outputIds.includes(currentTreeViewFocus)) {
        currentViewType = CustomizationObjectType.CustomizationOutput;
    } else if (baseIds.includes(currentTreeViewFocus)) {
        currentViewType = CustomizationObjectType.CustomizationBase;
    }

    return (
        <>
            {appConfig && appConfig.showErrorsInUi && customizationFetchError !== null ? (
                <PageSection variant={PageSectionVariants.light}>
                    <EmptyState>
                        <EmptyStateHeader titleText={<>{t('error.generic')}</>} headingLevel="h4" />
                        <EmptyStateBody>{t('error.customizationFetchFailed', { customizationFetchError: customizationFetchError })}</EmptyStateBody>
                    </EmptyState>
                </PageSection>
            ) : (
                <>
                    <DoseCustomizationToolbar
                        currentView={currentViewType}
                        customizationOutputId={currentTreeViewFocus}
                    />
                    <PageSection variant={PageSectionVariants.light}>
                        {currentViewType === CustomizationObjectType.CustomizationOutput ? (
                            <DoseCustomizationForm
                                outputId={currentTreeViewFocus}
                            />
                        ) : (
                            <EmptyState>
                                <EmptyStateBody className='unselectable'>{t('customizationPage.selectCustomization.message')}</EmptyStateBody>
                            </EmptyState>
                        )
                        }
                    </PageSection>
                </>
            )}
        </>
    );
}

export default ContourCustomizationPage;
