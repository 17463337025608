import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CogIcon, EllipsisVIcon } from '@patternfly/react-icons';
import { useTranslation } from 'react-i18next';
import { ExclamationTriangleIcon, BanIcon, CheckIcon } from '@patternfly/react-icons';
import {
    StackItem,
    FormGroup,
    Modal,
    ModalVariant,
    Button,
    InputGroup,
    Divider,
    Split,
    SplitItem,
    Tooltip, InputGroupItem,
    DropdownItem,
    Dropdown,
    MenuToggle,
    MenuToggleElement,
    DropdownGroup,
} from '@patternfly/react-core';

import DebouncedTextInput from '../../components/debounced-text-input';
import { ModelSelectionUndoState } from './ModelSelectionPage';
import { literalTextToRegex, regexToLiteralText } from '../../util/string-convert';
import { getFormValidationErrorMessage } from '../../store/global-types/form-errors';
import { defaultValidation, FormValidationState } from '../../components/form-validation-state';
import { DicomAttributeRule, ModelSelectionScope, ModelType } from '../../store/global-types/customization-types';
import { FormValidationError } from '../../store/global-types/store-errors';
import { RetainedDicomAttributes, RetainStatus } from '../../store/global-types/daemon-types';
import RegexField from '../../components/regex-field';
import ValidationHelperText from '../../components/validation-helper-text';



interface DicomAttributeRuleFormProps {
    modelType: ModelType,
    dicomAttributeRule: DicomAttributeRule,
    formValidationErrors: FormValidationError[],
    runningNumber: number,
    setUndoState: (undoState: ModelSelectionUndoState) => void,
    isUndoDisabled?: boolean,
    retainedDicomAttributes: RetainedDicomAttributes,
    onRemoveDicomRule: () => void,
    onDicomAttributeRuleRemoved: () => void,
    onDicomAttributeRuleUpdated: (attribute: string, value: string) => void,
}

const DicomAttributeRuleForm = (props: DicomAttributeRuleFormProps) => {
    const { dicomAttributeRule, formValidationErrors, runningNumber, setUndoState, isUndoDisabled,
        retainedDicomAttributes, onRemoveDicomRule, onDicomAttributeRuleRemoved, onDicomAttributeRuleUpdated } = props;

    const { t } = useTranslation();


    const inputRef = useRef<HTMLInputElement>(null);

    const [isActionListOpen, setActionListOpenState] = useState(false);
    const [isRemoveAttributeModalOpen, setRemoveAttributeModalOpenState] = useState(false);
    // const [isAttributeHovered, setAttributeHovered] = useState(false);
    const [isPresetToggleOpen, setIsPresetToggleOpen] = useState(false);
    const [nameFieldValidation, setNameFieldValidation] = useState<FormValidationState>(defaultValidation);

    /** Set non-blocking form validation errors generated in redux store reducers to be visible in this form's normal UI validation. */
    useEffect(() => {
        if (formValidationErrors.length > 0) {
            const allErrorsAsMessage = formValidationErrors.map(e => getFormValidationErrorMessage(e.errorType)).join(' ');
            setNameFieldValidation({ validated: 'error', helperTextInvalid: allErrorsAsMessage });
        } else {
            setNameFieldValidation(defaultValidation);
        }
    }, [formValidationErrors, dicomAttributeRule]);

    const handleRemoveAttributeButtonClick = useCallback(() => {
        setActionListOpenState(false);
        setRemoveAttributeModalOpenState(true);
    }, []);


    const handleRemoveAttributeConfirmation = useCallback(() => {
        setRemoveAttributeModalOpenState(false);
        onDicomAttributeRuleRemoved();
    }, [onDicomAttributeRuleRemoved]);


    const handleDicomAttributeNameChange = useCallback((name: string) => {
        onDicomAttributeRuleUpdated(name, dicomAttributeRule.value);
    }, [dicomAttributeRule, onDicomAttributeRuleUpdated]);

    const handleDicomAttributeValueChange = useCallback((regexValue: string) => {
        onDicomAttributeRuleUpdated(dicomAttributeRule.attribute, regexValue);
    }, [dicomAttributeRule]);

    const handleUndo = useCallback(() => {
        setUndoState({ isModalOpen: true, scope: ModelSelectionScope.DicomAttributeRule, id: dicomAttributeRule.id });
        setActionListOpenState(false);
    }, [dicomAttributeRule, setUndoState]);

    const handleSelectPreset = useCallback((dicomAttributeName: string, key?: string) => {
        handleDicomAttributeNameChange(dicomAttributeName);
        if (key === 'FreeTextEntry') {
            // Set focus on the input for 'free text entry'
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
        setIsPresetToggleOpen(false);
    }, [handleDicomAttributeNameChange, inputRef]);

    const getRetainedAttributeIcon = ((attribute: string): React.ReactNode | null => {

        let isRetainedInAllModels = false;
        let isRetainedInSomeModels = false;

        // check if the attribute name is present in the RetainedDicomAttributes object
        if (retainedDicomAttributes[attribute] !== undefined) {
            if (retainedDicomAttributes[attribute] === RetainStatus.Retained) {
                isRetainedInAllModels = true;
            } else if (retainedDicomAttributes[attribute] === RetainStatus.RetainedInSome) {
                isRetainedInSomeModels = true;
            }
        }

        if (isRetainedInAllModels) {
            return <Tooltip content={t("selectionPage.dicomAttributePresets.retainedInAll")}><CheckIcon className='dicom-attributes-helper-icons' /></Tooltip>;
        } else if (isRetainedInSomeModels) {
            return <Tooltip content={t("selectionPage.dicomAttributePresets.retainedInSome")}><ExclamationTriangleIcon className='dicom-attributes-helper-icons' /></Tooltip>;
        } else {
            return <Tooltip content={t("selectionPage.dicomAttributePresets.NotRetained")}><BanIcon className='dicom-attributes-helper-icons' /></Tooltip>;
        }
    });

    // don't render anything if we don't have a valid object
    if (dicomAttributeRule === undefined) {
        return null;
    }

    const dicomAttributePresets = [
        <DropdownItem key="PatientSex" className='dicom-attr-defaults' onClick={() => handleSelectPreset('PatientSex')} icon={getRetainedAttributeIcon('PatientSex')} title={''}>PatientSex</DropdownItem>,
        <DropdownItem key="ProtocolName" className='dicom-attr-defaults' onClick={() => handleSelectPreset('ProtocolName')} icon={getRetainedAttributeIcon('ProtocolName')} title={''}>ProtocolName</DropdownItem>,
        <DropdownItem key="SeriesDescription" className='dicom-attr-defaults' onClick={() => handleSelectPreset('SeriesDescription')} icon={getRetainedAttributeIcon('SeriesDescription')} title={''}>SeriesDescription</DropdownItem>,
        <DropdownItem key="BodyPartExamined" className='dicom-attr-defaults' onClick={() => handleSelectPreset('BodyPartExamined')} icon={getRetainedAttributeIcon('BodyPartExamined')}>BodyPartExamined</DropdownItem>,
        <DropdownItem key="ImageComments" className='dicom-attr-defaults' onClick={() => handleSelectPreset('ImageComments')} icon={getRetainedAttributeIcon('ImageComments')}>ImageComments</DropdownItem>,
        <DropdownItem key="Laterality" className='dicom-attr-defaults' onClick={() => handleSelectPreset('Laterality')} icon={getRetainedAttributeIcon('Laterality')}>Laterality</DropdownItem>,
        <DropdownItem key="ScanOptions" className='dicom-attr-defaults' onClick={() => handleSelectPreset('ScanOptions')} icon={getRetainedAttributeIcon('ScanOptions')}>ScanOptions</DropdownItem>,
        <DropdownItem key="StationName" className='dicom-attr-defaults' onClick={() => handleSelectPreset('StationName')} icon={getRetainedAttributeIcon('StationName')}>StationName</DropdownItem>,
        <DropdownItem key="StudyDescription" className='dicom-attr-defaults' onClick={() => handleSelectPreset('StudyDescription')} icon={getRetainedAttributeIcon('StudyDescription')}>StudyDescription</DropdownItem>,
        <DropdownItem key="FreeTextEntry" className='dicom-attr-defaults' onClick={() => handleSelectPreset('', "FreeTextEntry")}>{t("selectionPage.dicomAttributePresets.freeTextEntry")}</DropdownItem>,
    ];

    // TODO: this can be used to display which action menu item (attribute or entire rule) is being targeted by an action but
    // it will require some extra ui work to clean the hover style in weird cases (like pressing esc when the action menu is
    // open, or if a modal is opened). Similar approach can be used to style the entire rule.
    const hoveredClass = '';// isAttributeHovered ? 'attribute-hovered' : '';

    const formValidationState = dicomAttributeRule.isValid ? 'default' : 'error';

    const dropdownItems = [
        <DropdownItem key="removeAttribute" /*onMouseEnter={handleRemoveAttributeHover} onMouseLeave={handleRemoveAttributeHoverLeave}*/ onClick={handleRemoveAttributeButtonClick}>{t('selectionPage.removeDicomAttributeRule')}</DropdownItem>,
        <DropdownItem key="removeDicomRule" onClick={onRemoveDicomRule}>{t('selectionPage.removeDicomRule')}</DropdownItem>
    ];
    if (!isUndoDisabled) {
        dropdownItems.unshift(<DropdownItem key="undoDicomAttributeRuleChanges" isDisabled={!dicomAttributeRule.isModified || dicomAttributeRule.isNew}
            onClick={handleUndo}>{t('selectionPage.undoDicomAttributeRuleChanges')}</DropdownItem>);
    }

    return (
        <>
            <StackItem className={`rule-item dicom-rule-item ${hoveredClass}`}>
                <Split className="dicom-attribute-row" hasGutter>
                    <SplitItem className="dicom-attribute-column">
                        <FormGroup
                            label={t('selectionPage.dicomAttributeRuleNumber', { number: runningNumber })}
                            fieldId={`attribute-name-field-for-${dicomAttributeRule.id}`}
                            title="A DICOM attribute that is used in this rule."
                        >
                            <InputGroup>
                                <InputGroupItem>
                                    <DebouncedTextInput
                                        fieldId={`attribute-name-field-for-${dicomAttributeRule.id}`}
                                        onDebouncedChange={handleDicomAttributeNameChange}
                                        className="input-field dicom-input-field"
                                        inputRef={inputRef}
                                        defaultValue={dicomAttributeRule.attribute}
                                        validated={formValidationState}
                                        placeholder={"Type or select attribute..."}
                                    />
                                </InputGroupItem>
                                <InputGroupItem>
                                    <Dropdown
                                        isPlain
                                        isOpen={isPresetToggleOpen}
                                        shouldFocusFirstItemOnOpen={false}
                                        popperProps={{position:'right'}}
                                        title="Optional preset attribute names"
                                        toggle={(toggleRef: React.Ref<MenuToggleElement>) => <MenuToggle ref={toggleRef} onClick={() => setIsPresetToggleOpen(!isPresetToggleOpen)} variant='plain' className='menutoggle-plain-select'><CogIcon /></MenuToggle>}
                                        onOpenChange={() => setIsPresetToggleOpen(!isPresetToggleOpen)}
                                    ><DropdownGroup label={t('selectionPage.dicomAttributePresetsLabel')} className='dropdown-group-label'><Divider className='unselectable' />{dicomAttributePresets}</DropdownGroup></Dropdown>
                                </InputGroupItem>
                            </InputGroup>
                            {formValidationState !== 'default' && (
                                <ValidationHelperText
                                    validated={formValidationState}
                                    helperText={nameFieldValidation.helperTextInvalid}
                                />
                            )}
                        </FormGroup>
                    </SplitItem>
                    <SplitItem isFilled>
                        <RegexField
                            id={dicomAttributeRule.id}
                            label={t('selectionPage.requiredValue')}
                            onChange={handleDicomAttributeValueChange}
                            regexValue={dicomAttributeRule.value}
                            title="Value (or value range) used in this rule condition."
                        />
                    </SplitItem>
                    <SplitItem>
                        <Dropdown
                            toggle={(toggleRef: React.Ref<MenuToggleElement>) => <MenuToggle ref={toggleRef} onClick={() => setActionListOpenState(!isActionListOpen)} variant='plain'>
                                <EllipsisVIcon />
                            </MenuToggle>}
                            isPlain
                            shouldFocusFirstItemOnOpen={false}
                            popperProps={{position: 'right'}}
                            isOpen={isActionListOpen}
                            onOpenChange={() => setActionListOpenState(!isActionListOpen)}
                        >
                            {dropdownItems}</Dropdown>
                    </SplitItem>
                </Split>
                {/* <Divider className="dicom-rule-divider" /> */}
            </StackItem>
            <Modal
                variant={ModalVariant.small}
                title={t('selectionPage.removeDicomAttributeRule.confirm')}
                isOpen={isRemoveAttributeModalOpen}
                onClose={() => setRemoveAttributeModalOpenState(false)}
                actions={[
                    <Button key="confirmRemoval" variant="danger" onClick={handleRemoveAttributeConfirmation}>{t('selectionPage.removeDicomAttributeRule.short')}</Button>,
                    <Button key="cancel" variant="tertiary" onClick={() => setRemoveAttributeModalOpenState(false)}>{t('common.cancel')}</Button>
                ]}
            >
                Do you want to remove this DICOM attribute/value condition "{dicomAttributeRule.attribute}: {dicomAttributeRule.value}" from this model selection rule? Note that your changes won't be saved until you click on "Save".
            </Modal>
        </>
    );
}

export default DicomAttributeRuleForm;
