import { Button, MenuToggle, DropdownList, DropdownItem, Dropdown, MenuToggleElement } from '@patternfly/react-core';
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { supportedLanguages } from "../../i18n";
import { backendTierAppAuths } from "../../web-apis/cloud-backend-auth";

import './UserSettings.css';
import { logOutRequested } from "../../store/auth/authSlice";
import { appConfigSelectors } from "../../store/appConfig/appConfigSlice";
import TabbedSupportedModelTypes from '../../components/TabbedSupportedModelTypes/TabbedSupportedModelTypes';
import ConfigurationTargetBoxDetailsContour from "./ConfigurationTargetBoxDetailsContour";
import ConfigurationTargetBoxDetailsDose from "./ConfigurationTargetBoxDetailsDose";
import { ModelType } from '../../store/global-types/customization-types';

const UserSettings = () => {

    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();
    const onLogOutClick = useCallback(() => {
        dispatch(logOutRequested());
    }, [dispatch]);

    const [isLangDropdownOpen, setIsLangDropdownOpen] = React.useState(false);

    const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);

    const handleLangDropdownToggle = useCallback((isOpen: boolean) => {
        setIsLangDropdownOpen(isOpen);
    }, []);

    const onLangSelect = useCallback((nextLang: string) => {
        const langKey = supportedLanguages.find(l => l.label === nextLang)?.langKey;
        i18n.changeLanguage(langKey);
    }, [i18n]);

    const handleLangSelect = useCallback((value: any) => {
        setIsLangDropdownOpen(false);
        onLangSelect(value.target.innerText)
    }, [onLangSelect]);

    const loggedInUsernames = Object.values(backendTierAppAuths)
        .filter(appAuth => appAuth.isLoggedIn)
        .map(appAuth => ({ appName: appAuth.appName, user: appAuth.loggedInUser }));

    // show supported languages, but filter out languages set as omitted from UI in config.json
    const langItems = supportedLanguages.filter(l => !deploymentInfo || !deploymentInfo.omitLocalizationsFromUi || !deploymentInfo.omitLocalizationsFromUi.includes(l.langKey))
        .map(l => (<DropdownItem key={l.langKey} value={l.langKey} size={2}>{l.label}</DropdownItem>))
    const currentLanguage = supportedLanguages.find(l => l.langKey === i18n.resolvedLanguage)?.label;

    return (
        <div className="user-settings">
            {loggedInUsernames.length > 0 &&
                (
                    <div className="setting-item">
                        <span className="label">{t('settings.loggedInAs')}</span>
                        <span className="value" title={loggedInUsernames[0].user?.email}>
                            {loggedInUsernames.length > 1 ?
                                loggedInUsernames.map(appNameAndUser => (<div key={appNameAndUser.appName} title={appNameAndUser.user?.email}>{appNameAndUser.user?.fullName} ({appNameAndUser.appName})</div>))
                                : loggedInUsernames[0].user?.fullName}
                        </span>
                        <span className="log-out-button"><Button variant="danger" onClick={onLogOutClick}>{t('common.logOut')}</Button></span>
                    </div>
                )
            }

            <div className="setting-item">
                <span className="label">{t('settings.language')}</span>
                <span className="value">
                    <Dropdown
                        toggle={(toggleRef: React.Ref<MenuToggleElement>) => (
                            <MenuToggle ref={toggleRef} onClick={() => handleLangDropdownToggle(!isLangDropdownOpen)} isExpanded={isLangDropdownOpen}>
                                {currentLanguage}
                            </MenuToggle>)
                        }
                        isOpen={isLangDropdownOpen}
                        onSelect={handleLangSelect}
                        shouldFocusFirstItemOnOpen={false}
                        onOpenChange={() => handleLangDropdownToggle(!isLangDropdownOpen)}>
                            
                            <DropdownList>{langItems}</DropdownList>
                    </Dropdown>
                </span>
            </div>

            <TabbedSupportedModelTypes
                hasNoBorderBottom
                content={[
                    { modelType: ModelType.Contouring, content: <ConfigurationTargetBoxDetailsContour /> },
                    { modelType: ModelType.Dose, content: <ConfigurationTargetBoxDetailsDose /> }
                ]}
            />

        </div >
    );
}

export default UserSettings;
