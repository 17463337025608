import { call, delay, fork, put, take } from "typed-redux-saga";
import { get } from "lodash-es";
import { daemonConfigDeleted, daemonConfigRemove, daemonConfigReset, daemonConfigSaved, daemonConfigSet, daemonConfigsFetched, daemonConfigsSet, deleteDaemonConfigFinished, deleteDaemonConfigStarted, resetDaemonConfigFinished, resetDaemonConfigStarted, saveDaemonConfigFinished, saveDaemonConfigStarted } from "./daemonSlice";
import { MVisionAppClient } from "../configurationTarget/mvision-client-list";
import { DaemonConfig } from "./daemon-config";
import { isBackendValidationError } from "../../util/errors";
import { callApi } from "../sagas";


export function* fetchDaemonConfigsSaga(appClient: MVisionAppClient | undefined) {
    yield* callApi({
        doApiCall: function* (client) {
            const daemonConfigs = appClient === undefined ? null : yield* call(async () => client.fetchDaemonConfigsAsync(appClient));
            return daemonConfigs;
        },
        onSuccess: function* (result) {
            yield* put(daemonConfigsSet({ daemonConfigs: result }));
        },
        onFailure: function* (error) {
            console.error('Was not able to fetch daemon configuration');
            const errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
            yield* put(daemonConfigsSet({ daemonConfigs: null, error: errorMessage }));
        }
    });
}

/** Fetches a single daemon configuration, not all of them. Generally you'll want to use **fetchDaemonConfigsSaga** to get all of them instead of just one. */
function* fetchSingleDaemonConfigSaga(appClient: MVisionAppClient | undefined, daemonClientId: string) {
    return yield* callApi({
        doApiCall: function* (client) {
            const daemonConfig = appClient === undefined ? null : yield* call(async () => client.fetchDaemonConfigAsync(appClient, daemonClientId));
            return daemonConfig;
        },
        onSuccess: function* (result) {
            yield* put(daemonConfigSet({ daemonConfig: result }));
        },
        onFailure: function* (error) {
            yield* put(daemonConfigSet({ daemonConfig: null, error: error.message || 'Unspecified error.' }));
        }
    });
}

function* saveDaemonConfigSaga(appClient: MVisionAppClient | undefined, daemonConfig: DaemonConfig) {
    yield* callApi({
        doApiCall: function* (client) {
            yield* put(saveDaemonConfigStarted({ appClient, daemonConfig }));
            return yield* call(async () => client.saveDaemonConfigAsync(appClient, daemonConfig));
        },
        onFinish: function* (result, error) {
            const success = result || false;
            let errorMessage: string | undefined = undefined;

            if (error !== null) {
                console.error('Was not able to save daemon configuration');
                errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
                console.error(errorMessage);
            } else if (!success) {
                errorMessage = 'Unable to save daemon configuration. There might be a problem with the server.';
            }

            yield* put(saveDaemonConfigFinished({
                appClient,
                daemonConfig,
                saveWasSuccessful: success,
                errorMessage: errorMessage || undefined,
                error: isBackendValidationError(error) ? error : undefined
            }));

            // store internal version of saved daemon config into redux collection if save succeeded
            if (success) {
                yield* put(daemonConfigSet({ daemonConfig, updateOnly: true }));
            }

            return success;
        }
    });
}

function* resetDaemonConfigSaga(appClient: MVisionAppClient | undefined, daemonConfig: DaemonConfig) {
    yield* callApi({
        doApiCall: function* (client) {
            yield* put(resetDaemonConfigStarted({ appClient, daemonConfig }));
            let success =  yield* call(async () => client.resetDaemonConfigAsync(appClient, daemonConfig));
            if (success) {
                // wait a bit, then fetch updated (reset) daemon config
                yield* delay(2000);  // 2 seconds
                success = yield* call(fetchSingleDaemonConfigSaga, appClient, daemonConfig.sessionId);
            }
            
            return success;
        },
        onFinish: function* (result, error) {
            const success = result || false;
            let errorMessage: string | undefined = undefined;

            if (error !== null) {
                console.error('Was not able to reset daemon configuration');
                errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
                console.error(errorMessage);
            } else if (!success) {
                errorMessage = 'Unable to reset daemon configuration. There might be a problem with the server.';
            }

            yield* put(resetDaemonConfigFinished({
                appClient,
                daemonConfig,
                saveWasSuccessful: success,
                errorMessage: errorMessage || undefined,
                error: isBackendValidationError(error) ? error : undefined
            }));

            return success;
        }
    });
}

function* deleteDaemonConfigSaga(appClient: MVisionAppClient | undefined, daemonConfig: DaemonConfig) {
    yield* callApi({
        doApiCall: function* (client) {
            yield* put(deleteDaemonConfigStarted({ appClient, daemonConfig }));
            return yield* call(async () => client.deleteDaemonConfigAsync(appClient, daemonConfig));
        },
        onFinish: function* (result, error) {
            const success = result || false;
            let errorMessage: string | undefined = undefined;

            if (success) {
                // remove the just-removed entry from store
                yield* put(daemonConfigRemove(daemonConfig.sessionId));
            }

            if (error !== null) {
                console.error('Was not able to remove daemon configuration');
                errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
                console.error(errorMessage);
            } else if (!success) {
                errorMessage = 'Unable to remove daemon configuration. There might be a problem with the server.';
            }

            yield* put(deleteDaemonConfigFinished({
                appClient,
                daemonConfig,
                saveWasSuccessful: success,
                errorMessage: errorMessage || undefined,
                error: isBackendValidationError(error) ? error : undefined
            }));

            return success;
        }
    });
}



function* watchFetchDaemonConfigsSaga() {
    while (true) {
        const action = yield* take(daemonConfigsFetched);
        yield* fork(fetchDaemonConfigsSaga, action.payload);
    }
}

function* watchSaveDaemonConfigSaga() {
    while (true) {
        const action = yield* take(daemonConfigSaved);
        yield* fork(saveDaemonConfigSaga, action.payload.appClient, action.payload.daemonConfig);
    }
}

function* watchResetDaemonConfigSaga() {
    while (true) {
        const action = yield* take(daemonConfigReset);
        yield* fork(resetDaemonConfigSaga, action.payload.appClient, action.payload.daemonConfig);
    }
}

function* watchDeleteDaemonConfigSaga() {
    while (true) {
        const action = yield* take(daemonConfigDeleted);
        yield* fork(deleteDaemonConfigSaga, action.payload.appClient, action.payload.daemonConfig);
    }
}

/** Returns all relevant watches to be added to a main root watch saga */
export function getWatchesForDaemonSagas() {
    return [
        watchFetchDaemonConfigsSaga(),
        watchSaveDaemonConfigSaga(),
        watchResetDaemonConfigSaga(),
        watchDeleteDaemonConfigSaga(),
    ];
}
