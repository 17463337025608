import { isObject, has, get, isArray } from "lodash-es";
import ExpectedProp from "../../util/expected-prop";
import { ModelType } from "../global-types/customization-types";
import { LabelJsonWrapper } from "../../web-apis/backend-api-interface";

export type Labeling = {
    /** The model type that this labeling is for */
    modelType: ModelType,

    udi: string | null,
    manufacturer: string,
    manufacturerModelName: string,
    systemVersion: string,
    description: string,
    labelingObject: any,
    labelingAsString: string,
}

export type CombinedLabeling = {
    labels: Labeling[],
    backendVersionAsString: string | undefined,
}

export const createNewLabeling = (
    modelType: ModelType,
    udi: string | null,
    manufacturer: string,
    manufacturerModelName: string,
    systemVersion: string,
    description: string,
    labelingObject: any,
): Labeling => {
    return {
        modelType: modelType,
        udi: udi,
        manufacturer: manufacturer,
        manufacturerModelName: manufacturerModelName,
        systemVersion: systemVersion,
        description: description,
        labelingObject: labelingObject,
        labelingAsString: Object.keys(labelingObject).map((key) => `${key}: ${labelingObject[key]}`).join('\n'),
    };
}


/** Parses backend version JSON object into a user-presentable(-ish) string */
export const parseBackendVersion = (backendVersionJson: any): string | undefined => {
    if (!backendVersionJson) {
        return undefined;
    }

    const labels: string[] = [];

    for (const key of Object.keys(backendVersionJson)) {
        const value = backendVersionJson[key];
        if (isArray(value) && key.includes('_workers')) {
            // parse this as an array of worker version objects
            const workers: string[] = [];

            for (const worker of value) {
                // build_date and git_hash are the only supported worker version fields as of now
                const workerInfo: string[] = [];
                for (const supportedJsonField of ['build_date', 'git_hash']) {
                    if (!!get(worker, supportedJsonField, undefined)) { workerInfo.push(`${supportedJsonField}: ${worker[supportedJsonField]}`); }
                }
                workers.push(workerInfo.join(', '));
            }
            if (workers.length) {
                labels.push(`${key}: [{ ${workers.join(' }, { ')} }]`);
            } else {
                labels.push(`${key}: N/A`);
            }

        } else {
            // show any non-array field
            labels.push(`${key}: ${value}`);
        }
    }

    return labels.join('\n');
}


const expectedLabelingProps = [
    new ExpectedProp('manufacturer', 'manufacturer', typeof String),
    new ExpectedProp('manufacturer_model_name', 'manufacturerModelName', typeof String),
    new ExpectedProp('system_version', 'systemVersion', typeof String),
    new ExpectedProp('description', 'description', typeof String),
];

export const convertJsonObjectToLabeling = (labelingJsonObjects: LabelJsonWrapper[], backendVersion?: any): CombinedLabeling => {
    if (labelingJsonObjects.length === 0 || !labelingJsonObjects.every(j => isObject(j.labeling))) {
        throw new Error('Invalid JSON object provided for Labeling conversion.');
    }

    const labels: Labeling[] = [];

    for (const labelingJsonObject of labelingJsonObjects) {

        const json = labelingJsonObject.labeling;

        const notFoundProps: ExpectedProp[] = [];
        for (const expectedProp of expectedLabelingProps) {
            if (!has(json, expectedProp.jsonProp)) {
                notFoundProps.push(expectedProp);
            }
        }

        if (notFoundProps.length > 0) {
            const msg = `Expected to find following props from Labeling JSON object, but didn't: ${notFoundProps.map(p => p.jsonProp).join(', ')}.`;
            console.error(msg + ' JSON:');
            console.error(json);
            throw new Error(msg);
        }

        labels.push(createNewLabeling(
            labelingJsonObject.modelType,
            get(json, 'udi', null),
            get(json, `${expectedLabelingProps.find(e => e.modelProp === 'manufacturer')!.jsonProp}`) as string,
            get(json, `${expectedLabelingProps.find(e => e.modelProp === 'manufacturerModelName')!.jsonProp}`) as string,
            get(json, `${expectedLabelingProps.find(e => e.modelProp === 'systemVersion')!.jsonProp}`) as string,
            get(json, `${expectedLabelingProps.find(e => e.modelProp === 'description')!.jsonProp}`) as string,
            json,
        ));
    }

    return { labels, backendVersionAsString: parseBackendVersion(backendVersion) };
}
