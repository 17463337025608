import { call, all, fork, take, SagaGenerator } from 'typed-redux-saga';
import { Dispatch } from '@reduxjs/toolkit';
import { getWatchesForAppVersionSagas } from './appVersion/appVersionSagas';
import { getWatchesForAppDeploymentInfoSagas } from './appConfig/appConfigSagas';
import { initializeAppSaga } from './appStatus/appStatusSagas';
import { checkIfInteractionIsRequired, getWatchesForAuthSagas } from './auth/authSagas';
import { appInitializationStarted } from './appStatus/appStatusSlice';
import { getWatchesForConfigurationTargetSagas } from './configurationTarget/configurationTargetSagas';
import { getWatchesForContouringConfigSagas } from './contouring/contouringSagas';
import { getWatchesForTextEditorSagas } from './textEditor/textEditorSagas';
import { getWatchesForLicenseSagas } from './license/licenseSagas';
import { getWatchesForDaemonSagas } from './daemon/daemonSagas';
import { getWatchesForUserSagas } from './user/userSagas';
import { getWatchesForDoseConfigSagas } from './dose/doseSagas';
import ConfigurationClient from '../web-apis/configuration-client';
import { BackendValidationError, isBackendValidationError, isInstanceOfError } from '../util/errors';


/**
 * Collect all rtk slice watch functions in one place.
 */
function* startWatchesSaga() {
    yield* all([
        ...getWatchesForAppDeploymentInfoSagas(),
        ...getWatchesForAppVersionSagas(),
        ...getWatchesForAuthSagas(),
        ...getWatchesForConfigurationTargetSagas(),
        ...getWatchesForContouringConfigSagas(),
        ...getWatchesForDoseConfigSagas(),
        ...getWatchesForDaemonSagas(),
        ...getWatchesForLicenseSagas(),
        ...getWatchesForTextEditorSagas(),
        ...getWatchesForUserSagas(),
    ]);
}

/** Initialize app and launch all watch sagas. Dispatch is needed to initialize certain backend client configurations. */
export default function* rootSaga(dispatch: Dispatch) {
    // wait for permission to start initializing app (and logging in the user)
    yield* take(appInitializationStarted);

    yield* fork(startWatchesSaga);
    yield* call(initializeAppSaga, dispatch);
}

/** Wraps an API return value for the apiCall helper so we can
 * differentiate between e.g. intentional 'undefined' values returned
 * from the API and the API call failing. This should not be needed
 * outside of the callApi wrapper. */
class ApiReturnValue<T> {
    /** Return value from the API. */
    value: T;

    /** Constructs a new API return value and assigns it. */
    constructor(value: T) {
        this.value = value;
    }
}

/** Helper function for wrapping an API call within a framework for handling most usual error cases,
 * including handling expired MSAL refresh tokens (i.e. an auth case where user interaction is 
 * required to refresh current auth session).
 * 
 * @typeParam T - Return type(s) from the API call.
 * 
 * Usage: define doApiCall, onSuccess and onFailure as generator/redux-saga functions. Alternatively an
 * onFinish function can be defined to replace both onSuccess and onFailure. These functions
 * can (and should) use redux saga functions such as put and call.
 * 
 * The doApiCall function should perform the actual API call with the {@link ConfigurationClient | client: ConfigurationClient}
 * object received as an argument. The function should also return the result. In an error case
 * the function should throw an error or a BackendValidationError object -- this is the only way
 * to change the run flow to the onFailure path.
 * 
 * Note that the internally used {@link ConfigurationClient | ConfigurationClient} generally already 
 * handles throwing {@link BackendValidationError} objects as needed if the backend sends back 
 * validation errors.
 * 
 * @example doApiCall example:
 * ```
 * const apiCallResult = yield* callApi(
 *   {
 *     doApiCall: function* (client: ConfigurationClient) {
 *       let contouringCustomizations: ContouringCustomizationEntities | null = null;
 *       contouringCustomizations = yield* call(async () => client.fetchContouringCustomizationsAsync(appClient));
 *       if (contouringCustomizations === undefined) {
 *         throw new Error('Something went wrong when fetching contouring customizations');
 *       }
 *       return contouringCustomizations;
 *    }
 * });
 * ```
 * 
 * 
 * The callApi function returns a boolean -- true if the entire call was a success,
 * false if the call failed for any reason. The actual API value is NOT returned by
 * the callApi function -- any side effects (such as calling redux actions) should
 * be handled in the onSuccess/onFailure/onFinish callback functions.
 * 
 * The onSuccess function is called with the doApiCall result. onSuccess returns true
 * after it has finished executing.
 * 
 * The onFailure function is called with an error if one was thrown during the
 * doApiCall execution. False is returned after onFailure has finished executing.
 * 
 * @example onSuccess and onFailure example:
 * ```
 * yield* callApi({
 *      doApiCall: function* (client) {
 *          // call the API to fetch access rights
 *          const config = yield* select(appConfigSelectors.selectAppDeploymentInfo);
 *          const accessRights = yield* call(() => client.fetchAndParseAccessRights());
 *          return accessRights;
 *      },
 *      onSuccess: function* (result) {
 *          // put the returned access rights (the result object) into store
 *          yield* put(accessRightsSet({ accessRights: result }));
 *      },
 *      onFailure: function* (error) {
 *          // get error message from the error
 *          const errorMessage = error.message;
 *          yield* put(accessRightsSet({ accessRights: getInitialUserAccessRights(), errorMessage: `An error occurred when trying to retrieve access rights: ${errorMessage}` }));
 *      }
 *   });
 * ```
 * 
 * 
 * The onFinish function, if defined, will be called whether the doApiCall function
 * succeeds or fails. It will be given either the result from the callApi function
 * if it ran to the end successfully or undefined if not, and an error or null, 
 * depending on how the doApiCall execution went. The onFinish function must handle 
 * deducing the correct state from these input arguments itself and act accordingly.
 * 
 * @example onFinish example:
 * ```
 * // get configuration target from redux action payload and call backend API
 * const configurationTarget = action.payload;
 * yield* callApi({
 *      doApiCall: function* (client: ConfigurationClient) {
 *          // collect model customization
 *          const modelCustomization: ContouringCustomizationEntitiesForExport = yield* collectContouringCustomizationForExport();
 *
 *          // start the save operation
 *          yield* put(saveModelCustomizationStarted());
 * 
 *          // client.saveContouringCustomizationAsync returns the success or failure of this operation as a boolean
 *          const success = yield* call(async () => client.saveContouringCustomizationAsync(configurationTarget, modelCustomization));
 * 
 *          return success;
 *      },
 *      onFinish: function* (result: boolean | undefined, error: Error | BackendValidationError | null) {
 *          let errorMessage = null;
 * 
 *          // the result object will be undefined if the API call threw an error so make 
 *          // "success" default to false if so
 *          const success = result || false;  
 *
 *          // "error" will be null if the API call succeeded (i.e. did not throw), otherwise handle
 *          // the error case here
 *          if (error !== null) {
 *              console.error('Was not able to save model customization');
 *              errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
 *              console.error(errorMessage);
 *          } else if (!success) {
 *              // our API might also return a "failed" value even if nothing in the API call itself failed
 *              // (i.e. nothing threw an error) so handle that case
 *              errorMessage = 'Unable to save model customization. There might be a problem with the server.';
 *          }
 *
 *          yield* put(saveModelCustomizationFinished({
 *              saveWasSuccessful: success,
 *              errorMessage: errorMessage || undefined,
 *              error: isBackendValidationError(error) ? error : undefined
 *          }));
 *
 *          // onFinish must always return a boolean -- onSuccess and onFailure automate that process
 *          return success;
 *      }
 *  });
 * ```
 * 
 * If the API call fails because MSAL authentication requires user interaction
 * then authSlice is flagged and set accordingly and the onFailure function will
 * be run (or onFinish with an error).
 * 
 * NOTE 1: it is recommended to write your doApiCall function in such a
 * resilient way that you don't perform any mutations to application state
 * in it that would get the application into a bad state if the API call 
 * fails for any reason.
 * 
 * NOTE 2: all the arguments for callApi are generator functions. They need to
 * be specified with the " function* " syntax and without arrow functions in the 
 * following way for them to work correctly:
 * 
 * @example
 * ```
 * const result = yield* callApi({
 *      doApiCall: function* (client) { ... },
 *      onSuccess: function* (result) { ... },
 *      onFailure: function* (error) { ... }
 *      < altenatively: onFinish: function* (result, error) { ... } >
 * });
 * ```
 * 
 * Specifying argument types in these function definitions is not necessary, 
 * VSCode/Typescript can detect the correct types for client/result/error automatically.
 * 
 * Note that callApi itself must also be called with yield*.
 *    
 */
export function* callApi<T>(
    /** A settings object for the callApi wrapper. Either both onSuccess and onFailure generator functions must be defined, 
     * OR the onFinish generator function must be defined, not both.
     * 
     * Functions must be defined as generator functions, e.g.:
     * @example
     *   callApi({
     *     doApiCall: function* (client) { ... },
     *     onSuccess: function* (result) { ... },
     *     onFailure: function* (error) { ... }
     *   });
     */
    settings: {
        /** A generator/saga function for calling a backend API with the supplied {@link ConfigurationClient} client. 
         * This function should return object(s) of type T from the backend API on success, or throw an error or
         * {@link BackendValidationError} on a failure. */
        doApiCall: (client: ConfigurationClient) => SagaGenerator<T>,
        /** A generator/saga callback function for when the backend API call is successful. Backend result object(s)
         * of type T are given as an argument. Either this function and the onFailure function OR the onFinish function
         * must be defined. */
        onSuccess?: (result: T) => SagaGenerator<void>,
        /** A generator/saga callback function for when the backend API call fails. The catched error or 
         * {@link BackendValidationError} is given as an argument. Either this function and the onSuccess function 
         * OR the onFinish function must be defined. */
        onFailure?: (error: Error | BackendValidationError) => SagaGenerator<void>,
        /** A generator/saga callback function for handling both success and failure cases. Result object(s) of type
         * T are given as an argument if the doApiCall function succeeds, and error is set to null. If the doApiCall
         * function fails then result is set to undefined and error is set to the catched error or {@link BackendValidationError}.
         * Either this function OR both the onSuccess and onFailure functions must be defined.
         */
        onFinish?: (result: T | undefined, error: Error | BackendValidationError | null) => SagaGenerator<boolean>,
    }
) {
    const { doApiCall, onSuccess, onFailure, onFinish } = settings;

    if (onFinish && (onSuccess || onFailure)) {
        throw new Error('callApi must be called with either onSuccess and onFailure functions, or an onFinish function, but not both!');
    }
    if (!onFinish && (!onSuccess || !onFailure)) {
        throw new Error('callApi must be called with either onSuccess and onFailure functions, or an onFinish function!');
    }

    const client = new ConfigurationClient();
    let error = null;
    let apiReturnValue: ApiReturnValue<T> | undefined = undefined;

    try {
        const result = yield* call(doApiCall, client);
        apiReturnValue = new ApiReturnValue(result);
    }
    catch (ex) {
        console.error(ex);
        error = ex;

        // special case for checking if auth session had expired
        // (this will mark the redux authSlice appropriately if so)
        yield* call(checkIfInteractionIsRequired, error);
    }

    if (onSuccess && onFailure) {
        if (error !== null && (isInstanceOfError(error) || isBackendValidationError(error))) {
            yield* call(onFailure, error);
            return false;
        } else if (apiReturnValue === undefined) {
            yield* call(onFailure, new Error('API call failed'));
            return false;
        } else {
            yield* call(onSuccess, apiReturnValue.value);
            return true;
        }
    } else if (onFinish !== undefined) {
        let finishError = isInstanceOfError(error) || isBackendValidationError(error) ? error : null;
        const finishResult = yield* call(onFinish, apiReturnValue?.value, finishError);
        return finishResult;
    }

    // normal program flow should never get here but "false" is returned here to make
    // eslint happy
    return false;
}
