
// Keys for window.localStorage. Stores data locally with no expiration date

const LatestActiveMsalAccount = "MVCONFIGUI_LATEST_ACTIVE_MSAL_ACCOUNT";
const HasAcknowledgedRegexServiceDisclaimer = 'MVCONFIGUI_REGEX_SERVICE_DISCLAIMER';

export function setLatestActiveMsalAccount(accountId: string | undefined) {
    if (accountId) {
        localStorage.setItem(LatestActiveMsalAccount, accountId);
    } else {
        localStorage.removeItem(LatestActiveMsalAccount);
    }
}

export function getLatestActiveMsalAccount(): string | undefined {
    const value = localStorage.getItem(LatestActiveMsalAccount) || undefined;
    return value;
}

export function getHasAcknowledgedRegexServiceDisclaimer() {
    return window.localStorage.getItem(HasAcknowledgedRegexServiceDisclaimer);
}

export function setHasAcknowledgedRegexServiceDisclaimer(signed: boolean) {
    window.localStorage.setItem(
        HasAcknowledgedRegexServiceDisclaimer,
        JSON.stringify({ signed: signed })
    );
}
