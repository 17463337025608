import { helpFileLocations } from "../environments";
import { HelpDocument } from "../i18n";
import { DEPLOYMENT_CONFIGURATION_FILE_NAME, DeploymentConfigInfo, generateDeploymentConfigInfo } from "../store/appConfig/appDeploymentInfoTypes";
import { APP_VERSION_FILE_NAME, AppVersionInfo, AppVersionInfoSchema } from "../store/appVersion/appVersionTypes";

/** Trying to download a JSON file but getting a HTML file back that starts with this means the request failed. */
export const HTML_404_PAGE_HEADER = '<!DOCTYPE html>';

/** Try two different ways of fetching files hosted within this deployment
 * (the latter is required in cockpit mode but we don't know if we're in cockpit
 * mode until after one of these downloads finishes successfully!)
 */
const getResponse = async (url: string): Promise<Response> => {
    let response: Response;

    try {
        response = await fetch(url, { cache: 'no-store' });
        if (response.status === 200) {
            return response;
        }
    } catch (err) {
        console.log(`An error occurred while trying to fetch '${url}':`);
        console.log(err);
    }

    const secondAttemptUrl = './' + url;
    try {
        response = await fetch(secondAttemptUrl, { cache: 'no-store' });
        if (response.status === 200) {
            return response;
        }
    } catch (err) {
        console.log(`An error occurred while trying to fetch '${url}':`);
        console.log(err);
    }

    throw new Error(`Could not fetch ${url}`);
}

/** Client for retrieving configuration associated with this deployment of MVision Configuration. */
export class WebConfigurationClient {

    public static async getAppVersionInfo(): Promise<AppVersionInfo> {
        const url = APP_VERSION_FILE_NAME;

        return new Promise(async (resolve, reject) => {
            const response = await getResponse(url);
            if (response.status === 200) {
                const versionJson = await response.json();
                const parsedVersionInfo = AppVersionInfoSchema.safeParse(versionJson);
                if (parsedVersionInfo.success) {
                    resolve(parsedVersionInfo.data);
                } else {
                    const errorMsg = `Received version.json has invalid properties: ${parsedVersionInfo.error}`;
                    console.log(errorMsg);
                    console.log(versionJson);
                    reject(errorMsg);
                }
            }
            else {
                const error = "Could not get app version file, HTTP error status: " + response.status;
                console.log(error);
                reject(error);
            }
        });
    }

    /** Gets deployment info from deployment's config.json file. */
    public static async getDeploymentConfigInfo(): Promise<DeploymentConfigInfo> {
        const url = DEPLOYMENT_CONFIGURATION_FILE_NAME;

        return new Promise(async (resolve, reject) => {
            const response = await getResponse(url);
            if (response.status === 200) {
                try {
                    const configJson = await response.json();
                    const configInfo = generateDeploymentConfigInfo(configJson);
                    if (configInfo !== undefined) {
                        resolve(configInfo);
                    } else {
                        const error = "Received app config file has invalid properties";
                        console.log(error);
                        console.log(configJson);
                        reject(error);
                    }
                }
                catch (err) {
                    console.log('An error occurred when trying to get app config file:')
                    console.error(err);
                    reject(err);
                }
            }
            else {
                const error = "Could not get app config file, HTTP error status: " + response.status;
                console.log(error);
                reject(error);
            }
        });
    }

    /** Loads the help markdown file as a string. */
    public static async getHelpDocuments(): Promise<HelpDocument[]> {

        // load all help files in one go
        // TODO: this may be a waste of time and effort if we end up having many languages and actually meaningful help
        // files (i.e. large ones) but for now it's fine
        const helpFiles: HelpDocument[] = [];
        for (const helpFileLocation of helpFileLocations) {

            const response = await getResponse(helpFileLocation.url);
            if (response.status === 200) {
                try {
                    const helpDocument = await response.text();
                    checkIfRequestReturned404(helpDocument);
                    helpFiles.push({ langKey: helpFileLocation.langKey, helpDocument });
                }
                catch (err) {
                    // log files that couldn't be loaded for a lang key but proceed loading whatever is found
                    console.log(`An error occurred when trying to get the help document file ${helpFileLocation.url} for ${helpFileLocation.langKey}`);
                    console.error(err);
                }
            }
            else {
                const error = "Could not get the help document, HTTP error status: " + response.status;
                console.log(error);
                throw new Error(error);
            }
        }

        return helpFiles;
    }
}

function checkIfRequestReturned404(result: string) {
    if (result.indexOf(HTML_404_PAGE_HEADER) === 0) {
        throw new Error('File not found.');
    }
}

