import { createEntityAdapter, createSelector, createSlice, current, EntityState, PayloadAction, Update } from "@reduxjs/toolkit";
import { ContouringCustomizationOutput, ContouringRoi, GlobalContouringRoi, ContouringCustomizationEntities, CodingScheme, PhysicalProperty, generateRandomColor, DEFAULT_INTERPRETED_TYPE, ContouringCustomizationOutputEntities } from "./contouring-types";
import { Model, CustomizationBase, OutputMetadataItem, AeTitleRule, DicomRule, DicomAttributeRule, ModelVisibility, ModelSelectionScope, METADATA_FILENAME_ATTRIBUTE, CustomizationObjectType } from "../global-types/customization-types";
import { createNewContouringRoi, getDefaultCodingScheme, getCodingScheme, roiCustomizationMatchesGlobalRoi } from "./contouring-helpers";
import { BackendValidationErrorViewModel, FormValidationError } from "../global-types/store-errors";
import { updateRoiCustomizationField, getGlobalRoiItemsInAlphabeticalOrder, calculateGlobalRoiChangesForRegularRoi, ensureGlobalRoiItemsAreInAlphabeticalOrder } from "./contouringReducerHelpers";
import { get, has, isArray } from "lodash-es";
import { MVisionAppClient } from "../configurationTarget/mvision-client-list";
import { naturalSort } from "../../util/sort";
import { BackendValidationError } from "../../util/errors";
import { createNewMetadataItem } from "../global-types/customization-helpers";
import { convertBackendValidationErrorToViewModels, DuplicatedIdMap, duplicateFormValidationErrors, getFormValidationErrorIdsForItem, getFormValidationErrorIdsForItems, setObjectAndAncestorsAsModified } from "../global-types/reducer-helpers";
import { ContourSliceWrapper } from "../global-types/modelTypeReducerHelper/contourSliceWrapper";



// entity adapters (see https://redux-toolkit.js.org/api/createEntityAdapter) for contouring customization types
export const modelAdapter = createEntityAdapter<Model>();
export const customizationBaseAdapter = createEntityAdapter<CustomizationBase>();
export const customizationOutputAdapter = createEntityAdapter<ContouringCustomizationOutput>();
export const outputMetadataAdapter = createEntityAdapter<OutputMetadataItem>();
export const roiCustomizationAdapter = createEntityAdapter<ContouringRoi>();
export const globalRoiCustomizationAdapter = createEntityAdapter<GlobalContouringRoi>();
export const aeTitleRuleAdapter = createEntityAdapter<AeTitleRule>();
export const dicomRuleAdapter = createEntityAdapter<DicomRule>();
export const dicomAttributeRuleAdapter = createEntityAdapter<DicomAttributeRule>();

// entity adapter selectors -- these should not be exported/used directly but rather through the slice selectors
export const modelAdapterSelectors = modelAdapter.getSelectors((state: ContouringSliceState) => state.models);
export const customizationBaseAdapterSelectors = customizationBaseAdapter.getSelectors((state: ContouringSliceState) => state.customizationBases);
export const customizationOutputAdapterSelectors = customizationOutputAdapter.getSelectors((state: ContouringSliceState) => state.contourOutputs);
export const outputMetadataAdapterSelectors = outputMetadataAdapter.getSelectors((state: ContouringSliceState) => state.outputMetadata);
export const roiCustomizationAdapterSelectors = roiCustomizationAdapter.getSelectors((state: ContouringSliceState) => state.roiCustomizations);
export const globalRoiCustomizationAdapterSelectors = globalRoiCustomizationAdapter.getSelectors((state: ContouringSliceState) => state.globalRoiCustomizations);
export const aeTitleRuleAdapterSelectors = aeTitleRuleAdapter.getSelectors((state: ContouringSliceState) => state.aeTitleRules);
export const dicomRuleAdapterSelectors = dicomRuleAdapter.getSelectors((state: ContouringSliceState) => state.dicomRules);
export const dicomAttributeRuleAdapterSelectors = dicomAttributeRuleAdapter.getSelectors((state: ContouringSliceState) => state.dicomAttributeRules);

/** An entity adapter for CRUD operations for backend-based customization validation errors */
export const backendValidationErrorAdapter = createEntityAdapter<BackendValidationErrorViewModel>();
/** An entity adapter for CRUD operations for UI-supplied customization validation errors */
export const formValidationErrorAdapter = createEntityAdapter<FormValidationError>();

// selectors for 'original' data
export const originalOutputMetadataAdapterSelectors = outputMetadataAdapter.getSelectors((state: ContouringSliceState) => state.originalModelCustomizationsMetadata);
export const originalRoiCustomizationAdapterSelectors = roiCustomizationAdapter.getSelectors((state: ContouringSliceState) => state.originalRoiCustomizations);

// validation error selectors
export const customizationValidationErrorAdapterSelectors = backendValidationErrorAdapter.getSelectors((state: ContouringSliceState) => state.backendValidationErrors);
export const formValidationErrorAdapterSelectors = formValidationErrorAdapter.getSelectors((state: ContouringSliceState) => state.formValidationErrors);

export const isContouringSliceState = (obj: any): obj is ContouringSliceState => {
    return has(obj, 'models') &&
        has(obj, 'contourOutputs') &&
        has(obj, 'roiCustomizations') &&
        has(obj, 'globalRoiCustomizations') &&
        has(obj, 'roiCustomizations.entities') &&
        isArray(obj.roiCustomizations.entities);
}

export type ContouringSliceState = {
    /** In-memory CRUD-based normalized data structure for customization objects modelling contouring models. */
    models: EntityState<Model, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling customization base objects. */
    customizationBases: EntityState<CustomizationBase, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling customization outputs. */
    contourOutputs: EntityState<ContouringCustomizationOutput, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling customization metadata. */
    outputMetadata: EntityState<OutputMetadataItem, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling structure (ROI) customizations. */
    roiCustomizations: EntityState<ContouringRoi, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling distinct groups of ROI customizations. */
    globalRoiCustomizations: EntityState<GlobalContouringRoi, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling AE Title-based model selection rules. */
    aeTitleRules: EntityState<AeTitleRule, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling DICOM-based model selection rules. */
    dicomRules: EntityState<DicomRule, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling DICOM attribute rules within DICOM-based model selection rules. */
    dicomAttributeRules: EntityState<DicomAttributeRule, string>,


    // these are for storing data directly from the backend, so any local modifications can be easily reverted back to them

    /** Original, unmodified directly-from-backend versions of contouring model customization data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalModels: EntityState<Model, string>;
    /** Original, unmodified directly-from-backend versions of contouring customization base data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalCustomizationBases: EntityState<CustomizationBase, string>;
    /** Original, unmodified directly-from-backend versions of contouring customization output data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalCustomizationOutputs: EntityState<ContouringCustomizationOutput, string>;
    /** Original, unmodified directly-from-backend versions of contouring customization metadata objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalModelCustomizationsMetadata: EntityState<OutputMetadataItem, string>;
    /** Original, unmodified directly-from-backend versions of contouring ROI customization objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalRoiCustomizations: EntityState<ContouringRoi, string>;
    /** Original, unmodified directly-from-backend versions of contouring global ROI customization objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalGlobalRoiCustomizations: EntityState<GlobalContouringRoi, string>;
    /** Original, unmodified directly-from-backend versions of contouring AE Title rule data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalAeTitleRules: EntityState<AeTitleRule, string>;
    /** Original, unmodified directly-from-backend versions of contouring DICOM rule data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalDicomRules: EntityState<DicomRule, string>;
    /** Original, unmodified directly-from-backend versions of contouring DICOM attribute rule data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalDicomAttributeRules: EntityState<DicomAttributeRule, string>;


    /** Contouring customization validation errors coming from backend. */
    backendValidationErrors: EntityState<BackendValidationErrorViewModel, string>,
    /** Contouring customization validation errors generated in UI (in "forms"). */
    formValidationErrors: EntityState<FormValidationError, string>,



    /** An error that occurred when fetching contouring customizations, or null. */
    customizationFetchError: string | null,

    /** Saving of model customizations to backend is in progress if true, false otherwise. */
    isModelCustomizationSavingInProgress: boolean,

    /** True if resetting all contouring customizations to factory defaults is currently in progress, false otherwise. */
    isAllModelCustomizationsResetInProgress: boolean,

    /** True if resetting a single customization output to factory defaults is currently in progress, false otherwise. */
    isCustomizationOutputResetInProgress: boolean,

    /** Model customization save error, if any. */
    modelCustomizationSaveError: string | null,

    /** An error regarding model customization data that's not directly related to saving */
    modelCustomizationDataError: string | null,

    /** An error message regarding exporting or importing entire customization config to/from JSON */
    customizationImportExportError: string | null,

};





export const initialState: ContouringSliceState = {
    models: modelAdapter.getInitialState(),
    customizationBases: customizationBaseAdapter.getInitialState(),
    contourOutputs: customizationOutputAdapter.getInitialState(),
    outputMetadata: outputMetadataAdapter.getInitialState(),
    roiCustomizations: roiCustomizationAdapter.getInitialState(),
    globalRoiCustomizations: globalRoiCustomizationAdapter.getInitialState(),
    aeTitleRules: aeTitleRuleAdapter.getInitialState(),
    dicomRules: dicomRuleAdapter.getInitialState(),
    dicomAttributeRules: dicomAttributeRuleAdapter.getInitialState(),

    originalModels: modelAdapter.getInitialState(),
    originalCustomizationBases: customizationBaseAdapter.getInitialState(),
    originalCustomizationOutputs: customizationOutputAdapter.getInitialState(),
    originalModelCustomizationsMetadata: outputMetadataAdapter.getInitialState(),
    originalRoiCustomizations: roiCustomizationAdapter.getInitialState(),
    originalGlobalRoiCustomizations: globalRoiCustomizationAdapter.getInitialState(),
    originalAeTitleRules: aeTitleRuleAdapter.getInitialState(),
    originalDicomRules: dicomRuleAdapter.getInitialState(),
    originalDicomAttributeRules: dicomAttributeRuleAdapter.getInitialState(),

    backendValidationErrors: backendValidationErrorAdapter.getInitialState(),
    formValidationErrors: formValidationErrorAdapter.getInitialState(),

    customizationFetchError: null,
    isModelCustomizationSavingInProgress: false,
    isAllModelCustomizationsResetInProgress: false,
    isCustomizationOutputResetInProgress: false,
    modelCustomizationSaveError: null,
    modelCustomizationDataError: null,
    customizationImportExportError: null,
};


const sliceWrapper = new ContourSliceWrapper();


/** Redux store slice for interacting with contouring configuration and customization. */
const contouringSlice = createSlice({
    name: 'contouring',
    initialState,
    reducers: {
        /**
         * Sets current contouring model customization entities to provided values. This is used when initializing, swapping, or
         * resetting entire model customizations loaded into memory.
         * @param action.customizations All contouring-related model customization entities to load into memory. If set to undefined then
         * in-model entities are reset to default empty values.
         * @param action.errorMessage Optional error message.
         */
        modelCustomizationsSet(state, action: PayloadAction<{ customizations: ContouringCustomizationEntities | null, errorMessage?: string }>) {
            const { customizations, errorMessage } = action.payload;

            if (customizations !== null) {
                // set everything according to input
                modelAdapter.setAll(state.models, customizations.models);
                customizationBaseAdapter.setAll(state.customizationBases, customizations.customizationBases);
                customizationOutputAdapter.setAll(state.contourOutputs, customizations.contouringOutputs);
                outputMetadataAdapter.setAll(state.outputMetadata, customizations.outputMetadata);
                roiCustomizationAdapter.setAll(state.roiCustomizations, customizations.contouringRois);
                globalRoiCustomizationAdapter.setAll(state.globalRoiCustomizations, customizations.contouringGlobalRois);
                aeTitleRuleAdapter.setAll(state.aeTitleRules, customizations.aeTitleRules);
                dicomRuleAdapter.setAll(state.dicomRules, customizations.dicomRules);
                dicomAttributeRuleAdapter.setAll(state.dicomAttributeRules, customizations.dicomAttributeRules);

                // set original collections, also, so we can revert changes to the actual collection with ease
                modelAdapter.setAll(state.originalModels, customizations.models);
                customizationBaseAdapter.setAll(state.originalCustomizationBases, customizations.customizationBases);
                customizationOutputAdapter.setAll(state.originalCustomizationOutputs, customizations.contouringOutputs);
                outputMetadataAdapter.setAll(state.originalModelCustomizationsMetadata, customizations.outputMetadata);
                roiCustomizationAdapter.setAll(state.originalRoiCustomizations, customizations.contouringRois);
                globalRoiCustomizationAdapter.setAll(state.originalGlobalRoiCustomizations, customizations.contouringGlobalRois);
                aeTitleRuleAdapter.setAll(state.originalAeTitleRules, customizations.aeTitleRules);
                dicomRuleAdapter.setAll(state.originalDicomRules, customizations.dicomRules);
                dicomAttributeRuleAdapter.setAll(state.originalDicomAttributeRules, customizations.dicomAttributeRules);
            } else {
                // set everything to default values
                modelAdapter.setAll(state.models, modelAdapter.getInitialState().entities as Record<string, Model>);
                customizationBaseAdapter.setAll(state.customizationBases, customizationBaseAdapter.getInitialState().entities as Record<string, CustomizationBase>);
                customizationOutputAdapter.setAll(state.contourOutputs, customizationOutputAdapter.getInitialState().entities as Record<string, ContouringCustomizationOutput>);
                outputMetadataAdapter.setAll(state.outputMetadata, outputMetadataAdapter.getInitialState().entities as Record<string, OutputMetadataItem>);
                roiCustomizationAdapter.setAll(state.roiCustomizations, roiCustomizationAdapter.getInitialState().entities as Record<string, ContouringRoi>);
                globalRoiCustomizationAdapter.setAll(state.globalRoiCustomizations, globalRoiCustomizationAdapter.getInitialState().entities as Record<string, GlobalContouringRoi>);
                aeTitleRuleAdapter.setAll(state.aeTitleRules, aeTitleRuleAdapter.getInitialState().entities as Record<string, AeTitleRule>);
                dicomRuleAdapter.setAll(state.dicomRules, dicomRuleAdapter.getInitialState().entities as Record<string, DicomRule>);
                dicomAttributeRuleAdapter.setAll(state.dicomAttributeRules, dicomAttributeRuleAdapter.getInitialState().entities as Record<string, DicomAttributeRule>);

                // set original collections, also, so we can revert changes to the actual collection with ease
                modelAdapter.setAll(state.originalModels, modelAdapter.getInitialState().entities as Record<string, Model>);
                customizationBaseAdapter.setAll(state.originalCustomizationBases, customizationBaseAdapter.getInitialState().entities as Record<string, CustomizationBase>);
                customizationOutputAdapter.setAll(state.originalCustomizationOutputs, customizationOutputAdapter.getInitialState().entities as Record<string, ContouringCustomizationOutput>);
                outputMetadataAdapter.setAll(state.originalModelCustomizationsMetadata, outputMetadataAdapter.getInitialState().entities as Record<string, OutputMetadataItem>);
                roiCustomizationAdapter.setAll(state.originalRoiCustomizations, roiCustomizationAdapter.getInitialState().entities as Record<string, ContouringRoi>);
                globalRoiCustomizationAdapter.setAll(state.originalGlobalRoiCustomizations, globalRoiCustomizationAdapter.getInitialState().entities as Record<string, GlobalContouringRoi>);
                aeTitleRuleAdapter.setAll(state.originalAeTitleRules, aeTitleRuleAdapter.getInitialState().entities as Record<string, AeTitleRule>);
                dicomRuleAdapter.setAll(state.originalDicomRules, dicomRuleAdapter.getInitialState().entities as Record<string, DicomRule>);
                dicomAttributeRuleAdapter.setAll(state.originalDicomAttributeRules, dicomAttributeRuleAdapter.getInitialState().entities as Record<string, DicomAttributeRule>);
            }

            state.customizationFetchError = errorMessage !== undefined ? errorMessage : null;
            if (errorMessage === undefined) {
                // clear any errors
                backendValidationErrorAdapter.removeAll(state.backendValidationErrors);
                formValidationErrorAdapter.removeAll(state.formValidationErrors);

                // calculate initial form validation errors
                for (const outputId of state.contourOutputs.ids) {
                    sliceWrapper.performFormValidationForOutput(state, outputId);
                }
            }
        },

        /**
         * Updates the contour color of a specified ROI.
         * @param action.roiId Internal (entity adapter) ID of the ROI to update.
         * @param action.color RGB value of the color to set to. Valid values are [0-255, 0-255, 0-255] although this is not enforced in this reducer.
         * @param action.isGlobalRoi True if the ROI being updated is a global ROI (and thus the changes should be applied to all the actual
         * ROIs that this global ROI represents) or false if the ROI being updated is a normal single ROI.
         */
        roiCustomizationColorUpdated(state, action: PayloadAction<{ roiId: string, color: [number, number, number], isGlobalRoi: boolean }>) {
            const { roiId, color, isGlobalRoi } = action.payload;
            updateRoiCustomizationField(state, roiId, isGlobalRoi, { color: color, isModified: true });
        },

        /**
         * Updates if a ROI is included in a customization output or not.
         * @param action.roiId Internal (entity adapter) ID of the ROI to update.
         * @param action.isIncluded True if ROI is to be included in its customization output, false otherwise.
         * @param action.isGlobalRoi True if the ROI being updated is a global ROI (and thus the changes should be applied to all the actual
         * ROIs that this global ROI represents) or false if the ROI being updated is a normal single ROI.
         */
        roiCustomizationIncludedInModelUpdated(state, action: PayloadAction<{ roiId: string, isIncluded: boolean, isGlobalRoi: boolean }>) {
            const { roiId, isIncluded, isGlobalRoi } = action.payload;

            // isIncluded field doesn't affect global roi groupings so recalculation is unnecessary
            const noGlobalRoiRecalculate = true;

            updateRoiCustomizationField(state, roiId, isGlobalRoi, { isIncluded: isIncluded, isModified: true }, noGlobalRoiRecalculate);

            // perform form validation afterwards
            // if we have a global roi then start by collecting applicable roi ids
            const roiIdsForValidation = !isGlobalRoi ? [roiId] : state.globalRoiCustomizations.entities[roiId]?.coveredRois;
            if (roiIdsForValidation) {
                for (const roiIdForValidation of roiIdsForValidation) {
                    // validate form changes after inclusion was changed
                    const roi = state.roiCustomizations.entities[roiIdForValidation];
                    if (roi && roi.customizationOutputId) {
                        sliceWrapper.performFormValidationForOutput(state, roi.customizationOutputId);
                    }
                }
            }
        },

        /**
         * Updates the name of a specified ROI.
         * @param action.roiId Internal (entity adapter) ID of the ROI to update.
         * @param action.name The new name of the ROI.
         * @param action.isGlobalRoi True if the ROI being updated is a global ROI (and thus the changes should be applied to all the actual
         * ROIs that this global ROI represents) or false if the ROI being updated is a normal single ROI.
         */
        roiCustomizationNameUpdated(state, action: PayloadAction<{ roiId: string, name: string, isGlobalRoi: boolean }>) {
            const { roiId, name, isGlobalRoi } = action.payload;
            updateRoiCustomizationField(state, roiId, isGlobalRoi, { name: name, isModified: true });

            // perform form validation afterwards
            // if we have a global roi then start by collecting applicable roi ids
            const roiIdsForValidation = !isGlobalRoi ? [roiId] : state.globalRoiCustomizations.entities[roiId]?.coveredRois;
            if (roiIdsForValidation) {
                for (const roiIdForValidation of roiIdsForValidation) {
                    // validate form changes after roi name was changed
                    const roi = state.roiCustomizations.entities[roiIdForValidation];
                    if (roi && roi.customizationOutputId) {
                        sliceWrapper.performFormValidationForOutput(state, roi.customizationOutputId);
                    }
                }
            }
        },

        /**
         * Updates the contouring operation of a specified ROI.
         * @param action.roiId Internal (entity adapter) ID of the ROI to update.
         * @param action.operation The new contouring operation of the ROI.
         * @param action.isGlobalRoi True if the ROI being updated is a global ROI (and thus the changes should be applied to all the actual
         * ROIs that this global ROI represents) or false if the ROI being updated is a normal single ROI.
         */
        roiCustomizationOperationUpdated(state, action: PayloadAction<{ roiId: string, operation: string, isGlobalRoi: boolean }>) {
            const { roiId, operation, isGlobalRoi } = action.payload;
            const roiBeforeChanges = state.roiCustomizations.entities[roiId];
            updateRoiCustomizationField(state, roiId, isGlobalRoi, { operation: operation, isModified: true });

            // need to check that this roi isn't in an another global roi's exclusions
            // TODO: this would be an easier check to make if RoiCustomization.globalRoiId would also include global roi id if the roi is in that item's
            // exclusions, which currently doesn't happen. Consider this change if the following code has bad performance (and put it into calculateGlobalRoiChangesForRegularRoi())
            if (roiBeforeChanges) {
                const previousExcludedGlobalRoi = Object.values(state.globalRoiCustomizations.entities).find(g => g?.operation === roiBeforeChanges.operation && g?.excludedRois.includes(roiId));
                if (previousExcludedGlobalRoi) {
                    const excludedRois = previousExcludedGlobalRoi.excludedRois.filter(rId => rId !== roiId);
                    if (excludedRois.length === 0 && previousExcludedGlobalRoi.coveredRois.length === 0) {
                        globalRoiCustomizationAdapter.removeOne(state.globalRoiCustomizations, previousExcludedGlobalRoi.id);
                    } else {
                        globalRoiCustomizationAdapter.updateOne(state.globalRoiCustomizations,
                            {
                                id: previousExcludedGlobalRoi.id,
                                changes: { ...getGlobalRoiItemsInAlphabeticalOrder(state, previousExcludedGlobalRoi, previousExcludedGlobalRoi.coveredRois, previousExcludedGlobalRoi.excludedRois.filter(rId => rId !== roiId)) }
                            });
                    }
                }
            }
        },

        /**
         * Updates the DICOM interpreted type field of a specified ROI.
         * @param action.roiId Internal (entity adapter) ID of the ROI to update.
         * @param action.interpretedType The new interpreted type value of the ROI.
         * @param action.isGlobalRoi True if the ROI being updated is a global ROI (and thus the changes should be applied to all the actual
         * ROIs that this global ROI represents) or false if the ROI being updated is a normal single ROI.
         */
        roiInterpretedTypeUpdated(state, action: PayloadAction<{ roiId: string, interpretedType: string, isGlobalRoi: boolean }>) {
            const { roiId, interpretedType, isGlobalRoi } = action.payload;
            updateRoiCustomizationField(state, roiId, isGlobalRoi, { interpretedType: interpretedType, isModified: true });
        },

        /**
         * Updates the nested coding scheme value of a specified ROI.
         * @param action.roiId Internal (entity adapter) ID of the ROI to update.
         * @param action.codingScheme The coding scheme values to be set for the ROI. Any existing values in the ROI are replaced.
         * @param action.isGlobalRoi True if the ROI being updated is a global ROI (and thus the changes should be applied to all the actual
         * ROIs that this global ROI represents) or false if the ROI being updated is a normal single ROI.
         */
        roiCodingSchemeUpdated(state, action: PayloadAction<{ roiId: string, codingScheme: Partial<CodingScheme>, isGlobalRoi: boolean }>) {
            const { roiId, codingScheme, isGlobalRoi } = action.payload;
            updateRoiCustomizationField(state, roiId, isGlobalRoi, { ...codingScheme, isModified: true });
        },

        /**
        * Updates the physical property value of a specified ROI.
        * TODO: support more than one physical property.
        * @param action.roiId Internal (entity adapter) ID of the ROI to update.
        * @param action.physicalPropertyAttribute The name of the physical property attribute to set.
        * @param action.physicalPropertyValue The value of the physical property to set.
        * @param action.isGlobalRoi True if the ROI being updated is a global ROI (and thus the changes should be applied to all the actual
        * ROIs that this global ROI represents) or false if the ROI being updated is a normal single ROI.
        */
        roiPhysicalPropertyUpdated(state,
            action: PayloadAction<{
                roiId: string,
                physicalPropertyAttribute: PhysicalProperty | undefined,
                physicalPropertyValue: string | undefined,
                isGlobalRoi: boolean
            }>) {
            const { roiId, physicalPropertyAttribute, physicalPropertyValue, isGlobalRoi } = action.payload;

            const physicalPropertyChanges: Partial<ContouringRoi> | Partial<GlobalContouringRoi> = {};
            if (physicalPropertyAttribute !== undefined) { physicalPropertyChanges.physicalPropertyAttribute = physicalPropertyAttribute; }
            if (physicalPropertyValue !== undefined) { physicalPropertyChanges.physicalPropertyValue = physicalPropertyValue; }

            updateRoiCustomizationField(state, roiId, isGlobalRoi, { ...physicalPropertyChanges, isModified: true });
        },

        /**
         * Adds a new advanced ROI to given customization output. The new ROI is created either using default values
         * or by duplicating it from an existing ROI.
         * 
         * An advanced ROI is a customization ROI where the operation field is "unlocked" and can be used for advanced
         * contouring operations.
         * 
         * @param action.outputId Internal (entity adapter) ID of the customization base where the advanced ROI will be added.
         * @param action.newRoiId Internal (entity adapter) ID for the new advanced ROI.
         * @param action.duplicatedRoiId An optional internal (entity adapter) ID for a ROI to be used as a duplication base
         * for the new advanced ROI. 
         */
        advancedRoiCustomizationAdded(state, action: PayloadAction<{ outputId: string, newRoiId: string, duplicatedRoiId?: string | undefined }>) {
            const { outputId, newRoiId, duplicatedRoiId } = action.payload;
            const output = state.contourOutputs.entities[outputId];
            if (output === undefined) { throw new Error(`Could not find customization output ${outputId}`); }

            const duplicatedRoi = duplicatedRoiId ? state.roiCustomizations.entities[duplicatedRoiId] : undefined;

            const operation = duplicatedRoi ? duplicatedRoi.operation : '';
            const name = duplicatedRoi ? duplicatedRoi.name : '';
            const color = duplicatedRoi ? duplicatedRoi.color : generateRandomColor();
            const interpretedType = duplicatedRoi ? duplicatedRoi.interpretedType : DEFAULT_INTERPRETED_TYPE;
            const physicalPropertyAttribute = duplicatedRoi ? duplicatedRoi.physicalPropertyAttribute : PhysicalProperty.NotSet;
            const physicalPropertyValue = duplicatedRoi ? duplicatedRoi.physicalPropertyValue : '';
            const codingScheme = duplicatedRoi ? getCodingScheme(duplicatedRoi) : getDefaultCodingScheme();
            const isIncluded = duplicatedRoi ? duplicatedRoi.isIncluded : true;
            const canBeDeleted = true;
            const isModified = true;
            const scrollToView = true;

            const newRoi = createNewContouringRoi(operation, name, color, interpretedType, physicalPropertyAttribute, physicalPropertyValue, codingScheme, isIncluded, canBeDeleted, outputId, newRoiId, isModified, scrollToView);
            roiCustomizationAdapter.addOne(state.roiCustomizations, newRoi);
            customizationOutputAdapter.updateOne(state.contourOutputs, { id: outputId, changes: { rois: output.rois.concat(newRoiId), isModified: true } });
            setObjectAndAncestorsAsModified(state, [outputId], CustomizationObjectType.CustomizationOutput);

            // update global roi if we were duplicating
            if (duplicatedRoi !== undefined) {
                if (duplicatedRoi.globalRoiId !== undefined) {
                    const globalRoi = state.globalRoiCustomizations.entities[duplicatedRoi.globalRoiId];
                    if (globalRoi !== undefined) {
                        globalRoiCustomizationAdapter.updateOne(state.globalRoiCustomizations, {
                            id: duplicatedRoi.globalRoiId,
                            changes: { ...getGlobalRoiItemsInAlphabeticalOrder(state, globalRoi, globalRoi.coveredRois.concat(newRoiId), globalRoi.excludedRois) }
                        });
                    }
                } else {
                    const excludedGlobalRoi = Object.values(state.globalRoiCustomizations.entities).find(g => g?.operation === newRoi.operation);
                    if (excludedGlobalRoi !== undefined) {
                        globalRoiCustomizationAdapter.updateOne(state.globalRoiCustomizations, {
                            id: excludedGlobalRoi.id,
                            changes: { ...getGlobalRoiItemsInAlphabeticalOrder(state, excludedGlobalRoi, excludedGlobalRoi.coveredRois, excludedGlobalRoi.excludedRois.concat(newRoiId)) }
                        });
                    }
                }
            }

            // validate form changes after a new advanced roi (with most likely a duplicated name) was added
            sliceWrapper.performFormValidationForOutput(state, outputId);
        },

        /**
        * Removes an advanced ROI from the customization configuration.
        * 
        * Built-in ROIs (non-advanced ROIs) cannot be removed. This function will throw if a built-in ROI is attempted
        * to be removed.
        * 
        * An advanced ROI is a customization ROI where the operation field is "unlocked" and can be used for advanced
        * contouring operations.
        * 
        * @param action Internal (entity adapter) ID of the advanced ROI to be removed. This function throws if a 
        * non-advanced ROI (non-built-in ROI) ID is given instead.
        */
        advancedRoiCustomizationRemoved(state, action: PayloadAction<string>) {
            const roiId = action.payload;
            const roi = state.roiCustomizations.entities[roiId];
            if (roi && !roi.isBuiltInRoi) {
                const globalRoiId = roi.globalRoiId;
                const outputId = roi.customizationOutputId;

                const globalRoi = globalRoiId ? state.globalRoiCustomizations.entities[globalRoiId] : Object.values(state.globalRoiCustomizations.entities).find(g => g?.excludedRois.includes(roiId));
                if (globalRoi) {
                    globalRoiCustomizationAdapter.updateOne(state.globalRoiCustomizations, {
                        id: globalRoi.id,
                        changes: { ...getGlobalRoiItemsInAlphabeticalOrder(state, globalRoi, globalRoi.coveredRois.filter(rId => rId !== roiId), globalRoi.excludedRois.filter(rId => rId !== roiId)) }
                    });
                }

                const output = outputId ? state.contourOutputs.entities[outputId] : undefined;
                if (outputId && output) {
                    customizationOutputAdapter.updateOne(state.contourOutputs, { id: outputId, changes: { rois: output.rois.filter(rId => rId !== roiId), isModified: true } });
                    setObjectAndAncestorsAsModified(state, [outputId], CustomizationObjectType.CustomizationOutput);
                }

                roiCustomizationAdapter.removeOne(state.roiCustomizations, roiId);
                backendValidationErrorAdapter.removeOne(state.backendValidationErrors, roiId);
                formValidationErrorAdapter.removeMany(state.formValidationErrors, getFormValidationErrorIdsForItem(state, roiId));

                // validate form changes after advanced roi name was removed
                if (outputId) {
                    sliceWrapper.performFormValidationForOutput(state, outputId);
                }
            }
        },

        /**
         * Updates a ROI customization to match a specified Global ROI (and adds it as covered to the Global ROI).
         * @param action.roiId Internal (entity adapter) ID of the ROI to update to match the Global ROI.
         * @param action.globalRoiId Internal (entity adapter) ID of the Global ROI that will be used as source props for the ROI.
         */
        roiCustomizationChangedToMatchGlobalRoi(state, action: PayloadAction<{ roiId: string, globalRoiId: string }>) {
            const { roiId, globalRoiId } = action.payload;
            const globalRoi = state.globalRoiCustomizations.entities[globalRoiId];
            if (globalRoi) {
                const coveredRois = globalRoi.coveredRois.includes(roiId) ? globalRoi.coveredRois : globalRoi.coveredRois.concat(roiId);
                const excludedRois = globalRoi.excludedRois.filter(rId => rId !== roiId);
                globalRoiCustomizationAdapter.updateOne(state.globalRoiCustomizations, { id: globalRoiId, changes: { ...getGlobalRoiItemsInAlphabeticalOrder(state, globalRoi, coveredRois, excludedRois) } });
                roiCustomizationAdapter.updateOne(state.roiCustomizations, {
                    id: roiId, changes: {
                        name: globalRoi.name,
                        operation: globalRoi.operation,
                        color: globalRoi.color,
                        globalRoiId: globalRoiId,
                        interpretedType: globalRoi.interpretedType,
                        ...getCodingScheme(globalRoi),
                        isModified: true,
                    }
                });
                roiCustomizationAdapter.updateOne(state.roiCustomizations, { id: roiId, changes: { scrollToView: false } });
                setObjectAndAncestorsAsModified(state, [roiId], CustomizationObjectType.Roi);

                // validate form changes within output after a roi was changed to match a global roi
                const roi = state.roiCustomizations.entities[roiId];
                if (roi && roi.customizationOutputId) {
                    sliceWrapper.performFormValidationForOutput(state, roi.customizationOutputId);
                }
            }
        },

        /**
         * Changes the specified ROI into the Global ROI for that contouring operation (i.e. the Global ROI for that particular
         * contouring operation is changed to match the specified ROI instead of whatever ROI is currently most common in
         * that operation group).
         * @param action.roiId Internal (entity adapter) ID of the ROI to be used as Global ROI.
         * @param action.globalRoiId Internal (entity adapter) ID of the Global ROI to update.
         */
        roiCustomizationMadeIntoGlobalRoi(state, action: PayloadAction<{ roiId: string, globalRoiId: string }>) {
            const { roiId, globalRoiId } = action.payload;
            const roi = state.roiCustomizations.entities[roiId];
            const globalRoi = state.globalRoiCustomizations.entities[globalRoiId];
            if (roi && globalRoi) {
                const globalRoiChanges: Partial<GlobalContouringRoi> = {
                    name: roi.name, operation: roi.operation, color: roi.color,
                    interpretedType: roi.interpretedType,
                    ...getCodingScheme(roi),
                };

                const allRoiIds = globalRoi.coveredRois.concat(globalRoi.excludedRois);
                if (!allRoiIds.includes(roiId)) { allRoiIds.push(roiId); }

                const coveredRois: string[] = [];
                const excludedRois: string[] = [];
                const removedRois: string[] = [];
                let isBuiltInRoi = false;
                for (const rId of allRoiIds) {
                    const r = state.roiCustomizations.entities[rId];
                    if (r) {
                        if (roiCustomizationMatchesGlobalRoi(r, globalRoi, globalRoiChanges)) {
                            coveredRois.push(rId);
                            isBuiltInRoi = isBuiltInRoi || r.isBuiltInRoi;
                        } else if (r.operation === globalRoiChanges.operation) {
                            excludedRois.push(rId);
                        } else {
                            removedRois.push(rId);
                        }
                    }
                }

                globalRoiCustomizationAdapter.updateOne(state.globalRoiCustomizations, {
                    id: globalRoiId, changes:
                    {
                        ...globalRoiChanges,
                        ...getGlobalRoiItemsInAlphabeticalOrder(state, globalRoi, coveredRois, excludedRois),
                        isBuiltInRoi,
                    }
                });
                roiCustomizationAdapter.updateMany(state.roiCustomizations, coveredRois.map(c => ({ id: c, changes: { globalRoiId: globalRoiId } })));
                roiCustomizationAdapter.updateMany(state.roiCustomizations, excludedRois.concat(removedRois).map(c => ({ id: c, changes: { globalRoiId: undefined } })));
            }
        },

        /** Copies supplied coding scheme to every ROI in current customization. */
        roiCodingSchemeCopied(state, action: PayloadAction<Partial<CodingScheme>>) {
            const codingSchemeChanges = action.payload;

            const modelIds = state.models.ids;
            const customizationBasesIds = state.customizationBases.ids;
            const customizationOutputsIds = state.contourOutputs.ids;

            if (!codingSchemeChanges) {
                throw new Error("Missing coding scheme changes.");
            }

            // Update FMA IDs for all ROIs
            const roisToUpdate = Object.values(state.roiCustomizations.entities);
            const globalRoisToUpdate = Object.values(state.globalRoiCustomizations.entities);

            roiCustomizationAdapter.updateMany(
                state.roiCustomizations,
                roisToUpdate.map(roi => ({ id: roi.id, changes: { isModified: true, ...codingSchemeChanges } }))
            );
            globalRoiCustomizationAdapter.updateMany(
                state.globalRoiCustomizations,
                globalRoisToUpdate.map(globalRoi => ({ id: globalRoi.id, changes: { isModified: true, ...codingSchemeChanges } }))
            );

            if (modelIds) {
                modelAdapter.updateMany(
                    state.models,
                    modelIds.map(modelId => ({ id: modelId, changes: { isModified: true } }))
                );
            }
            if (customizationBasesIds) {
                customizationBaseAdapter.updateMany(
                    state.customizationBases,
                    customizationBasesIds.map(customizationBaseId => ({ id: customizationBaseId, changes: { isModified: true } }))
                );
            }
            if (customizationOutputsIds) {
                customizationOutputAdapter.updateMany(
                    state.contourOutputs,
                    customizationOutputsIds.map(customizationOutputId => ({ id: customizationOutputId, changes: { isModified: true } }))
                );
            }
        },

        /** Copies supplied coding scheme to every ROI in supplied contouring output. */
        roiCodingSchemeCopiedToOutput(state, action: PayloadAction<{ codingSchemeChanges: Partial<CodingScheme>, outputId: string | undefined }>) {
            const { codingSchemeChanges, outputId } = action.payload;

            if (!codingSchemeChanges || !outputId) {
                throw new Error("Missing coding scheme changes.");
            }

            // Update FMA IDs for ROIs within the specified customization output
            const roisToUpdate = Object.values(state.roiCustomizations.entities).filter(roi => roi.customizationOutputId === outputId);

            roiCustomizationAdapter.updateMany(
                state.roiCustomizations,
                roisToUpdate.map(roi => ({ id: roi.id, changes: { isModified: true, ...codingSchemeChanges } }))
            );

            // update parent objects
            setObjectAndAncestorsAsModified(state, [outputId], CustomizationObjectType.CustomizationOutput);

            // update global rois
            for (const roi of roisToUpdate) {
                calculateGlobalRoiChangesForRegularRoi(state, roi.id);
            }
        },


        /**
         * Renames given customization base.
         * @param action.customizationBaseId ID of the customization base to rename.
         * @param action.name The new name of the customization base.
         */
        modelCustomizationRenamed(state, action: PayloadAction<{ customizationBaseId: string, name: string }>) {
            const { customizationBaseId, name } = action.payload;
            customizationBaseAdapter.updateOne(state.customizationBases, { id: customizationBaseId, changes: { isModified: true, customizationName: name } });
            setObjectAndAncestorsAsModified(state, [customizationBaseId], CustomizationObjectType.CustomizationBase);
        },

        /**
         * Removes the specified customization base entity and all entities under it in the hierarchy.
         * @param action The ID of the customization base to remove.
         */
        customizationBaseRemoved(state, action: PayloadAction<string>) {
            const customizationBaseId = action.payload;

            sliceWrapper.removeCustomizationBase(state, customizationBaseId);
        },

        customizationOutputRemoved(state, action: PayloadAction<string>) {
            const customizationOutputId = action.payload;

            const output = state.contourOutputs.entities[customizationOutputId];
            if (output === undefined) { throw new Error(`Could not find customization output ${customizationOutputId}`); }

            const customization = state.customizationBases.entities[output.modelCustomizationBaseId];
            if (customization === undefined) { throw new Error(`Could not find customization base ${output.modelCustomizationBaseId} for output ${customizationOutputId}`); }

            // mark parent objects as modified so we know we need to save the configuration
            setObjectAndAncestorsAsModified(state, [customizationOutputId], CustomizationObjectType.CustomizationOutput);

            const removedOutputEntities = sliceWrapper.collectCustomizationOutputEntitiesFromStoreForRemoval(state, output, output.modelCustomizationBaseId);

            // update global rois
            globalRoiCustomizationAdapter.updateMany(state.globalRoiCustomizations, removedOutputEntities.globalRoiChanges);

            // delete all matching items
            roiCustomizationAdapter.removeMany(state.roiCustomizations, removedOutputEntities.roiIdsToBeRemoved);
            outputMetadataAdapter.removeMany(state.outputMetadata, removedOutputEntities.metadataIdsToBeRemoved);
            customizationOutputAdapter.removeOne(state.contourOutputs, customizationOutputId);

            // update customization base
            customizationBaseAdapter.updateOne(state.customizationBases, { id: output.modelCustomizationBaseId, changes: { outputs: customization.outputs.filter(o => o !== customizationOutputId) } });

            // no need to specifically remove validation errors here -- they will not show up in UI and will be cleared from store during next save operation
            // however form validation errors should be removed
            const removedIds: string[] = [
                customizationOutputId,
                ...removedOutputEntities.roiIdsToBeRemoved,
                ...removedOutputEntities.metadataIdsToBeRemoved,
            ];
            formValidationErrorAdapter.removeMany(state.formValidationErrors, getFormValidationErrorIdsForItems(state, removedIds));
        },

        /**
         * Updates the description field on a customization base object.
         * @param action.customizationBaseId Internal (entity adapter) ID of the customization base to update.
         * @param action.description The new description to set.
         */
        modelCustomizationDescriptionUpdated(state, action: PayloadAction<{ customizationBaseId: string, description: string }>) {
            const { customizationBaseId, description } = action.payload;
            sliceWrapper.updateModelCustomizationDescription(state, customizationBaseId, description);
        },

        /**
         * Updates a segmentation model's visibility.
         * @param action.modelId Internal (entity adapter) ID of the segmentation model to update.
         * @param action.visibility The new visibility setting for the segmentation model.
         */
        modelVisibilityUpdated(state, action: PayloadAction<{ modelId: string, visibility: ModelVisibility }>) {
            const { modelId, visibility } = action.payload;
            sliceWrapper.updateModelVisibility(state, modelId, visibility);
        },

        /**
         * Creates a new empty metadata item into given customization output.
         * @param action Internal (entity adapter) ID of the customization output where the new metadata item will be added.
         */
        metadataItemAdded(state, action: PayloadAction<string>) {
            const outputId = action.payload;
            const output = state.contourOutputs.entities[outputId];
            if (output === undefined) { throw new Error(`Could not find customization output ${outputId}`); }

            const newMetadata = createNewMetadataItem('', '', outputId);
            newMetadata.isModified = true;
            newMetadata.scrollToView = true;
            outputMetadataAdapter.addOne(state.outputMetadata, newMetadata);
            customizationOutputAdapter.updateOne(state.contourOutputs, { id: outputId, changes: { metadata: output.metadata.concat(newMetadata.id), isModified: true } });
            setObjectAndAncestorsAsModified(state, [outputId], CustomizationObjectType.CustomizationOutput);
        },

        /**
         * Updates a customization metadata item tuple. If either attribute or value is left as undefined then that particular prop is not updated
         * and the existing prop is left unchanged.
         * @param action.metadataId Internal (entity adapter) ID of the customization metadata item that will be updated.
         * @param action.attribute The new attribute name of the metadata item that will be used, or undefined if attribute should not be changed.
         * @param action.value The new value of the metadata item, or undefined if value should not be changed.
         * @param action.isUndoOperation Marks this update as part of an undo operation. Note that the correct reverted attribute and value props
         * must be supplied to this function manually, this function does not perform an undo by itself. Optional, defaults to false.
         */
        metadataItemUpdated(state, action: PayloadAction<{ metadataId: string, attribute: string | undefined, value: string | undefined, isUndoOperation?: boolean }>) {
            const { metadataId, attribute, value, isUndoOperation } = action.payload;

            sliceWrapper.updateMetadataItem(state, metadataId, attribute, value, isUndoOperation);
        },

        /**
         * Removes a customization metadata item.
         * @param action.metadataId Internal (entity adapter) ID of the customization metadata item that will be removed.
         */
        metadataItemRemoved(state, action: PayloadAction<string>) {
            const metadataId = action.payload;

            sliceWrapper.removeMetadataItem(state, metadataId);
        },


        /**
         * Sets the isIncluded setting of all ROIs in a specified customization output to either true or false.
         * @param action.customizationOutputId Internal (entity adapter) ID of the customization output.
         * @param action.isIncluded Whether all the ROIs in the customization output should be set as included (true) or not included (false).
         */
        allRoisInCustomizationOutputToggled(state, action: PayloadAction<{ customizationOutputId: string, isIncluded: boolean }>) {
            const { customizationOutputId, isIncluded } = action.payload;
            const allRois = state.contourOutputs.entities[customizationOutputId]?.rois;

            // isIncluded field doesn't affect global roi groupings so recalculation is unnecessary
            const noGlobalRoiRecalculate = true;

            // if isIncluded true, then update all rois to be included using updateRoiCustomizationField function
            if (allRois) {
                for (const roiId of allRois) {
                    updateRoiCustomizationField(state, roiId as string, false, { isIncluded: isIncluded, isModified: true }, noGlobalRoiRecalculate);
                }
            }

            // perform form validation afterwards
            if (allRois) {
                for (const roiIdForValidation of allRois) {
                    // validate form changes after roi name was changed
                    const roi = state.roiCustomizations.entities[roiIdForValidation];
                    if (roi && roi.customizationOutputId) {
                        sliceWrapper.performFormValidationForOutput(state, roi.customizationOutputId);
                    }
                }
            }
        },



        /**
         * Signals that all current model customizations should be reset back to factory default values.
         * @param action Current configuration target.
         */
        allModelCustomizationsReset(state, action: PayloadAction<MVisionAppClient>) {
            // this is an empty action and is only used for signalling in sagas
        },

        /**
         * Signals that a single customization output should be reset back to factory default values.
         * @param action Internal ID of the output to reset.
         */
        singleModelCustomizationReset(state, action: PayloadAction<string>) {
            // this is an empty action and is only used for signalling in sagas
        },

        /** Marks model customization reset as having started. */
        resetAllModelCustomizationsStarted(state) {
            state.isAllModelCustomizationsResetInProgress = true;
        },

        /** Marks model customization reset as finished. */
        resetAllModelCustomizationsFinished(state) {
            state.isAllModelCustomizationsResetInProgress = false;
            state.modelCustomizationSaveError = null;
        },

        /** Marks a single customization output reset as having started. */
        resetSingleCustomizationOutputStarted(state) {
            state.isCustomizationOutputResetInProgress = true;
        },

        /** Marks a single customization output reset as finished.
         * @param action.resetWasSuccessful True if reset was succesful, false otherwise.
         * @param action.errorMessage Optional error message if output reset failed.
         */
        resetSingleCustomizationOutputFinished(state, action: PayloadAction<{ resetWasSuccessful: boolean, errorMessage?: string }>) {
            state.isCustomizationOutputResetInProgress = false;
            state.modelCustomizationDataError = action.payload.errorMessage || null;
        },

        /**
         * Replaces a specified contouring customization output with supplied values, including ROIs and metadata. ID of the original 
         * customization output is retained.
         * @param action.customizationOutputId The ID of the customization output to be replaced.
         * @param action.newOutputEntities The values that will be used to replace the customization output.
         */
        modelCustomizationOutputReplaced(state, action: PayloadAction<{ customizationOutputId: string, newOutputEntities: ContouringCustomizationOutputEntities }>) {
            const { customizationOutputId, newOutputEntities } = action.payload;
            const originalOutput = state.contourOutputs.entities[customizationOutputId];

            if (!originalOutput) { throw new Error(`No original customization output (${customizationOutputId}) to replace!`); }
            if (newOutputEntities.customizationOutputs.length < 1) { throw new Error('No replacement customization output given'); }

            const originalRois = originalOutput.rois.map(rId => state.roiCustomizations.entities[rId]);

            // currently only support replacing first output from given input even if we'd get several
            const newOutput = newOutputEntities.customizationOutputs[0];
            const newMetadata = newOutputEntities.modelCustomizationsMetadata.filter(m => newOutput.metadata.includes(m.id));
            const newRois = newOutputEntities.roiCustomizations.filter(r => newOutput.rois.includes(r.id));

            // modify key parts of the replacement output items to match the original. also mark everything as modified
            newOutput.id = originalOutput.id;
            newOutput.modelCustomizationBaseId = originalOutput.modelCustomizationBaseId;
            newOutput.filename = originalOutput.filename;
            newOutput.isModified = true;

            newMetadata.forEach(m => {
                if (m.attribute === METADATA_FILENAME_ATTRIBUTE) { m.value = originalOutput.filename; }
                m.modelCustomizationOutputId = originalOutput.id;
                m.isModified = true;
            });

            newRois.forEach(r => {
                r.customizationOutputId = originalOutput.id;
                r.isModified = true;
            });

            // remove old metadata, roi items, and related form validation errors
            outputMetadataAdapter.removeMany(state.outputMetadata, originalOutput.metadata);
            roiCustomizationAdapter.removeMany(state.roiCustomizations, originalOutput.rois);
            const removedIds: string[] = [
                customizationOutputId,
                ...originalOutput.rois,
                ...originalOutput.metadata,
            ];

            formValidationErrorAdapter.removeMany(state.formValidationErrors, getFormValidationErrorIdsForItems(state, removedIds));

            // add new items
            outputMetadataAdapter.addMany(state.outputMetadata, newMetadata);
            roiCustomizationAdapter.addMany(state.roiCustomizations, newRois);

            // swap in the new customization output
            customizationOutputAdapter.setOne(state.contourOutputs, newOutput);

            // mark ancestors as modified (start with customization base as the output was already marked earlier)
            setObjectAndAncestorsAsModified(state, [newOutput.modelCustomizationBaseId], CustomizationObjectType.CustomizationBase);

            // also remove links to just deleted ROIs from global ROIs
            const allGlobalRois = Object.values(state.globalRoiCustomizations.entities);
            for (const roi of originalRois) {
                if (roi.globalRoiId) {
                    // find and modify global roi where this roi is covered
                    const globalRoi = state.globalRoiCustomizations.entities[roi.globalRoiId];
                    if (globalRoi && globalRoi.coveredRois.includes(roi.id)) {
                        if (globalRoi) {
                            globalRoiCustomizationAdapter.updateOne(state.globalRoiCustomizations, {
                                id: globalRoi.id, changes: { coveredRois: globalRoi.coveredRois.filter(cr => cr !== roi.id) }
                            });
                        }
                    }
                } else {
                    // find and modify global roi where this roi is excluded
                    const globalRoi = allGlobalRois.find(g => g.operation === roi.operation && g.excludedRois.includes(roi.id));
                    if (globalRoi) {
                        if (globalRoi) {
                            globalRoiCustomizationAdapter.updateOne(state.globalRoiCustomizations, {
                                id: globalRoi.id, changes: { excludedRois: globalRoi.excludedRois.filter(er => er !== roi.id) }
                            });
                        }
                    }
                }
            };

            // recalculate global ROIs
            newRois.forEach(r => calculateGlobalRoiChangesForRegularRoi(state, r.id));
        },

        /**
         * Duplicates given customization base and any entities under it.
         * @param action.customizationBaseId The ID of the customization base to duplicate.
         * @param action.newCustomizationName The name of the new duplicate customization base.
         */
        customizationBaseDuplicated(state, action: PayloadAction<{ customizationBaseId: string, newCustomizationName: string }>) {
            // this is an empty action and is only used for signalling in sagas
        },

        /**
         * Duplicates given customization output, including any entities under it.
         * @param action.customizationOutputId ID of the customization output to duplicate.
         * @param action.newFilename The filename of the new duplicate customization output.
         */
        customizationOutputDuplicated(state, action: PayloadAction<{ customizationOutputId: string, newFilename: string }>) {
            // this is an empty action and is only used for signalling in sagas
        },

        /**
        * Adds duplicated customization base items into redux store. Duplication itself is done in a saga function.
        */
        customizationDuplicationItemsAdded(state, action: PayloadAction<{
            parentModelId: string,
            customization: CustomizationBase,
            outputs: ContouringCustomizationOutput[],
            metadata: OutputMetadataItem[],
            rois: ContouringRoi[],
            globalRoiChanges: Update<GlobalContouringRoi, string>[],
            duplicatedIds: DuplicatedIdMap[]
        }>) {
            const { parentModelId, customization, outputs, metadata, rois, globalRoiChanges, duplicatedIds } = action.payload;

            const parentModel = state.models.entities[parentModelId];
            if (!parentModel) { throw new Error('No parent model found for duplication target'); }

            customizationBaseAdapter.addOne(state.customizationBases, customization);
            customizationOutputAdapter.addMany(state.contourOutputs, outputs);
            outputMetadataAdapter.addMany(state.outputMetadata, metadata);
            roiCustomizationAdapter.addMany(state.roiCustomizations, rois);
            globalRoiCustomizationAdapter.updateMany(state.globalRoiCustomizations, globalRoiChanges);

            for (const { id } of globalRoiChanges) {
                // make sure global roi covered and excluded items are in order
                ensureGlobalRoiItemsAreInAlphabeticalOrder(state, id as string);
            }


            modelAdapter.updateOne(state.models, {
                id: parentModelId,
                changes: { customizations: parentModel.customizations.concat(customization.id) }
            });

            setObjectAndAncestorsAsModified(state, [parentModelId], CustomizationObjectType.Model);

            // collect and duplicate any form errors we may have -- they still apply (backend errors are not worth duplicating, they'll re-appear during save)
            const duplicatedErrors = duplicateFormValidationErrors(state, duplicatedIds);
            if (duplicatedErrors.length > 0) {
                formValidationErrorAdapter.addMany(state.formValidationErrors, duplicatedErrors);
            }
        },

        /**
        * Adds duplicated customization output items into redux store. Duplication itself is done in a saga function.
        */
        outputDuplicationItemsAdded(state, action: PayloadAction<{
            parentCustomizationId: string,
            output: ContouringCustomizationOutput,
            metadata: OutputMetadataItem[],
            rois: ContouringRoi[],
            globalRoiChanges: Update<GlobalContouringRoi, string>[],
            duplicatedIds: DuplicatedIdMap[]
        }>) {
            const { parentCustomizationId, output, metadata, rois, globalRoiChanges, duplicatedIds } = action.payload;

            const parentCustomization = state.customizationBases.entities[parentCustomizationId];
            if (!parentCustomization) { throw new Error('No parent customization found for duplication target'); }

            customizationOutputAdapter.addOne(state.contourOutputs, output);
            outputMetadataAdapter.addMany(state.outputMetadata, metadata);
            roiCustomizationAdapter.addMany(state.roiCustomizations, rois);
            globalRoiCustomizationAdapter.updateMany(state.globalRoiCustomizations, globalRoiChanges);
            for (const { id } of globalRoiChanges) {
                // make sure global roi covered and excluded items are in order
                ensureGlobalRoiItemsAreInAlphabeticalOrder(state, id as string);
            }

            customizationBaseAdapter.updateOne(state.customizationBases, {
                id: parentCustomizationId,
                changes: { outputs: parentCustomization.outputs.concat(output.id) }
            });

            setObjectAndAncestorsAsModified(state, [parentCustomization.id], CustomizationObjectType.CustomizationBase);

            // collect and duplicate any form errors we may have -- they still apply (backend errors are not worth duplicating, they'll re-appear during save)
            const duplicatedErrors = duplicateFormValidationErrors(state, duplicatedIds);
            if (duplicatedErrors.length > 0) {
                formValidationErrorAdapter.addMany(state.formValidationErrors, duplicatedErrors);
            }
        },


        /**
         * Performs an undo for a customization ROI, changing it back to original values retrieved from backend.
         * @param action.roiId Internal (entity adapter) ID of the ROI to revert back to original values.
         * @param action.isGlobalRoi True if the ROI specified is a Global ROI, false otherwise. Note that Global ROIs are currently NOT supported.
         */
        roiCustomizationChangesReverted(state, action: PayloadAction<{ roiId: string, isGlobalRoi: boolean }>) {
            const { roiId, isGlobalRoi } = action.payload;

            if (isGlobalRoi) {
                // it's NOT straightforward into what state global rois should be reverted to, so let's disable this for now. work-in-progress code
                // left in for posterity although it's not finished & doesn't do what it should
                throw new Error('Reverting changes to a global structure customization is NOT supported.');

                // // if this is a global roi, only revert changes to CURRENTLY COVERED rois
                // const globalRoi = state.globalRoiCustomizations.entities[roiId];
                // const originalGlobalRoi = state.originalGlobalRoiCustomizations.entities[roiId];
                // if (globalRoi && originalGlobalRoi) {
                //     const covered: string[] = [];
                //     for (const coveredRoiId of globalRoi.coveredRois) {
                //         const originalCoveredRoi = state.originalRoiCustomizations.entities[coveredRoiId];
                //         if (originalCoveredRoi) {
                //             roiCustomizationAdapter.upsertOne(state.roiCustomizations, originalCoveredRoi)
                //         }
                //     }

                //     globalRoiCustomizationAdapter.upsertOne(state.globalRoiCustomizations, originalGlobalRoi);
                // }
            } else {
                const originalRoi = state.originalRoiCustomizations.entities[roiId];
                if (originalRoi) {
                    roiCustomizationAdapter.upsertOne(state.roiCustomizations, { ...originalRoi, scrollToView: false, globalRoiId: undefined });
                    const output = originalRoi.customizationOutputId ? state.contourOutputs.entities[originalRoi.customizationOutputId] : undefined;
                    if (output && !output.rois.includes(roiId)) {
                        customizationOutputAdapter.updateOne(state.contourOutputs, { id: output.id, changes: { rois: output.rois.filter(rId => rId !== roiId) } });
                    }
                    calculateGlobalRoiChangesForRegularRoi(state, roiId);

                    // remove any validation errors
                    backendValidationErrorAdapter.removeOne(state.backendValidationErrors, roiId);
                    formValidationErrorAdapter.removeMany(state.formValidationErrors, getFormValidationErrorIdsForItem(state, roiId));

                    // re-validate form changes after partial data in an output just got undoed
                    if (output) {
                        sliceWrapper.performFormValidationForOutput(state, output.id);
                    }
                }
            }
        },

        /**
         * Performs an undo operation for all customization data, reverting all contouring customization entities
         * to their original values from backend. Any new unsaved entries are lost.
         */
        allCustomizationChangesReverted(state) {
            modelAdapter.setAll(state.models, state.originalModels.entities as Record<string, Model>);
            customizationBaseAdapter.setAll(state.customizationBases, state.originalCustomizationBases.entities as Record<string, CustomizationBase>);
            customizationOutputAdapter.setAll(state.contourOutputs, state.originalCustomizationOutputs.entities as Record<string, ContouringCustomizationOutput>);
            outputMetadataAdapter.setAll(state.outputMetadata, state.originalModelCustomizationsMetadata.entities as Record<string, OutputMetadataItem>);
            roiCustomizationAdapter.setAll(state.roiCustomizations, state.originalRoiCustomizations.entities as Record<string, ContouringRoi>);
            globalRoiCustomizationAdapter.setAll(state.globalRoiCustomizations, state.originalGlobalRoiCustomizations.entities as Record<string, GlobalContouringRoi>);
            aeTitleRuleAdapter.setAll(state.aeTitleRules, state.originalAeTitleRules.entities as Record<string, AeTitleRule>);
            dicomRuleAdapter.setAll(state.dicomRules, state.originalDicomRules.entities as Record<string, DicomRule>);
            dicomAttributeRuleAdapter.setAll(state.dicomAttributeRules, state.originalDicomAttributeRules.entities as Record<string, DicomAttributeRule>);

            // remove any validation errors
            backendValidationErrorAdapter.removeAll(state.backendValidationErrors);
            formValidationErrorAdapter.removeAll(state.formValidationErrors);
            state.modelCustomizationSaveError = null;

            // calculate initial form validation errors
            for (const outputId of state.contourOutputs.ids) {
                sliceWrapper.performFormValidationForOutput(state, outputId);
            }
        },

        /**
         * Marks any supplied contouring customization entities as having been modified.
         * @param action A collection of customization entities to mark as modified as per their
         * entity adapter IDs.
         */
        modelCustomizationObjectsSetAsModified(state, action: PayloadAction<Partial<ContouringCustomizationEntities>>) {
            const entitiesToUpdate = action.payload;

            sliceWrapper.setStateObjectsAsModified(state, entitiesToUpdate);
        },



        /**
         * Removes a tag from a (newly created) ROI customization that would mark it as being the target of being
         * automatically scrolled to in UI.
         * 
         * This tag is generally given to advanced ROIs after their creation in the UI and should be removed once
         * the UI has scrolled to the correct position in the UI once.
         * 
         * @param action Internal (entity adapter) ID of the ROI where this tag is to be removed.
         */
        scrollToViewFromRoiCustomizationRemoved(state, action: PayloadAction<string>) {
            const roiId = action.payload;
            roiCustomizationAdapter.updateOne(state.roiCustomizations, { id: roiId, changes: { scrollToView: false } });
        },

        /**
         * Removes a tag from a (newly created) customization metadata item that would mark it as being the target of being
         * automatically scrolled to in UI.
         * 
         * This tag is generally given to metadata items after their creation in the UI and should be removed once
         * the UI has scrolled to the correct position in the UI once.
         * 
         * @param action Internal (entity adapter) ID of the customization metadata item where this tag is to be removed.
         */
        scrollToViewFromModelCustomizationMetadataRemoved(state, action: PayloadAction<string>) {
            const metadataId = action.payload;
            sliceWrapper.removeScrollToViewFromMetadata(state, metadataId);
        },



        /**
         * Adds supplied AE Title rule into contouring customization configuration.
         * @param action The AE Title rule to add.
         */
        aeTitleRuleAdded(state, action: PayloadAction<AeTitleRule>) {
            const aeTitleRule = action.payload;
            sliceWrapper.addAeTitleRule(state, aeTitleRule);
        },

        /**
         * Updates the 'action' field in an AE Title rule.
         * @param action.id Internal (entity adapter) ID of the AE Title rule to update.
         * @param action.action The new action value for the rule.
         */
        aeTitleRuleActionUpdated(state, action: PayloadAction<{ id: string, action: string }>) {
            const { id, action: newAction } = action.payload;
            sliceWrapper.updateAeTitleRuleAction(state, id, newAction);
        },

        /**
         * Removes an AE Title rule from contouring customization configuration.
         * Throws if the AE Title rule to be removed is not found from configuration.
         * @param action Internal (entity adapter) ID of the AE Title rule to remove.
         */
        aeTitleRuleRemoved(state, action: PayloadAction<string>) {
            const id = action.payload;
            sliceWrapper.removeAeTitleRule(state, id);
        },

        /**
         * Adds supplied DICOM rule into contouring customization configuration.
         * Note that DICOM attribute rules under a DICOM rule must be added separately.
         * @param action The DICOM rule to add.
         */
        dicomRuleAdded(state, action: PayloadAction<DicomRule>) {
            const dicomRule = action.payload;
            sliceWrapper.addDicomRule(state, dicomRule);
        },

        /**
         * Adds supplied DICOM attribute rule into contouring customization configuration.
         * Note that the parent DICOM rule must already exist in configuration or this function will throw.
         * @param action The DICOM attribute rule to add.
         */
        dicomAttributeRuleAdded(state, action: PayloadAction<DicomAttributeRule>) {
            const dicomAttributeRule = action.payload;
            sliceWrapper.addDicomAttributeRule(state, dicomAttributeRule);
        },

        /**
         * Updates a DICOM attribute rule.
         * @param action.id Internal (entity adapter) ID of the DICOM attribute rule to update.
         * @param action.dicomAttribute The attribute field of the rule is updated to this value.
         * @param action.dicomValue The DICOM value field of the rule is updated to this value.
         */
        dicomAttributeRuleUpdated(state, action: PayloadAction<{ id: string, dicomAttribute: string, dicomValue: string }>) {
            const { id, dicomAttribute, dicomValue } = action.payload;
            sliceWrapper.updateDicomAttributeRule(state, id, dicomAttribute, dicomValue);
        },

        /**
         * Removes a DICOM rule from contouring customization configuration. Also removes any DICOM attribute
         * rules under the DICOM rule.
         * Throws if the DICOM rule to be removed is not found from configuration.
         * @param action Internal (entity adapter) ID of the DICOM rule to remove.
         */
        dicomRuleRemoved(state, action: PayloadAction<string>) {
            const id = action.payload;
            sliceWrapper.removeDicomRule(state, id);
        },

        /**
         * Removes a DICOM attribute rule from contouring customization configuration.
         * Throws if the DICOM attribute rule to be removed or its parent DICOM rule are not found from configuration.
         * @param action Internal (entity adapter) ID of the DICOM attribute rule to remove.
         */
        dicomAttributeRuleRemoved(state, action: PayloadAction<string>) {
            const id = action.payload;
            sliceWrapper.removeDicomAttributeRule(state, id);
        },


        /**
         * Reverts changes to contouring customization model selections.
         * @param action.revertScope Defines the scope of undo operations -- either only a partial scope, or the entire current in-memory 
         * model selection set.
         * @param action.id Internal (entity adapter) ID of the item to be reverted in case a non-exhaustive scope is used. Required if
         * revertScope is not 'All'.
         */
        modelSelectionChangesReverted(state, action: PayloadAction<{ revertScope: ModelSelectionScope, id?: string }>) {
            // revert changes to model selection depending on the selected scope
            const { revertScope, id } = action.payload;
            if (revertScope === ModelSelectionScope.All) {
                // revert everything selection-related (except the isModified tag on customization bases and models -- we can't know if those are from selection or
                // customization changes)
                aeTitleRuleAdapter.setAll(state.aeTitleRules, state.originalAeTitleRules.entities as Record<string, AeTitleRule>);
                dicomRuleAdapter.setAll(state.dicomRules, state.originalDicomRules.entities as Record<string, DicomRule>);
                dicomAttributeRuleAdapter.setAll(state.dicomAttributeRules, state.originalDicomAttributeRules.entities as Record<string, DicomAttributeRule>);

                // customization base changes are a bit more involved to revert
                // note that removed customization bases are NOT reverted back in -- use 'revert ALL customizations' for that
                // any new customization base selection rules are REMOVED
                customizationBaseAdapter.updateMany(state.customizationBases, state.customizationBases.ids.map(customizationBaseId => {
                    const originalCustomizationBase = state.originalCustomizationBases.entities[customizationBaseId];
                    return {
                        id: customizationBaseId,
                        changes: {
                            aeTitleRules: originalCustomizationBase?.aeTitleRules || [],
                            dicomRules: originalCustomizationBase?.dicomRules || []
                        }
                    };
                }));
            } else if (id !== undefined) {
                // revert specific items
                // TODO: perform some kind of full modification checks here if we also want to mark parent objects as no longer
                // being modified if the reverted entry was the only modified child object (e.g. mark one modelRules entity as 
                // no longer being modified if its only rule was reverted).
                // TODO 2: reverting new items (i.e. ones that do not exist in originalEntities collections) is not supported.
                // Technically we could either a) remove the item (probably weird) or b) set it to empty, default values
                // (also a bit weird)
                if (revertScope === ModelSelectionScope.AeTitleRule) {
                    const originalAeTitleRule = state.originalAeTitleRules.entities[id];
                    if (originalAeTitleRule === undefined) { throw new Error(`Could not find original AE title rule ${id}`); }
                    aeTitleRuleAdapter.setOne(state.aeTitleRules, originalAeTitleRule);
                } else if (revertScope === ModelSelectionScope.DicomRule) {
                    const originalDicomRule = state.originalDicomRules.entities[id];
                    const currentDicomRule = state.dicomRules.entities[id];
                    if (originalDicomRule === undefined || currentDicomRule === undefined) { throw new Error(`Could not find current/original DICOM rule ${id}`); }

                    // remove any newly added attribute rules
                    dicomAttributeRuleAdapter.removeMany(state.dicomAttributeRules, currentDicomRule.dicomAttributes.filter(da => !originalDicomRule.dicomAttributes.includes(da)));

                    // restore original dicom attribute rules
                    for (const originalDicomAttributeId of originalDicomRule.dicomAttributes) {
                        const originalDicomAttributeRule = state.originalDicomAttributeRules.entities[originalDicomAttributeId];
                        if (originalDicomAttributeRule === undefined) { throw new Error(`Could not find original DICOM attribute rule ${id}`); }
                        dicomAttributeRuleAdapter.setOne(state.dicomAttributeRules, originalDicomAttributeRule);
                    }

                    // restore the original dicom rule itself
                    dicomRuleAdapter.setOne(state.dicomRules, originalDicomRule);
                } else if (revertScope === ModelSelectionScope.DicomAttributeRule) {
                    const originalDicomAttributeRule = state.originalDicomAttributeRules.entities[id];
                    if (originalDicomAttributeRule === undefined) { throw new Error(`Could not find original DICOM attribute rule ${id}`); }
                    dicomAttributeRuleAdapter.setOne(state.dicomAttributeRules, originalDicomAttributeRule);
                }
            } else {
                throw new Error('Invalid scope for undo');
            }
        },





        /**
         * Signals that entire contouring model customization should be saved to backend.
         * @param action Current configuration target (AppClient).
         */
        modelCustomizationSaved(state, action: PayloadAction<MVisionAppClient>) {
            // this is an empty action and is only used for signalling in sagas
        },

        /**
         * Marks contouring customization save as being in progress.
         */
        saveModelCustomizationStarted(state) {
            state.isModelCustomizationSavingInProgress = true;
        },

        /**
         * Marks contouring customization save as having been finished, whether in success or failure.
         * @param action.saveWasSuccessful True if save succeeded, false otherwise.
         * @param action.errorMessage An optional error message if save was unsuccessful,
         * @param action.error An optional backend validation error object if save was unsuccessful.
         */
        saveModelCustomizationFinished(state, action: PayloadAction<{ saveWasSuccessful: boolean, errorMessage?: string, error?: BackendValidationError }>) {
            const { saveWasSuccessful, errorMessage, error } = action.payload;
            state.isModelCustomizationSavingInProgress = false;

            // remove existing backend validation errors -- we may add new ones more in just a moment
            // (DON'T remove FORM validation errors though -- we may have warnings there that still apply)
            backendValidationErrorAdapter.removeAll(state.backendValidationErrors);

            if (saveWasSuccessful) {
                // set everything back to unmodified & store as the new original entities
                Object.values(state.models.entities).forEach(m => { if (m !== undefined) { m.isModified = false } });
                Object.values(state.customizationBases.entities).forEach(c => { if (c !== undefined) { c.isModified = false } });
                Object.values(state.contourOutputs.entities).forEach(c => { if (c !== undefined) { c.isModified = false } });
                Object.values(state.outputMetadata.entities).forEach(md => { if (md !== undefined) { md.isModified = false } });
                Object.values(state.roiCustomizations.entities).forEach(r => { if (r !== undefined) { r.isModified = false } });
                Object.values(state.globalRoiCustomizations.entities).forEach(gr => { if (gr !== undefined) { gr.isModified = false } });
                Object.values(state.aeTitleRules.entities).forEach(r => { if (r !== undefined) { r.isModified = false; r.isNew = false; } });
                Object.values(state.dicomRules.entities).forEach(r => { if (r !== undefined) { r.isModified = false; r.isNew = false; } });
                Object.values(state.dicomAttributeRules.entities).forEach(r => { if (r !== undefined) { r.isModified = false; r.isNew = false; } });

                modelAdapter.setAll(state.originalModels, state.models.entities as Record<string, Model>);
                customizationBaseAdapter.setAll(state.originalCustomizationBases, state.customizationBases.entities as Record<string, CustomizationBase>);
                customizationOutputAdapter.setAll(state.originalCustomizationOutputs, state.contourOutputs.entities as Record<string, ContouringCustomizationOutput>);
                outputMetadataAdapter.setAll(state.originalModelCustomizationsMetadata, state.outputMetadata.entities as Record<string, OutputMetadataItem>);
                roiCustomizationAdapter.setAll(state.originalRoiCustomizations, state.roiCustomizations.entities as Record<string, ContouringRoi>);
                globalRoiCustomizationAdapter.setAll(state.originalGlobalRoiCustomizations, state.globalRoiCustomizations.entities as Record<string, GlobalContouringRoi>);
                aeTitleRuleAdapter.setAll(state.originalAeTitleRules, state.aeTitleRules.entities as Record<string, AeTitleRule>);
                dicomRuleAdapter.setAll(state.originalDicomRules, state.dicomRules.entities as Record<string, DicomRule>);
                dicomAttributeRuleAdapter.setAll(state.originalDicomAttributeRules, state.dicomAttributeRules.entities as Record<string, DicomAttributeRule>);

            }

            state.modelCustomizationSaveError = errorMessage !== undefined ? errorMessage : null;

            // collect save errors
            try {
                if (!saveWasSuccessful && error !== undefined) {
                    const backendValidationErrors = convertBackendValidationErrorToViewModels(state, error);
                    if (backendValidationErrors.length > 0) {
                        // apply new validation errors and override whatever error message we received from sagas
                        backendValidationErrorAdapter.addMany(state.backendValidationErrors, backendValidationErrors);
                        state.modelCustomizationSaveError = 'Could not save customizations because they have invalid entries. Please fix any validation errors and try again.';
                    }
                }
            }
            catch (ex) {
                const message: string | undefined = get(ex, 'message', undefined);
                state.modelCustomizationSaveError = `An error occurred when trying to parse validation error message${message ? `: ${message}` : '.'}`
            }
        },


        /**
         * Export entire current contouring customization into a JSON file.
         * @param action Current configuration target.
         */
        modelCustomizationExported(state, action: PayloadAction<MVisionAppClient | undefined>) {
            // this is an empty action and is only used for signalling in sagas
        },

        /**
         * Imports parsed JSON file contents into current contouring configuration target.
         * @param action Contouring customization to import.
         */
        modelCustomizationImported(state, action: PayloadAction<ContouringCustomizationEntities | null>) {
            // this is an empty action and is only used for signalling in sagas
        },

        /**
         * Marks importing of a JSON file into contouring customization as failed.
         * @param action Error message, or null if error message should be reset.
         */
        modelCustomizationImportFailed(state, action: PayloadAction<string | null>) {
            const errorMessage = action.payload;
            state.customizationImportExportError = errorMessage;
        },

        /**
         * Marks exporting of a JSON file from contouring customization as failed.
         * @param action Error message, or null if error message should be reset.
         */
        modelCustomizationExportFailed(state, action: PayloadAction<string | null>) {
            const errorMessage = action.payload;
            state.customizationImportExportError = errorMessage;
        },


        /** Unit test helper reducer for setting an object and all its ancestors as modified.
         *
         * Do not use this reducer outside of unit tests!
         */
        _unitTest_customizationObjectPathSetAsModified(state, action: PayloadAction<{ objectIds: string[], objectType: CustomizationObjectType }>) {
            const { objectIds, objectType } = action.payload;
            setObjectAndAncestorsAsModified(state, objectIds, objectType);
        },

        /** Unit test helper reducer for adding a form validation error.
         *
         * Do not use this reducer outside of unit tests!
         */
        _unitTest_formValidationErrorAdded(state, action: PayloadAction<FormValidationError>) {
            const formError = action.payload;
            formValidationErrorAdapter.addOne(state.formValidationErrors, formError);
        },

        /** Unit test helper reducer for removing a form validation error.
         * 
         * Do not use this reducer outside of unit tests!
         */
        _unitTest_formValidationErrorRemoved(state, action: PayloadAction<string>) {
            const formErrorId = action.payload;
            formValidationErrorAdapter.removeOne(state.formValidationErrors, formErrorId);
        },

        /** Unit test helper reducer for adding a backend validation error.
         *
         * Do not use this reducer outside of unit tests!
         */
        _unitTest_backendValidationErrorAdded(state, action: PayloadAction<BackendValidationErrorViewModel>) {
            const backendValidationError = action.payload;
            backendValidationErrorAdapter.addOne(state.backendValidationErrors, backendValidationError);
        },

    },
    selectors: {

        /** Returns all contouring models that have their visibility set as Always Hidden. */
        selectUserHiddenModels: createSelector(
            (state: ContouringSliceState): Model[] => localSelectors.selectModels(state),
            models => models.filter(m => m.visibility === ModelVisibility.AlwaysHidden)),

        /** Returns all contouring models that are not in current user's license. */
        selectNonLicensedModels: createSelector(
            (state: ContouringSliceState): Model[] => localSelectors.selectModels(state),
            models => models.filter(m => !m.isAvailable)),

        /** Returns all contouring models that are deprecated. */
        selectDeprecatedModels: createSelector(
            (state: ContouringSliceState): Model[] => localSelectors.selectModels(state),
            models => models.filter(m => m.isDeprecated)),

        /** Returns the top-level model name for a customization output */
        selectModelNameForCustomizationOutput: (state, customizationOutputId: string) => {
            const output = localSelectors.selectOutputById(state, customizationOutputId);
            if (output) {
                const customization = localSelectors.selectCustomizationBaseById(state, output.modelCustomizationBaseId);
                if (customization) {
                    const model = localSelectors.selectModelById(state, customization.modelId);
                    if (model) {
                        return model.modelName;
                    }
                }
            }
        },

        /** Returns true if any model customization object has been modified. */
        selectIsAnyCustomizationModelModified: createSelector(
            (state: ContouringSliceState): Model[] => localSelectors.selectModels(state),
            (models) => models.some(m => m.isModified)),

        /** Returns true if all model selection rules are valid, false if any of them is invalid. */
        selectIsEverySelectionRuleValid: createSelector(
            [
                (state: ContouringSliceState): AeTitleRule[] => localSelectors.selectAeTitleRules(state),
                (state: ContouringSliceState): DicomRule[] => localSelectors.selectDicomRules(state),
                (state: ContouringSliceState): DicomAttributeRule[] => localSelectors.selectDicomAttributeRules(state),

            ],
            (aeTitleRules, dicomRules, dicomAttributeRules) => aeTitleRules.every(r => r.isValid)
                && dicomRules.every(r => r.isValid)
                && dicomAttributeRules.every(r => r.isValid)),

        /** Returns an array of all customization names and their internal parent model IDs. */
        selectAllCustomizationNamesAndParentModelIds: createSelector(
            (state: ContouringSliceState): CustomizationBase[] => localSelectors.selectCustomizationBases(state),
            (customizations) => customizations.map(c => ({ customizationName: c.customizationName, modelId: c.modelId }))),

        /** Returns true if there are ANY unsaved CONTOURING changes in the app. */
        selectAnyUnsavedContouringChangesInApp: (state) => localSelectors.selectIsAnyCustomizationModelModified(state),



        // ENTITY ADAPTER SELECTORS:
        // for all supported createEntityAdapter selectors see https://redux-toolkit.js.org/api/createEntityAdapter#selector-functions

        selectModels: (state): Model[] => modelAdapterSelectors.selectAll(state),
        selectModelEntities: (state) => modelAdapterSelectors.selectEntities(state),
        selectModelIds: (state) => modelAdapterSelectors.selectIds(state),
        selectModelById: (state, id: string): Model | undefined => modelAdapterSelectors.selectById(state, id),
        selectModelsInAlphabeticalOrder: createSelector(
            [(state: ContouringSliceState): Model[] => localSelectors.selectModels(state)],
            models => naturalSort(models, 'modelName')),


        selectCustomizationBases: (state) => customizationBaseAdapterSelectors.selectAll(state),
        selectCustomizationBaseIds: (state) => customizationBaseAdapterSelectors.selectIds(state),
        selectCustomizationBaseById: (state, id: string): CustomizationBase | undefined => customizationBaseAdapterSelectors.selectById(state, id),
        selectCustomizationBaseEntities: (state) => customizationBaseAdapterSelectors.selectEntities(state),
        selectCustomizationBasesInAlphabeticalOrder: createSelector(
            [(state: ContouringSliceState): CustomizationBase[] => localSelectors.selectCustomizationBases(state)],
            bases => naturalSort(bases, 'customizationName')),

        selectOutputs: (state) => customizationOutputAdapterSelectors.selectAll(state),
        selectOutputIds: (state) => customizationOutputAdapterSelectors.selectIds(state),
        selectOutputEntities: (state) => customizationOutputAdapterSelectors.selectEntities(state),
        selectOutputById: (state, id: string): ContouringCustomizationOutput | undefined => customizationOutputAdapterSelectors.selectById(state, id),

        selectOutputMetadata: (state) => outputMetadataAdapterSelectors.selectAll(state),
        selectOutputMetadataById: (state, id: string): OutputMetadataItem | undefined => outputMetadataAdapterSelectors.selectById(state, id),
        selectOutputMetadataEntities: (state) => outputMetadataAdapterSelectors.selectEntities(state),
        selectOriginalOutputMetadataById: (state, id: string): OutputMetadataItem | undefined => originalOutputMetadataAdapterSelectors.selectById(state, id),
        selectOriginalOutputMetadataEntities: (state) => originalOutputMetadataAdapterSelectors.selectEntities(state),

        selectRois: (state) => roiCustomizationAdapterSelectors.selectAll(state),
        selectRoiById: (state, id: string): ContouringRoi | undefined => roiCustomizationAdapterSelectors.selectById(state, id),
        selectRoiEntities: (state) => roiCustomizationAdapterSelectors.selectEntities(state),
        selectOriginalRoiById: (state, id: string): ContouringRoi | undefined => originalRoiCustomizationAdapterSelectors.selectById(state, id),

        selectGlobalRois: (state) => globalRoiCustomizationAdapterSelectors.selectAll(state),
        selectGlobalRoiById: (state, id: string): GlobalContouringRoi | undefined => globalRoiCustomizationAdapterSelectors.selectById(state, id),
        selectGlobalRoiIds: (state) => globalRoiCustomizationAdapterSelectors.selectIds(state),

        selectAeTitleRules: (state) => aeTitleRuleAdapterSelectors.selectAll(state),
        selectAeTitleRuleEntities: (state) => aeTitleRuleAdapterSelectors.selectEntities(state),
        selectAeTitleRuleById: (state, id: string): AeTitleRule | undefined => aeTitleRuleAdapterSelectors.selectById(state, id),

        selectDicomRules: (state) => dicomRuleAdapterSelectors.selectAll(state),
        selectDicomRuleEntities: (state) => dicomRuleAdapterSelectors.selectEntities(state),
        selectDicomRuleById: (state, id: string): DicomRule | undefined => dicomRuleAdapterSelectors.selectById(state, id),

        selectDicomAttributeRules: (state) => dicomAttributeRuleAdapterSelectors.selectAll(state),
        selectDicomAttributeRuleById: (state, id: string): DicomAttributeRule | undefined => dicomAttributeRuleAdapterSelectors.selectById(state, id),

        selectCustomizationValidationError: (state, id: string): BackendValidationErrorViewModel | undefined => customizationValidationErrorAdapterSelectors.selectById(state, id),
        selectCustomizationValidationErrorEntities: (state) => customizationValidationErrorAdapterSelectors.selectEntities(state),

        selectFormValidationErrors: (state) => formValidationErrorAdapterSelectors.selectAll(state),
    }
});




export const {
    modelCustomizationsSet,
    roiCustomizationColorUpdated,
    roiCustomizationIncludedInModelUpdated,
    roiCustomizationNameUpdated,
    roiCustomizationOperationUpdated,
    roiInterpretedTypeUpdated,
    roiCodingSchemeUpdated,
    roiPhysicalPropertyUpdated,
    advancedRoiCustomizationAdded,
    advancedRoiCustomizationRemoved,
    roiCustomizationChangedToMatchGlobalRoi,
    roiCustomizationMadeIntoGlobalRoi,
    roiCodingSchemeCopied,
    roiCodingSchemeCopiedToOutput,
    modelCustomizationRenamed,
    customizationBaseRemoved,
    customizationOutputRemoved,
    modelCustomizationDescriptionUpdated,
    modelVisibilityUpdated,
    metadataItemAdded,
    metadataItemUpdated,
    metadataItemRemoved,
    allRoisInCustomizationOutputToggled,
    allModelCustomizationsReset,
    singleModelCustomizationReset,
    resetAllModelCustomizationsStarted,
    resetAllModelCustomizationsFinished,
    resetSingleCustomizationOutputStarted,
    resetSingleCustomizationOutputFinished,
    modelCustomizationOutputReplaced,
    roiCustomizationChangesReverted,
    allCustomizationChangesReverted,
    modelCustomizationObjectsSetAsModified,
    customizationBaseDuplicated,
    customizationOutputDuplicated,
    customizationDuplicationItemsAdded,
    outputDuplicationItemsAdded,
    scrollToViewFromRoiCustomizationRemoved,
    scrollToViewFromModelCustomizationMetadataRemoved,
    aeTitleRuleAdded,
    aeTitleRuleActionUpdated,
    aeTitleRuleRemoved,
    dicomRuleAdded,
    dicomAttributeRuleAdded,
    dicomAttributeRuleUpdated,
    dicomRuleRemoved,
    dicomAttributeRuleRemoved,
    modelSelectionChangesReverted,
    modelCustomizationSaved,
    saveModelCustomizationStarted,
    saveModelCustomizationFinished,
    modelCustomizationExported,
    modelCustomizationImported,
    modelCustomizationImportFailed,
    modelCustomizationExportFailed,

    _unitTest_customizationObjectPathSetAsModified,
    _unitTest_formValidationErrorAdded,
    _unitTest_formValidationErrorRemoved,
    _unitTest_backendValidationErrorAdded,

} = contouringSlice.actions;

const localSelectors = contouringSlice.getSelectors();

export const { getInitialState, selectors: contouringSelectors, actions } = contouringSlice;

export default contouringSlice.reducer;
