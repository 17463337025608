import { isArray, isBoolean } from "lodash-es";
import { BackendTier } from "../auth/auth";
import { ModelType } from "../global-types/customization-types";

export const DEPLOYMENT_CONFIGURATION_FILE_NAME = 'config.json';

/** Deployment mode of this entire Configuration UI environment -- either cloud or local. */
export enum DeploymentMode {
    NotSet = 'NotSet',
    Cockpit = 'cockpit',
    Cloud = 'cloud',
    Local = 'local',
}

export type DocumentLink = {
    label: string;
    url: string;
}

export enum DisabledFeature {
    AccessRights = 'AccessRights',
}

export enum SupportedModelType {
    Contour = 'Contour',
    Dose = 'Dose'
}

export const convertSupportedModelTypeToActualModelType = (supportedModelType: SupportedModelType) => {
    switch (supportedModelType) {
        case SupportedModelType.Contour:
            return ModelType.Contouring;
        case SupportedModelType.Dose:
            return ModelType.Dose;
        default:
            throw new Error(`Invalid supported model type: ${supportedModelType}`);
    }
}

/**
 * Deployment configuration file. Is placed into a MVision Configuration deployment and is used to configure particulars of that deployment.
 * File is read upon app initialization and is used to set up some fundamental app configurations.
 */
export type DeploymentConfigInfo = {
    /** Deployment mode of this app deployment/installation. */
    deploymentMode: DeploymentMode,
    /** The URL in which a CLOUD or LOCAL backend is located, including any required port number. Not used in cockpit mode. */
    backendUrl?: string;
    /** The tier of the cloud backend.  Not used in local or cockpit mode. */
    cloudBackendTier?: BackendTier;
    /** Port number (as string) used in cockpit environment. Not used in cloud mode. */
    cockpitBackendPort?: string;
    /** Folder tag used for fetching certain files in cockpit environments. Not used in cloud mode. */
    cockpitEnvironmentTag?: string;
    /** URL linking to a dashboard deployment. */
    dashboardUrl?: string;
    /** URL linking to a regex website. */
    regexHelpUrl?: string;
    /** File for help with regex writing */
    regexHelpFile?: string;
    /** Optional name for the backend. Not used in UI as the app doesn't currently support multiple backends. Not used in local mode. */
    backendName?: string;
    /** Link to a ROI customization operations manual. */
    operationsManualUrl?: string;
    /** Omit any localizations from UI that match these lang keys (e.g.: ['en-US', 'fr-FR', 'pseudo']) */
    omitLocalizationsFromUi?: string[];
    /** Daemon config page can optionally be disabled altogether. */
    disableDaemonConfigPage: boolean;
    /** List of document links to be shown in the UI. */
    documentLinks?: DocumentLink[];
    /** List of features that are disabled in this deployment. */
    disabledFeatures: DisabledFeature[];
    /** List of model types that are supported in this deployment. */
    supportedModelTypes: SupportedModelType[];
}


// Function to validate if an object is a valid DocumentLink
function isValidDocumentLink(link: any): link is DocumentLink {
    return (
        typeof link === 'object' &&
        'label' in link && typeof link.label === 'string' &&
        'url' in link && typeof link.url === 'string'
    );
}

/** Returns true if queried feature is DISABLED in current deployment, false if it is ENABLED. */
export const isFeatureDisabled = (config: DeploymentConfigInfo, feature: DisabledFeature): boolean => {
    return config.disabledFeatures.includes(feature);
}

export const generateDeploymentConfigInfo = (jsonObject: any): DeploymentConfigInfo | undefined => {
    if ('deploymentMode' in jsonObject && Object.values(DeploymentMode).includes(jsonObject['deploymentMode'])) {

        const deploymentConfigInfo: DeploymentConfigInfo = {
            deploymentMode: jsonObject['deploymentMode'],
            disableDaemonConfigPage: false,
            disabledFeatures: [],

            // support all model types if nothing else has been specified
            supportedModelTypes: [SupportedModelType.Contour, SupportedModelType.Dose],
        }

        if ('backendUrl' in jsonObject) {
            deploymentConfigInfo.backendUrl = jsonObject['backendUrl'];
        }
        if ('cloudBackendTier' in jsonObject && Object.values(BackendTier).includes(jsonObject['cloudBackendTier'])) {
            deploymentConfigInfo.cloudBackendTier = jsonObject['cloudBackendTier'];
        }
        if ('cockpitBackendPort' in jsonObject) {
            deploymentConfigInfo.cockpitBackendPort = jsonObject['cockpitBackendPort'];
        }
        if ('cockpitEnvironmentTag' in jsonObject) {
            deploymentConfigInfo.cockpitEnvironmentTag = jsonObject['cockpitEnvironmentTag'];
        }
        if ('dashboardUrl' in jsonObject) {
            deploymentConfigInfo.dashboardUrl = jsonObject['dashboardUrl'];
        }
        if ('backendName' in jsonObject) {
            deploymentConfigInfo.backendName = jsonObject['backendName'];
        }
        if ('regexHelpUrl' in jsonObject) {
            deploymentConfigInfo.regexHelpUrl = jsonObject['regexHelpUrl'];
        }
        if ('regexHelpFile' in jsonObject) {
            deploymentConfigInfo.regexHelpFile = jsonObject['regexHelpFile'];
        }
        if ('operationsManualUrl' in jsonObject) {
            deploymentConfigInfo.operationsManualUrl = jsonObject['operationsManualUrl'];
        }
        if ('omitLocalizationsFromUi' in jsonObject) {
            deploymentConfigInfo.omitLocalizationsFromUi = jsonObject['omitLocalizationsFromUi'];
        }
        if ('disableDaemonConfigPage' in jsonObject) {
            // although daemon config page is NOT disabled by default, in an unclear situation it will get disabled
            deploymentConfigInfo.disableDaemonConfigPage = isBoolean(jsonObject['disableDaemonConfigPage']) ? jsonObject['disableDaemonConfigPage'] : true;
        }

        // check for features that have been disabled in config file
        if ('disabledFeatures' in jsonObject && isArray(jsonObject['disabledFeatures'])) {
            const disabledFeatures: DisabledFeature[] = [];
            for (const disabledFeature of jsonObject['disabledFeatures']) {
                if (Object.values(DisabledFeature).includes(disabledFeature)) {
                    disabledFeatures.push(disabledFeature);
                }
            }
            deploymentConfigInfo.disabledFeatures = disabledFeatures;
        }

        // check for supported model types in config file
        if ('supportedModelTypes' in jsonObject && isArray(jsonObject['supportedModelTypes'])) {
            const supportedModelTypes: SupportedModelType[] = [];
            for (const supportedModelType of jsonObject['supportedModelTypes']) {
                if (Object.values(SupportedModelType).includes(supportedModelType)) {
                    supportedModelTypes.push(supportedModelType);
                }
            }

            // use default supported model types if the array was empty
            if (supportedModelTypes.length > 0) {
                deploymentConfigInfo.supportedModelTypes = supportedModelTypes;
            }
        }

        if ('documentLinks' in jsonObject && Array.isArray(jsonObject['documentLinks'])) {
            const validLinks = [];

            for (const link of jsonObject['documentLinks']) {
                if (isValidDocumentLink(link)) {
                    validLinks.push(link);
                }
            }
            // Assign only valid links to deploymentConfigInfo.documentLinks
            deploymentConfigInfo.documentLinks = validLinks;
        }

        // ensure required fields have been set depending on deployment mode
        if (deploymentConfigInfo.deploymentMode === DeploymentMode.Cloud) {
            if (!deploymentConfigInfo.backendUrl) { throw new Error('backendUrl not specified in configuration'); }
            if (!deploymentConfigInfo.cloudBackendTier) { throw new Error('cloudBackendTier not specified in configuration'); }
        }
        else if (deploymentConfigInfo.deploymentMode === DeploymentMode.Local) {
            if (!deploymentConfigInfo.backendUrl) { throw new Error('backendUrl not specified in configuration'); }
        }
        else if (deploymentConfigInfo.deploymentMode === DeploymentMode.Cockpit) {
            if (!deploymentConfigInfo.cockpitBackendPort) { throw new Error('cockpitBackendPort not specified in configuration'); }
            if (!deploymentConfigInfo.cockpitEnvironmentTag) { throw new Error('cockpitEnvironmentTag not specified in configuration'); }
        }

        return deploymentConfigInfo;
    }

    throw new Error('No supported deployment set in config.json.');
}
