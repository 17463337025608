import { Button, Modal, ModalVariant, Stack, TextArea } from "@patternfly/react-core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { appConfigSelectors } from "../store/appConfig/appConfigSlice";
import RegexHelp from "./regex-help";

interface RegexFieldModalProps {
    isOpen: boolean,
    onConfirm: (text: string) => void,
    onClose: () => void,
    regex?: string,
}

const RegexFieldModal = (props: RegexFieldModalProps) => {
    const { isOpen, onConfirm, onClose, regex } = props;
    const { t } = useTranslation();
    
    const deploymentConfigInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);

    const [text, setText] = useState<string>(regex || '');

    // update the internal value here when regex is adjusted in the parent component
    useEffect(() => {
        setText(regex || '');
    }, [regex])

    const handleConfirmClick = useCallback(() => {
        const parsedText = text.replace(/[\n\r]/g, '').trim();
        onConfirm(parsedText);
        onClose();
    }, [onConfirm, onClose, text]);

    const handleCancel = useCallback(() => {
        // revert text back to input value on cancel
        setText(regex || '');
        onClose();
    }, [onClose, regex]);

    const handleTextChange = useCallback((_: unknown, value: string) => {
        setText(value);
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            variant={ModalVariant.medium}
            title={t('regex.modal.title')}
            actions={[
                <Button key="confirm" variant="primary" onClick={handleConfirmClick}>{t('common.ok')}</Button>,
                <Button key="cancel" variant="tertiary" onClick={handleCancel}>{t('common.cancel')}</Button>,
                <RegexHelp key="helpIcon" deploymentConfigInfo={deploymentConfigInfo} regexAlwaysOn />
            ]}
            showClose={false}
        >
            <Stack hasGutter>
            </Stack>
            <TextArea aria-label="text area for regex pattern" width="auto" rows={4} onChange={handleTextChange} defaultValue={text} resizeOrientation='vertical' />
        </Modal>
    );
}

export default RegexFieldModal;
