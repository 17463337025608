import { useTranslation } from "react-i18next";

interface IsMandatoryProps {
    isMandatory: boolean,
}

const IsMandatory = (props: IsMandatoryProps) => {
    const { isMandatory } = props;

    const { t } = useTranslation();

    return (
        <div className="dose-roi-is-mandatory-switch">
            {isMandatory ?
                (<div className="is-mandatory" title={t('customizationPage.dose.roi.isMandatory.on.tooltip')}>
                    <span className="is-mandatory-icon">*</span> <span className="is-mandatory-text">{t('customizationPage.dose.roi.isMandatory.on')}</span>
                </div>) :
                (<div className="is-not-mandatory" title={t('customizationPage.dose.roi.isMandatory.off.tooltip')}>&nbsp;</div>)
            }
        </div>
    );

}

export default IsMandatory;
