import React, { memo } from 'react';
import { ExclamationCircleIcon } from '@patternfly/react-icons'
import { Badge } from '@patternfly/react-core';

import './customization-page.css';

interface SectionLabelProps {
    isValid: boolean,
    isModified: boolean,
    itemCount?: number,
    label: string,
}

const SectionLabel = (props: SectionLabelProps) => {
    const { isValid, isModified, itemCount, label } = props;
    return (
        <span className={`customization-section-header ${isValid ? '' : 'validation-error'}`}>
            <span>
                {!isValid && <span className="validation-icon"><ExclamationCircleIcon /></span>}
                <span className="header-title">{label}</span>
                {isModified && <span className="modified"> *</span>}
            </span>
            {itemCount !== undefined && (<span className="item-count"><Badge isRead>{itemCount}</Badge></span>)}
        </span>
    )
}

export default memo(SectionLabel);
