import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../customization-page.css';
import { CustomizationObjectType, ModelType } from '../../../store/global-types/customization-types';
import { MVisionAppClient } from '../../../store/configurationTarget/mvision-client-list';
import { StoreState } from '../../../store/store';
import CustomizationToolbar from '../CustomizationToolbar';
import { allCustomizationChangesReverted, customizationBaseDuplicated, customizationBaseRemoved, customizationRenamed, doseSelectors, modelCustomizationSaved, singleCustomizationReset } from '../../../store/dose/doseSlice';

interface DoseCustomizationToolbarProps {
    customizationOutputId?: string,
    currentView: CustomizationObjectType,
}

const DoseCustomizationToolbar = (props: DoseCustomizationToolbarProps) => {
    const { customizationOutputId, currentView } = props;

    const dispatch = useDispatch();

    const modelCustomizationSaveError = useSelector((state: StoreState) => state.dose.modelCustomizationSaveError);
    const modelCustomizationDataError = useSelector((state: StoreState) => state.dose.modelCustomizationDataError);
    const isSavingInProgress = useSelector((state: StoreState) => state.dose.isModelCustomizationSavingInProgress);

    // needed for duplicated model name validation
    const allCustomizationNamesAndModelIds = useSelector(doseSelectors.selectAllCustomizationNamesAndParentModelIds);

    const isAnyCustomizationModelModified = useSelector(doseSelectors.selectIsAnyCustomizationModelModified);

    const customizationOutput = useSelector((state: StoreState) => customizationOutputId ? doseSelectors.selectOutputById(state, customizationOutputId) : undefined);
    const customizationBase = useSelector((state: StoreState) => customizationOutput ? doseSelectors.selectCustomizationBaseById(state, customizationOutput.modelCustomizationBaseId) : undefined);
    const segmentationModel = useSelector((state: StoreState) => customizationBase ? doseSelectors.selectModelById(state, customizationBase.modelId) : undefined);
    const isCustomizationOutputResetInProgress = useSelector((state: StoreState) => state.dose.isOutputResetInProgress);

    const handleUndo = useCallback(() => {
        dispatch(allCustomizationChangesReverted());
    }, [dispatch]);

    const handleSave = useCallback((currentTarget: MVisionAppClient) => {
        dispatch(modelCustomizationSaved(currentTarget));
    }, [dispatch]);

    const handleAddNewCustomization = useCallback((customizationBaseId: string, newCustomizationName: string) => {
        dispatch(customizationBaseDuplicated({ customizationBaseId, newCustomizationName }));
    }, [dispatch]);

    const handleCustomizationDelete = useCallback((customizationBaseId: string) => {
        dispatch(customizationBaseRemoved(customizationBaseId));
    }, [dispatch]);

    const handleOutputReset = useCallback((customizationOutputId: string) => {
        dispatch(singleCustomizationReset(customizationOutputId));
    }, [dispatch]);

    const handleRenameCustomization = useCallback((customizationBaseId: string, newCustomizationName: string) => {
        dispatch(customizationRenamed({ customizationBaseId, name: newCustomizationName }));
    }, [dispatch]);

    return (
        <CustomizationToolbar
            currentView={currentView}
            customizationOutputId={customizationOutputId}
            modelType={ModelType.Dose}

            allCustomizationNamesAndModelIds={allCustomizationNamesAndModelIds}
            isAnyCustomizationModelModified={isAnyCustomizationModelModified}
            customizationOutput={customizationOutput}
            customizationBase={customizationBase}
            model={segmentationModel}

            modelCustomizationSaveError={modelCustomizationSaveError}
            modelCustomizationDataError={modelCustomizationDataError}
            isSavingInProgress={isSavingInProgress}
            // intentionally use "output reset" state here in place of "customization reset state" -- under the hood
            // it calls the exact same API 
            isCustomizationResetInProgress={isCustomizationOutputResetInProgress}
            isCustomizationOutputResetInProgress={false}

            onUndo={handleUndo}
            onSave={handleSave}
            onAddNewCustomization={handleAddNewCustomization}
            onDeleteCustomization={handleCustomizationDelete}
            onResetCustomization={handleOutputReset}
            onRenameCustomization={handleRenameCustomization}
        />
    );
}

export default DoseCustomizationToolbar;
