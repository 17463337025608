import { Dispatch } from "@reduxjs/toolkit";
import NoAuthPage from "../../pages/auth/NoAuthPage";
import BackendApiInterface, { QUERY_PARAM_SESSION_ID } from "../backend-api-interface";
import { configurationTargetListFetched } from "../../store/configurationTarget/configurationTargetSlice";
import CloudApi from "./cloud-api";
import { BackendClient, BackendFetchOptions, defaultBackendFetchOptions } from "../cloud-backend-auth";
import { appConfigSelectors } from "../../store/appConfig/appConfigSlice";
import store from "../../store/store";
import { Backend } from "../../store/auth/auth";
import AppAuth from "../app-auth";
import { get, has, set } from "lodash-es";
import { timeoutSignal } from "../../util/timeout-signal";
import { DISPLAY_VERSION, getDefaultAppName, getSessionId } from "../../environments";
import { sleep } from "../../util/sleep";


export default class LocalApi extends CloudApi implements BackendApiInterface {

    getAuthBackendClient(): BackendClient {
        const backend = appConfigSelectors.selectBackend(store.getState());
        if (backend === undefined || backend.tier !== undefined) {
            throw new Error('No valid local backend configured.');
        }

        return new LocalBackendClient(backend);
    }

    public async init(dispatch: Dispatch) {

        // we don't need to do login here, but we do need to fetch the client list
        dispatch(configurationTargetListFetched());
    }

    getAppWrapperComponent(): (props: React.PropsWithChildren<{}>) => JSX.Element {
        return NoAuthPage;
    }
}

class LocalBackendClient extends BackendClient {

    constructor(backend: Backend) {

        // call super constructor with a dummy AppAuth -- it won't be used for anything
        super(backend, new AppAuth('N/A', 'N/A'));
    }

    async fetch(partialUrl: string, httpRequestOptions: any = undefined, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<Response> {
        const fullUrl = this.backend.url + partialUrl;
        return await this.doFetch(fullUrl, httpRequestOptions, backendFetchOptions);
    }

    async get(partialUrl: string, httpRequestOptions: any = undefined, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<Response> {
        const optionsWithGetMethod = set(httpRequestOptions || {}, 'method', 'get');
        return await this.fetch(partialUrl, optionsWithGetMethod, backendFetchOptions);
    }

    async post(partialUrl: string, httpRequestOptions: any = undefined, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<Response> {
        const optionsWithPostMethod = set(httpRequestOptions || {}, 'method', 'post');
        return await this.fetch(partialUrl, optionsWithPostMethod, backendFetchOptions);
    }

    async quickFetch(partialUrl: string, httpRequestOptions: any, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<any> {
        return this.fetch(partialUrl, httpRequestOptions, backendFetchOptions)
            .then(async response => backendFetchOptions.asText ? await response.text() : await response.json())
            .catch(error => {
                const errorMessage = `Error calling quickFetch on ${partialUrl} (${this.name}):`;
                console.log(errorMessage);
                console.log(error);
                throw new Error(errorMessage);
            });
    }

    // main fetch function -- basically copy-paste from app-auth.ts
    // TODO: consider a more DRY solution
    async doFetch(url: string, httpRequestOptions: any = undefined, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<Response> {

        const backendOptions = defaultBackendFetchOptions(backendFetchOptions);
    
        const fetchOptions = httpRequestOptions || {};
    
        if (!backendOptions.allowCache) {
          set(fetchOptions, 'headers.Cache-Control', 'no-store');
          set(fetchOptions, 'cache', 'no-store');
          set(fetchOptions, 'headers.pragma', 'no-cache');
        }
    
        if (backendOptions.fetchTimeoutInMs !== undefined) {
          set(fetchOptions, 'signal', timeoutSignal(backendOptions.fetchTimeoutInMs));
        }
    
        // set content type as JSON if it's not already set & we're POSTing JSON
        if (backendFetchOptions.postJson && !has(fetchOptions, 'headers.Content-Type')) {
          set(fetchOptions, 'headers.Content-Type', 'application/json');
        }
    
        // set app version so backend knows which app and which version of it is sending the request
        const appVersionHack = 'LOCAL_MODE_TEST'
        set(fetchOptions, 'headers.appVersion', `${getDefaultAppName()}/${DISPLAY_VERSION}/${appVersionHack || 'N/A'}`);
    
        const fullUrl = new URL(url);
    
        if (!backendFetchOptions.noClientId) {
          // append client ID to query parameters
          const clientIdQueryParam = `${QUERY_PARAM_SESSION_ID}=${getSessionId()}`;
          fullUrl.search = fullUrl.search ? `${fullUrl.search}&${clientIdQueryParam}` : clientIdQueryParam;
        }
    
        let currentRetry = 0;
        while (true) {
          try {
            return await fetch(fullUrl.toString(), fetchOptions);
          } catch (err) {
            console.log(`An error occurred when trying to fetch from ${url}`);
            console.log('Fetch options:');
            console.log(fetchOptions);
            console.log('Fetch error:');
            console.log(err);
    
            if (backendOptions.maxRetries > currentRetry) {
              await sleep(backendOptions.retryWaitInMs);
              currentRetry++;
              console.warn(`Attempting retry ${currentRetry}/${backendOptions.maxRetries} to ${url}`)
            } else {
              if (fetchOptions && get(fetchOptions, 'signal.reason.name', undefined) === 'TimeoutError') {
                throw new Error('Request timed out.');
              }
    
              throw err;
            }
          }
        }
      }
}
