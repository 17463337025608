import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { appConfigSelectors } from "../../store/appConfig/appConfigSlice";
import { convertSupportedModelTypeToActualModelType, SupportedModelType } from "../../store/appConfig/appDeploymentInfoTypes";
import HomePage from "../home/HomePage";
import { currentAppModelTypeSet } from "../../store/appStatus/appStatusSlice";

interface SupportedModelTypePageWrapperProps {
    supportedModelType: SupportedModelType;
}

/**
 * This component wraps a model type-specific page (such as ContourCustomizationPage) and blocks it if
 * current app deployment does not support the matching model type.
 * 
 */
const SupportedModelTypePageWrapper = (props: React.PropsWithChildren<SupportedModelTypePageWrapperProps>) => {

    const { supportedModelType } = props;
    const dispatch = useDispatch();

    const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);
    const isModelTypeSupported = useMemo(() => deploymentInfo?.supportedModelTypes.includes(supportedModelType) || false, [supportedModelType, deploymentInfo]);

    // save the model type user is currently accessing (if supported) to store
    // (mainly so user settings dialog can show correct model type tab by default)
    useEffect(() => {
        if (isModelTypeSupported) {
            const modelType = convertSupportedModelTypeToActualModelType(supportedModelType);
            dispatch(currentAppModelTypeSet(modelType));
        }
    }, [supportedModelType]);

    // app deployment info is not loaded in yet, don't show anything
    if (!deploymentInfo) {
        return null;
    }

    // current model type is not supported, show home page instead
    if (!isModelTypeSupported) {
        return (<HomePage />);
    }

    // current model type is supported, render the child components normally
    return (<>{props.children}</>);
}

export default SupportedModelTypePageWrapper;
