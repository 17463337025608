import { DataListItem, DataListItemRow, DataListItemCells, DataListCell, Stack, StackItem, Toolbar, ToolbarContent, ToolbarItem, Button, Form, DataList } from '@patternfly/react-core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '@patternfly/react-icons';

import { ModelSelectionUndoState } from './ModelSelectionPage';
import CustomizationLabel from '../../components/customization-label';
import { ModelType } from '../../store/global-types/customization-types';

interface SelectionRulesFormProps {
    modelType: ModelType,
    aeTitleRuleIds: string[],
    dicomRuleIds: string[],
    modelName: string,
    modelLabel: string,
    customizationName: string,
    isCustomizationBaseModified: boolean,
    onAeTitleRuleAdded: () => void,
    onDicomRuleAdded: () => void,
    setUndoState: (undoState: ModelSelectionUndoState) => void,
    heading: string,
    hideCustomizationName?: boolean,
    showMultipleOutputsWarning: boolean,
    /** If true, don't show undo UI options. */
    isUndoDisabled?: boolean,
    getAeTitleRule: (id: string, runningNumber: number) => JSX.Element,
    getDicomRule: (id: string, runningNumber: number) => JSX.Element,
    isDicomSelectionDisabled?: boolean,
}


const SelectionRulesForm = (props: SelectionRulesFormProps) => {
    const { modelType, aeTitleRuleIds, dicomRuleIds, modelName, modelLabel, customizationName, onAeTitleRuleAdded, onDicomRuleAdded,
        isCustomizationBaseModified, heading, hideCustomizationName, showMultipleOutputsWarning,
        getAeTitleRule, getDicomRule, isDicomSelectionDisabled } = props;

    const { t } = useTranslation();


    const handleAddAeTitleRuleClick = useCallback(() => {
        onAeTitleRuleAdded();
    }, [onAeTitleRuleAdded]);

    const handleAddDicomRuleClick = useCallback(() => {
        onDicomRuleAdded();
    }, [onDicomRuleAdded]);

    if (modelType !== ModelType.Contouring && modelType !== ModelType.Dose) {
        throw new Error(`Unsupported model type: ${modelType}`);
    }


    const dataListCells = [
        <DataListCell key="modelRules" width={4}>
            <div>
                <Form>
                    <Stack>
                        {showMultipleOutputsWarning && (
                            <div className="multiple-outputs-warning">
                                Note: This customization contains multiple target files. Adjusting the selection triggers here will affect the entire
                                customization <span className="multiple-outputs-base">"{modelName}.{customizationName}"</span>, not just this target file.
                            </div>
                        )}
                        <div>
                            {aeTitleRuleIds.map((aeTitleRuleId, i) => (getAeTitleRule(aeTitleRuleId, i + 1)))}
                        </div>
                        <div>
                            {dicomRuleIds.map((dicomRuleId, i) => (getDicomRule(dicomRuleId, i + 1)))}
                        </div>
                        <StackItem className="new-rule-toolbar model-selection-toolbar">
                            <Toolbar>
                                <ToolbarContent>
                                    {!isDicomSelectionDisabled &&
                                        <ToolbarItem>
                                            <Button
                                                size="sm"
                                                variant="secondary"
                                                icon={<PlusIcon />}
                                                // isDisabled={isToolbarDisabled}
                                                onClick={handleAddDicomRuleClick}>{t('selectionPage.addDicomRule')}</Button>
                                        </ToolbarItem>}
                                    <ToolbarItem>
                                        <Button
                                            size="sm"
                                            variant="tertiary"
                                            style={{ color: 'white' }}
                                            icon={<PlusIcon />}
                                            // isDisabled={isToolbarDisabled}
                                            className="save-changes-button"
                                            onClick={handleAddAeTitleRuleClick}>{t('selectionPage.addAeTitleRule')}</Button>
                                    </ToolbarItem>
                                </ToolbarContent>
                            </Toolbar>
                        </StackItem>
                    </Stack>
                </Form>
            </div>
        </DataListCell>
    ];

    if (!hideCustomizationName) {
        dataListCells.unshift(
            <DataListCell key="modelName" width={1}>
                <div className="selection-customization-label">
                    <CustomizationLabel friendlyName={modelLabel} modelName={modelName} customizationName={customizationName} isModified={isCustomizationBaseModified} />
                </div>
            </DataListCell>
        );
    }

    return (
        <DataList aria-label={`Model selection configuration`} isCompact>
            <div id={heading} className="customization-rule-jump-heading"></div>
            <DataListItem aria-labelledby={`Model selection rules for customization ${customizationName}`}>
                <DataListItemRow className="model-selection-model-rules">
                    <DataListItemCells dataListCells={dataListCells} />
                </DataListItemRow>
            </DataListItem>
        </DataList>
    );
}

export default SelectionRulesForm;
