import { DataList, FormGroup, InputGroup, Switch, Radio } from '@patternfly/react-core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../store/store';
import { doseScalingDoseSet, doseScalingMethodSet, doseScalingVolumeSet, doseSelectors, outputIsBeamIncludedSet, outputIsRTPlanIncludedSet, outputMachineNameSet, outputMachineTypeSet, pixelSpacingSet, doseCroppingValueSet } from '../../../store/dose/doseSlice';
import DoseOutputSection from './DoseOutputSection';
import DoseOutputSectionItem from './DoseOutputSectionItem';
import { DoseScalingMethod } from '../../../store/dose/dose-types';
import NumberInput from '../../../components/number-input';
import MachineTypeSelect from './MachineTypeSelect';
import TextOverrideField from '../../../components/text-override-field';
import { createLength, getLengthInCm, LengthUnit } from '../../../store/global-types/units';
import DoseOutputSectionHelpText from './DoseOutputSectionHelpText';

interface DoseOutputFormProps {
    outputId: string,
}

const DoseOutputForm = (props: DoseOutputFormProps) => {

    const { outputId } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const output = useSelector((state: StoreState) => doseSelectors.selectOutputById(state, outputId));

    const handleSetDoseScalingMethodToVolume = useCallback(() => {
        dispatch(doseScalingMethodSet({ id: outputId, method: DoseScalingMethod.Volume }));
    }, [dispatch, outputId]);

    const handleSetDoseScalingMethodToMean = useCallback(() => {
        dispatch(doseScalingMethodSet({ id: outputId, method: DoseScalingMethod.Mean }));
    }, [dispatch, outputId]);

    const handleDoseScalingVolumeMethodVolumeChanged = useCallback((value: number) => {
        dispatch(doseScalingVolumeSet({ id: outputId, volume: value }));
    }, [dispatch, outputId]);

    const handleDoseScalingVolumeMethodDoseChanged = useCallback((value: number) => {
        dispatch(doseScalingDoseSet({ id: outputId, dose: value }));
    }, [dispatch, outputId]);

    const handleDoseScalingMeanMethodDoseChanged = useCallback((value: number) => {
        dispatch(doseScalingDoseSet({ id: outputId, dose: value }));
    }, [dispatch, outputId]);

    const handlePixelSpacingChanged = useCallback((value: number) => {
        dispatch(pixelSpacingSet({ id: outputId, pixelSpacing: createLength(value, LengthUnit.LengthInCm) }));
    }, [dispatch, outputId]);

    const handleZDoseCroppingValueChanged = useCallback((value: number) => {
        if (!output) { throw new Error('Dose output is undefined'); }
        dispatch(doseCroppingValueSet({ id: outputId, zMargin: createLength(value, LengthUnit.LengthInCm) }));
    }, [dispatch, outputId]);

    const handleIsRTPlanIncludedToggled = useCallback(() => {
        if (!output) { throw new Error('Dose output is undefined'); }
        dispatch(outputIsRTPlanIncludedSet({ id: outputId, isIncluded: !output.isRTPlanIncluded }));
    }, [dispatch, outputId, output]);

    const handleIsBeamIncludedToggled = useCallback(() => {
        if (!output) { throw new Error('Dose output is undefined'); }
        dispatch(outputIsBeamIncludedSet({ id: outputId, isIncluded: !output.isBeamIncluded }));
    }, [dispatch, outputId, output]);

    const handleMachineTypeChanged = useCallback((value: string) => {
        if (!output) { throw new Error('Dose output is undefined'); }
        dispatch(outputMachineTypeSet({ id: outputId, machineType: value }));
    }, [dispatch, outputId]);

    const handleMachineNameChanged = useCallback((value: string) => {
        if (!output) { throw new Error('Dose output is undefined'); }
        dispatch(outputMachineNameSet({ id: outputId, machineName: value }));
    }, [dispatch, outputId]);

    // TODO: add section-specific output validity checking here later on if we have
    // any relevant form validation for those parts -- currently we don't
    const outputIsValid = true;

    if (!output) {
        return null;
    }

    return (
        <DataList aria-label={`Dose file customization`} isCompact className="dose-output-form">

            <DoseOutputSection
                title={t('customizationPage.dose.outputSection.doseScaling')}
                isModified={false}
                hasValidationError={!outputIsValid}
                validationError={undefined}
            >

                <DoseOutputSectionHelpText
                    helpText={t('customizationPage.dose.outputSection.doseScaling.helpText')}
                />

                <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.doseScaling.method.label')}>
                    <FormGroup
                        role="radiogroup"
                        isStack
                    >
                        <Radio
                            name="dose-output-dosescaling-method"
                            id="dose-output-dosescaling-method-mean"
                            label={t('customizationPage.dose.outputSection.doseScaling.method.enum.Mean')}
                            onChange={handleSetDoseScalingMethodToMean}
                            isChecked={output.doseScaling.method === DoseScalingMethod.Mean}
                        />
                        <Radio
                            name="dose-output-dosescaling-method"
                            id="dose-output-dosescaling-method-volume"
                            label={t('customizationPage.dose.outputSection.doseScaling.method.enum.Volume')}
                            onChange={handleSetDoseScalingMethodToVolume}
                            isChecked={output.doseScaling.method === DoseScalingMethod.Volume}
                        />

                    </FormGroup>
                </DoseOutputSectionItem>

                {output.doseScaling.method === DoseScalingMethod.Mean && (
                    <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.doseScaling.meanMethod.dose.label')}>
                        <FormGroup>
                            <NumberInput
                                fieldId={`dose-scaling-mean-dose-field-for-${outputId}`}
                                onChange={handleDoseScalingMeanMethodDoseChanged}
                                defaultValue={output.doseScaling.dose}
                                lowerBound={0}
                                upperBound={2}
                            />
                        </FormGroup>
                    </DoseOutputSectionItem>
                )}

                {output.doseScaling.method === DoseScalingMethod.Volume && (
                    <>
                        <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.doseScaling.volumeMethod.volume.label')}>
                            <FormGroup>
                                <NumberInput
                                    fieldId={`dose-scaling-volume-volume-field-for-${outputId}`}
                                    onChange={handleDoseScalingVolumeMethodVolumeChanged}
                                    defaultValue={output.doseScaling.volume}
                                    lowerBound={0}
                                    upperBound={2}
                                />
                            </FormGroup>
                        </DoseOutputSectionItem>

                        <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.doseScaling.volumeMethod.dose.label')}>
                            <FormGroup>
                                <NumberInput
                                    fieldId={`dose-scaling-volume-dose-field-for-${outputId}`}
                                    onChange={handleDoseScalingVolumeMethodDoseChanged}
                                    defaultValue={output.doseScaling.dose}
                                    lowerBound={0}
                                    upperBound={2}
                                />
                            </FormGroup>
                        </DoseOutputSectionItem>
                    </>
                )}

            </DoseOutputSection>

            <DoseOutputSection
                title={t('customizationPage.dose.outputSection.outputDoseGeometry')}
                isModified={false}
                hasValidationError={!outputIsValid}
                validationError={undefined}
            >
                <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.outputDoseGeometry.pixelSpacing')}>
                    <FormGroup>
                        <NumberInput
                            fieldId={`pixel-spacing-field-for-${outputId}`}
                            onChange={handlePixelSpacingChanged}
                            defaultValue={output.pixelSpacing ? getLengthInCm(output.pixelSpacing) : undefined}
                            lowerBound={0.1}
                            upperBound={0.4}
                            unit="cm"
                        />
                    </FormGroup>
                </DoseOutputSectionItem>

                <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.outputDoseGeometry.zDoseCropping')}>
                    <FormGroup>
                        <NumberInput
                            fieldId={`zdose-cropping-field-for-${outputId}`}
                            onChange={handleZDoseCroppingValueChanged}
                            defaultValue={getLengthInCm(output.doseCropping.zMargin)}
                            lowerBound={0}
                            upperBound={20}
                            unit="cm"
                        />
                    </FormGroup>
                </DoseOutputSectionItem>
            </DoseOutputSection>

            <DoseOutputSection
                title={t('customizationPage.dose.outputSection.supportingRTPlan')}
                isModified={false}
                hasValidationError={!outputIsValid}
                validationError={undefined}
            >
                <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.supportingRTPlan.isRTPlanIncluded')}>
                    <FormGroup>
                        <InputGroup>
                            <Switch
                                label={t('customizationPage.dose.outputSection.supportingRTPlan.isRTPlanIncluded.on')}
                                labelOff={t('customizationPage.dose.outputSection.supportingRTPlan.isRTPlanIncluded.off')}
                                isChecked={output.isRTPlanIncluded}
                                onChange={handleIsRTPlanIncludedToggled}
                            />
                        </InputGroup>
                    </FormGroup>
                </DoseOutputSectionItem>

                {output.isRTPlanIncluded && (
                    <>
                        <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.supportingRTPlan.isBeamIncluded')}>
                            <FormGroup>
                                <InputGroup>
                                    <Switch
                                        label={t('customizationPage.dose.outputSection.supportingRTPlan.isBeamIncluded.on')}
                                        labelOff={t('customizationPage.dose.outputSection.supportingRTPlan.isBeamIncluded.off')}
                                        isChecked={output.isBeamIncluded}
                                        onChange={handleIsBeamIncludedToggled}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </DoseOutputSectionItem>

                        {output.isBeamIncluded && (
                            <>
                                <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.supportingRTPlan.machineType')}>
                                    <FormGroup>
                                        <MachineTypeSelect
                                            value={output.machineType}
                                            onSelect={handleMachineTypeChanged}
                                        />
                                    </FormGroup>
                                </DoseOutputSectionItem>

                                <DoseOutputSectionItem label={t('customizationPage.dose.outputSection.supportingRTPlan.machineName')}>
                                    <FormGroup>
                                        <TextOverrideField
                                            defaultText={output.machineName}
                                            textValue={output.machineName}
                                            id={`machine-name-for-${output.id}`}
                                            onChange={handleMachineNameChanged}
                                            allowEmptyField={true}
                                            placeholder={t('customizationPage.dose.outputSection.supportingRTPlan.machineName.prompt')}
                                        />
                                        {/* {nameFieldValidation.validated !== 'default' && (
                                            <ValidationHelperText
                                                validated={nameFieldValidation.validated}
                                                helperText={nameFieldValidation.helperTextInvalid}
                                            />
                                        )} */}
                                    </FormGroup>
                                </DoseOutputSectionItem>
                            </>
                        )}
                    </>
                )}

            </DoseOutputSection>

        </DataList >
    );
}

export default DoseOutputForm;
