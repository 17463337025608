
import i18n, { ResourceKey } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import LangEnUs from './locales/en-US/translation.json';
import LangFrFr from './locales/fr-FR/translation.json';
import LangPseudo from './locales/pseudo/translation.json';  // pseudolanguage used for testing
import { ModelType } from './store/global-types/customization-types';

/**
 * 
 * MVISION CONFIGURATION INTERNATIONALIZATION (i18n) SUPPORT
 * 
 * REGULAR USAGE:
 * In React components the usage is very straightforward, just import useTranslation in your component...
 *   import { useTranslation } from 'react-i18next';
 * ... and use it to extract the 't' function...
 *   const { t } = useTranslation();
 * ... and use that in the component to get the translated string for the correct dictionary key:
 *   label={t('daemonConfigPage.form.identificationAttributes')}
 * 
 * i18n can also be used outside of React components -- you'll just be using the regular i18next, not i18next-react. Some configuration will
 * be missing but otherwise it's mostly the same. E.g. you could write:
 *   return i18next.t('translation:error.formValidation.duplicateRoiNamesInOutput');
 * Note that the 'translation:' prefix is required, unlike with i18n-react.
 * 
 * 
 * To ADD new languages follow the steps below:
 * * add a new translation to src/locales/[langKey]/translation.json. Any keys not present in the json file will default to the en-US translation.
 * * import the json file in this file (see the imports above)
 * * add the imported translation pack into the i18n init resources object below
 * * add the new language to the suppportedLanguages collection at the bottom of this file
 * * note: if you want to disable a language from selection from UI, add its langKey to the omitLocalizationsFromUi array in config.json
 *     (this is really only useful for disabling localizations that are meant for testing and are NOT being auto-detected from user's browser language settings)
 *     * note 2: KNOWN ISSUE: adding localizations to omits list does NOT stop it from being loaded from local storage or from being auto-detected by
 *               any other means. Omitting will ONLY make it unselectable from user settings, but will retain any previous user selections and doesn't
 *               affect lang detection.
 * * re-build and re-deploy MVision Configuration to see the new translation
 * 
 * 
 * To UPDATE an existing language translation file with new entries:
 * * while you work, focus on adding new entries directly to the English translation file (src/locales/en-US/translation.json)
 * * towards the end of the project use the node script src/locales/jsondiff.js to generate a dump of new entries that exist in the original
 *   language file (the en-US) one and the target language file (see the script itself for run instructions -- you need to have nodejs installed)
 * * send the generated diff file to be translated. It may be helpful for the translator to have full context of the translations by sending them
 *   also the full English and the previous existing their-language version translation files
 * * once the finished new translations are received, insert them into the matching translation file (just copy-paste them in assuming they're still
 *   in valid-ish JSON format) and use something to alphabetically sort the file (if using Visual Studio Code you can use e.g. the "Sort JSON objects"
 *   plugin). Make sure the resulting file is valid JSON -- you may need to remove a trailing comma (,) from the very final entry in the list, and add 
 *   a comma to another entry that used to be the last item in the list before the sorting but now, after sorting, may be somewhere in the middle.
 */

/** Supported language keys */
export type LangKey = 'en-US' | 'fr-FR' | 'pseudo';


i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en-US',
        debug: false,

        resources: {
            'en-US': { 'translation': LangEnUs },
            'fr-FR': { 'translation': LangFrFr },
            'pseudo': { 'translation': LangPseudo },
        },

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        // this might be unnecessary as the i18next backend component is not being used? TODO: remove
        backend: {
            requestOptions: {
                cache: 'no-cache'
            },
        },

        // store & detect user language primary from local storage
        // (using i18n.changeLanguage will set this automatically)
        // A couple of things to note here:
        // * Initial load will try to assign language from user's browser language ('navigator')
        // * query string ?lng=LANGUAGE can be used to overwrite settings (e.g. to revert back to English use ?lng=en-US),
        // but it's not being actively used for this purpose (e.g. caching the settings to local storage is the primary
        // way to control the app language, the query string is just an emergency override)
        detection: {
            order: ['querystring', 'localStorage', 'sessionStorage', 'navigator'],
            lookupLocalStorage: 'i18nextLng',
            caches: ['localStorage'],
        },

    });


export default i18n;

type SupportedLanguage = {
    /** Localization key that must match the key used for the translation file (e.g. /src/locales/<langKey>/translation.json) */
    langKey: LangKey;
    /** Name of the localization as presented to the user in UI. */
    label: string;
}

export const supportedLanguages: SupportedLanguage[] = [
    { langKey: 'en-US', label: 'English' },
    { langKey: 'fr-FR', label: 'Français' },
    { langKey: 'pseudo', label: 'Pseudolanguage (for testing)' },
];

export type HelpDocumentLocation = {
    /** Localization key that must match the key used for the translation file (e.g. /src/locales/<langKey>/translation.json) */
    langKey: LangKey;
    /** Location of the help file. */
    url: string;
}

export type HelpDocument = {
    /** Localization key that must match the key used for the translation file (e.g. /src/locales/<langKey>/translation.json) */
    langKey: LangKey;
    /** Markdown help document. */
    helpDocument: string;
}

export function getCurrentLangKey(): LangKey | undefined {
    return supportedLanguages.find(l => l.langKey === i18n.resolvedLanguage)?.langKey;
}

export function getModelTypeLangKey(modelType: ModelType): ResourceKey {
    switch (modelType) {
        case ModelType.Contouring:
            return 'common.modelType.contour';
            case ModelType.Dose:
                return 'common.modelType.dose';
        default:
            throw new Error(`Unsupported model type: ${modelType}`);
    }
}
