import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FullRoiDetailsContext from './full-roi-details-context';
import { ModelType } from '../global-types/customization-types';

export type AppStatusSliceState = {
    /** True if app initialization is complete, false otherwise. */
    isAppInitialized: boolean,

    /** App initialization error as string if any, undefined otherwise.
     * TODO: currently unused in UI.
     */
    appInitializationError: string | undefined,

    /** Tracks which ROI is currently opened in the full ROI details dialog
     * (if any) and its context (basically which other ROIs can be navigated
     * to within that dialog using the next/previous buttons).
     */
    fullRoiDetails: FullRoiDetailsContext | undefined,

    /** Change model customization page to immediately show this item. */
    modelCustomizationPageFocusId: string | undefined,

    /** The latest model type the user has interacted with in the app (e.g.
     * which page they have last opened). */
    currentAppModelType: ModelType,
};

export const initialState: AppStatusSliceState = {
    isAppInitialized: false,
    appInitializationError: undefined,
    fullRoiDetails: undefined,
    modelCustomizationPageFocusId: undefined,
    currentAppModelType: ModelType.None,
};

/** Redux slice for handling state related to app status and program flow, such as initialization or deployment config */
const appStatusSlice = createSlice({
    name: 'appStatus',
    initialState,
    reducers: {
        /** Marks app initialization as having started -- used for signalling sagas */
        appInitializationStarted() {
            // this is an empty action and is only used for signalling in sagas
        },

        /** Marks app initialization as having succeeded */
        appInitializationSucceeded(state) {
            state.isAppInitialized = true;
            state.appInitializationError = undefined;
        },

        /** Marks app initialization as having been failed.
         * @param action payload: initialization error message as string.
         */
        appInitializationFailed(state, action: PayloadAction<string>) {
            const initializationErrorMessage = action.payload;
            state.isAppInitialized = false;
            state.appInitializationError === undefined ? state.appInitializationError = initializationErrorMessage : state.appInitializationError += `\n${initializationErrorMessage}`;
        },

        /**
         * Prepares a full ROI details dialog with given context.
         * @param action The ROI and its surrounding context to open in a full ROI details dialog.
         */
        fullRoiDetailsShown(state, action: PayloadAction<FullRoiDetailsContext>) {
            const roiDetails = action.payload;
            state.fullRoiDetails = roiDetails;
        },

        /**
         * Prepares to close a full ROI details dialog and clears out any current context.
         */
        fullRoiDetailsHidden(state) {
            state.fullRoiDetails = undefined;
        },


        /**
         * Clears model customization page focus.
         */
        modelCustomizationPageFocusCleared(state) {
            state.modelCustomizationPageFocusId = undefined;
        },

        /**
         * Sets model customization page focus to selected id
         * @param string 
         */
        modelCustomizationPageFocusSet(state, action: PayloadAction<string>) {
            state.modelCustomizationPageFocusId = action.payload;
        },

        /**
         * Sets the latest model type user has interacted with in the app.
         * @param action The modelType to use as latest model type.
         */
        currentAppModelTypeSet(state, action: PayloadAction<ModelType>) {
            state.currentAppModelType = action.payload;
        }
    },
    selectors: {
        /** Returns the latest model type user has interacted with in the app. */
        selectCurrentAppModelType: (state) => state.currentAppModelType,

    }

});

export const {
    appInitializationStarted,
    appInitializationSucceeded,
    appInitializationFailed,
    fullRoiDetailsShown,
    fullRoiDetailsHidden,
    modelCustomizationPageFocusCleared,
    modelCustomizationPageFocusSet,
    currentAppModelTypeSet,
} = appStatusSlice.actions;

// uncomment if these are needed locally
// const localSelectors = appStatusSlice.getSelectors();

export const { getInitialState, selectors: appStatusSelectors } = appStatusSlice;

export default appStatusSlice.reducer;
