import { Table, Tbody, Th, Thead, Tr } from '@patternfly/react-table';
import { ResourceKey } from "i18next";
import { useTranslation } from "react-i18next";

import DoseRoiForm from "./DoseRoiForm";

import './dose-output-form.css';

/** Ids for different types of dose roi table columns. */
export const columnIds = {
    name: 'Name',
    isMandatory: 'Mandatory',
    matchPattern: 'Match pattern'
};

/** Localization keys for dose roi table column headers. */
export const columnLangKeys: { [columnId: string]: ResourceKey } = {
    [columnIds.name]: 'column.name',
    [columnIds.isMandatory]: 'column.isMandatory',
    [columnIds.matchPattern]: 'column.matchPattern',
}

/** Styling names for dose roi table columns. */
export const columnCssNames = {
    modified: 'column-modified',
    name: 'column-name',
    isMandatory: 'column-is-mandatory',
    matchPattern: 'column-match-pattern',
}

export const columnTooltips = {
    [columnIds.isMandatory]: (<span>Indicates whether this structure is mandatory in predicted dose.</span>),
}

export type DoseRoiTableColumnKey = keyof typeof columnIds;



interface DoseRoiTableProps {
    outputId: string;
    roiIds: string[];
}


const DoseRoiTable = (props: DoseRoiTableProps) => {

    const { outputId, roiIds } = props;

    const { t } = useTranslation();

    return (
        <div>

            <div>
                <Table variant='compact' isStickyHeader className="mv-sticky-fix">
                    <Thead aria-label='Dose ROIs'>
                        <Tr className='customization-form-table unselectable'>
                            <Th aria-label='Is modified?'></Th>
                            <Th aria-label='Structure Name' className="dose-roi-name" width={25}>{t(columnLangKeys[columnIds.name])}</Th>
                            <Th aria-label='Is structure mandatory?' className="dose-roi-is-mandatory" width={20}
                                info={{ tooltip: columnTooltips[columnIds.isMandatory] }}>
                                {t(columnLangKeys[columnIds.isMandatory])}
                            </Th>
                            <Th aria-label='Match pattern'>{t(columnLangKeys[columnIds.matchPattern])}</Th>
                            <Th aria-label='Action menu'></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {roiIds.map(rId => <DoseRoiForm roiId={rId} key={rId} roiIdList={roiIds} />)}
                    </Tbody>
                </Table>
            </div>
        </div>
    );
}

export default DoseRoiTable;
