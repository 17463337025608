import { FormHelperText, HelperText, HelperTextItem } from '@patternfly/react-core';
import { ExclamationCircleIcon, ExclamationTriangleIcon, InfoCircleIcon, CheckCircleIcon } from '@patternfly/react-icons';

interface ValidationHelperTextProps {
    validated: 'default' | 'error' | 'warning' | 'success' | undefined;
    helperText?: string | null;
}

const getValidationIcon = (validated: 'default' | 'error' | 'warning' | 'success' | undefined) => {
    switch (validated) {
        case 'error':
            return <ExclamationCircleIcon />;
        case 'warning':
            return <ExclamationTriangleIcon />;
        case 'success':
            return <CheckCircleIcon />;
        case 'default':
            return <InfoCircleIcon />;
        default:
            undefined;
    }
}

const ValidationHelperText = ({ validated, helperText }: ValidationHelperTextProps) => {
    // Return null if no helper text is provided
    if (!helperText || !validated) return null;

    return (
        <FormHelperText>
            <HelperText>
                <HelperTextItem icon={getValidationIcon(validated)} variant={validated}>
                    {helperText}
                </HelperTextItem>
            </HelperText>
        </FormHelperText>
    );
};

export default ValidationHelperText;
