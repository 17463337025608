import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Td } from '@patternfly/react-table';
import { useTranslation } from 'react-i18next';

import RoiItem from '../customization/contour/RoiItem';
import CustomizationLabel from '../../components/customization-label';
import { columnIds } from '../customization/contour/ContourRoiTable';
import { StoreState } from '../../store/store';
import { contouringSelectors, roiCustomizationChangedToMatchGlobalRoi, roiCustomizationMadeIntoGlobalRoi, roiCustomizationNameUpdated } from '../../store/contouring/contouringSlice';
import { fullRoiDetailsShown } from '../../store/appStatus/appStatusSlice';

interface ExcludedRoiProps {
    excludedRoiId: string;
    nonMatchingGlobalRoiId: string;
    excludedRoisList: string[];
}


const ExcludedRoi = (props: ExcludedRoiProps) => {
    const { excludedRoiId, nonMatchingGlobalRoiId, excludedRoisList } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const roi = useSelector((state: StoreState) => contouringSelectors.selectRoiById(state, excludedRoiId));
    const customizationOutput = useSelector((state: StoreState) => roi && roi.customizationOutputId ? contouringSelectors.selectOutputById(state, roi.customizationOutputId) : undefined);
    const customizationBase = useSelector((state: StoreState) => customizationOutput && customizationOutput.modelCustomizationBaseId ? contouringSelectors.selectCustomizationBaseById(state, customizationOutput.modelCustomizationBaseId) : undefined);
    const segmentationModel = useSelector((state: StoreState) => customizationBase && contouringSelectors.selectModelById(state, customizationBase.modelId));

    const handleChangeToMatchGlobal = useCallback(() => {
        dispatch(roiCustomizationChangedToMatchGlobalRoi({ roiId: excludedRoiId, globalRoiId: nonMatchingGlobalRoiId }));
    }, [dispatch, excludedRoiId, nonMatchingGlobalRoiId]);

    const handleMakeThisGlobal = useCallback(() => {
        dispatch(roiCustomizationMadeIntoGlobalRoi({ roiId: excludedRoiId, globalRoiId: nonMatchingGlobalRoiId }))
    }, [dispatch, excludedRoiId, nonMatchingGlobalRoiId]);

    const handleRevertName = useCallback(() => {
        dispatch(roiCustomizationNameUpdated({ roiId: roi!.id, name: roi!.operation, isGlobalRoi: false }));
    }, [dispatch, roi]);

    const handleShowFullRoiDetails = useCallback(() => {
        dispatch(fullRoiDetailsShown({ currentRoiId: excludedRoiId, roiIdList: excludedRoisList, isGlobalRoi: false }));
    }, [dispatch, excludedRoiId, excludedRoisList]);

    if (!roi || !customizationOutput || !customizationBase || !segmentationModel) {
        if (!roi) { console.log(`no roi: ${excludedRoiId}`) }
        else if (!customizationOutput) console.log(`no customization output: ${roi.customizationOutputId}`)
        else if (!customizationBase) console.log(`no customization: ${customizationOutput.modelCustomizationBaseId}`)
        else if (!segmentationModel) console.log(`no segmentation model: ${customizationBase.modelId}`)
        return null;
    }

    return (
        <RoiItem
            roiId={excludedRoiId}
            extraColumnsBefore={[
                <Td key={columnIds.customization}
                    dataLabel={columnIds.customization}
                >
                    <CustomizationLabel friendlyName={segmentationModel.label} modelName={segmentationModel.modelName} customizationName={customizationBase.customizationName} />
                </Td>,
                <Td key={columnIds.targetFile}
                    dataLabel={columnIds.targetFile}
                >
                    <span className="target-file">{customizationOutput.filename}</span>
                </Td>,
            ]}
            rowActions={[
                {
                    title: t('customizeRoi.showFullRoiDetails'),
                    onClick: handleShowFullRoiDetails,
                },
                {
                    title: t('customizeRoi.global.changeToMatchGlobalRoi'),
                    onClick: handleChangeToMatchGlobal,
                },
                {
                    title: t('customizeRoi.global.makeThisGlobalRoi'),
                    onClick: handleMakeThisGlobal,
                },
                {
                    title: roi.isBuiltInRoi ? t('customizeRoi.revertNameToDefault') : t('customizeRoi.changeNameToMatchOperation'),
                    onClick: handleRevertName,
                    disabled: roi.name === roi.operation,
                },
            ]}
        />

    );
}

export default ExcludedRoi;
