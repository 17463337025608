import { EntityAdapter, Update } from "@reduxjs/toolkit";
import { aeTitleRuleAdapter, backendValidationErrorAdapter, ContouringSliceState, customizationBaseAdapter, customizationOutputAdapter, dicomAttributeRuleAdapter, dicomRuleAdapter, formValidationErrorAdapter, globalRoiCustomizationAdapter, modelAdapter, outputMetadataAdapter, roiCustomizationAdapter } from "../../contouring/contouringSlice";
import GenericSliceWrapper, { FormValidationErrorCalculationResults, RemovedOutputEntities } from "./genericSliceWrapper";
import { ContouringCustomizationEntities, ContouringCustomizationOutput, ContouringRoi, GlobalContouringRoi } from "../../contouring/contouring-types";
import { CustomizationObjectType } from "../customization-types";
import { FormValidationErrorType } from "../form-errors";
import { FormValidationError, generateNewFormValidationError } from "../store-errors";

export class ContourSliceWrapper extends GenericSliceWrapper<ContouringSliceState> {

    outputAdapter: EntityAdapter<ContouringCustomizationOutput, string>;
    roiAdapter: EntityAdapter<ContouringRoi, string>;
    globalRoiAdapter: EntityAdapter<GlobalContouringRoi, string>

    constructor() {
        super(
            modelAdapter,
            customizationBaseAdapter,
            outputMetadataAdapter,
            aeTitleRuleAdapter,
            dicomRuleAdapter,
            dicomAttributeRuleAdapter,
            backendValidationErrorAdapter,
            formValidationErrorAdapter
        )
        this.outputAdapter = customizationOutputAdapter;
        this.roiAdapter = roiCustomizationAdapter;
        this.globalRoiAdapter = globalRoiCustomizationAdapter;
    }

    protected findOutput(state: ContouringSliceState, outputId: string): ContouringCustomizationOutput | undefined {
        return state.contourOutputs.entities[outputId];
    }

    protected updateOutput(state: ContouringSliceState, update: Update<ContouringCustomizationOutput, string>): void {
        this.outputAdapter.updateOne(state.contourOutputs, update);
    }

    /** Collects which store entities need to be removed and updated when a customization output is removed. */
    public collectCustomizationOutputEntitiesFromStoreForRemoval(state: ContouringSliceState, output: ContouringCustomizationOutput, customizationBaseId: string): RemovedOutputEntities {

        const globalRoiChanges: Update<GlobalContouringRoi, string>[] = [];
        const roiIdsToBeRemoved: string[] = [];
        const metadataIdsToBeRemoved: string[] = [];


        metadataIdsToBeRemoved.push(...output.metadata);

        for (const roiId of output.rois) {
            const roi = state.roiCustomizations.entities[roiId];
            if (roi === undefined) {
                throw new Error(`Could not retrieve roi ${roiId} for output ${output.id} in customization base ${customizationBaseId}`);
            }

            roiIdsToBeRemoved.push(roiId);

            const globalRoi = roi.globalRoiId ?
                state.globalRoiCustomizations.entities[roi.globalRoiId] :
                Object.values(state.globalRoiCustomizations.entities).find(r => r?.excludedRois.includes(roiId));
            if (globalRoi) {
                globalRoiChanges.push({
                    id: globalRoi.id, changes: {
                        excludedRois: globalRoi.excludedRois.filter(r => r !== roiId),
                        coveredRois: globalRoi.coveredRois.filter(r => r !== roiId),
                    }
                });
            }
        }

        return {
            globalRoiChanges,
            roiIdsToBeRemoved,
            metadataIdsToBeRemoved,
            targetIdsToBeRemoved: [],
        };
    };

    protected removeOutputRelatedItems(state: ContouringSliceState, outputIds: string[], items: RemovedOutputEntities) {
        const { roiIdsToBeRemoved, metadataIdsToBeRemoved, globalRoiChanges } = items;

        // update global rois first
        this.globalRoiAdapter.updateMany(state.globalRoiCustomizations, globalRoiChanges);

        this.roiAdapter.removeMany(state.roiCustomizations, roiIdsToBeRemoved);
        this.outputMetadataAdapter.removeMany(state.outputMetadata, metadataIdsToBeRemoved);
        this.outputAdapter.removeMany(state.contourOutputs, outputIds);
    }

    protected setOutputRelatedItemsAsModified(state: ContouringSliceState, entitiesToUpdate: Partial<ContouringCustomizationEntities>) {
        const roiIds = entitiesToUpdate.contouringRois?.map(id => id.id);
        const globalRoiIds = entitiesToUpdate.contouringGlobalRois?.map(id => id.id);
        const customizationOutputsIds = entitiesToUpdate.contouringOutputs?.map(id => id.id);

        if (roiIds && roiIds.length > 0) {
            this.roiAdapter.updateMany(
                state.roiCustomizations,
                roiIds.map(roiId => ({ id: roiId, changes: { isModified: true } }))
            );
        }

        if (globalRoiIds && globalRoiIds.length > 0) {
            this.globalRoiAdapter.updateMany(
                state.globalRoiCustomizations,
                globalRoiIds.map(globalRoiId => ({ id: globalRoiId, changes: { isModified: true } }))
            );
        }
        
        if (customizationOutputsIds && customizationOutputsIds.length > 0) {
            this.outputAdapter.updateMany(
                state.contourOutputs,
                customizationOutputsIds.map(customizationOutputId => ({ id: customizationOutputId, changes: { isModified: true } }))
            );
        }
    }


    /** Calculates form validation errors for given customization output.
     * TODO: once we have more types of validation errors consider splitting
     * this into smaller functions, and consider whether all of them need to be
     * checked here.
     */
    protected calculateFormValidationErrorsForCustomizationOutput(state: ContouringSliceState, outputId: string): FormValidationErrorCalculationResults {
        const errorIdsToRemove: string[] = [];
        const errorsToAdd: FormValidationError[] = [];

        const output = state.contourOutputs.entities[outputId];
        if (!output) { throw new Error(`Customization output (${outputId}) is undefined`); }
        const rois = output.rois.map(rId => state.roiCustomizations.entities[rId]);

        const existingErrors = Object.values(state.formValidationErrors.entities).filter(e => output.rois.includes(e?.itemId));
        const existingEmptyNameErrors = existingErrors.filter(e => e?.errorType === FormValidationErrorType.RoiNameIsEmpty);
        const existingDuplicateNameErrors = existingErrors.filter(e => e?.errorType === FormValidationErrorType.DuplicateRoiNamesInOutput);

        // collect existing duplicate name errors nearby so we can use them when doing a delta within this output
        // map potential INCLUDED duplicate roiIds (string array value) under the potential duplicate name (string key)
        // this is needed for the duplicate name check resolution later
        const duplicateNames: { [name: string]: string[] } = {};
        for (const roi of rois) {
            if (roi.isIncluded) {
                duplicateNames[roi.name] = duplicateNames[roi.name] || [];
                duplicateNames[roi.name].push(roi.id);
            }
        }

        // check all the validation errors for ROIs in one loop
        for (const roi of rois) {

            let dontAddNewValidationErrors = false;


            // check for empty names rois (RoiNameIsEmpty)
            const matchingExistingEmptyNameErrors = existingEmptyNameErrors.filter(e => e.itemId === roi.id);
            const hasMatchingEmptyNameErrors = matchingExistingEmptyNameErrors.length > 0;
            const isNameEmpty = !roi.name.trim();

            // NOTE: new errors are NOT created if existing ones with the same FormValidationErrorType have already been found!
            if (isNameEmpty && !hasMatchingEmptyNameErrors && !dontAddNewValidationErrors) {
                // create a new error
                errorsToAdd.push(generateNewFormValidationError(FormValidationErrorType.RoiNameIsEmpty, roi.id, CustomizationObjectType.Roi));
            } else if (dontAddNewValidationErrors || (!isNameEmpty && hasMatchingEmptyNameErrors)) {
                // remove existing errors
                errorIdsToRemove.push(...matchingExistingEmptyNameErrors.map(m => m.id));
            }

            // if this name was empty then don't add any new validation errors (but we still need to check if any can be removed)
            if (isNameEmpty) {
                dontAddNewValidationErrors = true;
            }


            // check for duplicate names within (included) rois in the same output (DuplicateRoiNamesInOutput)
            // now loop through the ROIs again and do a delta against existing errors, creating new errors and marking existing ones to be removed as needed
            const matchingExistingDuplicateNameErrors = existingDuplicateNameErrors.filter(e => e.itemId === roi.id);
            const hasMatchingDuplicateNameErrors = matchingExistingDuplicateNameErrors.length > 0;
            const isNameDuplicated = duplicateNames[roi.name] && duplicateNames[roi.name].length > 1;

            // NOTE: new errors are NOT created if existing ones with the same FormValidationErrorType have already been found!
            if (isNameDuplicated && !hasMatchingDuplicateNameErrors && !dontAddNewValidationErrors) {
                // create a new error
                errorsToAdd.push(generateNewFormValidationError(FormValidationErrorType.DuplicateRoiNamesInOutput, roi.id, CustomizationObjectType.Roi));
            } else if (dontAddNewValidationErrors || (!isNameDuplicated && hasMatchingDuplicateNameErrors)) {
                // remove existing errors
                errorIdsToRemove.push(...matchingExistingDuplicateNameErrors.map(m => m.id));
            }
        }

        return {
            validationErrorIdsToRemove: errorIdsToRemove,
            validationErrorsToAdd: errorsToAdd,
        }
    }

}
