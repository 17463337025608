import { useCallback } from "react";
import ConfigurationTargetBoxDetails from "./ConfigurationTargetBoxDetails";
import { MVisionAppClient } from '../../store/configurationTarget/mvision-client-list';
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/store";
import { allModelCustomizationsReset, modelCustomizationExported, modelCustomizationImported, modelCustomizationImportFailed } from "../../store/dose/doseSlice";
import { readModelCustomizationJsonFile } from "../../store/dose/dose-helpers";
import { ModelType } from "../../store/global-types/customization-types";


const ConfigurationTargetBoxDetailsContour = () => {

    const dispatch = useDispatch();

    const isResetInProgress = useSelector((state: StoreState) => state.dose.isAllModelCustomizationsResetInProgress);
    const importExportErrorMessage = useSelector((state: StoreState) => state.dose.customizationImportExportError);

    const handleResetAllModelCustomizations = useCallback((configurationTarget: MVisionAppClient) => {
        dispatch(allModelCustomizationsReset(configurationTarget));
    }, [dispatch]);

    const handleExportModelCustomization = useCallback((configurationTarget: MVisionAppClient) => {
        dispatch(modelCustomizationExported(configurationTarget));
    }, [dispatch]);

    const handleImportUserData = useCallback(async (json: any) => {
        try {
            const readFile = await readModelCustomizationJsonFile(json);
            dispatch(modelCustomizationImported(readFile));
            return true;
        } catch (error) {
            console.log(error);
            dispatch(modelCustomizationImportFailed('Error importing dose configuration. Please ensure the imported data is valid.'));
            return false;
        }
    }, [dispatch]);


    return (
        <ConfigurationTargetBoxDetails
            modelType={ModelType.Dose}
            isResetInProgress={isResetInProgress}
            onResetAllModelCustomizations={handleResetAllModelCustomizations}
            onExportModelCustomization={handleExportModelCustomization}
            onImportUserData={handleImportUserData}
            importExportErrorMessage={importExportErrorMessage}
        />
    );
}

export default ConfigurationTargetBoxDetailsContour;
