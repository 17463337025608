import { call, fork, put, select, take, takeEvery } from "typed-redux-saga";
import { MVisionAppClient } from "../configurationTarget/mvision-client-list";
import { DoseCustomizationEntities, DoseCustomizationEntitiesForExport, DoseCustomizationOutput, DoseRoi, DoseTarget, EXPORT_DOSE_JSON_FILE_PREFIX } from "./dose-types";
import { isBackendValidationError } from "../../util/errors";
import { allModelCustomizationsReset, customizationBaseDuplicated, customizationDuplicationItemsAdded, customizationOutputReplaced, doseSelectors, modelCustomizationExported, modelCustomizationExportFailed, modelCustomizationImported, modelCustomizationImportFailed, modelCustomizationObjectsSetAsModified, modelCustomizationSaved, modelCustomizationsSet, resetAllModelCustomizationsFinished, resetAllModelCustomizationsStarted, resetSingleOutputFinished, resetSingleOutputStarted, saveModelCustomizationFinished, saveModelCustomizationStarted, singleCustomizationReset } from "./doseSlice";
import { Action } from "@reduxjs/toolkit";
import { cloneDeep, get } from "lodash-es";
import { createNewModelCustomizationBase, duplicateModelCustomizationMetadataItem, generateNewId } from "../global-types/customization-helpers";
import { DuplicatedIdMap } from "../global-types/reducer-helpers";
import { METADATA_FILENAME_ATTRIBUTE, ModelType, OutputMetadataItem } from "../global-types/customization-types";
import { modelCustomizationPageFocusSet } from "../appStatus/appStatusSlice";
import { ensureFilenameHasDcmExtension } from "../../pages/customization/file-operations";
import { convertDoseViewModelsToJson, createNewDoseCustomizationOutput, duplicateRoiCustomization, duplicateTargetCustomization } from "./dose-helpers";
import { callApi } from "../sagas";
import { appConfigSelectors } from "../appConfig/appConfigSlice";
import { DeploymentMode } from "../appConfig/appDeploymentInfoTypes";
import { downloadFileToDisk } from "../../util/files";


export function* fetchDoseCustomizationsSaga(appClient: MVisionAppClient | undefined) {

    const result = yield* callApi({
        doApiCall: function* (client) {
            const doseCustomizations = appClient === undefined ? null : yield* call(async () => client.fetchDoseCustomizationsAsync(appClient));
            if (doseCustomizations !== null) {
                const fetchModels = yield* call(async () => client.fetchAvailableModelsAsync(appClient, ModelType.Dose));
                if (fetchModels !== undefined) {
                    // if the available models collection is in use, mark which segmentation models are unavailable
                    for (const model of doseCustomizations.models) {
                        model.isAvailable = fetchModels.availableModels.includes(model.modelName);
                    }
                } else {
                    throw new Error('Could not retrieve list of available dose models');
                }
            }
            return doseCustomizations;
        },
        onSuccess: function* (result) {
            yield* put(modelCustomizationsSet({ customizations: result }));
        },
        onFailure: function* (error) {
            yield* put(modelCustomizationsSet({ customizations: null, errorMessage: error.message || 'Unspecified error.' }));
        }
    });

    return result;
}

function* saveDoseCustomizationSaga(action: Action) {
    if (modelCustomizationSaved.match(action)) {
        const configurationTarget = action.payload;


        yield* callApi({
            doApiCall: function* (client) {
                // collect model customization -- this is essentially a cache database dump (but global rois are not needed)
                const modelCustomization: DoseCustomizationEntitiesForExport = yield* collectDoseCustomizationForExport();

                // sanity-check that everything is valid in case invalid data slips through the UI debouncing
                if (modelCustomization.aeTitleRules.some(r => !r.isValid)
                    || modelCustomization.dicomRules.some(r => !r.isValid)
                    || modelCustomization.dicomAttributeRules.some(r => !r.isValid)) {
                    throw new Error(`Model configuration has invalid entries. Please fix them before saving.`);
                }

                yield* put(saveModelCustomizationStarted());
                const success = yield* call(async () => client.saveDoseCustomizationAsync(configurationTarget, modelCustomization));
                return success;
            },
            onFinish: function* (result, error) {
                let errorMessage = null;
                const success = result || false;

                if (error !== null) {
                    console.error('Was not able to save model customization');
                    errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
                    console.error(errorMessage);
                } else if (!success) {
                    errorMessage = 'Unable to save model customization. There might be a problem with the server.';
                }

                yield* put(saveModelCustomizationFinished({
                    saveWasSuccessful: success,
                    errorMessage: errorMessage || undefined,
                    error: isBackendValidationError(error) ? error : undefined
                }));

                return success;
            }
        });
    }
}

function* duplicateDoseCustomizationSaga(action: Action) {
    if (customizationBaseDuplicated.match(action)) {
        const { customizationBaseId, newCustomizationName } = action.payload;

        const sourceCustomizationBase = yield* select(doseSelectors.selectCustomizationBaseById, customizationBaseId);
        if (!sourceCustomizationBase) { throw new Error(`Could not retrieve customization base with id ${customizationBaseId}`); }
        const parentSegmentationModel = yield* select(doseSelectors.selectModelById, sourceCustomizationBase.modelId);
        if (!parentSegmentationModel) { throw new Error(`Could not retrieve parent segmentation model with id ${sourceCustomizationBase.modelId}`); }

        const newCustomizationBaseId = generateNewId();

        // collect and map all ids we duplicate here so we can also duplicate related form errors to new targets
        const duplicatedIds: DuplicatedIdMap[] = [{ sourceId: customizationBaseId, targetId: newCustomizationBaseId }];

        // duplicate customization outputs (note: triggers are NOT duplicated)
        const newOutputs: DoseCustomizationOutput[] = [];
        let newRois: DoseRoi[] = [];
        let newTargets: DoseTarget[] = [];
        let newMetadata: OutputMetadataItem[] = [];
        for (const outputId of sourceCustomizationBase.outputs) {
            const sourceCustomizationOutput = yield* select(doseSelectors.selectOutputById, outputId);
            if (!sourceCustomizationOutput) { throw new Error(`Could not retrieve customization output with id ${outputId}`); }
            const duplicationEntities = yield* duplicateCustomizationOutputFromStore(sourceCustomizationOutput, undefined, newCustomizationBaseId);
            newOutputs.push(duplicationEntities.duplicatedOutput);
            newRois = newRois.concat(duplicationEntities.newRois);
            newTargets = newTargets.concat(duplicationEntities.newTargets);
            newMetadata = newMetadata.concat(duplicationEntities.newMetadata);

            duplicatedIds.push(...duplicationEntities.duplicatedIds);
        }

        // duplicate customization base
        const duplicatedCustomizationBase = createNewModelCustomizationBase(
            newCustomizationName,
            sourceCustomizationBase.description,
            newOutputs.map(o => o.id),
            [],
            [],
            sourceCustomizationBase.modelId,
            newCustomizationBaseId,
            true
        );

        // store everything
        yield* put(customizationDuplicationItemsAdded({
            parentModelId: parentSegmentationModel.id,
            customization: duplicatedCustomizationBase,
            outputs: newOutputs,
            metadata: newMetadata,
            rois: newRois,
            targets: newTargets,
            duplicatedIds: duplicatedIds,
        }));

        // focus on the new duplicate
        if (newOutputs.length > 0) {
            yield* put(modelCustomizationPageFocusSet(newOutputs[0].id));
        }
    }
}

function* resetSingleDoseCustomizationSaga(action: Action) {
    if (singleCustomizationReset.match(action)) {

        // HACK: current code is being sent a customizationBaseId, NOT an outputId,
        // but as of writing this we expect a single output per dose customization so
        // we can easily retrieve the correct output
        const customizationId = action.payload;

        const customization = yield* select(doseSelectors.selectCustomizationBaseById, customizationId);

        if (!customization) { throw new Error('Could not find correct customization for reset'); }
        if (customization.outputs.length !== 1) { throw new Error('Expected customization to have exactly one output -- cannot reset'); }

        const output = yield* select(doseSelectors.selectOutputById, customization.outputs[0]);
        if (!output) { throw new Error('Could not find correct customization output for reset'); }

        const customizationOutputId = output.id;
        const modelName = yield* select(doseSelectors.selectModelNameForOutput, customizationOutputId);
        if (!modelName) { throw new Error('No model name specified, cannot fetch default configuration for model'); }

        yield* callApi({
            doApiCall: function* (client) {
                yield* put(resetSingleOutputStarted());

                // get default config for this model
                return yield* call(async () => client.fetchDefaultConfigurationForDoseOutput(modelName));
            },
            onFinish: function* (result, error) {
                if (result) {
                    // replace existing output and its items with received default items
                    yield* put(customizationOutputReplaced({ customizationOutputId, newOutputEntities: result }));
                }

                let errorMessage = null;
                if (error !== null) {
                    console.error(`Was not able to reset single customization output (${customizationOutputId})`);
                    errorMessage = `An error occurred when trying to reset target file: ${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
                    console.error(errorMessage);
                }

                const success = error === null;
                yield* put(resetSingleOutputFinished({
                    resetWasSuccessful: success,
                    errorMessage: errorMessage || undefined
                }));

                return success;
            }
        });
    }
}



/// helper functions for dose config sagas

function* collectDoseCustomizationForExport() {
    const modelCustomization: DoseCustomizationEntitiesForExport = {
        models: yield* select(doseSelectors.selectModels),
        customizationBases: yield* select(doseSelectors.selectCustomizationBases),
        doseOutputs: yield* select(doseSelectors.selectOutputs),
        doseRois: yield* select(doseSelectors.selectRois),
        doseTargets: yield* select(doseSelectors.selectTargets),
        outputMetadata: yield* select(doseSelectors.selectOutputMetadata),
        aeTitleRules: yield* select(doseSelectors.selectAeTitleRules),
        dicomRules: yield* select(doseSelectors.selectDicomRules),
        dicomAttributeRules: yield* select(doseSelectors.selectDicomAttributeRules),
    };

    return modelCustomization;
}

type DuplicatedOutputEntities = {
    duplicatedOutput: DoseCustomizationOutput;
    newRois: DoseRoi[];
    newTargets: DoseTarget[];
    newMetadata: OutputMetadataItem[];
    duplicatedIds: DuplicatedIdMap[];
};

/**
 * Duplicates a customization output from store.
 * 
 * This should only be called from within contouringConfig reducers (or from unit tests).
 * 
 */
function* duplicateCustomizationOutputFromStore(
    sourceCustomizationOutput: DoseCustomizationOutput,
    newFilename?: string,
    newCustomizationBaseId?: string) {

    const newRois: DoseRoi[] = [];
    const newTargets: DoseTarget[] = [];
    const newMetadata: OutputMetadataItem[] = [];
    const newCustomizationOutputId = generateNewId();
    const duplicatedIds: DuplicatedIdMap[] = [{ sourceId: sourceCustomizationOutput.id, targetId: newCustomizationOutputId }];

    let duplicateFilename: string = '';

    // duplicate metadata
    for (const metadataId of sourceCustomizationOutput.metadata) {
        const metadata = yield* select(doseSelectors.selectOutputMetadataById, metadataId);
        if (!metadata) { throw new Error(`Could not find customization metadata with id ${metadataId}`) }
        const duplicatedMetadata = duplicateModelCustomizationMetadataItem(metadata, newCustomizationOutputId);

        // handle filename metadata
        if (duplicatedMetadata.attribute === METADATA_FILENAME_ATTRIBUTE && newFilename !== undefined) {
            duplicatedMetadata.value = ensureFilenameHasDcmExtension(newFilename);
        }

        if (duplicatedMetadata.attribute === METADATA_FILENAME_ATTRIBUTE) {
            duplicateFilename = duplicatedMetadata.value;
        }

        newMetadata.push(duplicatedMetadata);
        duplicatedIds.push({ sourceId: metadataId, targetId: duplicatedMetadata.id });
    }

    // duplicate rois
    for (const roiId of sourceCustomizationOutput.rois) {
        const roi = yield* select(doseSelectors.selectRoiById, roiId);
        if (!roi) { throw new Error(`Could not find roi with id ${roiId}`); }
        const duplicatedRoi = duplicateRoiCustomization(roi, newCustomizationOutputId);
        newRois.push(duplicatedRoi);
        duplicatedIds.push({ sourceId: roiId, targetId: duplicatedRoi.id });
    }

    // duplicate targets
    for (const targetId of sourceCustomizationOutput.targets) {
        const target = yield* select(doseSelectors.selectTargetById, targetId);
        if (!target) { throw new Error(`Could not find target with id ${targetId}`); }
        const duplicatedTarget = duplicateTargetCustomization(target, newCustomizationOutputId);
        newTargets.push(duplicatedTarget);
        duplicatedIds.push({ sourceId: targetId, targetId: duplicatedTarget.id });
    }

    // create new output
    const roiIds = newRois.map(r => r.id);
    const targetIds = newTargets.map(r => r.id);
    const metadataIds = newMetadata.map(m => m.id);
    const duplicatedOutput = createNewDoseCustomizationOutput(
        metadataIds,
        roiIds,
        targetIds,
        cloneDeep(sourceCustomizationOutput.doseScaling),
        cloneDeep(sourceCustomizationOutput.pixelSpacing),
        cloneDeep(sourceCustomizationOutput.doseCropping),
        sourceCustomizationOutput.isRTPlanIncluded,
        sourceCustomizationOutput.isBeamIncluded,
        sourceCustomizationOutput.machineType,
        sourceCustomizationOutput.machineName,
        sourceCustomizationOutput.targetMethod,
        sourceCustomizationOutput.targetUnit,
        newCustomizationBaseId || sourceCustomizationOutput.modelCustomizationBaseId,
        duplicateFilename,
        newCustomizationOutputId,
        true
    );

    const result: DuplicatedOutputEntities = {
        duplicatedOutput,
        newMetadata,
        newRois,
        newTargets,
        duplicatedIds,
    };

    return result;
};

function* resetAllDoseCustomizationsSaga(action: Action) {
    if (allModelCustomizationsReset.match(action)) {
        const configurationTarget = action.payload;

        yield* callApi({
            doApiCall: function* (client) {
                yield* put(resetAllModelCustomizationsStarted());

                let success = yield* call(async () => client.deleteDoseCustomizationAsync(configurationTarget));
                if (success) {
                    // immediately fetch updated (reset) customizations
                    success = yield* call(fetchDoseCustomizationsSaga, configurationTarget);
                }

                return success;
            },
            onFinish: function* (result, error) {
                let errorMessage = null;
                const success = result || false;

                if (error !== null) {
                    console.error('Was not able to reset dose customizations');
                    errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
                    console.error(errorMessage);
                } else if (!success) {
                    errorMessage = 'Unable to reset dose customizations. There might be a problem with the server.';
                }


                // TODO: show results of error handling in UI
                // yield* put(resetModelCustomizationFinished(success, errorMessage || undefined, isInstanceOfError(error) ? error : undefined));

                yield* put(resetAllModelCustomizationsFinished());

                return success;
            },
        });
    }
}

function* exportDoseCustomization(appClient: MVisionAppClient | undefined) {
    try {
        // collect dose customization -- this is essentially a cache database dump
        const modelCustomization: DoseCustomizationEntitiesForExport = yield* collectDoseCustomizationForExport();
        const jsonObject = yield* call(async () => convertDoseViewModelsToJson(modelCustomization, true));

        // Default filename if appClient?.userName is undefined or empty
        const defaultFilename = "model-customizations.json";
        const localModeFilename = "local.json";
        const appDeploymentInfo = yield* select(appConfigSelectors.selectAppDeploymentInfo);
        const isLocalMode = appDeploymentInfo && (appDeploymentInfo.deploymentMode === DeploymentMode.Cockpit || appDeploymentInfo.deploymentMode === DeploymentMode.Local);
        const filename = `${EXPORT_DOSE_JSON_FILE_PREFIX}${isLocalMode ? localModeFilename : (appClient?.userName ? `${appClient.userName}.json` : defaultFilename)}`;

        downloadFileToDisk(JSON.stringify(jsonObject, null, 2), filename, 'application/json');
        yield* put(modelCustomizationExportFailed(null)); // resets any failure error message
    } catch (error) {
        console.log(`An error occurred in exportDoseCustomization:`);
        console.error(error);

        const errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'JSON export failed.')}`;
        yield* put(modelCustomizationExportFailed(errorMessage));
    }
}

function* importDoseCustomization(modelCustomization: DoseCustomizationEntities) {
    try {
        yield* put(modelCustomizationsSet({ customizations: modelCustomization }));
        yield* put(modelCustomizationObjectsSetAsModified(modelCustomization)); // set the entire ModelCustomizationEntities object as modified
        yield* put(modelCustomizationImportFailed(null));  // resets any failure error message
    } catch (error) {
        const errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'JSON import failed.')}`;
        yield* put(modelCustomizationImportFailed(errorMessage));
    }
}


/// watches for dose config sagas

function* watchSaveDoseCustomizationSaga() {
    // take all dose customization save requests as they come -- do limiting simultaenous saves by temporarily 
    // disabling relevant ui elements instead of doing it with sagas here
    yield* takeEvery(modelCustomizationSaved, saveDoseCustomizationSaga);
}

export function* watchDoseCustomizationDuplicatedSaga() {
    yield* takeEvery(customizationBaseDuplicated, duplicateDoseCustomizationSaga);
}

function* watchResetSingleDoseCustomizationSaga() {
    yield* takeEvery(singleCustomizationReset, resetSingleDoseCustomizationSaga);
}

function* watchResetAllDoseCustomizationsSaga() {
    yield* takeEvery(allModelCustomizationsReset, resetAllDoseCustomizationsSaga);
}

function* watchExportDoseCustomizationSaga() {
    while (true) {
        const action = yield* take(modelCustomizationExported);
        const errorMessage = 'Export of dose customization has failed.';
        if (!action.payload) {
            yield* put(modelCustomizationExportFailed(errorMessage));
            continue;
        }
        yield* fork(exportDoseCustomization, action.payload);
    }
}

function* watchImportDoseCustomizationSaga() {
    while (true) {
        const action = yield* take(modelCustomizationImported);
        const errorMessage = 'No JSON data provided for dose import.';

        if (!action.payload) {
            yield* put(modelCustomizationImportFailed(errorMessage));
            continue;
        }

        yield* fork(importDoseCustomization, action.payload);
    }
}

/** Returns all relevant watches to be added to a main root watch saga */
export function getWatchesForDoseConfigSagas() {
    return [
        watchSaveDoseCustomizationSaga(),
        watchDoseCustomizationDuplicatedSaga(),
        watchResetSingleDoseCustomizationSaga(),
        watchResetAllDoseCustomizationsSaga(),
        watchExportDoseCustomizationSaga(),
        watchImportDoseCustomizationSaga(),
    ];
}
