import { call, fork, put, take, takeEvery } from "typed-redux-saga";
import { get } from "lodash-es";
import { Action } from "@reduxjs/toolkit";
import { licenseStatusFetched, licenseStatusSet, updateLicenseFinished, updateLicenseStarted } from "./licenseSlice";
import { MVisionAppClient } from "../configurationTarget/mvision-client-list";
import { callApi } from "../sagas";

function* fetchLicenseStatusSaga(appClient: MVisionAppClient | undefined) {
    yield* callApi({
        doApiCall: function* (client) {
            const licenseStatus = appClient === undefined ? [] : yield* call(async () => client.fetchLicenseStatusAsync(appClient));
            return licenseStatus;
        },
        onFinish: function* (result, error) {
            if (error === null && result) {
                yield* put(licenseStatusSet({ licenseStatus: result }));
                return true;
            } else {
                // error case
                console.error('Was not able to fetch license status');
                const errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
                yield* put(licenseStatusSet({ licenseStatus: null, error: errorMessage }));
                return false;
            }
        }
    });
}


function* updateLicenseSaga(action: Action) {
    if (updateLicenseStarted.match(action)) {
        const { newLicense, appClient } = action.payload;

        yield* callApi({
            doApiCall: function* (client) {
                return yield* call(async () => client.saveLicenseAsync(appClient, newLicense));
            },
            onFinish: function* (result, error) {
                const success = result || false;
                let errorMessage: string | undefined = undefined;

                if (error !== null) {
                    console.error('Was not able to update license');
                    errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
                    console.error(errorMessage);
                } else if (!success) {
                    errorMessage = 'Unable to update license. The given license key might not be valid, or there might be a problem with the server.';
                }
        
                yield* put(updateLicenseFinished({ success: success, error: errorMessage }));
        
                if (success) {
                    // immediately fetch the new license
                    yield* fork(fetchLicenseStatusSaga, appClient);
                }

                return success;
            }
        });
    }
}

function* watchFetchLicenseStatusSaga() {
    while (true) {
        const action = yield* take(licenseStatusFetched);
        if (licenseStatusFetched.match(action)) {
            yield* fork(fetchLicenseStatusSaga, action.payload);
        }
    }
}

function* watchUpdateLicenseSaga() {
    yield* takeEvery(updateLicenseStarted, updateLicenseSaga);
}

/** Returns all relevant watches to be added to a main root watch saga */
export function getWatchesForLicenseSagas() {
    return [
        watchFetchLicenseStatusSaga(),
        watchUpdateLicenseSaga(),
    ];
}
