import React, { useState, useCallback } from 'react';
import {
    Button,
    Dropdown,
    MenuToggle,
    InputGroup,
} from '@patternfly/react-core';
import { MenuToggleElement } from '@patternfly/react-core/dist/js/components/MenuToggle/MenuToggle';

interface SplitButtonProps {
    primaryLabel: string;
    dropdownItems: JSX.Element[]; // Ensures dropdown items are JSX elements
    onPrimaryAction: () => void;
    id: string; // Unique ID for each instance
}

const SplitButton = ({
    primaryLabel,
    dropdownItems,
    onPrimaryAction,
    id,
}: SplitButtonProps) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handlePrimaryAction = useCallback(() => {
        onPrimaryAction();
    }, [onPrimaryAction]);

    const toggleDropdown = useCallback(() => {
        setIsDropdownOpen((prevState) => !prevState);
    }, []);

    return (
        <InputGroup>
            {/* Primary Action Button */}
            <Button
                onClick={handlePrimaryAction}
                variant="primary"
                style={{ borderRadius: '5px 0 0 5px' }}
            >
                {primaryLabel}
            </Button>

            {/* Dropdown Toggle */}
            <Dropdown
                isOpen={isDropdownOpen}
                popperProps={{ position: 'right' }}
                onOpenChange={setIsDropdownOpen}
                shouldFocusFirstItemOnOpen={false}
                toggle={(toggleRef: React.Ref<MenuToggleElement>) => (
                    <MenuToggle
                        id={`${id}-menu-toggle`}
                        ref={toggleRef}
                        aria-label="More options"
                        style={{ borderRadius: '0 5px 5px 0', paddingInlineStart: 0 }}
                        onClick={toggleDropdown}
                        variant="primary"
                    />
                )}
            >
                {dropdownItems}
            </Dropdown>
        </InputGroup>
    );
};

export default SplitButton;
