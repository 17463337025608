import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { TreeViewDataItem, TreeView, DrawerPanelContent, DrawerPanelBody } from '@patternfly/react-core';
import { ExclamationCircleIcon, LockIcon } from '@patternfly/react-icons'
import { useDispatch, useSelector } from 'react-redux';

import './customization-page.css';
import { useTranslation } from 'react-i18next';

import iconBody from '../../img/body-outline.png';
import iconCombination from '../../img/combination-outline.png';
import iconFemalePelvis from '../../img/female-pelvis-outline.png';
import iconHead from '../../img/head-outline.png';
import iconTorso from '../../img/torso-outline.png';
import i18next from 'i18next';
import { userSelectors } from '../../store/user/userSlice';
import { CustomizationObjectType, CustomizationTreeViewDataItem, ModelType } from '../../store/global-types/customization-types';
import { convertTreeViewDataIntoItemHierarchy, showCustomizationTreeViewDataItem } from '../../store/global-types/customization-helpers';
import { StoreState } from '../../store/store';
import { uniq } from 'lodash-es';
import { modelCustomizationPageFocusCleared } from '../../store/appStatus/appStatusSlice';
import { selectContourTreeViewItems } from '../../store/contouring/contouringSelectors';
import { selectDoseTreeViewItems } from '../../store/dose/doseSelectors';
import CustomizationFilters from './CustomizationFilters';

interface CustomizationSideBarProps {
    currentTreeViewFocus: string,
    setCurrentTreeViewFocus: (value: string) => void,
    modelType: ModelType.Contouring | ModelType.Dose,
}

/** Creates a getTreeViewItemName function with include filters (includeNonLicensedModelsState etc) already pre-set */
const makeGetTreeViewItemName = (includeNonLicensedModelsState: boolean,
    includeDeprecatedModelsState: boolean,
    includeUserHiddenModelsState: boolean) => {
    return (item: CustomizationTreeViewDataItem, noLicenseWarning: string) =>
        getTreeViewItemName(item,
            noLicenseWarning,
            includeNonLicensedModelsState,
            includeDeprecatedModelsState,
            includeUserHiddenModelsState);
}

const getTreeViewItemName = (item: CustomizationTreeViewDataItem,
    noLicenseWarning: string,
    includeNonLicensedModelsState: boolean,
    includeDeprecatedModelsState: boolean,
    includeUserHiddenModelsState: boolean): ReactNode => {
    const label = `${item.label}${item.isModified ? ' *' : ''}`;
    const labelNode = item.isAvailable ? label : (<span className="tree-view-no-license" title={noLicenseWarning}><span className="label">{label}</span><LockIcon /></span>);

    if (item.isValid) {
        return labelNode;
    } else {
        // we need to deduce whether this item would've been hidden by default if it was valid
        const isHiddenByDefault = (item.type === CustomizationObjectType.CustomizationBase || item.type === CustomizationObjectType.CustomizationOutput)
            && showCustomizationTreeViewDataItem(item,
                includeNonLicensedModelsState,
                includeDeprecatedModelsState,
                includeUserHiddenModelsState,
                true) === false;

        const title = isHiddenByDefault ? i18next.t('translation:customizationPage.validationError.hiddenByDefault') : undefined;

        return (<span className="tree-view-validation-error">
            <ExclamationCircleIcon />
            <span className={`label${isHiddenByDefault ? ' is-hidden-by-default' : ''}`} title={title}>{labelNode}</span>
        </span>)
    }
}

/** Recursively find treeview items under and including target. */
const getActiveTreeItemsUnderTarget = (item: TreeViewDataItem, target: TreeViewDataItem, branch: TreeViewDataItem[], isTargetBranch: boolean = false) => {
    const isInTargetBranch = isTargetBranch || item.id === target.id;

    if (isInTargetBranch) {
        branch.push(item);
    }

    if (item.children) {
        item.children.forEach(c => {
            getActiveTreeItemsUnderTarget(c, target, branch, isInTargetBranch);
        });
    }
}

/** Recursively find and collect tree view items from a specific branch up to a set target item. */
const getActiveTreeViewItemBranch = (item: TreeViewDataItem, target: TreeViewDataItem, branch: TreeViewDataItem[]): boolean => {
    if (item.children) {
        let childContained = false;
        item.children.forEach(c => {
            const wasChildContained = getActiveTreeViewItemBranch(c, target, branch);
            childContained = childContained || wasChildContained;
        });

        if (childContained) {
            // collect the branch that we're going down if this one has the child object that's our target
            branch.push(item);
        }
    }

    if (item.id === target.id) {
        branch.push(item);
        return true;
    }

    return false;
}

/** Find a single item from tree view hierarchy. */
const findItemFromHierarchy = (hierarchy: TreeViewDataItem[], id: string, mustBeLeafNode: boolean = false): TreeViewDataItem | undefined => {
    const nextItems = [...hierarchy];
    while (nextItems.length > 0) {
        const next = nextItems.pop();
        if (next) {
            if (next.id && next.id === id && (!mustBeLeafNode || !next.children)) {
                return next;
            }
            if (next.children) { next.children.forEach(c => nextItems.push(c)); }
        }
    }

    return undefined;
}

const CustomizationSideBar = (props: CustomizationSideBarProps) => {

    const { currentTreeViewFocus, setCurrentTreeViewFocus, modelType } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();


    const noLicenseWarning = t('customizationPage.noLicense.tooltip');

    const [activeTreeViewItems, setActiveTreeViewItems] = useState<TreeViewDataItem[]>([]);
    const [treeViewData, setTreeViewData] = useState<TreeViewDataItem[]>([]);
    const modelCustomizationPageFocusId = useSelector((state: StoreState) => state.appStatus.modelCustomizationPageFocusId);

    let customizationHierarchy: CustomizationTreeViewDataItem[];
    switch (modelType) {
        case ModelType.Contouring:
            customizationHierarchy = useSelector(selectContourTreeViewItems);
            break;
        case ModelType.Dose:
            customizationHierarchy = useSelector(selectDoseTreeViewItems);
            break;
        default:
            throw new Error('Unsupported model type');
    }
    const includeDeprecatedModelsState = useSelector(userSelectors.selectAreDeprecatedModelsShown);
    const includeNonLicensedModelsState = useSelector(userSelectors.selectAreNonLicensedModelsShown);
    const includeUserHiddenModelsState = useSelector(userSelectors.selectAreUserHiddenModelsShown);

    const handleTreeViewDataUpdate = useCallback((data: TreeViewDataItem[]) => {

        // update tree view data with branches and leaves that should start expanded
        const nextItems = [...data];
        while (nextItems.length > 0) {
            const next = nextItems.pop();
            if (next) {
                if (next.children) { next.children.forEach(c => nextItems.push(c)); }
                if (activeTreeViewItems.find(i => i.id && next.id && i.id === next.id)) {
                    next.defaultExpanded = true;
                }
            }
        }

        setTreeViewData(data);
    }, [activeTreeViewItems]);

    // generate tree view data items
    useEffect(() => {
        // pre-populate common body part groupings in correct order
        const headerItems: TreeViewDataItem[] = [
            { name: t('bodyPart.category.head'), id: 'Head', children: undefined, icon: <img src={iconHead} alt="head" width={30} /> },
            { name: t('bodyPart.category.torso'), id: 'Torso', children: undefined, icon: <img src={iconTorso} alt="torso" width={30} /> },
            { name: t('bodyPart.category.pelvis'), id: 'Pelvis', children: undefined, icon: <img src={iconFemalePelvis} alt="pelvis" width={28} /> },
            { name: t('bodyPart.category.combination'), id: 'Combination', children: undefined, icon: <img src={iconCombination} alt="combination" width={30} /> },
            { name: t('bodyPart.category.wholeBody'), id: 'WholeBody', children: undefined, icon: <img src={iconBody} alt="whole body" width={30} /> },
        ];


        const data = convertTreeViewDataIntoItemHierarchy(customizationHierarchy,
            makeGetTreeViewItemName(includeNonLicensedModelsState, includeDeprecatedModelsState, includeUserHiddenModelsState),
            noLicenseWarning,
            headerItems,
            includeNonLicensedModelsState,
            includeDeprecatedModelsState,
            includeUserHiddenModelsState);

        handleTreeViewDataUpdate(data);

    }, [customizationHierarchy, noLicenseWarning, handleTreeViewDataUpdate, t, includeNonLicensedModelsState, includeDeprecatedModelsState, includeUserHiddenModelsState, dispatch]);

    const handleTreeViewItemSelect = useCallback((item: TreeViewDataItem) => {

        // TODO: some of this code updates the 'defaultExpanded' prop directly into objects already in state
        // which is not optimal behaviour.

        // are we selecting or unselecting an item from the current active selection?
        const isSelecting = activeTreeViewItems.find(i => i.id === item.id) === undefined;

        // is this a leaf item? we have some special rules for those.
        const isLeafItem = item.children === undefined;

        // don't allow de-selecting leaf items
        if (!isSelecting && isLeafItem) {
            return;
        }

        // prepare existing tree view selections
        const branchItems: TreeViewDataItem[] = [];
        activeTreeViewItems.forEach(item => {
            if (item.children === undefined) {
                // immediately unselect any leaf items -- this fixes some visual glitches
                item.defaultExpanded = false;
            } else {
                // include non-leaf items in the next active selection
                branchItems.push(item);
            }
        });

        if (!isSelecting) {
            const activeTreeViewItemBranch: TreeViewDataItem[] = [];
            treeViewData.forEach(t => getActiveTreeItemsUnderTarget(t, item, activeTreeViewItemBranch));
            activeTreeViewItemBranch.forEach(i => i.defaultExpanded = false);

            setActiveTreeViewItems(branchItems.filter(i => !activeTreeViewItemBranch.find(branchItem => branchItem.id === i.id)));
        } else {
            const activeTreeViewItemBranch: TreeViewDataItem[] = [];
            treeViewData.forEach(t => getActiveTreeViewItemBranch(t, item, activeTreeViewItemBranch));
            activeTreeViewItemBranch.forEach(i => i.defaultExpanded = true);

            setActiveTreeViewItems(uniq(branchItems.concat(activeTreeViewItemBranch)));
        }

        // set latest focus
        setCurrentTreeViewFocus(isLeafItem && item.id ? item.id : '');

    }, [activeTreeViewItems, treeViewData, setCurrentTreeViewFocus]);

    const handleSelect = useCallback((_: unknown, item: TreeViewDataItem) => {
        if (item.id === undefined) { throw new Error('TreeView item has no ID'); }
        handleTreeViewItemSelect(item);
    }, [handleTreeViewItemSelect]);

    useEffect(() => {
        // change active tab when signaled by redux store (basically when duplicating or removing models)
        if (modelCustomizationPageFocusId !== undefined && modelCustomizationPageFocusId !== currentTreeViewFocus) {

            // see if we can change the actual selection yet, otherwise keep waiting for next updates
            const item = findItemFromHierarchy(treeViewData, modelCustomizationPageFocusId, true);
            if (item) {
                dispatch(modelCustomizationPageFocusCleared());
                setCurrentTreeViewFocus(modelCustomizationPageFocusId);
                handleTreeViewItemSelect(item);
            }
        }
    }, [dispatch, currentTreeViewFocus, modelCustomizationPageFocusId, treeViewData, handleTreeViewItemSelect, setCurrentTreeViewFocus]);

    // patternfly's TreeView will crash if we try to render a 0-sized data array, so don't render it
    // if we're still initializing data
    const isTreeViewDisplayed = treeViewData.length > 0;

    return (
        <DrawerPanelContent hasNoBorder defaultSize="300px">
            <DrawerPanelBody hasNoPadding>
                <div className="mv-compact-tabs unselectable">
                    <CustomizationFilters modelType={modelType} />
                    {isTreeViewDisplayed && (<TreeView
                        data={treeViewData}
                        onSelect={handleSelect}
                        // activeItems={[{ name: '', id: activeTreeViewId }]}
                        activeItems={activeTreeViewItems}
                        hasGuides
                        className="customization-tree-view"
                        useMemo
                    />)}
                </div>
            </DrawerPanelBody>
        </DrawerPanelContent>
    );
}

export default CustomizationSideBar;
