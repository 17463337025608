import React, { useState } from 'react';
import { Button, FileUpload, Alert, Modal, ModalVariant } from '@patternfly/react-core';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { configurationTargetSelectors } from '../../store/configurationTarget/configurationTargetSlice';
import { getUserName, MVisionAppClient } from '../../store/configurationTarget/mvision-client-list';

interface ConfigurationImportExportProps {
    modelTypeUiString: string,
    importExportErrorMessage: string | null,
    onExportModelCustomization: (configurationTarget: MVisionAppClient) => void,
    onImportUserData: (json: any) => Promise<boolean>,
}

const ConfigurationImportExport = (props: ConfigurationImportExportProps) => {

    const { modelTypeUiString, importExportErrorMessage, onExportModelCustomization, onImportUserData } = props;

    const { t } = useTranslation();

    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);

    const [isImportingCompletedTextVisible, setIsImportingCompletedTextVisible] = useState(false);
    const [showCustomizationImportFile, setShowCustomizationImportFile] = useState<File | undefined>(undefined);
    const [showConfirmFileImport, setShowConfirmFileImport] = useState(false);

    const handleImportFileChange = async (file: File) => {
        await handleImportUserData(file);
        setShowConfirmFileImport(false);
    };

    const handleConfirmImportFileChange = async (
        _: unknown, file: File
    ) => {
        setShowCustomizationImportFile(file);
        setShowConfirmFileImport(true);
    };

    const handleClear = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowCustomizationImportFile(undefined);
    };

    const handleExportUserData = async () => {
        if (!currentTarget) {
            return;
        }
        onExportModelCustomization(currentTarget);
    };

    const handleImportUserData = async (json: any) => {
        const success = await onImportUserData(json);
        setIsImportingCompletedTextVisible(success);
    };

    return (
        <>
            {isImportingCompletedTextVisible && (
                <div className="configuration-reset-completed-box">
                    <Alert variant="info" isInline title={t('settings.importConfiguration.success')} />
                </div>
            )}

            {importExportErrorMessage && (
                <div className="configuration-reset-completed-box">
                    <Alert
                        variant="danger"
                        isInline
                        title={importExportErrorMessage}
                    />
                </div>
            )}

            {currentTarget && (
                <>
                    <div className="setting-item">
                        <span className="label">{t('settings.importConfiguration')}</span>
                        <span className="value">
                            <FileUpload
                                id="daemon-config-file"
                                filename={showCustomizationImportFile?.name || ''}
                                filenamePlaceholder={t('settings.importConfiguration.file.instructions')}
                                title={t('settings.importConfiguration.file.instructions')}
                                onFileInputChange={handleConfirmImportFileChange}
                                onClearClick={handleClear}
                                browseButtonText={t('settings.importConfiguration.file.browse')}
                                clearButtonText={t('settings.importConfiguration.file.clear')}
                            />
                        </span>
                    </div>

                    <div className="setting-item">
                        <span className="label">{t('settings.exportConfiguration')}</span>
                        <span className="value">
                            <Button variant="secondary" onClick={handleExportUserData}>{t('settings.exportConfiguration.button')}</Button>
                        </span>
                    </div>
                </>
            )}

            <Modal
                variant={ModalVariant.small}
                title={t('settings.confirmImport.title')}
                isOpen={showConfirmFileImport && showCustomizationImportFile !== undefined}
                showClose
                onClose={() => setShowConfirmFileImport(false)}
                actions={[
                    <Button key="confirmImport" variant="primary" onClick={() => handleImportFileChange(showCustomizationImportFile!)}>{t('common.import')}</Button>,
                    <Button key="cancelImport" variant="tertiary" onClick={() => setShowConfirmFileImport(false)}>{t('common.cancel')}</Button>
                ]}
                className="import-confirmation-modal"
            >
                <div>
                    <Trans i18nKey="settings.confirmImport.message.paragraph1">
                        Importing the selected configuration file will OVERRIDE all existing <span className="configuration-target-model-type">{{ modelType: modelTypeUiString }}</span> configurations for the target account <span className="reset-emphasis configuration-target-name">{{ configurationTargetName: currentTarget ? getUserName(currentTarget) : '' }}</span>.
                    </Trans>
                </div >
                <div>
                    {t('settings.confirmImport.message.paragraph2')}
                </div>
            </Modal >
        </>
    );
};

export default ConfigurationImportExport;
